:root {
  --violet_color: #36255d;
  --yellow_color: #ffc700;
  --yello_color_two: #f6a530;
  --black_color: #000;
  --white_color: #fff;
  --light_gray_color: #fafafa;
  --dark_gray_color: #666666;
  --black_color_two: #0a0a0a;
  --black_color_three: #151414;
  --dark_gray_color: #1e1e1e; 
}


/* start of button css */

.ylw_btn {
  background-color: var(--yellow_color);
  color: var(--black_color);
  outline: none !important;
  border: 1px solid var(--yellow_color) !important;
  font-size: 15px;
  font-weight: 600;
  padding: 4px 35px;
  border-radius: 13px;
  transition: 0.3s;
}
.ylw_btn:hover {
  background-color: transparent;
  color: var(--yellow_color) !important;
  border: 1px solid var(--yellow_color) !important;
}
.lyt_pnts_crd_btn {
  background-color: var(--yellow_color);
  color: var(--black_color_three);
  outline: none !important;
  border: 1px solid var(--yellow_color) !important;
  font-size: 15px;
  padding: 2px 35px;
  border-radius: 30px;
  transition: 0.3s;
  width: 100%;
  font-weight: 600;
}
.lyt_pnts_crd_btn:hover {
  background-color: transparent;
  color: var(--yellow_color) !important;
  border: 1px solid var(--yellow_color) !important;
}
/* end of button css */

/* start of common css */
.dark_theme .lyt_inr_page {
  background-color: var(--dark_gray_color);
}
.cust_container {
  max-width: 90% !important;
}
.img_fit_cvr {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}
/* end of common css */

/* start of banner section */

.lyt_h3 {
  font-size: 25px;
  font-weight: 700;
  color: var(--black_color);
}
.dark_theme .lyt_h3 {
  color: var(--white_color);
}
.lyt_bnr_p {
  color: var(--black_color);
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
}
.dark_theme .lyt_bnr_p {
  color: var(--white_color);
}
.lyt_bnr_img_wrp {
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  height: 500px;
  min-height: 500px;
  max-height: 500px;
  border-radius: 25px;
  overflow: hidden;
}

/* end of banner section */

/* start of pdu points section */
.lyt_pdu_pnts_tle {
  font-size: 24px;
  font-weight: 800;
  color: var(--black_color);
  filter: drop-shadow(0px 2px 1.2px gray);
}
.dark_theme .lyt_pdu_pnts_tle {
  color: var(--white_color);
}
.lyt_pdu_pnts_tle span {
  color: var(--yello_color_two);
}
.lyt_pdu_card {
  background: var(--light_gray_color);
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.lyt_pdu_card:hover {
  transform: scale(98%);
}
.dark_theme .lyt_pdu_card {
  background: var(--black_color);
}
.lyt_pdu_card.active {
  background: var(--violet_color);
  border-radius: 10px;
}

.lyt_pdu_img_wrp {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  overflow: hidden;
  border-radius: 5px;
}
.lyt_pdu_img_wrp img {
  border-radius: 5px;
}
.lyt_pdu_crd_subtle {
  color: var(--dark_gray_color);
  font-size: 12px;
}
.dark_theme .lyt_pdu_crd_subtle {
  color: var(--white_color);
}
.lyt_pdu_card.active .lyt_pdu_crd_subtle {
  color: var(--white_color);
}
.lyt_pdu_crd_tle {
  color: var(--dark_gray_color);
  font-size: 14px;
  font-weight: 600;
}
.dark_theme .lyt_pdu_crd_tle {
  color: var(--white_color);
}
.dark_theme .lyt_pdu_crd_rat {
  color: var(--white_color);
}
.lyt_pdu_card.active .lyt_pdu_crd_tle {
  color: var(--white_color);
}
.lyt_pdu_crd_tle {
  color: var(--black_color);
  font-size: 14px;
  font-weight: 600;
}
.lyt_pdu_card.active .lyt_pdu_crd_tle {
  color: var(--white_color);
}
.lyt_pdu_crd_tle {
  color: var(--black_color);
  font-size: 14px;
  font-weight: 600;
}
.lyt_pdu_card.active .lyt_pdu_crd_tle {
  color: var(--white_color);
}
.lyt_pdu_star_wrp {
  border-right: 1px solid var(--dark_gray_color);
  height: fit-content;
  color: var(--dark_gray_color);
}
.lyt_pdu_card.active .lyt_pdu_star_wrp {
  border-right: 1px solid var(--white_color);
  color: var(--white_color);
}

.lyt_pdu_star_wrp img {
  width: 20px;
}
.lyt_pdu_tkn_dtls {
  color: var(--black_color_two);
  font-size: 9px;
  line-height: 1.3;
}
.lyt_pdu_card.active .lyt_pdu_tkn_dtls {
  color: var(--white_color);
  line-height: 1.3;
}
.lyt_pdu_dot_icn {
  color: var(--dark_gray_color);
  font-size: 25px;
  cursor: pointer;
}
.dark_theme .lyt_pdu_dot_icn {
  color: var(--white_color);
}
.lyt_pdu_card.active .lyt_pdu_dot_icn {
  color: var(--white_color);
  font-size: 25px;
  cursor: pointer;
}
.lyt_pdu_crd_rat {
  font-size: 10px;
}
.lyt_pdu_card p {
  line-height: 1.4;
  /* color: var(--black_color); */
}
.dark_theme .lyt_pdu_card p {
  color: var(--white_color);
}
.pdu_content_wraper {
  background-color: var(--violet_color);
  border-radius: 13px;
}
.lyt_pdu_rgt_title {
  color: var(--white_color);
  font-weight: bold;
}
.lyt_pdu_rgt_subtle {
  color: var(--white_color);
  font-size: 14px;
  line-height: 2;
}
.lyt_pnts_crds .card.sub_domaincard {
  background-color: var(--violet_color) !important;
}
.lyt_pnts_crds .lyt_pnts_crd_img_wrp {
  height: 270px !important;
  min-height: 270px !important;
  max-height: 270px !important;
  padding: 15px !important;
  padding-bottom: 0px !important
  ;
}
.lyt_pnts_crd_img {
  border-radius: 20px;
}
.lyt_pnts_crds .height_subdomaincard.card.sub_domaincard {
  min-height: unset;
  max-height: unset;
}
.lyt_pnts_num_bg {
  background-color: var(--white_color);
  width: 40px;
  height: 30px;
  border-radius: 50%;
}
.lyt_pnts_num_bg p {
  color: var(--black_color);
  font-weight: 800;
  font-size: 16px;
}

.lyt_cust_tabs .nav-item {
  font-weight: 900;
  color: var(--black_color);
  font-size: 18px;
  filter: drop-shadow(0px 1px 1.2px gray) !important;
}
.dark_theme .lyt_cust_tabs .nav-item {
  color: var(--white_color);
}
.lyt_cust_tabs .nav-pills .nav-item .nav-link.active {
  background-color: transparent !important;
  color: var(--violet_color) !important;
  text-decoration: underline !important;
  box-shadow: none !important;
}
.lyt_cust_tabs .nav-pills .nav-item .nav-link {
  color: var(--black_color);
  font-weight: 900;
  min-width: unset !important;
  padding: 0px 11px !important;
  border-left: 3px solid var(--black_color);
  border-radius: 0px !important;
  font-size: 18px;
}
.dark_theme .lyt_cust_tabs .nav-pills .nav-item .nav-link {
  color: var(--white_color);
  border-color: var(--white_color);
}
.lyt_pdu_crd_hds {
  font-size: 25px;
  color: var(--black_color);
  font-weight: 900;
}
.dark_theme .lyt_pdu_crd_hds {
  color: var(--white_color);
}
.lyt_cust_tabs .nav-pills .nav-item .nav-link:hover {
  background-color: transparent !important;
}
.lyt_pnts_crds.lyt_cust_tabs .nav.nav-pills {
  gap: 12px;
}
.lyt_pdu_h6 {
  color: var(--white_color) !important;
}
.lyt_pdu_crd_cls_icn {
  right: 10px;
  top: 10px;
}
.lyt_cust_tabs .subd_cardswipe.mySwiper .swiper-pagination-bullet {
  background: #c4c4c4 !important;
}
.lyt_cust_tabs .subd_cardswipe.mySwiper .swiper-pagination-bullet-active {
  background: var(--yellow_color) !important;
}
.lyt_pdu_card.active .lyt_pdu_card {
  color: var(--white_color);
}
.lyt_h3_two {
  color: var(--white_color);
  font-size: 25px;
  font-weight: 700;
}
/* end of pdu points section */

/* start of top achiever  */

.lyt_ach_card {
  background-color: var(--violet_color);
  border-radius: 15px;
  transition: 0.3s;
  cursor: pointer;
}
.lyt_ach_card:hover {
  transform: scale(98%);
}
.lyt_ach_crd_img_wrp {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  overflow: hidden;
  border-radius: 50%;
}
.lyt_ach_crd_img {
  border-radius: 50%;
}
.lyt_ach_crd_p_one {
  font-size: 12px;
  color: var(--white_color);
  font-weight: 600;
  line-height: 1.7;
}
.lyt_ach_crd_p_two {
  font-size: 11px;
  color: var(--white_color);
  font-weight: 500;
  line-height: 1.7;
}
.lyt_ach_crd_p_three {
  font-size: 11px;
  color: var(--white_color);
  font-weight: 500;
  line-height: 1.7;
}
.lyt_ach_dot_icn {
  color: var(--white_color);
  cursor: pointer;
}
.lyt_ach_hds {
  font-weight: 900;
  font-size: 19px;
  color: var(--black_color);
}
.dark_theme .lyt_ach_hds {
  color: var(--white_color);
}
/* end of top achiever  */

/* start of header */

.lyt_crd_img_wrp {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  overflow: hidden;
  border-radius: 4px;
}
.lyt_hds_tle {
  font-weight: 900;
  font-size: 25px;
  color: var(--black_color);
  filter: drop-shadow(0px 2px 1px gray);
}
.dark_theme .lyt_hds_tle {
  color: var(--white_color);
}
.lyt_hds_subtle {
  color: var(--black_color);
  font-size: 16px;
}
.dark_theme .lyt_hds_subtle {
  color: var(--white_color);
}
.lyt_header_logo {
  width: 180px;
}

.lyt_hds_srch {
  color: var(--black_color);
  font-size: 20px;
}
.lyt_hds_wlt {
  color: var(--black_color);
  font-size: 20px;
}
.lyt_hds_theme {
  color: var(--black_color);
  font-size: 20px;
  cursor: pointer;
}
.dark_theme .lyt_hds_theme {
  color: var(--white_color);
}
.lyt_img_wrp{
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  height: 140px;
  min-height: 140px;
  max-height: 140px;
  overflow: hidden;
}
.lyt_img_wrp img{
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}

/* end of header */

/*  media */

@media (min-width: 380px) {
  /* start of pdu section */
  .lyt_pdu_img_wrp {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    overflow: hidden;
    border-radius: 5px;
  }
  .lyt_pdu_crd_subtle {
    font-size: 16px;
  }
  .lyt_pdu_crd_tle {
    font-size: 18px;
    margin-top: 3px;
  }
  .lyt_pdu_dot_icn {
    font-size: 30px;
  }
  .lyt_pdu_star_wrp img {
    width: 23px;
  }
  .lyt_pdu_crd_rat {
    font-size: 13px;
    margin-left: 7px !important;
  }
  .lyt_pdu_tkn_dtls {
    font-size: 12px;
  }
  /* end of pdu section */

  /* start of top achiver */
  .lyt_ach_crd_p_one {
    font-size: 13px;
  }
  .lyt_ach_crd_p_two {
    font-size: 12px;
  }
  .lyt_ach_crd_p_three {
    font-size: 12px;
  }
  .lyt_ach_crd_img_wrp {
    width: 65px;
    min-width: 65px;
    max-width: 65px;
    height: 65px;
    min-height: 65px;
    max-height: 65px;
  }
  .lyt_ach_dot_icn {
    font-size: 20px;
  }
  /* end of top achiver */
}

@media (min-width: 576px) {
  /* start of banner section */
  .lyt_h3 {
    font-size: 30px;
  }

  .lyt_bnr_p {
    color: var(--black_color);
    font-size: 19px;
  }
  .lyt_bnr_img_wrp {
    width: 330px;
    min-width: 330px;
    max-width: 330px;
    height: 500px;
    min-height: 500px;
    max-height: 500px;
    border-radius: 25px;
    overflow: hidden;
  }
  /* end of banner section */

  /* start of pdu points */
  .lyt_h3_two {
    font-size: 30px;
  }
  .lyt_pdu_pnts_tle {
    font-size: 26px;
  }
  /* end of pdu points */
  /* start of top achiver */
  .lyt_ach_crd_p_one {
    font-size: 17px;
  }
  .lyt_ach_crd_p_two {
    font-size: 15px;
  }
  .lyt_ach_crd_p_three {
    font-size: 15px;
  }
  .lyt_ach_crd_img_wrp {
    width: 70px;
    min-width: 70px;
    max-width: 70px;
    height: 70px;
    min-height: 70px;
    max-height: 70px;
  }
  .lyt_ach_dot_icn {
    font-size: 30px;
  }
  .lyt_ach_hds {
    font-size: 21px;
  }
  /* end of top achiver */
}

@media (min-width: 768px) {
  /* start of top achiver */
  .lyt_ach_crd_p_one {
    font-size: 15px;
  }
  .lyt_ach_crd_p_two {
    font-size: 13px;
  }
  .lyt_ach_crd_p_three {
    font-size: 13px;
  }

  .lyt_ach_dot_icn {
    font-size: 20px;
  }
  .lyt_ach_hds {
    font-size: 23px;
  }
  /* end of top achiver */
}
@media (min-width: 992px) {
  /* start of banner section */

  .lyt_banner {
    background-color: var(--violet_color);
    border-radius: 20px;
    padding: 40px;
  }

  .lyt_h3 {
    font-size: 35px;
    color: var(--white_color);
  }
  .lyt_bnr_p {
    font-size: 19px;
    color: var(--white_color);
  }
  .lyt_bnr_img_wrp {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    height: 480px;
    min-height: 480px;
    max-height: 480px;
    border-radius: 25px;
    overflow: hidden;
  }
  /* end of banner section */

  /* start of pdu points */
  .lyt_h3_two {
    font-size: 35px;
  }
  .lyt_pnts_crds {
    max-height: 891px;
    overflow: scroll;
  }
  /* end of pdu points */
  /* start of top achiver */
  .lyt_ach_crd_p_one {
    font-size: 17px;
  }
  .lyt_ach_crd_p_two {
    font-size: 15px;
  }
  .lyt_ach_crd_p_three {
    font-size: 15px;
  }
  .lyt_ach_crd_img_wrp {
    width: 85px;
    min-width: 85px;
    max-width: 85px;
    height: 85px;
    min-height: 85px;
    max-height: 85px;
  }
  .lyt_ach_dot_icn {
    font-size: 30px;
  }
  .lyt_ach_hds {
    font-size: 26px;
  }
  /* end of top achiver */
}
@media (min-width: 1200px) {
  /* start of banner section */
  .lyt_banner {
    /* height: 90vh; */
    min-height: 90vh;
    /* max-height: 90vh; */
  }
  .lyt_banner {
    padding: 50px;
  }
  .lyt_pdu_rgt_subtle {
    font-size: 15px;
  }
  /* end of banner section */
  /* start of top achiver */
  .lyt_ach_crd_p_one {
    font-size: 18px;
  }
  .lyt_ach_crd_p_two {
    font-size: 16px;
  }
  .lyt_ach_crd_p_three {
    font-size: 16px;
  }
  .lyt_ach_crd_img_wrp {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
  }
  .lyt_ach_dot_icn {
    font-size: 30px;
  }
  /* end of top achiver */
  /* start of header */
  .lyt_hds_tle {
    font-weight: 900;
    font-size: 30px;
  }
  .lyt_hds_subtle {
    font-size: 20px;
  }
  .lyt_header_logo {
    width: 200px;
  }

  .lyt_hds_srch {
    font-size: 30px;
  }
  .lyt_hds_wlt {
    font-size: 30px;
  }
  .lyt_hds_theme {
    font-size: 30px;
  }
  /* end of header */

  /* start of pdu section */
  .lyt_pdu_crd_hds {
    font-size: 30px;
  }
  .lyt_pnts_crds {
    max-height: 930px;
    overflow: scroll;
  }
  /* end of pdu section */
}
@media (min-width: 1400px) {
  /* start of button css */
  .ylw_btn {
    font-size: 20px;
    padding: 10px 55px;
  }

  /* end of button css */
  /* start of banner section */

  .lyt_banner {
    padding: 80px;
  }
  .lyt_h3 {
    font-size: 45px;
  }
  .lyt_bnr_p {
    font-size: 23px;
  }
  .lyt_bnr_img_wrp {
    width: 380px;
    min-width: 380px;
    max-width: 380px;
    height: 530px;
    min-height: 530px;
    max-height: 530px;
    border-radius: 30px;
    overflow: hidden;
  }
  .lyt_pdu_rgt_subtle {
    font-size: 18px;
  }
  /* end of banner section */
  .col-xxl-1 {
    flex: 0 0 auto !important;
    width: 8.333333333333333% !important;
    max-width: 8.333333333333333% !important;
  }
  .col-xxl-2 {
    flex: 0 0 auto !important;
    width: 16.66666666666667% !important;
    max-width: 16.66666666666667% !important;
  }
  .col-xxl-3 {
    flex: 0 0 auto !important;
    width: 25% !important;
    max-width: 25% !important;
  }
  .col-xxl-4 {
    flex: 0 0 auto !important;
    width: 33.33333333333333% !important;
    max-width: 33.33333333333333% !important;
  }
  .col-xxl-5 {
    flex: 0 0 auto !important;
    width: 41.66666666666667% !important;
    max-width: 41.66666666666667% !important;
  }
  .col-xxl-6 {
    flex: 0 0 auto !important;
    width: 50% !important;
    max-width: 50% !important;
  }
  .col-xxl-7 {
    flex: 0 0 auto !important;
    width: 58.33333333333333% !important;
    max-width: 58.33333333333333% !important;
  }
  .col-xxl-8 {
    flex: 0 0 auto !important;
    width: 66.66666666666667% !important;
    max-width: 66.66666666666667% !important;
  }
  .col-xxl-9 {
    flex: 0 0 auto !important;
    width: 75% !important;
    max-width: 75% !important;
  }
  .col-xxl-10 {
    flex: 0 0 auto !important;
    width: 83.33333333333333% !important;
    max-width: 83.33333333333333% !important;
  }
  .col-xxl-11 {
    flex: 0 0 auto !important;
    width: 91.66666666666667% !important;
    max-width: 91.66666666666667% !important;
  }
  .col-xxl-12 {
    flex: 0 0 auto !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  /* start of pdu section */
  .lyt_h3_two {
    font-size: 45px;
  }
  .lyt_pdu_pnts_tle {
    font-size: 27px;
  }
  .lyt_pnts_crds {
    max-height: 918px;
    overflow: scroll;
  }
  /* end of pdu section */
  /* start of top achiver */
  .lyt_ach_crd_p_one {
    font-size: 16px;
  }
  .lyt_ach_crd_p_two {
    font-size: 14px;
  }
  .lyt_ach_crd_p_three {
    font-size: 14px;
  }
  .lyt_ach_crd_img_wrp {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
  }
  .lyt_ach_dot_icn {
    font-size: 20px;
  }
  .lyt_ach_hds {
    font-size: 35px;
  }
  /* end of top achiver */
  /* start of header */

  .lyt_crd_img_wrp {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
    overflow: hidden;
    border-radius: 4px;
  }
  .lyt_hds_tle {
    font-weight: 900;
    font-size: 43px;
  }
  .lyt_hds_subtle {
    font-size: 28px;
  }
  .lyt_header_logo {
    width: 230px;
  }

  .lyt_hds_srch {
    font-size: 30px;
  }
  .lyt_hds_wlt {
    font-size: 30px;
  }
  .lyt_hds_theme {
    font-size: 30px;
  }
  /* end of header */
}

@media (min-width: 1500px) {
  /* start of pdu points */
  .lyt_pnts_crds {
    max-height: 955px;
    overflow: scroll;
  }
  /* end of pdu points */
}

@media (min-width: 1920px) {
  .col-xxxl-1 {
    flex: 0 0 auto !important;
    width: 8.333333333333333% !important;
    max-width: 8.333333333333333% !important;
  }
  .col-xxxl-2 {
    flex: 0 0 auto !important;
    width: 16.66666666666667% !important;
    max-width: 16.66666666666667% !important;
  }
  .col-xxxl-3 {
    flex: 0 0 auto !important;
    width: 25% !important;
    max-width: 25% !important;
  }
  .col-xxxl-4 {
    flex: 0 0 auto !important;
    width: 33.33333333333333% !important;
    max-width: 33.33333333333333% !important;
  }
  .col-xxxl-5 {
    flex: 0 0 auto !important;
    width: 41.66666666666667% !important;
    max-width: 41.66666666666667% !important;
  }
  .col-xxxl-6 {
    flex: 0 0 auto !important;
    width: 50% !important;
    max-width: 50% !important;
  }
  .col-xxxl-7 {
    flex: 0 0 auto !important;
    width: 58.33333333333333% !important;
    max-width: 58.33333333333333% !important;
  }
  .col-xxxl-8 {
    flex: 0 0 auto !important;
    width: 66.66666666666667% !important;
    max-width: 66.66666666666667% !important;
  }
  .col-xxxl-9 {
    flex: 0 0 auto !important;
    width: 75% !important;
    max-width: 75% !important;
  }
  .col-xxxl-10 {
    flex: 0 0 auto !important;
    width: 83.33333333333333% !important;
    max-width: 83.33333333333333% !important;
  }
  .col-xxxl-11 {
    flex: 0 0 auto !important;
    width: 91.66666666666667% !important;
    max-width: 91.66666666666667% !important;
  }
  .col-xxxl-12 {
    flex: 0 0 auto !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  /* start of top achiver */
  .lyt_ach_crd_p_one {
    font-size: 23px;
  }
  .lyt_ach_crd_p_two {
    font-size: 20px;
  }
  .lyt_ach_crd_p_three {
    font-size: 20px;
  }
  .lyt_ach_crd_img_wrp {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    height: 120px;
    min-height: 120px;
    max-height: 120px;
  }
  .lyt_ach_dot_icn {
    font-size: 35px;
  }
  /* end of top achiver */

  /* start of pdu points */
  .lyt_pnts_crds {
    max-height: 955px;
    overflow: scroll;
  }
  /* end of pdu points */
}
