/* playfair font import */

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@font-face {
  font-family: "Netflix"; /*Can be any text*/
  src: local("Netflix"),
    url("../src/assets/font/NetflixSans-Bold.otf") format("otf"),
    url("../src/assets/font/NetflixSans-Light.otf") format("otf"),
    url("../src/assets/font/NetflixSans-Medium.otf") format("otf"),
    url("../src/assets/font/NetflixSans-Regular.otf") format("otf"),
    url("../src/assets/font/NetflixSans-Bold.woff2") format("otf"),
    url("../src/assets/font/NetflixSans-Light.woff2") format("otf"),
    url("../src/assets/font/NetflixSans-Medium.woff2") format("otf"),
    url("../src/assets/font/NetflixSans-Regular.woff2") format("otf");
}

body{
  padding-right: 0px !important;
}
.ql-container{
  height: 300px !important;
}
.ql-editor, .ql-toolbar.ql-snow{
  background: #fff !important;
  color: #000 !important;
}
.ql-editor p{
  color: #000 !important;
}
.dark_theme .modal-body .ql-editor p{
  color: #000 !important;
}
.modal-body .ql-editor p{
  color: #000 !important;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  top: 20% !important;
}
.ql-snow.ql-toolbar button svg {
  float: unset !important; 
}


/* hari */ 
.btn.dropdown_togle_align.dropdown_togle_align_graph{
  background-color: gray;
  border: 1px solid gray;
}
.lyt_hds_subtle.lyt_hds_subtles{
  font-size: 17px !important;
}
.voteprofimg{
  object-fit: contain !important;
}
.ww_d span{
  color: #fff;
}
.light_theme .ww_d span{
  color: #000;
}
.voteslide .rangeslider-horizontal{pointer-events: none;}
.voteslide .rangeslider-horizontal .rangeslider__fill{
  background-color: #e50914;
}
.voteslide .progress {
  height: 12px;
  border-radius: 20px;
}
.voteslide .progress-bar{
  background-color: #e50914;
}
.voterow{
  background: #000;
    border-radius: 8px;
    padding: 10px 10px;
    margin-bottom: 20px;
}
.light_theme .voterow{
  background: #f2f2f2;
    border: 1px solid #0405063b;
    box-shadow: 0 3px 10px rgb(0 0 0 / 7%);
}
.daoback{
  background-color: #000;
  border-radius: 6px;
  padding: 12px 14px;
}
.smallp{
  color: #fff;
  font-size: 13px;
}
.set_p,.alert_p{
  color: #fff;
}
.position{
  position: relative;
  z-index: 4;
}
.selectright{
  position: absolute !important;
  right: 0;
  top:-3px;
  width: 126px;
  min-width: 126px;
  height: 44px !important;
}
.selectright .react-select__control{
  min-height: 44px !important;
  height: 44px !important;
  background-color: transparent !important;
  color: #fff !important;
}
.selectright .react-select__single-value{
  color: #fff !important;
}
.light_theme .selectright .react-select__single-value{
  color: #000 !important;
}
.crypt .create-btn{
  color: #fff !important;
}
.banner_image{
  position: relative;
  z-index: 4;
  text-align: center;
}
.banner_image .banimg{
  height: 300px;
  min-height: 300px;
  object-fit: contain;
  border-radius: 6px;
  margin: auto;
}
.ww_d{
  width: 250px;
  min-width: 250px;
}
.wtclr{
  color: #000;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.profimg{
  height: 300px;
  min-height: 300px;
  width: 100%;
  object-fit: cover;
}
.CircularProgressbar .CircularProgressbar-path{
  stroke: green !important;
}
.objectimg1{
  width: 160px;
    height: 160px;
    object-fit: cover;
}
.pos_rel{
  position: relative;
  z-index: 2;
}
.pos_rel input{
  opacity: 0;
}
.pos_rel button{
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.react-select__menu{
  z-index: 7 !important;
}
.card_height{
  height: 300px;
  min-height: 300px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .walletpages .tabsec ul li .crypt button{
  
} */
.walletpages .tabsec ul li .crypt button{
  color: #f50011 !important;
    background: transparent !important;
    font-weight: 400 !important;
}
.walletpages .tabsec ul li button.create_btn:hover{
  background-color: #e50914 !important;
  color: #fff !important;
}
.arrowsvg{
  font-size: 19px;
}
.refreshicon{
  cursor: pointer;
}
.dark_theme .refreshicon svg{
  color: #fff;
}
.refreshicon svg{
  color: #000;
  font-size: 28px;
}
.vote_text{
  word-break: break-all;
}
.trained .dropdown-submenu {
  position: relative;
}

.trained .dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.trained .dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.trained .dropdown-submenu>a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}

.trained .dropdown-submenu:hover>a:after {
  border-left-color: #ffffff;
}

.trained .dropdown-submenu.pull-left {
  float: none;
}

.trained .dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.cubstart{
  margin-top: 20px;
  border-bottom: 1px solid transparent !important;
}


.tempflow{
  padding: 40px 0px;
  overflow: hidden;
}
.previewtext{
  font-size: 20px !important;
  font-family: 'Poppins', sans-serif !important;
}
.previewname{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
  /* font-size: 16px; */
  font-size: 12px;
}
.myname{
  font-size: 26px;
  font-weight: 800 !important;
}
.myname1{
  /* font-size: 19px !important; */
  font-size: 17px !important;
}
.light_theme .subemail{
  color: #101010 !important;
}
.blurcontents{
  background: #CDCDCD;
    width: 95%;
    max-width: 95%;
    min-width: 95%;
    margin: auto;
    margin-top: 6px;
    border-radius: 8px !important;
    margin-bottom: 6px;
}
.profileimges{
  margin-top: 12%;
}
.light_theme .blurcontents{
  background: #9e9e9e91;
}
.issue{
  /* font-size: 15px !important; */
  font-size: 11px !important;
  line-height: 10px;
}
.prevaddr{
  /* font-size: 13px !important; */
  font-size: 10px !important;
  line-height: 20px;
}
.light_theme .white-sub {
  color: #101010;
  font-size: 15px;
}
.logoimage{
  /* max-width: 90px;
  width: 100%;
  min-width: 90px;
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  background: black;
  padding: 14px 10px;
  border-radius: 6px; */

  max-width: 45px;
  width: 100%;
  min-width: 45px;
  height: 35px;
  min-height: 35px;
  max-height: 35px;
  background: black;
  padding: 7px 7px;
  border-radius: 6px;
}
.det{
  background-color: #ddd4c9 !important;
  border-radius: 14px;
  padding: 10px;
  text-align: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 3%);
}
.det-tit{
  font-size: 18px;
  font-weight: 500;
}
.blutick{
  max-width: 50px;
}
.lighttext{
  color: #101C1D !important;
  font-family: 'Montserrat', sans-serif !important;
  line-height: 18px !important;
  font-weight: 600 !important;
}
.lighttext{
  font-size: 10px !important;
}
.temptheme button{
  padding: 12px 12px !important;
  background-color: black !important;
  border-radius: 50%;
  box-shadow: unset !important;
}
.fadownlod{
  cursor: pointer;
  color: #fff;
}
.light_theme .fadownlod{
  color: #000 !important;
}
.previewcard{
  box-shadow: unset !important;
  border-radius: 10px !important;
  background: #D9D9D9 !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.light_theme .previewcard{
  /* background: #fff !important; */
   background: #D9D9D9 !important;
}
.previewcard .card-body{
  background: #D9D9D9 !important;
  border-radius: 6px !important;
  padding: 10px !important;
}
.light_theme .previewcard .card-body{
  /* background: #fff !important; */
  background: #D9D9D9 !important;
}
.subtit{
  color: #FFC700;
  font-size: 15px;
}
.white-sub{
  color: #fff;
  font-size: 15px;
}
.previewcard .card-img-top{
  border-radius: 8px 8px 0px 0px !important;
  /* height: 400px;
  max-height: 400px;
  min-height: 400px; */
  height: 320px;
  max-height: 320px;
  min-height: 320px;
  object-fit: cover;
}
.card.previewcard{
  width: 320px;
  min-width: 320px;
}
.width_sets{
  width: 320px;
    min-width: 320px;
    margin: auto;
}


@media only screen and (max-width: 767px) {
  .padsizes{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .container.template_contain{
    max-width: 97% !important;
}
}
@media only screen and (max-width: 575px) {
.padsizes{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.container.template_contain{
    max-width: 99% !important;
}

}
@media only screen and (min-width: 1200px) {
  .previewcard .card-img-top{
    /* height: 360px;
    max-height: 360px;
    min-height: 360px; */
    height: 320px;
    max-height: 320px;
    min-height: 320px;
  }
  .logoimage {
    /* max-width: 52px;
    min-width: 52px;
    height: 41px;
    min-height: 41px;
    max-height: 41px;
    padding: 8px 8px; */
    max-width: 45px;
    width: 100%;
    min-width: 45px;
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    background: black;
    padding: 7px 7px;
    border-radius: 6px;
}
.prevaddr {
  font-size: 10px !important;
  line-height: 18px;
}
.smalltext{
  font-size: 12px;
}
}
.eyess{
  font-size: 22px;
    margin-left: 10px !important;
    margin-top: -3px !important;
}
.confirmbtn{
  padding: 12px 12px !important;
  margin: 0 !important;
}
.design{
  color: #fff;
}
.allcheck .form-check-label{
  margin-left: 20px;
}
.allcheck label{
  margin-left: 20px;
  text-decoration: underline;
}
.tempflow .form-check-input{
  margin-left: 0px !important;
  margin-top: 8px !important;
}
.tempflow .allcheck .form-check-input {
  margin-top: 3px !important;
}

.light_theme .design{
  color: #000;
}
.light_theme .norecentact{
  color: #000000a8;
}
.browsebtn{
  position: relative;
}
.browsebtn .subd_clctnowbtn{
  margin-top: 0px !important;
  padding: 5px 0px !important;
}
.uploadss{
  position: absolute;
    width: 100%;
    opacity: 0;
    top: 0;
}
.allselectmenu{
  margin-top: -4px;
}
.uploadname{
  background-color: #fff;
    height: 38px;
    padding: 5px 10px;
    margin-bottom: 0px !important;
    color: #000;
    word-wrap: break-word;
    font-size: 14px;
}

.mobilemodal 
.tempflow label{
  color: #FFFEFE;
}
.light_theme .tempflow label{
  color: #000;
}
.tempflow .form-control{
  background-image: unset !important;
  background-color: #fff !important;
}
.tempflow .form-control{
  height: 44px !important;
}
.light_theme .tempflow .form-control{
  border: 1px solid #b4b4b48f !important;
  border-radius: 6px !important;
}
.react-datepicker-wrapper{
  width: 100%;
}
.react-datepicker__input-container input
{
  height: 44px !important;
  width: 100%;
  padding-left: 10px;
}
.tempflow .PhoneInputInput{
  height: 44px !important;
}
.light_theme .react-datepicker__input-container input{
  border: 1px solid #b4b4b48f !important;
  border-radius: 6px !important;
}
.tempflow .input-group-text{
  color: #fff;
}
.tempflow input::placeholder{
  color: #80808061;
}
.tempflow select{
  width: 100%;
  height: 36px;

  padding: 0px 10px;
  /* background-position-x: 320px !important; */
  background-size: 18px !important;
  background: url("./assets/images/lazymintingnew/Polygonss.png") no-repeat right #fff;
  background-origin: content-box;
  -webkit-appearance: none;
}
.light_theme .tempflow select {
  border: 1px solid #b4b4b48f !important;
  border-radius: 6px !important;
}
.light_theme input.PhoneInputInput {
  border: 1px solid #b4b4b48f !important;
  border-radius: 6px !important;
}
.light_theme .input-group-text{
  color: #000 !important;
}
.light_theme .filterlogo{
  filter: invert(1) !important;
}
.light_theme .uploadname {
  border: 1px solid #b4b4b48f !important;
  border-radius: 6px !important;
}
.allselectmenu button{
  width: 100%;
  text-align: left;
  padding: 6px 10px;
  background: white !important;
  color: black !important;
  font-size: 16px;
  border-radius: 2px;
  box-shadow: unset !important;
}
.allselectmenu.dropdown button{
  height: 42px !important;
    margin-top: 4px !important;
}
.allselectmenu button:hover{
   box-shadow: unset !important;
}
.allselectmenu .dropdown-menu .dropdown-item, .dropdown-menu li > a {
  padding-right: 0.8rem !important;
  padding-left: 0.8rem !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.allselectmenu .dropdown-menu .dropdown-item:hover {
background-color: #ffc700 !important;
color: #101010 !important;
}
.allselectmenu .dropdown-menu {
  background: #fff !important;
}
.allselectmenu a{
  color: black !important;
}
.allselectmenu .dropdown-toggle::after {
  border-top: 0.9em solid !important;
  float: right;
  margin-top: 4px;
}
.allselectmenu .dropdown-toggle::after, .dropup .dropdown-toggle::after {
  vertical-align: 0.255em;
  content: "";
  border-right: 0.6em solid transparent !important;
  border-left: 0.6em solid transparent !important;
}
.mobilefullimage{
  position: relative;
  z-index: 6;
}
.mobilelist,.prevv{
  display: none;
}
.ftitle{
  display: block !important;
}
.mobiledesign
{
  /* position: absolute; */
  bottom: 10px;
  padding: 0px 7px;
  color: #fff !important;
}
.heartcolor{
  color: red;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 18px;
}
.det-tit{
  font-size: 15px;
  font-size: 12px;
}
.tempflow label{
  color: #fff !important;
}
.tempflow .dataleft{
  margin-left: 20px;
}
.light_theme .tempflow label{
  color: #101010 !important;
}

@media only screen and (max-width: 1199px) and (min-width: 992px)  {
  .previewcard .col-3{
    padding-left: 5px !important;
    padding-right: 5px !important;
 }
 .logoimage {
  max-width: 45px;
  width: 100%;
  min-width: 45px;
  height:35px;
  min-height:35px;
  max-height:35px;
  background: black;
  padding: 7px 7px;
  border-radius: 6px;
}
.prevaddr {
  font-size: 10px !important;
  line-height: 18px;
}
.lighttext {
  font-size: 10px;
}
}

@media only screen and (max-width: 575px) {
  .det-tit{
    font-size: 12px;
  }
  .previewcard .col-3{
    padding-left: 5px !important;
    padding-right: 5px !important;
 }
  .mobilelist,.prevv{
    display: block;
  }
  .ftitle{
    display: none !important;
  }
  .previewname {
    font-size: 11px;
}
.myname{
  font-size: 20px;
  font-weight: 800 !important;
}
.myname1{
  font-size: 17px !important;
}
.mobilemodal .previewtext{
  color: black !important;
}
.mobilemodal .fadownlod{
  color: black !important;
}
.lighttext {
  font-size: 10px;
}
.issue {
  font-size: 10px !important;
  line-height: 10px;
}
.logoimage {
  max-width: 45px;
  width: 100%;
  min-width: 45px;
  height:35px;
  min-height:35px;
  max-height:35px;
  background: black;
  padding: 7px 7px;
  border-radius: 6px;
}
.prevaddr {
  font-size: 10px !important;
  line-height: 18px;
}
}

@media only screen and (max-width: 370px) {
  .prevaddr {
    font-size: 8px !important;
}
}

@media only screen and (max-width: 350px) {
  .ww_d {
    width: 230px;
    min-width: 230px;
  }
}

/* hari end */








body {
  font-family: "Netflix" !important;
  /* background-color: #141414 !important; */
}
.card {
  font-size: 0.875rem;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Netflix", sans-serif !important;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgb(0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px !important;
  text-align: left;
  background-color: #fff;
  color: #787878 !important;
  overflow-x: hidden;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.fw_700{
  font-weight: 700;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.tab_head {
  display: block;
}
.masonry {
  display: flex;
  align-items: center;
  justify-content: center;
}
.masonry .item {
  display: inline-block;
  padding: 5px;
  width: 100%;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 20px;
  height: 100%;
}
.card_inner_item {
  border-radius: 15px;
  padding: 0;
  background: #141414;
  /* margin: 8px; */
  border: 1px solid #ffffff42;
}
.vaultend{
  text-align: end;
}
.explroeapge .card_inner_item {
  /* max-height: 455px;
  min-height: 455px; */

  max-height:500px;
  min-height: 500px;
}
.search_inp_group ::placeholder {
  color: rgb(158, 158, 158);
}
.dropdown-card .item h2,
.masonry .item h2,
.navtabs .item h2 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin: 0 !important;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-card .item h3,
.masonry .item h3,
.navtabs .item h3 {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin: 0;
}
.dropdown-car .item h4,
.masonry .item h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  color: #fff;
}
.masonry_likes i {
  color: #e50914;
}
.masonry_likes span {
  font-size: 16px;
  color: #e50914;
  font-weight: 600;
}
.masonry .item .fa-ellipsis-h {
  color: #d2d2d2;
}
.multiple_image_gallery {
  display: flex;
  flex-wrap: wrap;
}
.multiple_image_gallery img {
  margin: 3px;
  width: 65px;
}
.masonry_tab {
  border-radius: 0;
  white-space: nowrap;
  display: inline-block;
  min-width: 100%;
}
.nav-tabs.masonry_tab .nav-link {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 4px 15px 15px !important;
  display: inline-block;
  border-bottom: 0;
  background-color: #000;
  border: 1px solid #000;
}

.nav-tabs.cubstart .nav-link{
  /* background-color: #e50914 !important;
  border: 1px solid #e50914 !important; */
  padding: 6px 16px !important;
  border-radius: 4px !important;
}
.cubstart .nav-link a{
  color: #fff !important;
}
.nav-tabs.masonry_tab .nav-link.active,
.items_tab.nav-tabs.masonry_tab .nav-link.active {
  background-color: transparent;
  border-bottom: 3px solid #e50914 !important;
  border-radius: 0 !important;
}
.cubstart #all-tab.active {
  padding-bottom: 6px !important;
  border: 1px solid #e50914 !important;
  background: #e50914 !important;
}
.cubstart #all-tab.active .tab_head {
   color: #fff !important;
}
.items_tab.nav-tabs.masonry_tab .nav-link:focus,
.items_tab.nav-tabs.masonry_tab .nav-link:hover,
.nav-tabs.masonry_tab .nav-link:focus,
.nav-tabs.masonry_tab .nav-link:hover {
  background-color: transparent;
  border-bottom: 3px solid #e50914 !important;
  padding-bottom: 15px !important;
}
.cubstart #all-tab:hover {
  padding-bottom: 6px !important;
}
.items_tab.nav-tabs.masonry_tab .nav-link {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  position: relative;
  background-color: transparent;
  border-radius: 0 !important;
}
.nav-tabs.cubstart .nav-link {
  border-radius: 6px !important;
}
.cubstart a#all-tab {
  border-radius: 4px !important;
  border: 1px solid #e50914 !important;
  background: transparent !important;
}
.cubstart a#all-tab:hover {
  border-radius: 4px !important;
  border: 1px solid #e50914 !important;
  background: #e50914 !important;
}
.cubstart a#all-tab .tab_head {
 font-size: 15px !important;
 color: #838383 !important;
}
.cubstart a#all-tab:hover .tab_head{
 color: #fff !important;
}
.explore_tab_content {
  margin-top: 1px;
}
.item_multiple {
  position: relative;
}
.items_tab_outer {
  padding-top: 15px;
}
.media_num.font_14 {
  font-size: 14px !important;
  line-height: 15px;
}
.fon_w_600 {
  font-weight: 600;
}
.btn.btn_purple_sm {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 3px 15px;
  transition: 0.2s;
  color: #fff;
  margin: 0 !important;
  text-transform: inherit !important;
}
.btn.btn_purple_sm:focus,
.btn.btn_purple_sm:hover {
  background-color: #fff !important;
  color: #e50914 !important;
  border: 1px solid #fff;
}
h1.banner-head {
  font-size: 30px;
  font-weight: 700;
  color: #030303;
  line-height: 40px;
}
h1.banner-head span {
  color: #ca113e;
}
p.banner-parg {
  font-size: 18px;
  color: #252526;
  margin-bottom: 22px;
  font-weight: 600;
}
.banner-r h3,
.banner-r p {
  color: #3f3e3e;
  line-height: 1;
}
.banner-r h3 {
  font-size: 37px;
  margin-bottom: 0;
}
.banner-r p {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
}
.arrival{
  color: #fff;
}
span.bnbcount {
  font-size: 48px !important;
  color: #3f3e3e !important;
  font-weight: 600;
}
.banner-r span {
  font-size: 18px;
  color: #3f3e3e;
  font-weight: 600;
}
.creators_details img {
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  border-radius: 50%;
}
.creators_details {
  padding-left: 5px;
}
.timeleft {
  color: #707a83;
  font-size: 12px !important;
  font-weight: 600;
}
.liveauction .item_inner_img .img-fluid {
  height: 223px;
}
.d-flex.creators_details img:nth-child(2) {
  position: absolute;
  right: 13px;
}
.d-flex.creators_details,
.remaintime {
  position: relative;
}
button.btn.bg-theme {
  background: #ad0a2d;
  border-color: #ad0a2d;
  color: #fff;
  border-radius: 7px;
}
.owl-carousel .owl-nav,
.owl-carousel .owl-nav.disabled {
  display: block !important;
}
.dropdown-card .dropdown-menu.show,
.liveauction .dropdown-menu.show {
  transform: translate3d(-83%, 33px, 0) !important;
}
.drop-down {
  background: 0 0;
  border: none;
}
.item_inner_img .img-fluid,
.navtabs .img-fluid {
  width: 100%;
}
.dropdown-card .item,
.navtabs .item {
  margin-bottom: 30px;
}
.navbar .dropdown-menu .dropdown-item a {
  margin-bottom: 5px;
}
header .create_btn {
  height: 45px !important;
}
@media (max-width: 575px) {

  [aria-label="three-dots-loading"]{
     margin-top: 20%;
  }
  .flex_mob_blck,
  .join_head {
    display: block !important;
  }
  #ticketscards .ticketsliste,
  .btn_icon_only span {
    display: none;
  }
  .dropdown .drop-down.itemsselect_dd_new {
    font-size: 11px !important;
  }
  .flex_mob_blck .create_btn {
    margin-top: 10px;
  }
  .create_btn.btn_icon_only.btn_rect {
    font-size: 16px !important;
    border-radius: 50% !important;
    padding: 5px 12px !important;
  }
  .btn_icon_only .fas {
    padding-right: 0 !important;
  }
  .join_head .btn_rect {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  #share_modal .icon_div p {
    font-size: 14px !important;
  }
  #share_modal .icon_div .fab {
    font-size: 25px !important;
  }
  .not_found_text_404 {
    font-size: 30px !important;
  }
  .bid_form label {
    font-size: 16px !important;
  }
}
.shrink .activtab.table td,
.shrink .activtab.table th {
  min-width: 100px;
  color: #fff;
}
@media (max-width: 991px) {
  .footerlogo,
  .joincommunity {
    margin-top: 30px !important;
    text-align: center !important;
  }
  .shrink .activtab.table td,
  .shrink .activtab.table th {
    min-width: 150px;
    color: #fff;
  }
  .yellowheight .price-container .bottom {
    word-break: break-all;
  }
  .item .cardItem img {
    width: 100%;
  }
  .masonry .item_inner_img img {
    height: 100%;
    object-fit: cover;
    width: 100% !important;
    max-height: 100%;
    min-height: 295px;
    min-width: 100%;
    max-width: 100%;
  }
  .d-grid.itemsselect {
    display: grid;
    grid-template-columns: 40% 30% 30%;
  }
  .bor_top_blue {
    margin-top: 0 !important;
    border-left: none !important;
    padding-bottom: 0px !important;
  }
  .info_title,
  .section-head,
  .section-head-exp {
    font-size: 20px;
  }
  .sec_he_sm {
    font-size: 18px !important;
  }
  .items_tab .nav-link .tab_head {
    font-size: 14px !important;
    font-weight: 400;
  }
  .media_num,
  .media_text {
    font-size: 12px;
  }
  h1.banner-head {
    font-size: 38px;
  }
  .banner-r h3 {
    font-size: 30px;
  }
  span.bnbcount {
    font-size: 25px !important;
  }
  .btn_div_info .create_btn {
    height: 48px !important;
  }
  .footerhead h2 {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
  
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
.joincommunity {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: self-end;
}
@media (min-width: 1280px) {
  .pl-cust-user {
    padding-left: 1.5rem !important;
  }
  .ml-cust-search {
    margin-left: 3rem !important;
  }
  .search_inp {
    width: 300px !important;
  }
  .searchmneu_dd {
    right: 0 !important;
    min-width: 300px !important;
  }
  .icon_txt {
    display: none !important;
  }
}

@media (max-width:959px){
  .svg_lft_align svg{
    left: -9px;
  }
  .tempflow .svg_lft_align svg {
    left: 0px;
}
  .allthemes   .svg_lft_align svg{
    left: 0px;
  }

}
@media (min-width: 1200px) {
  .col-xl-3.col-xl-viral {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid;
  color: #fff;
}
.table tbody + tbody {
  border-top: 2px solid rgba(0, 0, 0, 0.06);
}
.table .table {
  background-color: #fafafa;
}
.table-sm td,
.table-sm th {
  padding: 0.3rem;
}
.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.table .thead-dark th,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #32383e;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-active,
.table-active > td,
.table-active > th,
.table-hover .table-active:hover,
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th,
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #e3c3e9;
}
.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #dab0e2;
}
.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d6d8db;
}
.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.table-success,
.table-success > td,
.table-success > th {
  background-color: #cde9ce;
}
.table-hover .table-success:hover,
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #bbe1bd;
}
.table-info,
.table-info > td,
.table-info > th {
  background-color: #b8ecf3;
}
.table-hover .table-info:hover,
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a2e6ef;
}
.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffe2b8;
}
.table-hover .table-warning:hover,
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffd89f;
}
.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #fccac7;
}
.table-hover .table-danger:hover,
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fbb3af;
}
.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}
.table-hover .table-light:hover,
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6c8ca;
}
.table .thead-dark th,
.table-dark {
  color: #fafafa;
  background-color: #212529;
}
.table-hover .table-dark:hover,
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: rgba(0, 0, 0, 0.06);
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .primary_btn,
  button.primary_btn {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .homebanerheight h2 {
    margin: auto;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.4375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border: 1px solid #d2d2d2;
  border-radius: 0;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
  border-color: #d176e1;
  outline: 0;
  box-shadow: none, 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
  color: #f0f8ff !important;
}
select.form-control:not([size]):not([multiple]) {
  height: calc(2.4375rem + 2px);
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.4375rem + 1px);
  padding-bottom: calc(0.4375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm,
.input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-append > .form-control-plaintext.btn,
.input-group-lg
  > .input-group-append
  > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg
  > .input-group-prepend
  > .form-control-plaintext.input-group-text,
.input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-append > .form-control-plaintext.btn,
.input-group-sm
  > .input-group-append
  > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm
  > .input-group-prepend
  > .form-control-plaintext.input-group-text {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-sm
  > .input-group-append
  > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm
  > .input-group-prepend
  > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
select.form-control-sm:not([size]):not([multiple]) {
  height: calc(2.125rem + 2px);
}
.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5625rem 0;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-lg
  > .input-group-append
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg
  > .input-group-prepend
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(4.125rem + 2px);
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.lightcheck{text-align: end;
background: unset !important;
}
.lightcheck form{
  margin-bottom: 5px;
}
.lightcheck input[type='checkbox']:checked {
  background-color: red;
}
.lightcheck input[type='checkbox']:checked:after {
  content: '\2713';
  color:white;
}
/* .detaillogo{
  height: 100% !important;
  object-fit: contain !important;
  border: 1px solid #ffffff4a;
  width: 100%;
  border-radius: 5px;
  max-height: 245px !important;
  min-height: 245px !important;
  min-width: 220px !important;
} */

.lightcheck input[type='checkbox']{
  text-align: center;
  /* display: table-cell; */
  vertical-align: middle;
  width: 24px !important;
  height: 26px !important;
  appearance:none;
  border-radius:10%;
  border: 1px solid rgb(191 35 42 / 90%);
  box-shadow: none;
  font-size: 18px;
}
.lightcheck .form-check-input {
  position: unset;
  margin-left: 12px;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.invalid-tooltip,
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  line-height: 1;
  border-radius: 0.2rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4caf50;
}
.valid-tooltip {
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(76, 175, 80, 0.8);
}
.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #4caf50;
}
.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
  display: block;
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #4caf50;
}
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #4caf50;
}
.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  background-color: #a3d7a5;
}
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  background-color: #6ec071;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #4caf50;
}
.custom-file-input.is-valid ~ .custom-file-label::before,
.was-validated .custom-file-input:valid ~ .custom-file-label::before {
  border-color: inherit;
}
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f44336;
}
.invalid-tooltip {
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(244, 67, 54, 0.8);
}
.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: #f44336;
}
.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block;
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #f44336;
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #f44336;
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background-color: #fbb4af;
}
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before {
  background-color: #f77066;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #f44336;
}
.custom-file-input.is-invalid ~ .custom-file-label::before,
.was-validated .custom-file-input:invalid ~ .custom-file-label::before {
  border-color: inherit;
}
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn,
.btn-link {
  font-weight: 400;
}
.btn-block,
input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}
.btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.46875rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-block,
.collapse.show {
  display: block;
}
.btn:focus,
.btn:hover {
  text-decoration: none;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  background-image: none;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25), none;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-info,
.btn-primary {
  color: #fff;
  background-color: #e50914;
  border-color: #e50914;
  box-shadow: none;
}
.btn-primary:hover {
  color: #fff;
  background-color: #802091;
  border-color: #771e86;
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-info.disabled,
.btn-info:disabled,
.btn-outline-info:hover,
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-primary.disabled,
.btn-primary:disabled,
.show > .btn-outline-info.dropdown-toggle,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #e50914;
  border-color: #e50914;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #771e86;
  border-color: #6e1b7c;
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: none;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary:hover,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-secondary.disabled,
.btn-secondary:disabled,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: none;
}
.btn-success:hover {
  color: #fff;
  background-color: #409444;
  border-color: #3d8b40;
}
.btn-success.focus,
.btn-success:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-outline-success:hover,
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-success.disabled,
.btn-success:disabled,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3d8b40;
  border-color: #39833c;
}
.btn-info:hover {
  color: #fff;
  background-color: #009aae;
  border-color: #008fa1;
}
.btn-info.focus,
.btn-info:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #008fa1;
  border-color: #008394;
}
.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: none;
}
.btn-warning:hover {
  color: #fff;
  background-color: #d98100;
  border-color: #cc7a00;
}
.btn-warning.focus,
.btn-warning:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-outline-warning:hover,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-warning.disabled,
.btn-warning:disabled,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #cc7a00;
  border-color: #bf7200;
}
.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: none;
}
.btn-danger:hover {
  color: #fff;
  background-color: #f22112;
  border-color: #ea1c0d;
}
.btn-danger.focus,
.btn-danger:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled,
.btn-outline-danger:hover,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea1c0d;
  border-color: #de1b0c;
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: none;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light.focus,
.btn-light:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-light.disabled,
.btn-light:disabled,
.btn-outline-light:hover,
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: none;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark.focus,
.btn-dark:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled,
.btn-outline-dark:hover,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-outline-info,
.btn-outline-info.disabled,
.btn-outline-info:disabled,
.btn-outline-primary,
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #e50914;
  background-color: transparent;
}
.btn-link.focus,
.btn-link:focus,
.btn-link:hover {
  text-decoration: underline;
  border-color: transparent;
}
.btn-outline-info,
.btn-outline-primary {
  background-image: none;
  border-color: #e50914;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-success,
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #4caf50;
  background-color: transparent;
}
.btn-outline-success {
  background-image: none;
  border-color: #4caf50;
}
.btn-outline-success.focus,
.btn-outline-success:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-outline-info.focus,
.btn-outline-info:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800;
}
.btn-outline-warning.focus,
.btn-outline-warning:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ff9800;
  background-color: transparent;
}
.btn-outline-danger,
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #f44336;
  background-color: transparent;
}
.btn-outline-danger {
  background-image: none;
  border-color: #f44336;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}
.btn-outline-light.focus,
.btn-outline-light:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-dark,
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark {
  background-image: none;
  border-color: #343a40;
}
.btn-outline-dark.focus,
.btn-outline-dark:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  color: #e50914;
  background-color: transparent;
}
.btn-link:hover {
  color: #641971;
  background-color: transparent;
}
.btn-link.focus,
.btn-link:focus {
  box-shadow: none;
}
.btn-link.disabled,
.btn-link:disabled {
  color: #999;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 1.125rem 2.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.40625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.show {
  opacity: 1;
}
.collapse {
  display: none;
}
tr.collapse.show {
  display: table-row;
}
tbody.collapse.show {
  display: table-row-group;
}
.collapsing,
.dropdown,
.dropup {
  position: relative;
}
.dropdown-toggle::after,
.dropup .dropdown-toggle::after {
  width: 0;
  height: 0;
  vertical-align: 0.255em;
  content: "";
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  display: inline-block;
}
.dropdown-header,
.dropdown-item {
  white-space: nowrap;
  display: block;
}
.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s;
}
.dropdown-toggle::after {
  margin-left: 0.255em;
  border-top: 0.3em solid;
  border-bottom: 0;
}
.dropdown-toggle:empty::after,
.dropleft .dropdown-toggle:empty::after,
.dropright .dropdown-toggle:empty::after,
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #fff !important;
  text-align: left;
  list-style: none;
  background-color: #000000cb;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  margin-left: 0.255em;
  border-top: 0;
  border-bottom: 0.3em solid;
}
.dropleft .dropdown-toggle::before,
.dropright .dropdown-toggle::after {
  width: 0;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  height: 0;
}
.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  border-left: 0.3em solid;
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  border-right: 0.3em solid;
  vertical-align: 0;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  width: 100%;
  padding: 0.625rem 1.25rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #e50914;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  padding: 0.5rem 1.25rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 0 1 auto;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn:first-child,
.dropdown-toggle-split::after {
  margin-left: 0;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.btn-group.show .dropdown-toggle,
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .custom-file:focus,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3;
}
.input-group-append,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text,
.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control {
  margin-left: -1px;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::before,
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child),
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label::before,
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child),
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group-append,
.input-group-prepend {
  display: flex;
}
.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.4375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
  margin-top: 0;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #e50914;
  box-shadow: none;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #dfa0ea;
  box-shadow: none;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  margin-bottom: 0;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #dee2e6;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e50914;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: #e50914;
  box-shadow: none;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(156, 39, 176, 0.5);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(156, 39, 176, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e50914;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(156, 39, 176, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.4375rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    right 0.75rem center/8px 10px no-repeat #fff;
  border: 1px solid #d2d2d2;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #d176e1;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
    0 0 5px rgba(209, 118, 225, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}
.custom-select-sm {
  height: calc(2.125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}
.custom-select-lg {
  height: calc(4.125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}
.custom-file,
.custom-file-input,
.custom-file-label {
  height: calc(2.4375rem + 2px);
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
}
.custom-file-label,
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.46875rem 1rem;
  line-height: 1.3;
  color: #495057;
  background-color: transparent;
}
.custom-file-input:focus ~ .custom-file-control {
  border-color: #d176e1;
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.custom-file-input:focus ~ .custom-file-control::before {
  border-color: #d176e1;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-label {
  left: 0;
  z-index: 1;
  border: 0 solid #d2d2d2;
  border-radius: 0;
  box-shadow: none;
}
.custom-file-label::after {
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(2.4375rem + 2px) - 0 * 2;
  content: "Browse";
  border-left: 0 solid #d2d2d2;
  border-radius: 0;
}
.nav,
.navbar {
  flex-wrap: wrap;
}
.nav,
.navbar-nav {
  margin-bottom: 0;
  list-style: none;
}
.nav {
  display: flex;
  padding-left: 0;
}
.nav-link,
.navbar {
  padding: 0.5rem 1rem;
}
.nav-link {
  display: block;
}
.nav-link:focus,
.nav-link:hover,
.navbar-brand:focus,
.navbar-brand:hover,
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fafafa;
  border-color: #dee2e6 #dee2e6 #fafafa;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #e50914;
}
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: center center/100% 100% no-repeat;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
.card-link:hover,
.social_links_header {
  text-decoration: none;
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}
.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover,
.social_links_header:hover,
a.mobile_menu {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.social_list {
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-content: center;
}
.social_list li {
  width: auto;
}
.social_links_header {
  background-image: linear-gradient(to right, #fe1301 0, #ff7713 100%);
  border-radius: 40px !important;
  box-sizing: border-box;
  color: #7d8082 !important;
  display: block;
  height: 45px;
  letter-spacing: 1px;
  margin: 0 auto;
  padding: 3px !important;
  width: 45px;
  z-index: 2;
  box-shadow: 0 0 1px 5px rgb(47 51 54 / 88%);
  font-size: 16px !important;
}
.card-header-pills,
.card-header-tabs {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.social_links_header i {
  align-items: center;
  background-image: linear-gradient(#2b2a2c, #4a5157);
  border-radius: 40px;
  display: flex;
  justify-content: center;
  height: 100%;
  transition: background 0.5s;
  width: 100%;
  text-shadow: 4px 6px 5px rgb(0 0 0 / 71%);
}
.social_links_header:hover i {
  background: 0 0;
  color: #fff;
}
a.mobile_menu svg {
  vertical-align: middle;
  margin-right: 8px;
}
a.mobile_menu:hover {
  color: #ff7800;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-header,
.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}
.card-subtitle {
  margin-top: -0.375rem;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.newclass .filtersec button.create_btn {
  padding: 10px 20px !important;
  border-radius: 5px;
}
.card-header {
  padding: 0.75rem 1.25rem;
  background-color: #141414;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-bottom: -0.75rem;
  border-bottom: 0;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-header,
  .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-footer,
  .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-header,
  .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-footer,
  .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-header,
  .card-group > .card:only-child .card-img-top {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-footer,
  .card-group > .card:only-child .card-img-bottom {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child),
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-footer,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-header,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-bottom,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-top {
    border-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #e50914;
  background-color: transparent;
  border: 0 solid #dee2e6;
}
.page-link:hover {
  color: #641971;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #e50914;
  border-color: #e50914;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 0;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 500;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #51145c;
  background-color: #ebd4ef;
  border-color: #e3c3e9;
}
.alert-primary hr {
  border-top-color: #dab0e2;
}
.alert-primary .alert-link {
  color: #2c0b32;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #285b2a;
  background-color: #dbefdc;
  border-color: #cde9ce;
}
.alert-success hr {
  border-top-color: #bbe1bd;
}
.alert-success .alert-link {
  color: #18381a;
}
.alert-info {
  color: #00626e;
  background-color: #ccf2f6;
  border-color: #b8ecf3;
}
.alert-info hr {
  border-top-color: #a2e6ef;
}
.alert-info .alert-link {
  color: #00353b;
}
.alert-warning {
  color: #854f00;
  background-color: #ffeacc;
  border-color: #ffe2b8;
}
.alert-warning hr {
  border-top-color: #ffd89f;
}
.alert-warning .alert-link {
  color: #523100;
}
.alert-danger {
  color: #7f231c;
  background-color: #fdd9d7;
  border-color: #fccac7;
}
.alert-danger hr {
  border-top-color: #fbb3af;
}
.alert-danger .alert-link {
  color: #551713;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
.badge,
.close {
  font-weight: 500;
  line-height: 1;
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: inherit;
  border: 0 solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-item:focus,
.list-group-item:hover {
  z-index: 1;
  text-decoration: none;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  background-color: inherit;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #e50914;
  border-color: #e50914;
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}
.list-group-item-primary {
  color: #51145c;
  background-color: #e3c3e9;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #51145c;
  background-color: #dab0e2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #51145c;
  border-color: #51145c;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #285b2a;
  background-color: #cde9ce;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #285b2a;
  background-color: #bbe1bd;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #285b2a;
  border-color: #285b2a;
}
.list-group-item-info {
  color: #00626e;
  background-color: #b8ecf3;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #00626e;
  background-color: #a2e6ef;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #00626e;
  border-color: #00626e;
}
.list-group-item-warning {
  color: #854f00;
  background-color: #ffe2b8;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #854f00;
  background-color: #ffd89f;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #854f00;
  border-color: #854f00;
}
.list-group-item-danger {
  color: #7f231c;
  background-color: #fccac7;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #7f231c;
  background-color: #fbb3af;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7f231c;
  border-color: #7f231c;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  --webkit-appearance: none;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-info,
.badge-primary {
  color: #fff;
  background-color: #e50914;
}
.badge-primary[href]:focus,
.badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #771e86;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}
.badge-success {
  color: #fff;
  background-color: #4caf50;
}
.badge-success[href]:focus,
.badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #3d8b40;
}
.badge-info[href]:focus,
.badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #008fa1;
}
.badge-warning {
  color: #212529;
  background-color: #ff9800;
}
.badge-warning[href]:focus,
.badge-warning[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #cc7a00;
}
.badge-danger {
  color: #fff;
  background-color: #f44336;
}
.badge-danger[href]:focus,
.badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #ea1c0d;
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.badge-light[href]:focus,
.badge-light[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge-dark[href]:focus,
.badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.cursor{
  cursor:pointer;
}
.set_icon{
  font-size: 30px;
}
.modal,
.modal-open {
  /* overflow: hidden; */
  overflow: auto;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}
/* .modal-backdrop.fade{opacity:0} */
.modal-backdrop{z-index:8 !important;}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: rgba(97, 97, 97, 0.9);
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: rgba(97, 97, 97, 0.9);
  border-radius: 0.25rem;
}
.popover,
.popover .arrow {
  position: absolute;
  display: block;
}
.popover {
  top: 0;
  left: 0;
  z-index: 1060;
  max-width: 276px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}
.popover .arrow {
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^="top"] .arrow,
.bs-popover-top .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^="top"] .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-top .arrow::before {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="top"] .arrow::after,
.bs-popover-top .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}
.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^="right"] .arrow,
.bs-popover-right .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="right"] .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-right .arrow::before {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="right"] .arrow::after,
.bs-popover-right .arrow::after {
  left: 1px;
  border-right-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] .arrow,
.bs-popover-bottom .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-bottom .arrow::before {
  border-width: 0 0.5rem 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.bs-popover-bottom .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^="left"] .arrow,
.bs-popover-left .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="left"] .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-left .arrow::before {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="left"] .arrow::after,
.bs-popover-left .arrow::after {
  right: 1px;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-info,
.bg-primary {
  background-color: #e50914 !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #771e86 !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #4caf50 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #3d8b40 !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #008fa1 !important;
}
.bg-warning {
  background-color: #ff9800 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #cc7a00 !important;
}
.bg-danger {
  background-color: #f44336 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #ea1c0d !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.rounded-right,
.rounded-top {
  border-top-right-radius: 0.25rem !important;
}
.rounded-bottom,
.rounded-right {
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-left,
.rounded-top {
  border-top-left-radius: 0.25rem !important;
}
.rounded-bottom,
.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-info,
.border-primary {
  border-color: #e50914 !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #4caf50 !important;
}
.border-warning {
  border-color: #ff9800 !important;
}
.border-danger {
  border-color: #f44336 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.fixed-bottom,
.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-top {
  top: 0;
}
.fixed-bottom {
  bottom: 0;
}
@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
/* .bootstrap-datetimepicker-widget .btn[data-action=clear]::after,.bootstrap-datetimepicker-widget .btn[data-action=decrementHours]::after,.bootstrap-datetimepicker-widget .btn[data-action=decrementMinutes]::after,.bootstrap-datetimepicker-widget .btn[data-action=incrementHours]::after,.bootstrap-datetimepicker-widget .btn[data-action=incrementMinutes]::after,.bootstrap-datetimepicker-widget .btn[data-action=showHours]::after,.bootstrap-datetimepicker-widget .btn[data-action=showMinutes]::after,.bootstrap-datetimepicker-widget .btn[data-action=today]::after,.bootstrap-datetimepicker-widget .btn[data-action=togglePeriod]::after,.bootstrap-datetimepicker-widget .picker-switch::after,.bootstrap-datetimepicker-widget table th.next::after,.bootstrap-datetimepicker-widget table th.prev::after,.sr-only{position:absolute;width:1px;height:1px;padding:0;overflow:hidden;clip:rect(0,0,0,0);white-space:nowrap;clip-path:inset(50%);border:0} */
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.bmd-layout-backdrop,
.bmd-layout-content,
.bmd-layout-header {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 500 !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-info,
.text-primary {
  color: #e50914 !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #771e86 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #545b62 !important;
}
.text-success {
  color: #4caf50 !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #3d8b40 !important;
}
a.text-info:focus,
a.text-info:hover {
  color: #008fa1 !important;
}
.text-warning {
  color: #ff9800 !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #cc7a00 !important;
}
.text-danger {
  color: #f44336 !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #ea1c0d !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:focus,
a.text-light:hover {
  color: #dae0e5 !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:focus,
a.text-dark:hover {
  color: #1d2124 !important;
}
.bmd-help,
.text-muted {
  color: #464243 !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
a:focus,
button:focus {
  outline: 0;
}
.bmd-layout-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}
.bmd-layout-container,
.bmd-layout-content {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.bmd-layout-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.bmd-layout-header {
  z-index: 3;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: flex-start;
  width: 100%;
  max-height: 1000px;
  transform: translateZ(0);
}
.bmd-layout-content {
  z-index: 1;
  display: inline-block;
  flex-grow: 1;
}
.bmd-layout-spacer {
  flex-grow: 1;
}
.bmd-layout-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: transparent;
  transition-property: background-color;
}
@supports (pointer-events: auto) {
  .bmd-layout-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition-property: opacity;
    visibility: visible;
    pointer-events: none;
  }
}
.btn {
  position: relative;
  padding: 12px 30px;
  margin: 0.3125rem 1px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.428571;
  text-decoration: none;
  letter-spacing: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: 0.2rem;
  outline: 0;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow, transform;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:active:focus.focus,
.btn:active:focus:focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}
.btn.btn-primary {
  color: #fff;
  background-color: #e50914;
  border-color: #e50914;
  box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14),
    0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
}
.btn.btn-primary.focus,
.btn.btn-primary:focus,
.btn.btn-primary:hover {
  color: #fff;
  background-color: #9124a3;
  border-color: #701c7e;
}
.btn.btn-primary.active,
.btn.btn-primary:active,
.open > .btn.btn-primary.dropdown-toggle,
.show > .btn.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #9124a3;
  border-color: #701c7e;
  box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14),
    0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
}
.btn.btn-primary.active.focus,
.btn.btn-primary.active:focus,
.btn.btn-primary.active:hover,
.btn.btn-primary:active.focus,
.btn.btn-primary:active:focus,
.btn.btn-primary:active:hover,
.open > .btn.btn-primary.dropdown-toggle.focus,
.open > .btn.btn-primary.dropdown-toggle:focus,
.open > .btn.btn-primary.dropdown-toggle:hover,
.show > .btn.btn-primary.dropdown-toggle.focus,
.show > .btn.btn-primary.dropdown-toggle:focus,
.show > .btn.btn-primary.dropdown-toggle:hover {
  color: #fff;
  background-color: #9124a3;
  border-color: #3f1048;
}
.open > .btn.btn-primary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #e50914;
}
.open > .btn.btn-primary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #9124a3;
}
.btn.btn-primary.disabled.focus,
.btn.btn-primary.disabled:focus,
.btn.btn-primary:disabled.focus,
.btn.btn-primary:disabled:focus {
  background-color: #e50914;
  border-color: #e50914;
}
.btn.btn-primary.disabled:hover,
.btn.btn-primary:disabled:hover {
  background-color: #e50914;
  border-color: #e50914;
}
.btn.btn-primary.btn-link,
.btn.btn-primary.btn-link:active,
.btn.btn-primary.btn-link:focus,
.btn.btn-primary.btn-link:hover {
  background-color: transparent;
  color: #e50914;
}
.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:hover {
  box-shadow: 0 14px 26px -12px rgba(156, 39, 176, 0.42),
    0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2);
}
.btn.btn-primary.btn-link {
  box-shadow: none;
}
.btn.btn-secondary {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fafafa;
  border-color: #ccc;
  box-shadow: 0 2px 2px 0 rgba(250, 250, 250, 0.14),
    0 3px 1px -2px rgba(250, 250, 250, 0.2),
    0 1px 5px 0 rgba(250, 250, 250, 0.12);
}
.btn.btn-secondary.focus,
.btn.btn-secondary:focus,
.btn.btn-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #adadad;
}
.btn.btn-secondary.active,
.btn.btn-secondary:active,
.open > .btn.btn-secondary.dropdown-toggle,
.show > .btn.btn-secondary.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #adadad;
  box-shadow: 0 2px 2px 0 rgba(250, 250, 250, 0.14),
    0 3px 1px -2px rgba(250, 250, 250, 0.2),
    0 1px 5px 0 rgba(250, 250, 250, 0.12);
}
.btn.btn-secondary.active.focus,
.btn.btn-secondary.active:focus,
.btn.btn-secondary.active:hover,
.btn.btn-secondary:active.focus,
.btn.btn-secondary:active:focus,
.btn.btn-secondary:active:hover,
.open > .btn.btn-secondary.dropdown-toggle.focus,
.open > .btn.btn-secondary.dropdown-toggle:focus,
.open > .btn.btn-secondary.dropdown-toggle:hover,
.show > .btn.btn-secondary.dropdown-toggle.focus,
.show > .btn.btn-secondary.dropdown-toggle:focus,
.show > .btn.btn-secondary.dropdown-toggle:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #8c8c8c;
}
.open > .btn.btn-secondary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #fafafa;
}
.open > .btn.btn-secondary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f2f2f2;
}
.btn.btn-secondary.disabled.focus,
.btn.btn-secondary.disabled:focus,
.btn.btn-secondary:disabled.focus,
.btn.btn-secondary:disabled:focus {
  background-color: #fafafa;
  border-color: #ccc;
}
.btn.btn-secondary.disabled:hover,
.btn.btn-secondary:disabled:hover {
  background-color: #fafafa;
  border-color: #ccc;
}
.btn.btn-secondary.btn-link,
.btn.btn-secondary.btn-link:active,
.btn.btn-secondary.btn-link:focus,
.btn.btn-secondary.btn-link:hover {
  background-color: transparent;
  color: #fafafa;
}
.btn.btn-secondary:active,
.btn.btn-secondary:focus,
.btn.btn-secondary:hover {
  box-shadow: 0 14px 26px -12px rgba(250, 250, 250, 0.42),
    0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(250, 250, 250, 0.2);
}
.btn.btn-secondary.btn-link {
  box-shadow: none;
}
.btn.btn-info {
  color: #fff;
  background-color: #e50914;
  border-color: #e50914;
  box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14),
    0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
}
.btn.btn-info.focus,
.btn.btn-info:focus,
.btn.btn-info:hover {
  color: #fff;
  background-color: #00aec5;
  border-color: #008697;
}
.btn.btn-info.active,
.btn.btn-info:active,
.open > .btn.btn-info.dropdown-toggle,
.show > .btn.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #00aec5;
  border-color: #008697;
  box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14),
    0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
}
.btn.btn-info.active.focus,
.btn.btn-info.active:focus,
.btn.btn-info.active:hover,
.btn.btn-info:active.focus,
.btn.btn-info:active:focus,
.btn.btn-info:active:hover,
.open > .btn.btn-info.dropdown-toggle.focus,
.open > .btn.btn-info.dropdown-toggle:focus,
.open > .btn.btn-info.dropdown-toggle:hover,
.show > .btn.btn-info.dropdown-toggle.focus,
.show > .btn.btn-info.dropdown-toggle:focus,
.show > .btn.btn-info.dropdown-toggle:hover {
  color: #fff;
  background-color: #00aec5;
  border-color: #004b55;
}
.open > .btn.btn-info.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #e50914;
}
.open > .btn.btn-info.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #00aec5;
}
.btn.btn-info.disabled.focus,
.btn.btn-info.disabled:focus,
.btn.btn-info:disabled.focus,
.btn.btn-info:disabled:focus {
  background-color: #e50914;
  border-color: #e50914;
}
.btn.btn-info.disabled:hover,
.btn.btn-info:disabled:hover {
  background-color: #e50914;
  border-color: #e50914;
}
.btn.btn-info.btn-link,
.btn.btn-info.btn-link:active,
.btn.btn-info.btn-link:focus,
.btn.btn-info.btn-link:hover {
  background-color: transparent;
  color: #e50914;
}
.btn.btn-info:active,
.btn.btn-info:focus,
.btn.btn-info:hover {
  box-shadow: 0 14px 26px -12px rgba(0, 188, 212, 0.42),
    0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2);
}
.btn.btn-info.btn-link {
  box-shadow: none;
}
.btn.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14),
    0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}
.btn.btn-success.focus,
.btn.btn-success:focus,
.btn.btn-success:hover {
  color: #fff;
  background-color: #47a44b;
  border-color: #39843c;
}
.btn.btn-success.active,
.btn.btn-success:active,
.open > .btn.btn-success.dropdown-toggle,
.show > .btn.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #47a44b;
  border-color: #39843c;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14),
    0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}
.btn.btn-success.active.focus,
.btn.btn-success.active:focus,
.btn.btn-success.active:hover,
.btn.btn-success:active.focus,
.btn.btn-success:active:focus,
.btn.btn-success:active:hover,
.open > .btn.btn-success.dropdown-toggle.focus,
.open > .btn.btn-success.dropdown-toggle:focus,
.open > .btn.btn-success.dropdown-toggle:hover,
.show > .btn.btn-success.dropdown-toggle.focus,
.show > .btn.btn-success.dropdown-toggle:focus,
.show > .btn.btn-success.dropdown-toggle:hover {
  color: #fff;
  background-color: #47a44b;
  border-color: #255627;
}
.open > .btn.btn-success.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #4caf50;
}
.open > .btn.btn-success.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #47a44b;
}
.btn.btn-success.disabled.focus,
.btn.btn-success.disabled:focus,
.btn.btn-success:disabled.focus,
.btn.btn-success:disabled:focus {
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn.btn-success.disabled:hover,
.btn.btn-success:disabled:hover {
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn.btn-success.btn-link,
.btn.btn-success.btn-link:active,
.btn.btn-success.btn-link:focus,
.btn.btn-success.btn-link:hover {
  background-color: transparent;
  color: #4caf50;
}
.btn.btn-success:active,
.btn.btn-success:focus,
.btn.btn-success:hover {
  box-shadow: 0 14px 26px -12px rgba(76, 175, 80, 0.42),
    0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2);
}
.btn.btn-success.btn-link {
  box-shadow: none;
}
.btn.btn-warning {
  color: #fff;
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: 0 2px 2px 0 rgba(255, 152, 0, 0.14),
    0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12);
}
.btn.btn-warning.focus,
.btn.btn-warning:focus,
.btn.btn-warning:hover {
  color: #fff;
  background-color: #f08f00;
  border-color: #c27400;
}
.btn.btn-warning.active,
.btn.btn-warning:active,
.open > .btn.btn-warning.dropdown-toggle,
.show > .btn.btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f08f00;
  border-color: #c27400;
  box-shadow: 0 2px 2px 0 rgba(255, 152, 0, 0.14),
    0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12);
}
.btn.btn-warning.active.focus,
.btn.btn-warning.active:focus,
.btn.btn-warning.active:hover,
.btn.btn-warning:active.focus,
.btn.btn-warning:active:focus,
.btn.btn-warning:active:hover,
.open > .btn.btn-warning.dropdown-toggle.focus,
.open > .btn.btn-warning.dropdown-toggle:focus,
.open > .btn.btn-warning.dropdown-toggle:hover,
.show > .btn.btn-warning.dropdown-toggle.focus,
.show > .btn.btn-warning.dropdown-toggle:focus,
.show > .btn.btn-warning.dropdown-toggle:hover {
  color: #fff;
  background-color: #f08f00;
  border-color: #804c00;
}
.open > .btn.btn-warning.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #ff9800;
}
.open > .btn.btn-warning.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f08f00;
}
.btn.btn-warning.disabled.focus,
.btn.btn-warning.disabled:focus,
.btn.btn-warning:disabled.focus,
.btn.btn-warning:disabled:focus {
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn.btn-warning.disabled:hover,
.btn.btn-warning:disabled:hover {
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn.btn-warning.btn-link,
.btn.btn-warning.btn-link:active,
.btn.btn-warning.btn-link:focus,
.btn.btn-warning.btn-link:hover {
  background-color: transparent;
  color: #ff9800;
}
.btn.btn-warning:active,
.btn.btn-warning:focus,
.btn.btn-warning:hover {
  box-shadow: 0 14px 26px -12px rgba(255, 152, 0, 0.42),
    0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2);
}
.btn.btn-warning.btn-link {
  box-shadow: none;
}
.btn.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14),
    0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
}
.btn.btn-danger.focus,
.btn.btn-danger:focus,
.btn.btn-danger:hover {
  color: #fff;
  background-color: #f33527;
  border-color: #e11b0c;
}
.btn.btn-danger.active,
.btn.btn-danger:active,
.open > .btn.btn-danger.dropdown-toggle,
.show > .btn.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f33527;
  border-color: #e11b0c;
  box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14),
    0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
}
.btn.btn-danger.active.focus,
.btn.btn-danger.active:focus,
.btn.btn-danger.active:hover,
.btn.btn-danger:active.focus,
.btn.btn-danger:active:focus,
.btn.btn-danger:active:hover,
.open > .btn.btn-danger.dropdown-toggle.focus,
.open > .btn.btn-danger.dropdown-toggle:focus,
.open > .btn.btn-danger.dropdown-toggle:hover,
.show > .btn.btn-danger.dropdown-toggle.focus,
.show > .btn.btn-danger.dropdown-toggle:focus,
.show > .btn.btn-danger.dropdown-toggle:hover {
  color: #fff;
  background-color: #f33527;
  border-color: #a21309;
}
.open > .btn.btn-danger.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #f44336;
}
.open > .btn.btn-danger.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f33527;
}
.btn.btn-danger.disabled.focus,
.btn.btn-danger.disabled:focus,
.btn.btn-danger:disabled.focus,
.btn.btn-danger:disabled:focus {
  background-color: #f44336;
  border-color: #f44336;
}
.btn.btn-danger.disabled:hover,
.btn.btn-danger:disabled:hover {
  background-color: #f44336;
  border-color: #f44336;
}
.btn.btn-danger.btn-link,
.btn.btn-danger.btn-link:active,
.btn.btn-danger.btn-link:focus,
.btn.btn-danger.btn-link:hover {
  background-color: transparent;
  color: #f44336;
}
.btn.btn-danger:active,
.btn.btn-danger:focus,
.btn.btn-danger:hover {
  box-shadow: 0 14px 26px -12px rgba(244, 67, 54, 0.42),
    0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2);
}
.btn.btn-danger.btn-link {
  box-shadow: none;
}
.btn.btn-rose {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
  box-shadow: 0 2px 2px 0 rgba(233, 30, 99, 0.14),
    0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12);
}
.btn.btn-rose.focus,
.btn.btn-rose:focus,
.btn.btn-rose:hover {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #b8124a;
}
.btn.btn-rose.active,
.btn.btn-rose:active,
.open > .btn.btn-rose.dropdown-toggle,
.show > .btn.btn-rose.dropdown-toggle {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #b8124a;
  box-shadow: 0 2px 2px 0 rgba(233, 30, 99, 0.14),
    0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12);
}
.btn.btn-rose.active.focus,
.btn.btn-rose.active:focus,
.btn.btn-rose.active:hover,
.btn.btn-rose:active.focus,
.btn.btn-rose:active:focus,
.btn.btn-rose:active:hover,
.open > .btn.btn-rose.dropdown-toggle.focus,
.open > .btn.btn-rose.dropdown-toggle:focus,
.open > .btn.btn-rose.dropdown-toggle:hover,
.show > .btn.btn-rose.dropdown-toggle.focus,
.show > .btn.btn-rose.dropdown-toggle:focus,
.show > .btn.btn-rose.dropdown-toggle:hover {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #7b0c32;
}
.open > .btn.btn-rose.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #e91e63;
}
.open > .btn.btn-rose.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #ea2c6d;
}
.btn.btn-rose.disabled.focus,
.btn.btn-rose.disabled:focus,
.btn.btn-rose:disabled.focus,
.btn.btn-rose:disabled:focus {
  background-color: #e91e63;
  border-color: #e91e63;
}
.btn.btn-rose.disabled:hover,
.btn.btn-rose:disabled:hover {
  background-color: #e91e63;
  border-color: #e91e63;
}
.btn.btn-rose.btn-link,
.btn.btn-rose.btn-link:active,
.btn.btn-rose.btn-link:focus,
.btn.btn-rose.btn-link:hover {
  background-color: transparent;
  color: #e91e63;
}
.btn.btn-rose:active,
.btn.btn-rose:focus,
.btn.btn-rose:hover {
  box-shadow: 0 14px 26px -12px rgba(233, 30, 99, 0.42),
    0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2);
}
.btn.btn-rose.btn-link {
  box-shadow: none;
}
.btn,
.btn.btn-default {
  color: #fff;
  background-color: #999;
  border-color: #999;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}
.btn.btn-default.focus,
.btn.btn-default:focus,
.btn.btn-default:hover,
.btn.focus,
.btn:focus,
.btn:hover {
  color: #fff;
  background-color: #919191;
  border-color: #7a7a7a;
}
.btn.active,
.btn.btn-default.active,
.btn.btn-default:active,
.btn:active,
.open > .btn.btn-default.dropdown-toggle,
.open > .btn.dropdown-toggle,
.show > .btn.btn-default.dropdown-toggle,
.show > .btn.dropdown-toggle {
  color: #fff;
  background-color: #919191;
  border-color: #7a7a7a;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}
.btn.active.focus,
.btn.active:focus,
.btn.active:hover,
.btn.btn-default.active.focus,
.btn.btn-default.active:focus,
.btn.btn-default.active:hover,
.btn.btn-default:active.focus,
.btn.btn-default:active:focus,
.btn.btn-default:active:hover,
.btn:active.focus,
.btn:active:focus,
.btn:active:hover,
.open > .btn.btn-default.dropdown-toggle.focus,
.open > .btn.btn-default.dropdown-toggle:focus,
.open > .btn.btn-default.dropdown-toggle:hover,
.open > .btn.dropdown-toggle.focus,
.open > .btn.dropdown-toggle:focus,
.open > .btn.dropdown-toggle:hover,
.show > .btn.btn-default.dropdown-toggle.focus,
.show > .btn.btn-default.dropdown-toggle:focus,
.show > .btn.btn-default.dropdown-toggle:hover,
.show > .btn.dropdown-toggle.focus,
.show > .btn.dropdown-toggle:focus,
.show > .btn.dropdown-toggle:hover {
  color: #fff;
  background-color: #919191;
  border-color: #595959;
}
.open > .btn.btn-default.dropdown-toggle.bmd-btn-icon,
.open > .btn.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #999;
}
.open > .btn.btn-default.dropdown-toggle.bmd-btn-icon:hover,
.open > .btn.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #919191;
}
.btn.btn-default.disabled.focus,
.btn.btn-default.disabled:focus,
.btn.btn-default:disabled.focus,
.btn.btn-default:disabled:focus,
.btn.disabled.focus,
.btn.disabled:focus,
.btn:disabled.focus,
.btn:disabled:focus {
  background-color: #999;
  border-color: #999;
}
.btn.btn-default.disabled:hover,
.btn.btn-default:disabled:hover,
.btn.disabled:hover,
.btn:disabled:hover {
  background-color: #999;
  border-color: #999;
}
.btn.btn-default.btn-link,
.btn.btn-default.btn-link:active,
.btn.btn-default.btn-link:focus,
.btn.btn-default.btn-link:hover,
.btn.btn-link,
.btn.btn-link:active,
.btn.btn-link:focus,
.btn.btn-link:hover {
  background-color: transparent;
  color: #999;
}
.btn.btn-default:active,
.btn.btn-default:focus,
.btn.btn-default:hover,
.btn:active,
.btn:focus,
.btn:hover {
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42),
    0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
}
.btn.btn-default.btn-link,
.btn.btn-link {
  box-shadow: none;
}
.btn.btn-white,
.btn.btn-white:focus,
.btn.btn-white:hover {
  background-color: #fff;
  color: #999;
}
.btn.btn-white.btn-link {
  color: #fff;
  background: 0 0;
  box-shadow: none;
}
.btn.btn-link:active,
.btn.btn-link:focus,
.btn.btn-link:hover {
  text-decoration: none !important;
}
.btn.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.btn-group-raised .btn.btn-link,
.btn-group-raised .btn.btn-link.active,
.btn-group-raised .btn.btn-link:active,
.btn-group-raised .btn.btn-link:focus,
.btn-group-raised .btn.btn-link:hover,
.btn-group-raised .btn.disabled,
.btn-group-raised .btn:disabled,
.btn-group-raised .btn[disabled],
.btn.btn-disabled:hover,
.btn.btn-raised.btn-link,
.btn.btn-raised.btn-link.active,
.btn.btn-raised.btn-link:active,
.btn.btn-raised.btn-link:focus,
.btn.btn-raised.btn-link:hover,
.btn.btn-raised.disabled,
.btn.btn-raised:disabled,
.btn.btn-raised[disabled],
fieldset[disabled][disabled] .btn-group-raised .btn,
fieldset[disabled][disabled] .btn.btn-raised {
  box-shadow: none;
}
.btn.btn-outline,
.btn.btn-outline-danger,
.btn.btn-outline-info,
.btn.btn-outline-primary,
.btn.btn-outline-secondary,
.btn.btn-outline-success,
.btn.btn-outline-warning {
  border: 1px solid currentColor;
}
.bg-inverse .btn.btn-outline,
.bg-inverse .btn.btn-outline-secondary,
.btn.btn-outline,
.btn.btn-outline-secondary {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary.focus,
.btn.btn-outline-secondary:focus,
.btn.btn-outline-secondary:hover,
.btn.btn-outline.focus,
.btn.btn-outline:focus,
.btn.btn-outline:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary.active,
.btn.btn-outline-secondary:active,
.btn.btn-outline.active,
.btn.btn-outline:active,
.open > .btn.btn-outline-secondary.dropdown-toggle,
.open > .btn.btn-outline.dropdown-toggle,
.show > .btn.btn-outline-secondary.dropdown-toggle,
.show > .btn.btn-outline.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline.active.focus,
.btn.btn-outline.active:focus,
.btn.btn-outline.active:hover,
.btn.btn-outline:active.focus,
.btn.btn-outline:active:focus,
.btn.btn-outline:active:hover,
.open > .btn.btn-outline.dropdown-toggle.focus,
.open > .btn.btn-outline.dropdown-toggle:focus,
.open > .btn.btn-outline.dropdown-toggle:hover,
.show > .btn.btn-outline.dropdown-toggle.focus,
.show > .btn.btn-outline.dropdown-toggle:focus,
.show > .btn.btn-outline.dropdown-toggle:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.4);
  border-color: rgba(0, 0, 0, 0.87);
}
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon,
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon,
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon,
.open > .bg-inverse .btn.btn-outline.dropdown-toggle.bmd-btn-icon,
.open > .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon,
.open > .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon,
.open > .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon,
.open > .btn.btn-outline.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open > .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover,
.open > .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover,
.open > .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover,
.open > .btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline.disabled.focus,
.btn.btn-outline.disabled:focus,
.btn.btn-outline:disabled.focus,
.btn.btn-outline:disabled:focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline.disabled:hover,
.btn.btn-outline:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary.focus,
.bg-inverse .btn.btn-outline-secondary:focus,
.bg-inverse .btn.btn-outline-secondary:hover,
.bg-inverse .btn.btn-outline.focus,
.bg-inverse .btn.btn-outline:focus,
.bg-inverse .btn.btn-outline:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-secondary.active,
.bg-inverse .btn.btn-outline-secondary:active,
.bg-inverse .btn.btn-outline.active,
.bg-inverse .btn.btn-outline:active,
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle,
.open > .bg-inverse .btn.btn-outline.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline.active.focus,
.bg-inverse .btn.btn-outline.active:focus,
.bg-inverse .btn.btn-outline.active:hover,
.bg-inverse .btn.btn-outline:active.focus,
.bg-inverse .btn.btn-outline:active:focus,
.bg-inverse .btn.btn-outline:active:hover,
.open > .bg-inverse .btn.btn-outline.dropdown-toggle.focus,
.open > .bg-inverse .btn.btn-outline.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline.dropdown-toggle:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover,
.open
  > .bg-inverse
  .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover,
.open > .bg-inverse .btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline.disabled.focus,
.bg-inverse .btn.btn-outline.disabled:focus,
.bg-inverse .btn.btn-outline:disabled.focus,
.bg-inverse .btn.btn-outline:disabled:focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline.disabled:hover,
.bg-inverse .btn.btn-outline:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-primary.btn-link,
.btn.btn-outline-secondary.btn-link,
.btn.btn-outline.btn-link {
  background-color: transparent;
}
.bg-inverse .btn.btn-outline-info,
.bg-inverse .btn.btn-outline-primary,
.btn.btn-outline-info,
.btn.btn-outline-primary {
  color: #e50914;
  background-color: transparent;
  border-color: #e50914;
}
.btn.btn-outline-info.focus,
.btn.btn-outline-info:focus,
.btn.btn-outline-info:hover,
.btn.btn-outline-primary.focus,
.btn.btn-outline-primary:focus,
.btn.btn-outline-primary:hover {
  color: #e50914;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #e50914;
}
.btn.btn-outline-info.active,
.btn.btn-outline-info:active,
.btn.btn-outline-primary.active,
.btn.btn-outline-primary:active,
.open > .btn.btn-outline-info.dropdown-toggle,
.open > .btn.btn-outline-primary.dropdown-toggle,
.show > .btn.btn-outline-info.dropdown-toggle,
.show > .btn.btn-outline-primary.dropdown-toggle {
  color: #e50914;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #e50914;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-primary.active.focus,
.btn.btn-outline-primary.active:focus,
.btn.btn-outline-primary.active:hover,
.btn.btn-outline-primary:active.focus,
.btn.btn-outline-primary:active:focus,
.btn.btn-outline-primary:active:hover,
.open > .btn.btn-outline-primary.dropdown-toggle.focus,
.open > .btn.btn-outline-primary.dropdown-toggle:focus,
.open > .btn.btn-outline-primary.dropdown-toggle:hover,
.show > .btn.btn-outline-primary.dropdown-toggle.focus,
.show > .btn.btn-outline-primary.dropdown-toggle:focus,
.show > .btn.btn-outline-primary.dropdown-toggle:hover {
  color: #e50914;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #e50914;
}
.btn.btn-outline-primary.disabled.focus,
.btn.btn-outline-primary.disabled:focus,
.btn.btn-outline-primary:disabled.focus,
.btn.btn-outline-primary:disabled:focus {
  background-color: transparent;
  border-color: #e50914;
}
.btn.btn-outline-primary.disabled:hover,
.btn.btn-outline-primary:disabled:hover {
  background-color: transparent;
  border-color: #e50914;
}
.bg-inverse .btn.btn-outline-info.focus,
.bg-inverse .btn.btn-outline-info:focus,
.bg-inverse .btn.btn-outline-info:hover,
.bg-inverse .btn.btn-outline-primary.focus,
.bg-inverse .btn.btn-outline-primary:focus,
.bg-inverse .btn.btn-outline-primary:hover {
  color: #e50914;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-info.active,
.bg-inverse .btn.btn-outline-info:active,
.bg-inverse .btn.btn-outline-primary.active,
.bg-inverse .btn.btn-outline-primary:active,
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle,
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-info.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-primary.dropdown-toggle {
  color: #e50914;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-primary.active.focus,
.bg-inverse .btn.btn-outline-primary.active:focus,
.bg-inverse .btn.btn-outline-primary.active:hover,
.bg-inverse .btn.btn-outline-primary:active.focus,
.bg-inverse .btn.btn-outline-primary:active:focus,
.bg-inverse .btn.btn-outline-primary:active:hover,
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle.focus,
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-primary.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-primary.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-primary.dropdown-toggle:hover {
  color: #e50914;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.bg-inverse .btn.btn-outline-primary.disabled.focus,
.bg-inverse .btn.btn-outline-primary.disabled:focus,
.bg-inverse .btn.btn-outline-primary:disabled.focus,
.bg-inverse .btn.btn-outline-primary:disabled:focus {
  background-color: transparent;
  border-color: #e50914;
}
.bg-inverse .btn.btn-outline-primary.disabled:hover,
.bg-inverse .btn.btn-outline-primary:disabled:hover {
  background-color: transparent;
  border-color: #e50914;
}
.btn.btn-outline-secondary.active.focus,
.btn.btn-outline-secondary.active:focus,
.btn.btn-outline-secondary.active:hover,
.btn.btn-outline-secondary:active.focus,
.btn.btn-outline-secondary:active:focus,
.btn.btn-outline-secondary:active:hover,
.open > .btn.btn-outline-secondary.dropdown-toggle.focus,
.open > .btn.btn-outline-secondary.dropdown-toggle:focus,
.open > .btn.btn-outline-secondary.dropdown-toggle:hover,
.show > .btn.btn-outline-secondary.dropdown-toggle.focus,
.show > .btn.btn-outline-secondary.dropdown-toggle:focus,
.show > .btn.btn-outline-secondary.dropdown-toggle:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.4);
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary.disabled.focus,
.btn.btn-outline-secondary.disabled:focus,
.btn.btn-outline-secondary:disabled.focus,
.btn.btn-outline-secondary:disabled:focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary.disabled:hover,
.btn.btn-outline-secondary:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary.active.focus,
.bg-inverse .btn.btn-outline-secondary.active:focus,
.bg-inverse .btn.btn-outline-secondary.active:hover,
.bg-inverse .btn.btn-outline-secondary:active.focus,
.bg-inverse .btn.btn-outline-secondary:active:focus,
.bg-inverse .btn.btn-outline-secondary:active:hover,
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle.focus,
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.bg-inverse .btn.btn-outline-secondary.disabled.focus,
.bg-inverse .btn.btn-outline-secondary.disabled:focus,
.bg-inverse .btn.btn-outline-secondary:disabled.focus,
.bg-inverse .btn.btn-outline-secondary:disabled:focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary.disabled:hover,
.bg-inverse .btn.btn-outline-secondary:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-info.active.focus,
.btn.btn-outline-info.active:focus,
.btn.btn-outline-info.active:hover,
.btn.btn-outline-info:active.focus,
.btn.btn-outline-info:active:focus,
.btn.btn-outline-info:active:hover,
.open > .btn.btn-outline-info.dropdown-toggle.focus,
.open > .btn.btn-outline-info.dropdown-toggle:focus,
.open > .btn.btn-outline-info.dropdown-toggle:hover,
.show > .btn.btn-outline-info.dropdown-toggle.focus,
.show > .btn.btn-outline-info.dropdown-toggle:focus,
.show > .btn.btn-outline-info.dropdown-toggle:hover {
  color: #e50914;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #e50914;
}
.btn.btn-outline-info.disabled.focus,
.btn.btn-outline-info.disabled:focus,
.btn.btn-outline-info:disabled.focus,
.btn.btn-outline-info:disabled:focus {
  background-color: transparent;
  border-color: #e50914;
}
.btn.btn-outline-info.disabled:hover,
.btn.btn-outline-info:disabled:hover {
  background-color: transparent;
  border-color: #e50914;
}
.bg-inverse .btn.btn-outline-info.active.focus,
.bg-inverse .btn.btn-outline-info.active:focus,
.bg-inverse .btn.btn-outline-info.active:hover,
.bg-inverse .btn.btn-outline-info:active.focus,
.bg-inverse .btn.btn-outline-info:active:focus,
.bg-inverse .btn.btn-outline-info:active:hover,
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle.focus,
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-info.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-info.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-info.dropdown-toggle:hover {
  color: #e50914;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover,
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover,
.open
  > .bg-inverse
  .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-info.disabled.focus,
.bg-inverse .btn.btn-outline-info.disabled:focus,
.bg-inverse .btn.btn-outline-info:disabled.focus,
.bg-inverse .btn.btn-outline-info:disabled:focus {
  background-color: transparent;
  border-color: #e50914;
}
.bg-inverse .btn.btn-outline-info.disabled:hover,
.bg-inverse .btn.btn-outline-info:disabled:hover {
  background-color: transparent;
  border-color: #e50914;
}
.btn.btn-outline-info.btn-link,
.btn.btn-outline-success.btn-link,
.btn.btn-outline-warning.btn-link {
  background-color: transparent;
}
.bg-inverse .btn.btn-outline-success,
.btn.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  border-color: #4caf50;
}
.btn.btn-outline-success.focus,
.btn.btn-outline-success:focus,
.btn.btn-outline-success:hover {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #4caf50;
}
.btn.btn-outline-success.active,
.btn.btn-outline-success:active,
.open > .btn.btn-outline-success.dropdown-toggle,
.show > .btn.btn-outline-success.dropdown-toggle {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #4caf50;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-success.active.focus,
.btn.btn-outline-success.active:focus,
.btn.btn-outline-success.active:hover,
.btn.btn-outline-success:active.focus,
.btn.btn-outline-success:active:focus,
.btn.btn-outline-success:active:hover,
.open > .btn.btn-outline-success.dropdown-toggle.focus,
.open > .btn.btn-outline-success.dropdown-toggle:focus,
.open > .btn.btn-outline-success.dropdown-toggle:hover,
.show > .btn.btn-outline-success.dropdown-toggle.focus,
.show > .btn.btn-outline-success.dropdown-toggle:focus,
.show > .btn.btn-outline-success.dropdown-toggle:hover {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #4caf50;
}
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon,
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon,
.open > .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon,
.open > .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open > .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover,
.open > .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-success.disabled.focus,
.btn.btn-outline-success.disabled:focus,
.btn.btn-outline-success:disabled.focus,
.btn.btn-outline-success:disabled:focus {
  background-color: transparent;
  border-color: #4caf50;
}
.btn.btn-outline-success.disabled:hover,
.btn.btn-outline-success:disabled:hover {
  background-color: transparent;
  border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-success.focus,
.bg-inverse .btn.btn-outline-success:focus,
.bg-inverse .btn.btn-outline-success:hover {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-success.active,
.bg-inverse .btn.btn-outline-success:active,
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-success.dropdown-toggle {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-success.active.focus,
.bg-inverse .btn.btn-outline-success.active:focus,
.bg-inverse .btn.btn-outline-success.active:hover,
.bg-inverse .btn.btn-outline-success:active.focus,
.bg-inverse .btn.btn-outline-success:active:focus,
.bg-inverse .btn.btn-outline-success:active:hover,
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle.focus,
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-success.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-success.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-success.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-success.dropdown-toggle:hover {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.bg-inverse .btn.btn-outline-success.disabled.focus,
.bg-inverse .btn.btn-outline-success.disabled:focus,
.bg-inverse .btn.btn-outline-success:disabled.focus,
.bg-inverse .btn.btn-outline-success:disabled:focus {
  background-color: transparent;
  border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-success.disabled:hover,
.bg-inverse .btn.btn-outline-success:disabled:hover {
  background-color: transparent;
  border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-warning,
.btn.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  border-color: #ff9800;
}
.btn.btn-outline-warning.focus,
.btn.btn-outline-warning:focus,
.btn.btn-outline-warning:hover {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #ff9800;
}
.btn.btn-outline-warning.active,
.btn.btn-outline-warning:active,
.open > .btn.btn-outline-warning.dropdown-toggle,
.show > .btn.btn-outline-warning.dropdown-toggle {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #ff9800;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-warning.active.focus,
.btn.btn-outline-warning.active:focus,
.btn.btn-outline-warning.active:hover,
.btn.btn-outline-warning:active.focus,
.btn.btn-outline-warning:active:focus,
.btn.btn-outline-warning:active:hover,
.open > .btn.btn-outline-warning.dropdown-toggle.focus,
.open > .btn.btn-outline-warning.dropdown-toggle:focus,
.open > .btn.btn-outline-warning.dropdown-toggle:hover,
.show > .btn.btn-outline-warning.dropdown-toggle.focus,
.show > .btn.btn-outline-warning.dropdown-toggle:focus,
.show > .btn.btn-outline-warning.dropdown-toggle:hover {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #ff9800;
}
.btn.btn-outline-warning.disabled.focus,
.btn.btn-outline-warning.disabled:focus,
.btn.btn-outline-warning:disabled.focus,
.btn.btn-outline-warning:disabled:focus {
  background-color: transparent;
  border-color: #ff9800;
}
.btn.btn-outline-warning.disabled:hover,
.btn.btn-outline-warning:disabled:hover {
  background-color: transparent;
  border-color: #ff9800;
}
.bg-inverse .btn.btn-outline-warning.focus,
.bg-inverse .btn.btn-outline-warning:focus,
.bg-inverse .btn.btn-outline-warning:hover {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-warning.active,
.bg-inverse .btn.btn-outline-warning:active,
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-warning.dropdown-toggle {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-warning.active.focus,
.bg-inverse .btn.btn-outline-warning.active:focus,
.bg-inverse .btn.btn-outline-warning.active:hover,
.bg-inverse .btn.btn-outline-warning:active.focus,
.bg-inverse .btn.btn-outline-warning:active:focus,
.bg-inverse .btn.btn-outline-warning:active:hover,
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle.focus,
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-warning.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-warning.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-warning.dropdown-toggle:hover {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.bg-inverse .btn.btn-outline-warning.disabled.focus,
.bg-inverse .btn.btn-outline-warning.disabled:focus,
.bg-inverse .btn.btn-outline-warning:disabled.focus,
.bg-inverse .btn.btn-outline-warning:disabled:focus {
  background-color: transparent;
  border-color: #ff9800;
}
.bg-inverse .btn.btn-outline-warning.disabled:hover,
.bg-inverse .btn.btn-outline-warning:disabled:hover {
  background-color: transparent;
  border-color: #ff9800;
}
.btn.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  border-color: #f44336;
}
.btn.btn-outline-danger.focus,
.btn.btn-outline-danger:focus,
.btn.btn-outline-danger:hover {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger,
.btn.btn-outline-danger.disabled:hover,
.btn.btn-outline-danger:disabled:hover {
  border-color: #f44336;
  background-color: transparent;
}
.btn.btn-outline-danger.active,
.btn.btn-outline-danger:active,
.open > .btn.btn-outline-danger.dropdown-toggle,
.show > .btn.btn-outline-danger.dropdown-toggle {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #f44336;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-danger.active.focus,
.btn.btn-outline-danger.active:focus,
.btn.btn-outline-danger.active:hover,
.btn.btn-outline-danger:active.focus,
.btn.btn-outline-danger:active:focus,
.btn.btn-outline-danger:active:hover,
.open > .btn.btn-outline-danger.dropdown-toggle.focus,
.open > .btn.btn-outline-danger.dropdown-toggle:focus,
.open > .btn.btn-outline-danger.dropdown-toggle:hover,
.show > .btn.btn-outline-danger.dropdown-toggle.focus,
.show > .btn.btn-outline-danger.dropdown-toggle:focus,
.show > .btn.btn-outline-danger.dropdown-toggle:hover {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #f44336;
}
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon,
.open > .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open > .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-danger.disabled.focus,
.btn.btn-outline-danger.disabled:focus,
.btn.btn-outline-danger:disabled.focus,
.btn.btn-outline-danger:disabled:focus {
  background-color: transparent;
  border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger {
  color: #f44336;
}
.bg-inverse .btn.btn-outline-danger.focus,
.bg-inverse .btn.btn-outline-danger:focus,
.bg-inverse .btn.btn-outline-danger:hover {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-danger.active,
.bg-inverse .btn.btn-outline-danger:active,
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle,
.show > .bg-inverse .btn.btn-outline-danger.dropdown-toggle {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-danger.active.focus,
.bg-inverse .btn.btn-outline-danger.active:focus,
.bg-inverse .btn.btn-outline-danger.active:hover,
.bg-inverse .btn.btn-outline-danger:active.focus,
.bg-inverse .btn.btn-outline-danger:active:focus,
.bg-inverse .btn.btn-outline-danger:active:hover,
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.focus,
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle:focus,
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle:hover,
.show > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.focus,
.show > .bg-inverse .btn.btn-outline-danger.dropdown-toggle:focus,
.show > .bg-inverse .btn.btn-outline-danger.dropdown-toggle:hover {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-danger.disabled.focus,
.bg-inverse .btn.btn-outline-danger.disabled:focus,
.bg-inverse .btn.btn-outline-danger:disabled.focus,
.bg-inverse .btn.btn-outline-danger:disabled:focus {
  background-color: transparent;
  border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger.disabled:hover,
.bg-inverse .btn.btn-outline-danger:disabled:hover {
  background-color: transparent;
  border-color: #f44336;
}
.btn.btn-outline-danger.btn-link {
  background-color: transparent;
}
.btn-group-lg .btn,
.btn-group-lg > .btn,
.btn.btn-lg {
  padding: 1.125rem 2.25rem;
  font-size: 0.875rem;
  line-height: 1.333333;
  border-radius: 0.2rem;
}
.btn-group-sm .btn,
.btn-group-sm > .btn,
.btn.btn-sm {
  padding: 0.40625rem 1.25rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn.btn-round {
  border-radius: 30px;
}
.btn.btn-fab,
.btn.btn-just-icon {
  font-size: 24px;
  height: 41px;
  min-width: 41px;
  width: 41px;
  padding: 0;
  overflow: hidden;
  position: relative;
  line-height: 41px;
}
.btn.btn-fab.btn-round,
.btn.btn-just-icon.btn-round {
  border-radius: 50%;
}
.btn-group-sm .btn.btn-fab,
.btn-group-sm .btn.btn-just-icon,
.btn-group-sm > .btn.btn-fab,
.btn-group-sm > .btn.btn-just-icon,
.btn.btn-fab.btn-fab-mini,
.btn.btn-fab.btn-sm,
.btn.btn-just-icon.btn-fab-mini,
.btn.btn-just-icon.btn-sm {
  height: 30px;
  min-width: 30px;
  width: 30px;
}
.btn-group-sm .btn.btn-fab .fa,
.btn-group-sm .btn.btn-fab .material-icons,
.btn-group-sm .btn.btn-just-icon .fa,
.btn-group-sm .btn.btn-just-icon .material-icons,
.btn-group-sm > .btn.btn-fab .fa,
.btn-group-sm > .btn.btn-fab .material-icons,
.btn-group-sm > .btn.btn-just-icon .fa,
.btn-group-sm > .btn.btn-just-icon .material-icons,
.btn.btn-fab.btn-fab-mini .fa,
.btn.btn-fab.btn-fab-mini .material-icons,
.btn.btn-fab.btn-sm .fa,
.btn.btn-fab.btn-sm .material-icons,
.btn.btn-just-icon.btn-fab-mini .fa,
.btn.btn-just-icon.btn-fab-mini .material-icons,
.btn.btn-just-icon.btn-sm .fa,
.btn.btn-just-icon.btn-sm .material-icons {
  font-size: 17px;
  line-height: 29px;
}
.btn-group-lg .btn.btn-fab,
.btn-group-lg .btn.btn-just-icon,
.btn-group-lg > .btn.btn-fab,
.btn-group-lg > .btn.btn-just-icon,
.btn.btn-fab.btn-lg,
.btn.btn-just-icon.btn-lg {
  height: 57px;
  min-width: 57px;
  width: 57px;
  line-height: 56px;
}
.btn-group-lg .btn.btn-fab .fa,
.btn-group-lg .btn.btn-fab .material-icons,
.btn-group-lg .btn.btn-just-icon .fa,
.btn-group-lg .btn.btn-just-icon .material-icons,
.btn-group-lg > .btn.btn-fab .fa,
.btn-group-lg > .btn.btn-fab .material-icons,
.btn-group-lg > .btn.btn-just-icon .fa,
.btn-group-lg > .btn.btn-just-icon .material-icons,
.btn.btn-fab.btn-lg .fa,
.btn.btn-fab.btn-lg .material-icons,
.btn.btn-just-icon.btn-lg .fa,
.btn.btn-just-icon.btn-lg .material-icons {
  font-size: 32px;
  line-height: 56px;
}
.btn.btn-fab .fa,
.btn.btn-fab .material-icons,
.btn.btn-just-icon .fa,
.btn.btn-just-icon .material-icons {
  margin-top: 0;
  position: absolute;
  width: 100%;
  transform: none;
  left: 0;
  top: 0;
  height: 100%;
  line-height: 41px;
  font-size: 20px;
}
.btn-group-lg > .btn-just-icon.btn,
.btn-just-icon.btn-lg {
  font-size: 24px;
  height: 41px;
  min-width: 41px;
  width: 41px;
}
.input-group-btn > .btn {
  border: 0;
}
.btn .material-icons,
.btn:not(.btn-just-icon):not(.btn-fab) .fa {
  position: relative;
  display: inline-block;
  top: 0;
  margin-top: -1em;
  margin-bottom: -1em;
  font-size: 1.1rem;
  vertical-align: middle;
}
.bg-inverse .btn-group-vertical.disabled,
.bg-inverse .btn-group-vertical:disabled,
.bg-inverse .btn-group-vertical[disabled],
.bg-inverse .btn-group.disabled,
.bg-inverse .btn-group:disabled,
.bg-inverse .btn-group[disabled],
.bg-inverse .btn.disabled,
.bg-inverse .btn:disabled,
.bg-inverse .btn[disabled],
.bg-inverse .input-group-btn .btn.disabled,
.bg-inverse .input-group-btn .btn:disabled,
.bg-inverse .input-group-btn .btn[disabled],
.bg-inverse fieldset[disabled][disabled] .btn,
.bg-inverse fieldset[disabled][disabled] .btn-group,
.bg-inverse fieldset[disabled][disabled] .btn-group-vertical,
.bg-inverse fieldset[disabled][disabled] .input-group-btn .btn {
  color: rgba(255, 255, 255, 0.3);
}
.btn-group,
.btn-group-vertical {
  position: relative;
  margin: 10px 1px;
}
.btn-group .dropdown-menu,
.btn-group-vertical .dropdown-menu {
  border-radius: 0 0 0.25rem 0.25rem;
}
.btn-group-vertical.btn-group-raised,
.btn-group.btn-group-raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn-group .btn,
.btn-group .btn + .btn,
.btn-group .btn-group,
.btn-group .btn:active,
.btn-group-vertical .btn,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn-group,
.btn-group-vertical .btn:active,
.btn-group-vertical > .btn-group,
.btn-group > .btn-group {
  margin: 0;
}
legend {
  border-bottom: 0;
}
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}
:focus {
  outline: 0;
}
a {
  color: #e50914;
}
a:focus,
a:hover {
  color: #e50914;
  text-decoration: none;
}
a.text-info:focus,
a.text-info:hover {
  color: #00a5bb;
}
a .material-icons {
  vertical-align: middle;
}
.main {
  position: relative;
  z-index: 3;
}
.form-check,
label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaa;
  font-weight: 400;
}
.main-raised {
  margin: -60px 30px 0;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
/*           Animations              */
.animation-transition-general,
.carousel .carousel-indicators li {
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  transition: 0.3s linear;
}
.animation-transition-slow {
  -webkit-transition: 370ms linear;
  -moz-transition: 370ms linear;
  -o-transition: 370ms linear;
  -ms-transition: 370ms linear;
  transition: 370ms linear;
}
.animation-transition-fast,
.bootstrap-datetimepicker-widget table td span,
.bootstrap-datetimepicker-widget table td > div,
.bootstrap-datetimepicker-widget table th,
.bootstrap-datetimepicker-widget table th > div,
.bootstrap-tagsinput .tag,
.bootstrap-tagsinput [data-role="remove"],
.card-collapse .card-header a i,
.navbar {
  -webkit-transition: 150ms;
  -moz-transition: 150ms;
  -o-transition: 150ms;
  -ms-transition: 150ms;
  transition: 150ms;
}
.signup-page .card-signup form .form-check {
  padding-left: 20px;
}
.signup-page .card-signup form .form-check .form-check-label {
  padding-left: 35px;
}
.section-signup .card-signup .card-header {
  width: auto;
}
.section-signup .card-signup .card-body .input-group {
  padding-bottom: 7px;
  margin: 27px 0 0;
}
.offline-free-doc .footer {
  position: absolute;
  width: 100%;
  background: 0 0;
  bottom: 0;
  color: #fff;
  z-index: 1;
}
.offline-doc .footer .copyright a,
.offline-doc .navbar .navbar-brand,
.offline-doc .navbar .navbar-collapse .nav-link,
.offline-free-doc .footer .copyright a,
.offline-free-doc .navbar .navbar-brand,
.offline-free-doc .navbar .navbar-collapse .nav-link {
  color: #fff;
}
@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
#map,
.tim-typo {
  width: 100%;
  position: relative;
}
#map,
#notifications,
.tim-typo {
  position: relative;
}
#notifications,
.parallax,
.space,
.space-100,
.space-110,
.space-50,
.space-70 {
  display: block;
}
.navigation-example,
.parallax {
  background-size: cover;
  background-position: center center;
}
.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}
.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.tim-row {
  margin-bottom: 20px;
  padding-top: 50px;
}
.tim-white-buttons {
  background-color: #777;
}
.title {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
}
#map {
  z-index: 2;
  height: calc(100vh - 70px);
  margin-top: 70px;
}
.tim-typo {
  padding-left: 25%;
  margin-bottom: 40px;
}
.tim-typo .tim-note {
  bottom: 5px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
}
.tim-row h3 {
  margin-top: 0;
}
#notifications .alert-danger,
#typography h1,
#typography h2,
#typography h3,
#typography h4,
#typography h5,
#typography h6 {
  margin-bottom: 0;
}
.switch {
  margin-right: 20px;
}
.modaltoggles .switch{
  margin-right:0px !important;
}
#navbar-full .navbar {
  border-radius: 0 !important;
  margin-bottom: 15px;
  z-index: 2;
}
.space {
  height: 130px;
}
.space-110 {
  height: 110px;
}
.space-50 {
  height: 50px;
}
.space-70 {
  height: 70px;
}
.navigation-example .img-src {
  background-attachment: scroll;
}
.navigation-example {
  margin-top: 0;
  min-height: 740px;
}
#notifications {
  background-color: #fff;
  width: 100%;
}
.tim-note {
  text-transform: capitalize;
}
#buttons .btn {
  margin: 0 0 15px;
}
.space-100 {
  height: 100px;
  width: 100%;
}
.parallax {
  width: 100%;
  height: 570px;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.parallax .parallax-image {
  width: 100%;
  overflow: hidden;
  position: absolute;
}
.parallax .parallax-image img {
  width: 100%;
}
@media (max-width: 768px) {
  .parallax .parallax-image {
    width: 100%;
    height: 640px;
    overflow: hidden;
  }
  .parallax .parallax-image img {
    height: 100%;
    width: auto;
  }
}
.separator-line,
.separator.separator-gray {
  background-color: #eee;
}
.separator {
  content: "Separator";
  color: #fff;
  display: block;
  width: 100%;
  padding: 20px;
}
.separator-line {
  height: 1px;
  width: 100%;
  display: block;
}
.social-buttons-demo .btn {
  margin: 10px 5px 7px 1px;
}
.img-container {
  width: 100%;
  overflow: hidden;
}
.img-container img {
  width: 100%;
}
.section-black,
.sharing-area .btn-github {
  background-color: #333;
}
.animate {
  transition: 1.5s ease-in-out;
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;
}
.sharing-area {
  margin-top: 80px;
}
.sharing-area .btn {
  margin: 15px 4px 0;
  color: #fff;
}
.sharing-area .btn i {
  font-size: 18px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
#navbar {
  margin-bottom: -20px;
}
.sharing-area .btn-twitter {
  background-color: #55acee;
}
.sharing-area .btn-facebook {
  background-color: #3b5998;
}
.sharing-area .btn-instagram {
  background-color: #e4405f;
}
#navbar .navbar {
  border-radius: 0;
}
@media (max-width: 830px) {
  .main-raised {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .tab-pane#schedule-2 {
    padding-top: 20px;
  }
}

@media all and (max-width: 375px) {
  .pagination.pagination-info .page-item:nth-of-type(2),
  .pagination.pagination-primary .page-item:nth-of-type(3),
  .pagination.pagination-primary .page-item:nth-of-type(7) {
    display: none;
  }
  .main.main-raised {
    margin-top: -30px;
  }
  .form-group.is-focused .bootstrap-datetimepicker-widget {
    left: -32px !important;
  }
}
.show.modal .dropdown-toggle:after {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
.form-check {
  margin-bottom: 0.5rem;
  padding-left: 0;
}
.form-check .form-check-label {
  cursor: pointer;
  padding-left: 0;
}
.form-group.is-focused .form-check .form-check-label,
fieldset[disabled] .form-group.is-focused .form-check .form-check-label {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .form-check .form-check-label:focus,
.form-group.is-focused .form-check .form-check-label:hover {
  color: rgba(0, 0, 0, 0.54);
}
.form-check .form-check-input {
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none;
}
.form-check .form-check-sign {
  vertical-align: middle;
  position: relative;
  top: -2px;
  padding-right: 10px;
  display: inline-block;
}
.form-check .form-check-sign:before {
  display: block;
  position: absolute;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  height: 20px;
  width: 20px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  top: 0;
  -webkit-transform: scale3d(2.3, 2.3, 1);
  -moz-transform: scale3d(2.3, 2.3, 1);
  -o-transform: scale3d(2.3, 2.3, 1);
  -ms-transform: scale3d(2.3, 2.3, 1);
  transform: scale3d(2.3, 2.3, 1);
}
.form-check .form-check-sign .check {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  overflow: hidden;
  z-index: 1;
  border-radius: 3px;
}
.form-check .form-check-sign .check:before {
  position: absolute;
  content: "";
  transform: rotate(45deg);
  display: block;
  margin-top: -3px;
  margin-left: 7px;
  width: 0;
  color: #fff;
  height: 0;
  box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0,
    0 0 0 0 inset;
  -webkit-animation: 0.3s forwards checkbox-off;
  -moz-animation: 0.3s forwards checkbox-off;
  -o-animation: 0.3s forwards checkbox-off;
  -ms-animation: checkbox-off 0.3s forwards;
  animation: 0.3s forwards checkbox-off;
}
.form-check .form-check-input:focus + .form-check-sign .check:after {
  opacity: 0.2;
}
.form-check .form-check-input:checked ~ .form-check-sign .check {
  background: #e50914;
}
.form-check .form-check-input:checked ~ .form-check-sign .check:before {
  color: #fff;
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
    -5px 5px 0 10px, 20px -12px 0 11px;
  -webkit-animation: 0.3s forwards checkbox-on;
  -moz-animation: 0.3s forwards checkbox-on;
  -o-animation: 0.3s forwards checkbox-on;
  -ms-animation: checkbox-on 0.3s forwards;
  animation: 0.3s forwards checkbox-on;
}
.light_theme .css-1wbuyyp-ValueContainer{
   background-color: #fff;
}
.form-check .form-check-input:checked ~ .form-check-sign:before {
  -webkit-animation: 0.5s rippleOn;
  -moz-animation: 0.5s rippleOn;
  -o-animation: 0.5s rippleOn;
  -ms-animation: rippleOn 500ms;
  animation: 0.5s rippleOn;
}
.form-check .form-check-input:checked ~ .form-check-sign .check:after {
  -webkit-animation: 0.5s forwards rippleOn;
  -moz-animation: 0.5s forwards rippleOn;
  -o-animation: 0.5s forwards rippleOn;
  -ms-animation: rippleOn 500ms forwards;
  animation: 0.5s forwards rippleOn;
}
.form-check .form-check-input:not(:checked) + .form-check-sign .check:after,
.form-check .form-check-input:not(:checked) + .form-check-sign:before {
  -webkit-animation: 0.5s rippleOff;
  -moz-animation: 0.5s rippleOff;
  -o-animation: 0.5s rippleOff;
  -ms-animation: rippleOff 500ms;
  animation: 0.5s rippleOff;
}
.form-check .form-check-input[disabled] + .circle,
.form-check .form-check-input[disabled] ~ .form-check-sign .check,
fieldset[disabled] .form-check,
fieldset[disabled] .form-check .form-check-input {
  opacity: 0.5;
}
.form-check .form-check-input[disabled] ~ .form-check-sign .check {
  border-color: #000;
  opacity: 0.26;
}
.form-check .form-check-input[disabled] + .form-check-sign .check:after {
  background-color: rgba(0, 0, 0, 0.87);
  transform: rotate(-45deg);
}
.form-check .form-check-input[disabled][checked] + .form-check-sign .check {
  background-color: #000;
}
@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 15px 2px 0 11px;
  }

  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px 2px 0 11px;
  }

  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}
.switch label {
  position: relative;
  padding-left: 2.4375rem;
}
.switch label .bmd-switch-track {
  position: absolute;
  top: 0.3125rem;
  left: 0;
  display: inline-block;
  width: 2.125rem;
  height: 0.875rem;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.12) 0,
    rgba(0, 0, 0, 0.12) 50%,
    #c49bcb 50%,
    #c49bcb 100%
  );
  background-position: 0;
  background-size: 4.25rem 0.875rem;
  border-radius: 2.125rem;
  transition: background-position 0.2s ease-in;
}
.switch label .bmd-switch-track::after {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  align-self: center;
  width: 1.25rem;
  height: 1.25rem;
  content: "";
  background: #f1f1f1;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  transition: left 0.2s ease-in, background-color 0.2s ease-in, transform 0.3s;
  transform: translateY(-50%);
}
.switch label .bmd-switch-track:active::after {
  transform: translateY(-50%) scale3d(1.15, 0.85, 1);
}
.switch label input {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
}
.switch label input:checked + .bmd-switch-track {
  background-position: -100%;
}
.switch label input:checked + .bmd-switch-track::after {
  left: calc(100% - 1.25rem);
  background-color: #e50914;
}
.switch label input:disabled + .bmd-switch-track:active::after {
  transform: translateY(-50%);
}
.switch label input:disabled + .bmd-switch-track {
  cursor: default;
  background: rgba(0, 0, 0, 0.12);
}
.switch label input:disabled + .bmd-switch-track::after {
  background: #bdbdbd;
}
.form-check .form-check-label {
  cursor: pointer;
  padding-left: 25px;
  position: relative;
}
.form-group.is-focused .form-check .form-check-label,
fieldset[disabled] .form-group.is-focused .form-check .form-check-label {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .form-check .form-check-label:focus,
.form-group.is-focused .form-check .form-check-label:hover {
  color: rgba(0, 0, 0, 0.54);
}
.form-check .form-check-label span {
  display: block;
  position: absolute;
  left: -1px;
  top: -1px;
  transition-duration: 0.2s;
}
.form-check .form-check-label .circle {
  border: 1px solid rgba(0, 0, 0, 0.54);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  top: 1px;
}
.form-check .form-check-label .circle .check {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: #e50914;
  -webkit-transform: scale3d(0, 0, 0);
  -moz-transform: scale3d(0, 0, 0);
  -o-transform: scale3d(0, 0, 0);
  -ms-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}
.form-check .form-check-input {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}
.form-check .form-check-input:checked ~ .check,
.form-check .form-check-input:checked ~ .circle {
  opacity: 1;
}
.form-check .form-check-input:checked ~ .check {
  background-color: #e50914;
}
.form-check .form-check-input:checked ~ .circle {
  border-color: #e50914;
}
.form-check .form-check-input:checked .check:before {
  animation: 0.5s forwards checkboxOn;
}
.form-check .form-check-input:checked ~ .circle .check {
  -webkit-transform: scale3d(0.65, 0.65, 1);
  -moz-transform: scale3d(0.65, 0.65, 1);
  -o-transform: scale3d(0.65, 0.65, 1);
  -ms-transform: scale3d(0.65, 0.65, 1);
  transform: scale3d(0.65, 0.65, 1);
}
.form-check .form-check-input[disabled] ~ .check,
.form-check .form-check-input[disabled] ~ .circle {
  opacity: 0.26;
}
.form-check .form-check-input[disabled] + .circle .check,
.form-check .form-check-input[disabled] ~ .check {
  background-color: #000;
}
.form-check .form-check-input[disabled] ~ .circle {
  border-color: #000;
}
.form-check .form-check-sign {
  vertical-align: middle;
  position: relative;
  top: -2px;
  padding-right: 10px;
  display: inline-block;
}
.form-check .form-check-label .circle:before {
  display: block;
  position: absolute;
  left: -1px;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  top: -1px;
  -webkit-transform: scale3d(2.3, 2.3, 1);
  -moz-transform: scale3d(2.3, 2.3, 1);
  -o-transform: scale3d(2.3, 2.3, 1);
  -ms-transform: scale3d(2.3, 2.3, 1);
  transform: scale3d(2.3, 2.3, 1);
}
.form-check .form-check-label .form-check-input:checked + .circle:before {
  animation: 0.5s rippleOn;
}
.form-check
  .form-check-label
  .form-check-input:checked
  + .circle
  .check:before {
  color: #fff;
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
    -5px 5px 0 10px, 20px -12px 0 11px;
  animation: 0.3s forwards checkboxOn;
}
.form-check + .form-check {
  margin-top: 0;
}
@keyframes checkboxOn {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 15px 2px 0 11px;
  }

  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px 2px 0 11px;
  }

  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}
form {
  margin-bottom: 1.125rem;
}
.card form {
  margin: 0;
}
.navbar form,
.navbar form .btn {
  margin-bottom: 0;
}
.navbar form .bmd-form-group {
  display: inline-block;
  padding-top: 0;
}
.form-control {
  background: center bottom no-repeat, center calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  border: 0;
  height: 36px;
  transition: background ease-out;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 14px;
}
.bmd-form-group.is-focused .form-control,
.form-control:focus {
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
  box-shadow: none;
}
.bmd-help {
  position: absolute;
  display: none;
  font-size: 0.8rem;
  font-weight: 400;
}
.bmd-form-group.is-focused .bmd-help {
  display: block;
}
.bmd-help:nth-of-type(2) {
  padding-top: 1rem;
}
.bmd-help + .bmd-help {
  position: relative;
  margin-bottom: 0;
}
.bmd-label-static,
.checkbox label,
.radio label,
label {
  font-size: 0.875rem;
}
.form-control,
label label {
  color: #aaa;
}
.checkbox label,
.radio label,
.switch label,
label.checkbox-inline,
label.radio-inline {
  line-height: 1.5;
}
.bmd-label-floating .is-focused .bmd-label-floating,
.is-filled .bmd-label-floating {
  top: 1rem;
  left: 0;
  font-size: 0.6875rem;
}
.bmd-label-static {
  top: 0.35rem;
  left: 0;
}
.bmd-help {
  margin-top: 0;
  font-size: 0.75rem;
}
.form-control.form-control-danger,
.form-control.form-control-success,
.form-control.form-control-warning {
  background-size: 0 100%, 100% 100%, 0.9375rem 0.9375rem;
}
.bmd-form-group.is-focused .form-control.form-control-danger,
.bmd-form-group.is-focused .form-control.form-control-success,
.bmd-form-group.is-focused .form-control.form-control-warning,
.form-control.form-control-danger,
.form-control.form-control-danger:focus,
.form-control.form-control-success,
.form-control.form-control-success:focus,
.form-control.form-control-warning,
.form-control.form-control-warning:focus {
  padding-right: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px),
    center right 0.46875rem;
}
.bmd-form-group.is-focused .form-control.form-control-danger,
.bmd-form-group.is-focused .form-control.form-control-success,
.bmd-form-group.is-focused .form-control.form-control-warning,
.form-control.form-control-danger:focus,
.form-control.form-control-success:focus,
.form-control.form-control-warning:focus {
  background-size: 100% 100%, 100% 100%, 0.9375rem 0.9375rem;
}
select,
select.form-control {
  --moz-appearance: none;
  --webkit-appearance: none;
}
@media (min-width: 576px) {
  .form-inline .input-group {
    display: inline-flex;
    align-items: center;
  }
}
.form-group {
  margin-bottom: 17px;
  position: relative;
}
textarea {
  height: auto !important;
  resize: none;
  line-height: 1.428571 !important;
}
.form-group input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.form-newsletter .form-group,
.form-newsletter .input-group {
  float: left;
  width: 78%;
  margin-right: 2%;
  margin-top: 9px;
  padding-top: 5px;
}
.form-newsletter .btn {
  float: left;
  width: 20%;
  margin: 9px 0 0;
}
.form-file-upload .input-group-btn:last-child > .btn-round {
  border-radius: 30px;
}
.form-file-upload .input-group-btn .btn {
  margin: 0;
}
.form-file-upload .input-group {
  width: 100%;
}
.input-group .input-group-btn {
  padding: 0 12px;
}
.input-group .input-group-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px !important;
  background-color: transparent;
  border-color: transparent;
}
.navbar.bg-info,
.navbar.bg-primary {
  background-color: #e50914 !important;
}
.input-group .input-group-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 0;
}
.list-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.5rem 0;
}
.list-group.bmd-list-group-sm {
  padding: 0.25rem 0;
}
.list-group.bmd-list-group-sm .list-group-item {
  padding: 0.5rem 1rem;
}
.bmd-list-group-col {
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.list-group-item {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 1rem;
  line-height: 1;
}
.list-group-item .list-group-item-text {
  min-width: 0;
  max-height: 2.188rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  color: #555;
}
.list-group-item :first-child {
  margin-right: 2rem;
}
.list-group-item > * ~ .label:last-child,
.list-group-item > * ~ .material-icons:last-child,
.list-group-item > .pull-lg-right,
.list-group-item > .pull-md-right,
.list-group-item > .pull-sm-right,
.list-group-item > .pull-xl-right,
.list-group-item > .pull-xs-right {
  margin-right: 0;
  margin-left: auto;
}
.list-group-item .material-icons.pull-lg-right,
.list-group-item .material-icons.pull-md-right,
.list-group-item .material-icons.pull-sm-right,
.list-group-item .material-icons.pull-xl-right,
.list-group-item .material-icons.pull-xs-right,
.list-group-item .material-icons ~ .material-icons:last-child {
  padding-left: 1rem;
}
.nav-link {
  text-transform: capitalize;
}
.navbar-nav .nav-link {
  padding: 0.5321rem;
  font-size: 0.875rem;
  font-weight: 400;
}
.nav-pills,
.nav-tabs {
  border: 0;
  border-radius: 3px;
}
.nav-pills .nav-link,
.nav-tabs .nav-link {
  padding: 1.4286em 0.8575em;
  font-size: 0.875rem;
  font-weight: 500;
  border: 0;
}
.nav .nav-item {
  position: relative;
}
.img-thumbnail {
  border-radius: 16px;
}
.img-raised {
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.rounded {
  border-radius: 6px !important;
}
.makeStyles-appBar-6,
.navbar {
  border: 0 !important;
  border-radius: 0 !important;
  padding: 15px 0 !important;
  margin-bottom: 20px;
  color: #fff !important;
  background-color: #1e2023 !important;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.12),
    0 7px 10px -5px rgba(0, 0, 0, 0.15);
}
.navbar .dropdown-item:focus,
.navbar .dropdown-item:hover {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(255, 255, 255, 0.4);
  background-color: #fff;
  color: #555;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-color: #555;
  position: relative;
  width: 22px;
  height: 2px;
  outline: 0;
  display: block;
  border-radius: 1px;
}
#navbar .navbar,
.navbar.fixed-top {
  border-radius: 0;
}
.navbar .navbar-nav .nav-item .nav-link {
  position: relative;
  color: inherit;
  padding: 0.9375rem;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  line-height: 20px;
}
.navbar .navbar-nav .nav-item .nav-link:not(.btn-just-icon) .fa {
  position: relative;
  top: 2px;
  margin-top: -4px;
  margin-right: 4px;
}
.navbar .navbar-nav .nav-item .nav-link .fa,
.navbar .navbar-nav .nav-item .nav-link .material-icons {
  font-size: 1.25rem;
  max-width: 24px;
  margin-top: -1.1em;
}
.navbar .navbar-nav .nav-item .nav-link:not(.btn) .material-icons {
  margin-top: -7px;
  top: 3px;
  position: relative;
  margin-right: 3px;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo {
  padding: 0;
  margin: 0 3px;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo:after {
  display: none;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo .profile-photo-small {
  height: 40px;
  width: 40px;
}
.navbar .navbar-nav .dropdown-menu-right {
  transform-origin: 100% 0;
}
.navbar .navbar-nav .nav-item.active .nav-link,
.navbar .navbar-nav .nav-item.active .nav-link:focus,
.navbar .navbar-nav .nav-item.active .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar .btn,
.navbar .navbar-nav .nav-item .btn {
  margin-top: 0;
  margin-bottom: 0;
}
.navbar .navbar-toggler {
  cursor: pointer;
}
.navbar .navbar-toggler .navbar-toggler-icon + .navbar-toggler-icon {
  margin-top: 4px;
}
.navbar.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
}
.navbar .navbar-wrapper {
  display: inline-flex;
  align-items: center;
}
.navbar .navbar-brand {
  position: relative;
  color: inherit;
  height: 50px;
  font-size: 1.125rem;
  line-height: 30px;
  padding: 0.625rem 0;
}
.navbar.bg-primary {
  color: #fff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(156, 39, 176, 0.46);
}
.navbar.bg-primary .dropdown-item:focus,
.navbar.bg-primary .dropdown-item:hover {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(156, 39, 176, 0.4);
  background-color: #e50914;
  color: #fff;
}
.navbar.bg-danger .navbar-toggler .navbar-toggler-icon,
.navbar.bg-dark .navbar-toggler .navbar-toggler-icon,
.navbar.bg-info .navbar-toggler .navbar-toggler-icon,
.navbar.bg-primary .navbar-toggler .navbar-toggler-icon,
.navbar.bg-rose .navbar-toggler .navbar-toggler-icon,
.navbar.bg-success .navbar-toggler .navbar-toggler-icon,
.navbar.bg-warning .navbar-toggler .navbar-toggler-icon {
  background-color: #fff;
}
.navbar.bg-info {
  color: #fff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(0, 188, 212, 0.46);
}
.navbar.bg-info .dropdown-item:focus,
.navbar.bg-info .dropdown-item:hover {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 188, 212, 0.4);
  background-color: #e50914;
  color: #fff;
}
.navbar.bg-warning {
  color: #fff;
  background-color: #ff9800 !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(255, 152, 0, 0.46);
}
.navbar.bg-warning .dropdown-item:focus,
.navbar.bg-warning .dropdown-item:hover {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(255, 152, 0, 0.4);
  background-color: #ff9800;
  color: #fff;
}
.navbar.bg-rose {
  color: #fff;
  background-color: #e91e63 !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(233, 30, 99, 0.46);
}
.navbar.bg-rose .dropdown-item:focus,
.navbar.bg-rose .dropdown-item:hover {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(233, 30, 99, 0.4);
  background-color: #e91e63;
  color: #fff;
}
.navbar.bg-danger {
  color: #fff;
  background-color: #f44336 !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(244, 67, 54, 0.46);
}
.navbar.bg-danger .dropdown-item:focus,
.navbar.bg-danger .dropdown-item:hover {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(244, 67, 54, 0.4);
  background-color: #f44336;
  color: #fff;
}
.navbar.bg-success {
  color: #fff;
  background-color: #4caf50 !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(76, 175, 80, 0.46);
}
.navbar.bg-success .dropdown-item:focus,
.navbar.bg-success .dropdown-item:hover {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(76, 175, 80, 0.4);
  background-color: #4caf50;
  color: #fff;
}
.navbar.bg-dark {
  color: #fff;
  background-color: #212121 !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 12px -5px rgba(33, 33, 33, 0.46);
}
.navbar.bg-dark .dropdown-item:focus,
.navbar.bg-dark .dropdown-item:hover {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(33, 33, 33, 0.4);
  background-color: #212121;
  color: #fff;
}
.navbar.navbar-transparent {
  background-color: transparent !important;
  box-shadow: none;
  padding-top: 25px;
  color: #fff;
}
.alert b,
.badge {
  text-transform: uppercase;
}
.badge {
  padding: 5px 12px;
  font-size: 10px;
  color: #fff;
}
.badge-primary[href]:focus,
.badge-primary[href]:hover,
.badge.badge-info,
.badge.badge-primary {
  background-color: #e50914;
}
.badge.badge-success {
  background-color: #4caf50;
}
.badge.badge-warning {
  background-color: #ff9800;
}
.badge.badge-danger {
  background-color: #f44336;
}
.badge.badge-rose {
  background-color: #e91e63;
}
.badge.badge-default {
  background-color: #999;
}
.badge-default[href]:focus,
.badge-default[href]:hover {
  background-color: #c1c1c1;
}
.badge-info[href]:focus,
.badge-info[href]:hover {
  background-color: #00a5bb;
}
.badge-success[href]:focus,
.badge-success[href]:hover {
  background-color: #449d48;
}
.badge-warning[href]:focus,
.badge-warning[href]:hover {
  background-color: #e68900;
  color: #fff;
}
.badge-danger[href]:focus,
.badge-danger[href]:hover {
  background-color: #f32c1e;
}
.badge-rose[href]:focus,
.badge-rose[href]:hover {
  background-color: #d81558;
}
.alert {
  border: 0;
  border-radius: 0;
  padding: 20px 15px;
  line-height: 20px;
}
.alert b {
  font-weight: 500;
  font-size: 12px;
}
.alert,
.alert.alert-default {
  background-color: #fff;
  color: #555;
}
.alert .alert-link,
.alert a,
.alert.alert-default .alert-link,
.alert.alert-default a {
  color: #555;
}
.alert.alert-inverse {
  background-color: #292929;
  color: #fff;
}
.alert-danger,
.alert-info,
.alert-success,
.alert-warning,
.alert.alert-danger .alert-link,
.alert.alert-danger a,
.alert.alert-info .alert-link,
.alert.alert-info a,
.alert.alert-inverse .alert-link,
.alert.alert-inverse a,
.alert.alert-primary .alert-link,
.alert.alert-primary a,
.alert.alert-rose .alert-link,
.alert.alert-rose a,
.alert.alert-success .alert-link,
.alert.alert-success a,
.alert.alert-warning .alert-link,
.alert.alert-warning a {
  color: #fff;
}
.alert.alert-primary {
  background-color: #a72abd;
  color: #fff;
}
.alert.alert-success {
  background-color: #55b559;
  color: #fff;
}
.alert.alert-info {
  background-color: #00cae3;
  color: #fff;
}
.alert.alert-warning {
  background-color: #ff9e0f;
  color: #fff;
}
.alert.alert-danger {
  background-color: #f55145;
  color: #fff;
}
.alert.alert-rose {
  background-color: #ea2c6d;
  color: #fff;
}
.alert-default .alert-link,
.alert-default a {
  color: rgba(0, 0, 0, 0.87);
}
.alert .alert-icon {
  display: block;
  float: left;
  margin-right: 1.071rem;
}
.alert .alert-icon i {
  margin-top: -7px;
  top: 5px;
  position: relative;
}
.alert .close {
  color: #fff;
  text-shadow: none;
  opacity: 0.9;
}
.alert .close i {
  font-size: 20px;
}
.alert .close:focus,
.alert .close:hover {
  opacity: 1;
}
.pagination > .page-item > .page-link,
.pagination > .page-item > span {
  border: 0;
  border-radius: 30px !important;
  transition: 0.3s;
  padding: 0 11px;
  margin: 0 3px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  color: #999;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  background: 0 0;
  text-align: center;
}
.pagination > .page-item.active > a,
.pagination > .page-item.active > span,
.pagination > .page-item > .page-link:focus,
.pagination > .page-item > .page-link:hover,
.pagination > .page-item > span:focus,
.pagination > .page-item > span:hover {
  color: #999;
}
.pagination > .page-item.active > a,
.pagination > .page-item.active > a:focus,
.pagination > .page-item.active > a:hover,
.pagination > .page-item.active > span,
.pagination > .page-item.active > span:focus,
.pagination > .page-item.active > span:hover {
  background-color: #e50914;
  border-color: #e50914;
  color: #fff;
  box-shadow: 0 4px 5px 0 rgba(156, 39, 176, 0.14),
    0 1px 10px 0 rgba(156, 39, 176, 0.12),
    0 2px 4px -1px rgba(156, 39, 176, 0.2);
}
.pagination.pagination-info > .page-item.active > a,
.pagination.pagination-info > .page-item.active > a:focus,
.pagination.pagination-info > .page-item.active > a:hover,
.pagination.pagination-info > .page-item.active > span,
.pagination.pagination-info > .page-item.active > span:focus,
.pagination.pagination-info > .page-item.active > span:hover {
  background-color: #e50914;
  border-color: #e50914;
  box-shadow: 0 4px 5px 0 rgba(0, 188, 212, 0.14),
    0 1px 10px 0 rgba(0, 188, 212, 0.12), 0 2px 4px -1px rgba(0, 188, 212, 0.2);
}
.pagination.pagination-success > .page-item.active > a,
.pagination.pagination-success > .page-item.active > a:focus,
.pagination.pagination-success > .page-item.active > a:hover,
.pagination.pagination-success > .page-item.active > span,
.pagination.pagination-success > .page-item.active > span:focus,
.pagination.pagination-success > .page-item.active > span:hover {
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: 0 4px 5px 0 rgba(76, 175, 80, 0.14),
    0 1px 10px 0 rgba(76, 175, 80, 0.12), 0 2px 4px -1px rgba(76, 175, 80, 0.2);
}
.pagination.pagination-warning > .page-item.active > a,
.pagination.pagination-warning > .page-item.active > a:focus,
.pagination.pagination-warning > .page-item.active > a:hover,
.pagination.pagination-warning > .page-item.active > span,
.pagination.pagination-warning > .page-item.active > span:focus,
.pagination.pagination-warning > .page-item.active > span:hover {
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: 0 4px 5px 0 rgba(255, 152, 0, 0.14),
    0 1px 10px 0 rgba(255, 152, 0, 0.12), 0 2px 4px -1px rgba(255, 152, 0, 0.2);
}
.pagination.pagination-danger > .page-item.active > a,
.pagination.pagination-danger > .page-item.active > a:focus,
.pagination.pagination-danger > .page-item.active > a:hover,
.pagination.pagination-danger > .page-item.active > span,
.pagination.pagination-danger > .page-item.active > span:focus,
.pagination.pagination-danger > .page-item.active > span:hover {
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: 0 4px 5px 0 rgba(244, 67, 54, 0.14),
    0 1px 10px 0 rgba(244, 67, 54, 0.12), 0 2px 4px -1px rgba(244, 67, 54, 0.2);
}
.pagination .page-item .page-link:focus {
  box-shadow: none;
}
.blockquote small,
.space,
.space-110,
.space-50,
.space-70 {
  display: block;
}
.nav-pills .nav-item .nav-link,
.nav-tabs .nav-item .nav-link {
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 15px;
}
.nav-pills:not(.flex-column) .nav-item + .nav-item:not(:first-child) {
  margin-left: 5px;
}
.nav-pills.flex-column .nav-item + .nav-item {
  margin-top: 5px;
}
.nav-pills .nav-item .nav-link {
  font-weight: 500;
  min-width: 100px;
  text-align: center;
  color: #555;
  transition: 0.3s;
  border-radius: 30px;
}
.nav-pills .nav-item .nav-link:hover {
  background-color: rgba(200, 200, 200, 0.2);
}
.nav-pills .nav-item .nav-link.active {
  color: #fff;
  background-color: #e50914;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(156, 39, 176, 0.6);
}
.nav-pills .nav-item i {
  display: block;
  font-size: 30px;
  padding: 15px 0;
}
.nav-pills.nav-pills-info .nav-item .nav-link.active,
.nav-pills.nav-pills-info .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-info .nav-item .nav-link.active:hover {
  background-color: #e50914;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(0, 188, 212, 0.6);
  color: #fff;
}
.nav-pills.nav-pills-rose .nav-item .nav-link.active,
.nav-pills.nav-pills-rose .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-rose .nav-item .nav-link.active:hover {
  background-color: #e91e63;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(233, 30, 99, 0.6);
  color: #fff;
}
.nav-pills.nav-pills-success .nav-item .nav-link.active,
.nav-pills.nav-pills-success .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-success .nav-item .nav-link.active:hover {
  background-color: #4caf50;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(76, 175, 80, 0.6);
  color: #fff;
}
.nav-pills.nav-pills-warning .nav-item .nav-link.active,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:hover {
  background-color: #ff9800;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(255, 152, 0, 0.6);
  color: #fff;
}
.nav-pills.nav-pills-danger .nav-item .nav-link.active,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:hover {
  background-color: #f44336;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(244, 67, 54, 0.6);
  color: #fff;
}
.nav-pills.nav-pills-icons .nav-item .nav-link {
  border-radius: 4px;
}
.tab-space {
  padding: 20px 0 50px;
}
.card-description,
.description,
.footer-big p,
.info .icon,
.info p,
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link.disabled:focus,
.nav-tabs .nav-link.disabled:hover {
  color: #999;
}
.info .icon > i {
  font-size: 3.85rem;
}
.info .info-title {
  color: #000;
  margin: 1.75rem 0 0.875rem;
}
.info-horizontal .icon {
  float: left;
  margin-top: 24px;
  margin-right: 10px;
}
.info-horizontal .icon > i {
  font-size: 2.25rem;
}
.info-horizontal .description {
  overflow: hidden;
}
.icon.icon-info,
.icon.icon-primary {
  color: #e50914;
}
.icon.icon-success {
  color: #4caf50;
}
.icon.icon-warning {
  color: #ff9800;
}
.icon.icon-danger {
  color: #f44336;
}
.icon.icon-rose {
  color: #e91e63;
}
.small,
small {
  font-size: 75%;
  color: #777;
}
.card-title,
.footer-big h4,
.footer-big h5,
.footer-brand,
.info-title,
.media .media-heading,
.title {
  font-weight: 500;
}
.card-title,
.card-title a,
.footer-big h4,
.footer-big h4 a,
.footer-big h5,
.footer-big h5 a,
.footer-brand,
.footer-brand a,
.info-title,
.info-title a,
.media .media-heading,
.media .media-heading a,
.title,
.title a {
  color: #000;
  text-decoration: none;
}
h2.title {
  margin-bottom: 1rem;
}
.text-warning {
  color: #ff9800 !important;
}
.text-info,
.text-primary {
  color: #e50914 !important;
}
.text-danger {
  color: #f44336 !important;
}
.text-success {
  color: #4caf50 !important;
}
.text-rose {
  color: #e91e63 !important;
}
.text-gray {
  color: #999 !important;
}
.space {
  height: 130px;
}
.space-110 {
  height: 110px;
}
.space-50 {
  height: 50px;
}
.space-70 {
  height: 70px;
}
.blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #eee;
}
.blockquote p {
  font-size: 1.063rem;
  font-style: italic;
}
.blockquote small {
  font-size: 80%;
  line-height: 1.42857143;
  color: #777;
}
.nav-tabs {
  border: 0;
  border-radius: 3px;
}
.nav-tabs .nav-item .nav-link {
  margin: 0;
  border-radius: 3px;
  background-color: transparent;
  transition: background-color 0.3s;
}
.nav-tabs .nav-item .nav-link,
.nav-tabs .nav-item .nav-link:focus,
.nav-tabs .nav-item .nav-link:hover {
  border: 0 !important;
  color: #fff !important;
  font-weight: 500;
}
.nav-tabs .nav-item.disabled .nav-link,
.nav-tabs .nav-item.disabled .nav-link:hover {
  color: rgba(255, 255, 255, 0.5);
}
.nav-tabs .nav-item .material-icons {
  margin: -1px 5px 0 0;
}
.nav-tabs .nav-item .nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  transition: background-color 0.3s 0.2s;
}
.nav-tabs .nav-link {
  border-bottom: 0.214rem solid transparent;
  color: #555;
}
.nav-tabs .nav-link.active {
  color: rgba(0, 0, 0, 0.87);
  border-color: #e50914;
}
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:hover {
  border-color: #e50914;
}
.nav-tabs.header-primary .nav-link {
  color: #fff;
}
.nav-tabs.header-primary .nav-link.active {
  color: #fff;
  border-color: #fff;
}
.nav-tabs.header-primary .nav-link.active:focus,
.nav-tabs.header-primary .nav-link.active:hover {
  border-color: #fff;
}
.nav-tabs.header-primary .nav-link.disabled {
  color: rgba(255, 255, 255, 0.84);
}
.nav-tabs.bg-inverse .nav-link.disabled,
.nav-tabs.bg-inverse .nav-link.disabled:focus,
.nav-tabs.bg-inverse .nav-link.disabled:hover,
.nav-tabs.header-primary .nav-link.disabled,
.nav-tabs.header-primary .nav-link.disabled:focus,
.nav-tabs.header-primary .nav-link.disabled:hover {
  color: rgba(255, 255, 255, 0.84);
}
.nav-tabs.bg-inverse .nav-link {
  color: #fff;
}
.nav-tabs.bg-inverse .nav-link.active {
  color: #fff;
  border-color: #fff;
}
.nav-tabs.bg-inverse .nav-link.active:focus,
.nav-tabs.bg-inverse .nav-link.active:hover {
  border-color: #fff;
}
.card-nav-tabs {
  margin-top: 45px;
}
.card-nav-tabs .card-header {
  margin-top: -30px !important;
}
.tooltip-arrow {
  display: none;
}
.tooltip.show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.tooltip {
  opacity: 0;
  transition: opacity, transform 0.2s;
  -webkit-transform: translate3d(0, 5px, 0);
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  font-size: 0.875rem;
}
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #fff;
}
.tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #fff;
}
.tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip.bs-tooltip-left .arrow::before {
  border-left-color: #fff;
}
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #fff;
}
.tooltip-inner {
  padding: 10px 15px;
  min-width: 130px;
}
.popover,
.tooltip-inner {
  line-height: 1.5em;
  background: #fff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  color: #555;
}
.popover {
  padding: 0;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.popover.bottom > .arrow,
.popover.left > .arrow,
.popover.right > .arrow,
.popover.top > .arrow {
  border: none;
}
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover.bs-popover-auto[x-placement^="left"] .arrow::before,
.popover.bs-popover-auto[x-placement^="right"] .arrow::before,
.popover.bs-popover-auto[x-placement^="top"] .arrow::before,
.popover.bs-popover-bottom .arrow::before,
.popover.bs-popover-left .arrow::before,
.popover.bs-popover-right .arrow::before,
.popover.bs-popover-top .arrow::before {
  border: 0;
}
.popover-header {
  background-color: #fff;
  border: none;
  padding: 15px 15px 5px;
  font-size: 1.125rem;
  margin: 0;
  color: #555;
}
.popover-body {
  padding: 10px 15px 15px;
  line-height: 1.4;
  color: #555;
}
.modal-dialog .modal-content {
  box-shadow: 0 0;
  border-radius: 6px;
  border: none;
}
.modal-dialog .modal-content .card-signup {
  margin: 0;
}
.modal-dialog .modal-body + .modal-footer,
.modal-dialog .modal-content .card-signup .modal-header {
  padding-top: 0;
}
.modal-dialog .modal-header {
  border-bottom: none;
  padding: 24px 24px 0;
}
.modal-dialog .modal-body {
  padding-top: 24px;
  padding-right: 24px !important;
  padding-bottom: 16px;
  /* padding-left: 40px !important; */
  padding-left: 24px !important;

}
.whtclrss{
  color: #fff !important;
}
.light_theme .whtclrss{
  color: #000 !important;
}
.mobilemodal .modal-header{
  justify-content: end;
  padding: 0px 10px 0;
}
.mobilemodal .modal-content {
  background-color: #d9d9d9;
}
.mobilemodal .modal-body{
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 5px !important;
}
.mobilemodal .card {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.modal-dialog .modal-footer {
  border-top: none;
  padding: 24px;
}
.modal-dialog .modal-footer.text-center {
  text-align: center;
}
.modal-dialog .modal-footer button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}
.modal-dialog .modal-footer button.pull-left {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  left: -5px;
}
.modal .modal-header .close {
  color: #999;
}
.modal .modal-header .close:focus,
.modal .modal-header .close:hover {
  opacity: 1;
}
.modal .modal-header .close i {
  font-size: 16px;
}
.dropdown:after {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
.dropdown.show > .dropdown-toggle:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dropdown-menu {
  display: none;
  padding: 0.3125rem 0;
  border: 0;
  opacity: 0;
  transform: scale(0);
  transform-origin: 0 0;
  will-change: transform, opacity;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.dropdown-menu.showing {
  animation-name: bmd-dropdown-animation;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.dropdown-menu.show,
.open > .dropdown-menu {
  display: block;
  opacity: 1;
  transform: scale(1);
}
.dropdown-menu.hiding {
  display: block;
  opacity: 0;
  transform: scale(0);
}
.dropdown-menu[x-placement="bottom-start"] {
  transform-origin: 0 0;
}
.dropdown-menu[x-placement="bottom-end"] {
  transform-origin: 100% 0;
}
.dropdown-menu[x-placement="top-start"] {
  transform-origin: 0 100%;
}
.dropdown-menu[x-placement="top-end"] {
  transform-origin: 100% 100%;
}
.dropdown-menu .disabled > a {
  color: #777;
}
.dropdown-menu .disabled > a:focus,
.dropdown-menu .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  color: #777;
}
.dropdown-menu.dropdown-with-icons .dropdown-item {
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
}
.dropdown-menu.dropdown-with-icons .dropdown-item .material-icons {
  vertical-align: middle;
  font-size: 24px;
  position: relative;
  margin-top: -4px;
  top: 1px;
  margin-right: 12px;
  opacity: 0.5;
}
.dropdown-menu .dropdown-item,
.dropdown-menu li > a {
  position: relative;
  width: auto;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  color: #fff;
  text-decoration: none;
  border-radius: 0.125rem;
  margin: 0 0.3125rem;
  -webkit-transition: 150ms linear;
  -moz-transition: 150ms linear;
  -o-transition: 150ms linear;
  -ms-transition: 150ms linear;
  transition: 150ms linear;
  min-width: 7rem;
  padding: 0.625rem 1.25rem;
  overflow: hidden;
  line-height: 1.428571;
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-size: 14px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .dropdown-menu .dropdown-item,
  .dropdown-menu li > a {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
.dropdown-toggle.bmd-btn-icon::after,
.dropdown-toggle.bmd-btn-fab::after {
  display: none;
}
.dropdown-toggle.bmd-btn-fab ~ .dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-fab ~ .dropdown-menu.dropdown-menu-top-right,
.dropdown-toggle.bmd-btn-icon ~ .dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-icon ~ .dropdown-menu.dropdown-menu-top-right {
  bottom: 2rem;
}
.dropdown-toggle:after {
  will-change: transform;
  transition: transform 150ms linear;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
.dropdown-toggle.open:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dropdown-toggle.bmd-btn-fab-sm ~ .dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-fab-sm ~ .dropdown-menu.dropdown-menu-top-right {
  bottom: 2.5rem;
}
.dropdown-toggle.bmd-btn-icon ~ .dropdown-menu {
  margin: 0;
}
.dropdown-header {
  font-size: 0.75rem;
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
  text-transform: none;
  color: #777;
  line-height: 1.428571;
  font-weight: inherit;
}
@keyframes bmd-dropdown-animation {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
.dropdown-menu.bootstrap-datetimepicker-widget {
  opacity: 0;
  transform: scale(0);
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: 0 0;
  will-change: transform, opacity;
  top: 0;
}
.dropdown-menu.bootstrap-datetimepicker-widget.top {
  transform-origin: 0 100%;
}
.dropdown-menu.bootstrap-datetimepicker-widget.open {
  opacity: 1;
  transform: scale(1);
  top: 0;
}
footer {
  padding: 0.9375rem 0;
  text-align: center;
  display: -webkit-flex;
  display: flex;
}
footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
footer ul.social_links li {
  display: inline-block;
  margin: 0 7px;
}
footer ul li .btn {
  margin: 0;
}
footer ul.links-horizontal:first-child a {
  padding-left: 0;
}
footer ul.links-horizontal:last-child a {
  padding-right: 0;
}
footer .footer-brand {
  float: left;
  height: 50px;
  padding: 15px;
  font-size: 18px;
  line-height: 20px;
  margin-left: -15px;
}
footer .footer-brand:hover,
footer .footer-brand:focus {
  color: #000000;
}
footer .copyright {
  padding: 15px 0;
}
footer .copyright .material-icons {
  font-size: 18px;
  position: relative;
  top: 3px;
}
.card {
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  width: 100%;
  box-shadow: 0 0 4px 4px #eee9e9;
}
.card .form-check {
  margin-top: 15px;
}
.card .card-title {
  margin-top: 0.625rem;
}
.card .card-title:last-child {
  margin-bottom: 0;
}
.card .card-body,
.card .card-footer {
  padding: 0.9375rem 1.875rem;
}
.card .card-body + .card-footer {
  padding-top: 0;
  border: 0;
  border-radius: 6px;
}
.card .card-footer {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
}
.card .card-footer .author,
.card .card-footer .stats {
  display: inline-flex;
}
.card .card-footer .stats {
  color: #999;
}
.card .card-footer .stats .material-icons {
  position: relative;
  top: 3px;
  margin-right: 3px;
  margin-left: 3px;
  font-size: 18px;
}
.card.bmd-card-raised {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}
@media (min-width: 992px) {
  .card.bmd-card-flat {
    box-shadow: none;
  }
}
.card .card-header {
  border-radius: 3px;
  padding: 1rem 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border: 0;
  background: linear-gradient(60deg, #eee, #bdbdbd);
}
.card .card-header .title {
  color: #fff;
}
.card .card-header:not([class*="header-"]) {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card .card-header .nav-tabs {
  padding: 0;
}
.card .card-header-primary {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(156, 39, 176, 0.6);
}
.card .card-header-danger {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(244, 67, 54, 0.6);
}
.card .card-header-rose {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(233, 30, 99, 0.6);
}
.card .card-header-warning {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(255, 152, 0, 0.6);
}
.card .card-header-info {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(0, 188, 212, 0.6);
}
.card .card-header-success {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(76, 175, 80, 0.6);
}
.card [class*="header-"],
.card[class*="bg-"] {
  color: #ffffff;
}
.card [class*="header-"] .card-title,
.card [class*="header-"] .card-title a,
.card [class*="header-"] .icon i,
.card[class*="bg-"] .card-title,
.card[class*="bg-"] .card-title a,
.card[class*="bg-"] .icon i {
  color: #fff;
}
.card [class*="header-"] .icon i,
.card[class*="bg-"] .icon i {
  border-color: rgba(255, 255, 255, 0.25);
}
.card [class*="header-"] .author a,
.card [class*="header-"] .stats,
.card[class*="bg-"] .author a,
.card[class*="bg-"] .stats {
  color: rgba(255, 255, 255, 0.8);
}
.card [class*="header-"] .author a:active,
.card [class*="header-"] .author a:focus,
.card [class*="header-"] .author a:hover,
.card[class*="bg-"] .author a:active,
.card[class*="bg-"] .author a:focus,
.card[class*="bg-"] .author a:hover {
  color: #fff;
}
.card .author .avatar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}
.card .author a {
  color: #000;
  text-decoration: none;
}
.card .card-category-social .fa {
  font-size: 24px;
  position: relative;
  margin-top: -4px;
  top: 2px;
  margin-right: 5px;
}
.card .card-category-social .material-icons {
  position: relative;
  top: 5px;
}
.card[class*="bg-"],
.card[class*="bg-"] .card-body {
  border-radius: 6px;
}
.card[class*="bg-"] .card-body h1 small,
.card[class*="bg-"] .card-body h2 small,
.card[class*="bg-"] .card-body h3 small,
.card[class*="bg-"] h1 small,
.card[class*="bg-"] h2 small,
.card[class*="bg-"] h3 small {
  color: rgba(255, 255, 255, 0.8);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card {
    display: inline-block;
  }
}
.card-carousel {
  overflow: hidden;
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
  padding: 1px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}
.section {
  padding: 50px 0;
}
@media all and (max-width: 991px) {
  #bodyClick,
  .navbar-collapse::after {
    height: 100%;
    width: 100%;
    top: 0;
    content: "";
  }
  [class*="navbar-expand-"] > .container,
  [class*="navbar-expand-"] > .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .dropdown-menu.show .dropdown-item.open + .dropdown-menu.show {
    right: 101% !important;
  }
  .dropdown-menu.show
    .dropdown-item.open
    + .dropdown-menu.show
    .dropdown-item.open
    + .dropdown-menu,
  .dropdown-menu.show
    .dropdown-item.open
    + .dropdown-menu.show
    .dropdown-item.open
    + .dropdown-menu.show {
    left: -165px !important;
  }
  .navbar .navbar-collapse .navbar-nav > li.button-container {
    padding: 15px;
  }
  .carousel .card .card-body {
    max-width: 340px;
    margin: 0 auto;
    min-height: 400px;
  }
  .navbar-collapse {
    position: fixed;
    display: block;
    top: 0;
    height: 100vh;
    width: 230px;
    right: 0;
    margin-right: 0 !important;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding-right: 0;
    padding-left: 0;
    max-height: none !important;
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    transform: translate3d(230px, 0, 0);
    -webkit-transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .navbar-collapse::after {
    left: 0;
    position: absolute;
    background-color: #fff;
    display: block;
    z-index: 1;
  }
  .navbar-collapse .dropdown-toggle:after {
    position: absolute;
    right: 16px;
    margin-top: 8px;
  }
  .navbar-collapse .navbar-nav {
    position: relative;
    z-index: 3;
  }
  .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: #000;
    margin: 5px 15px;
  }
  .navbar-collapse .navbar-nav .nav-item.active .nav-link,
  .navbar-collapse .navbar-nav .nav-item.active .nav-link:active,
  .navbar-collapse .navbar-nav .nav-item.active .nav-link:focus,
  .navbar-collapse .navbar-nav .nav-item.active .nav-link:hover {
    background-color: #ecedef;
    color: #000;
  }
  .navbar-collapse .navbar-nav .nav-item.button-container .nav-link {
    margin: 15px;
  }
  .navbar-collapse .navbar-nav .nav-item:after {
    width: calc(100% - 30px);
    content: "";
    display: block;
    height: 1px;
    margin-left: 15px;
    background-color: #e5e5e5;
  }
  .navbar .dropdown .dropdown-menu,
  .navbar-collapse .navbar-nav .nav-item:last-child:after {
    display: none;
  }
  .nav-open .navbar-collapse {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .navbar .dropdown.show .dropdown-menu {
    display: block;
  }
  .navbar .dropdown-menu .dropdown-item {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .navbar .dropdown .dropdown-menu,
  .navbar .dropdown.show .dropdown-menu {
    background-color: transparent;
    border: 0;
    padding-bottom: 15px;
    transition: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    transform: none !important;
    width: auto;
    margin-bottom: 15px;
    padding-top: 0;
    height: 300px;
    animation: none;
    opacity: 1;
    overflow-y: scroll;
  }
  .navbar.navbar-transparent .navbar-toggler .navbar-toggler-icon {
    background-color: #fff;
  }
  #bodyClick {
    position: fixed;
    opacity: 0;
    left: auto;
    right: 230px;
    z-index: 1029;
    overflow-x: hidden;
  }
  #navbar .navbar-collapse,
  #navigation .navbar-collapse {
    display: none !important;
  }
  .joincommunity {
    align-items: center;
  }
}
@media all and (min-width: 991px) {
  .navbar .navbar-nav {
    align-items: center;
  }
  .navbar .navbar-nav .button-container {
    margin-left: 0.1875px;
  }
}
@media screen and (max-width: 991px) {
  .menu_dropdown button {
    color: #fff !important;
  }
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 1px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.sideinput .form-control {
  color: #fff !important;
}
*::-webkit-scrollbar-thumb {
  background-color: #e50914;
}
.search_inp_group {
  position: relative;
  display: flex;
  align-items: center;
}
.search_inp_group_prepend {
  color: #fff;
  position: relative;
  z-index: 9999;
  margin-left: 10px;
}
.search_inp {
  position: relative;
  flex: 1 1 auto;
  margin-bottom: 0;
  width: 235px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ffffff40;
  padding-left: 45px;
  background-color: transparent;
  box-shadow: 1px 3px 14px -9px #000;
  color: #fff !important;
  font-weight: 500;
}
.search_inp:hover,
.search_inp:focus {
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 11%);
  transition: 0.5s;
}
.seafhcnlksdf {
  margin-left: auto !important;
  margin-right: 30px !important;
}
.search_inp_group_append {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 20;
  color: #9c9c9c;
  cursor: pointer;
}
.main_navbar {
  display: flex;
  align-items: center;
  margin-left: 40px !important;
}
.main_navbar li a {
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500 !important;
  width: 100%;
  display: inline-block;
}
.main_navbar li a:hover {
  color: #e50914;
  background: transparent;
}
.menu_dropdown button {
  background-color: transparent !important;
  box-shadow: unset;
  height: 48px;
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  padding: 0.9375rem 0 0.9375rem 0.9375rem;
  font-weight: 500;
}
.menu_dropdown button:focus,
.menu_dropdown button:hover {
  background-color: transparent !important;
  box-shadow: unset;
  color: #e50914 !important;
  transition: 0.5s;
}
.main_navbar li a:hover {
  transition: all 0.5s ease;
}
button.create_btn {
  background: #e50914 !important;
  height: 42px;
  padding: 5px 30px;
  transition: 0.2s;
  border: 2px solid #e50914;
  font-weight: 600 !important;
  font-size: 14px !important;
  border-radius: 5px !important;
  box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
}
button.create_btn:hover {
  background: #fff !important;
  color: #636363 !important;
  border-color: #fff;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.btn-success{
  font-size: 16px !important;
  font-weight: 600 !important;
  height: 40px !important;
  padding: 5px 20px !important;
  border-radius: 4px !important;
}
button.create_btn:hover a {
  color: #e50914 !important;
}
.main_navbar li .create_btn a {
  color: #fff;
}
.search_inp {
  display: none;
}
.menu_icons {
  width: 30px !important;
  height: 30px !important;
  border-radius: 5px;
  border: 1px solid #fff;
}
.menu_icons1 {
  width: 30px !important;
  height: 30px !important;
}
section.top_section {
  margin-top: -60px;
}
.home_header {
  background-color: transparent;
}
.card_details_alignable .content {
  width: 100% !important;
  max-width: 100% !important;
  background-color: unset !important;
}

.explore_section h2 {
  color: #000;
  font-size: 42px;
  font-weight: 600;
  text-align: center;
  margin-top: 2rem !important;
}

figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}

figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}

figure a {
  color: #e50914;
  font-size: 18px;
  text-decoration: none;
}

figcaption {
  grid-row: 2;
  grid-column: 1;
  background-color: #fff;
  padding: 0 5px;
  justify-self: flex-start;
  margin: 2px;
  opacity: 0;
  transition: 0.2s all;
}

.recrent_collections_img {
  column-count: 4;
  column-gap: 10px;
  transition: 0.2s all;
}

.recrent_collections_img img:hover {
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 50%);
}

.recrent_collections_img figure:hover figcaption {
  opacity: 1;
}

.recrent_collections_img img {
  max-width: 100%;
  display: block;
}

.masonry {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 1em;
  -moz-column-gap: 1em;
  column-gap: 1em;
  margin: 1.5em;
  padding: 0;
  -moz-column-gap: 1.5em;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em;
  font-size: 0.85em;
}

.masonry .item_no_boxshadow {
  box-shadow: unset;
}

.masonry_new .item h2 {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin: 5px 0 8px;
}

.masonry_new .item h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.masonry .item h4,
.masonry_new .item h4 {
  font-size: 18px;
  margin: 0;
}

.masonry .item h4 span,
.masonry .item h3 span,
.masonry_new .item h4 span,
.masonry_new .item h3 span {
  color: #000;
}

.masonry_likes i {
  color: #b8bdc2;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  top: 3px;
}

.masonry_likes i.active {
  color: #c5090d;
}

.masonry_likes span {
  font-size: 13px;
  color: #000;
}

.masonry .item .fa-ellipsis-h {
  color: #d2d2d2;
}

.masonry_tab_nav {
  display: block;
  overflow-x: auto;
}

.masonry_tab {
  border-radius: 0;
  white-space: nowrap;
  display: inline-block;
  min-width: 100%;
}

.nav-tabs.masonry_tab .nav-link {
  border-bottom: 3px solid transparent !important;
}

.nav-tabs.masonry_tab .nav-link.active,
.nav-tabs.masonry_tab .nav-link:hover {
  background-color: transparent;
  border-bottom: 3px solid #e50914 !important;
  border-radius: 0px !important;
}

.nav-tabs.masonry_tab .nav-link:hover,
.nav-tabs.masonry_tab .nav-link:focus {
  border: transparent;
}

footer h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  margin: 0;
}

.social_links {
  padding: 0;
  margin: 0;
}

.newsletter_grp {
  position: relative;
  text-align: left;
  margin-top: 10px;
  display: flex;
}

.newsletter_grp button {
  background: white;
  color: #204b75;
  border-color: #fff;
  border-radius: 10px !important;
  margin-left: 10px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.newsletter_grp button:hover {
  background-image: linear-gradient(
    to left,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
  transition: all 0.5s ease;
}

.newsletter_grp input {
  height: 40px;
  font-size: 12px;
  width: 100%;
  border-radius: 10px;
  border: transparent;
  padding-left: 10px;
  color: #2e2e2e;
}

.newsletter_grp_append {
  position: absolute;
  top: 0px;
  right: 0;
}

.newsletter_grp_append button {
  background-color: #e50914;
  background-image: linear-gradient(to bottom, #e50914, #d01242);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 9px;
  border-radius: 0 5px 5px 0;
}

.newsletter_grp_append button:hover {
  background-image: linear-gradient(to top, #e50914, #d01242);
}

.social_links li a {
  color: #969696;
}

.social_links li a:hover,
.copyright_txt {
  font-size: 14px;
  color: hsl(0, 0%, 51%);
  margin-left: 50px;
}
/* .inner_pageheader{margin-top: 40px !important;} */
.home_header header {
  padding: 5px 0px !important;
  background-color: #0000 !important;
  box-shadow: 0 4px 4px 0px rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
  z-index: 999;
  box-shadow: none !important;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

button.hamburger_icon {
  color: #e50914;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* Media Queries */
@media only screen and (max-width: 1279px) {
  .main_navbar .icon_txt {
    display: none !important;
  }
  .main_navbar .connectwallet {
    text-align: center;
    margin-top: 12px;
  }
  .ticketesexplorenpage .trendingbans {
    min-height: 650px !important;
    max-height: 650px !important;
  }
  #ticketscards,
  .trendingban {
    min-width: unset !important;
    max-width: unset !important;
    width: unset !important;
    margin-bottom: 30px;
  }
  #menu-list {
    left: -43px;
    position: absolute;
  }

  .main_navbar {
    display: block;
  }

  .main_navbar li {
    float: none;
  }

  .main_navbar li::after {
    display: none;
  }

  .main_navbar li a {
    margin: 0;
    font-size: 14px;
    font-weight: 600 !important;
  }

  .menu_dropdown button {
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  #ml_align{
    margin-left: -9px;
  }
  #ml_lit_align{
    margin-left: -2px;
  }

}

@media only screen and (max-width: 1199px) {
  .explore_section h2,
  footer .footer_right,
  footer .footer_left {
    float: none !important;
  }
}

@media only screen and (max-width: 959px) {
  section.top_section {
    margin-top: 50px;
  }
  .holder {
    max-width: 150px;
  }

  .top_panel {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .img_info_rad {
    height: unset !important;
  }

  .explore_section h2 {
    font-size: 24px;
  }

  .nav-tabs.masonry_tab .nav-link {
    font-size: 15px;
    padding: 8px 20px;
  }
}

@media only screen and (max-width: 599px) {
  .masonry {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1900px) {
  .margins{
    margin: auto !important;
  }
}


@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media only screen and (min-width: 1201px) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

.dropdown_header_ul li:hover {
  background-color: transparent !important;
  color: #e50914 !important;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  transition: all 0.5s ease;
  cursor: pointer;
}

.dropdown_header_ul li a:hover {
  color: #e50914 !important;
}

.explore_section {
  background-color: #fff;
}

.item_bg_red,
.item_bg_red .card {
  background-color: #e50914;
  color: #fff;
  border-radius: 15px;
  text-align: center;
}

.no_shadow_item_head {
  font-size: 35px;
  font-weight: 600;
  line-height: 45px;
}

.no_shadow_item_p {
  font-size: 22px;
  font-weight: 600;
}

.menu_dropdown button {
  color: #fff !important;
  font-size: 16px;
  font-weight: 500;
}

/* Innerpages  */
.inner_header header {
  background-color: #fff !important;
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%),
    0 7px 12px -5px rgb(33 33 33 / 46%);
}
.explore_card {
  background-color: #141414;
  box-shadow: 0 0 9px 3px #000;
  border-radius: 10px;
  margin-bottom: 30px;
}
.inner_pageheader {
  padding-top: 150px;
  background: #141414;
}
.card_main_iner .timercontent {
  font-size: 14px !important;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 8px 7px !important;
}
.inner_title {
  font-size: 36px;
  font-weight: 600;
  color: #000;
  margin: 0;
  line-height: 30px;
}
.arrow_icon {
  margin-right: 20px;
}
.create_box_panel {
  display: flex;
  margin-top: 20px;
}
.create_box {
  border: 1px solid #e50914;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #e50914;
  background-image: linear-gradient(to bottom, #e50914, #e50914, #d01242);
  border-radius: 15px;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);
  width: 25%;
  margin-right: 20px;
  padding: 20px;
}
.create_box h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.create_para {
  font-size: 15px;
  font-weight: 600;
  color: #c5c2c2;
}
@media only screen and (max-width: 767px) {
  .create_box {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .inner_title {
    font-size: 30px;
  }
  .create_box_panel {
    flex-wrap: wrap;
  }
}

.faq_panel .accordian_head h2 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.faq_panel .MuiAccordion-root {
  background: 0 0;
  box-shadow: none;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 !important;
}
.faq_panel .MuiAccordionSummary-root {
  min-height: unset !important;
}
.faq_panel .MuiAccordion-root:before {
  display: none;
}
.faq_panel .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}
.single_collectible .item h3 span,
.single_collectible .item h4 span {
  color: #e50914;
}
#TokenPrice,
#USDTokenPrice {
  color: #fff !important;
}
.tokenpeorcs {
  color: #e50914 !important;
}
.single_collectible .item h4 {
  font-size: 18px;
  margin: 0;
}
.primary_label {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 20px !important;
}
.modaltoggles .primary_label{
  margin-bottom: 0px !important;
}
.hide_txt_align{
  color: #fff;
  font-weight: 600;
  margin-top: 10px;
  font-size: 13px;
}
.Warning_label {
  color: #f44336;
  font-size: 13px;
  font-weight: 800;
  margin: 0;
  margin-bottom: 10px !important;
}
.submitbtng:hover {
  background-color: initial !important;
  font-size: 16px !important;
  color: #e50914 !important;
}
.submitbtng {
  background-color: #e50914 !important;
  border: 2px solid #e50914 !important;
  padding: 8px 20px !important;
  font-size: 16px !important;
  border-radius: 3px;
  height: auto !important;
  color: #fff !important;
  font-weight: 500 !important;
  transition: 0.5s ease-in-out;
}
.browsematdk {
  /* padding: 12px 20px !important; */
  padding: 6px 20px !important;
  background-color: #e50914 !important;
  border: 1px solid #e50914 !important;
  border-radius: 3px;
  transition: all 0.5s ease-in-out;
  color: #fff !important;
  font-weight: 600px !important;
}
.browsematdk:hover {
  background-color: transparent !important;
  color: #e50914 !important;
  box-shadow: unset !important;
}
.react-multi-email [data-tag] [data-tag-item] {
  word-break: break-word;
}

.form_note {
  color: #898989;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  top: 0px;
  position: relative;
}
.file_tn_classf {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.file_btn {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
input.inp_file {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
  z-index: 1 !important;
}
.loin_pass {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px !important;
}
.loin_pass i {
  color: #ccc;
  font-size: 16px;
}
.loin_pass input {
  border: none !important;
  --webkit-appearance: none !important;
  appearance: none !important;
  padding: 23px 8px !important;
}
.fielnewp {
  margin-bottom: 0 !important;
  color: #898989 !important;
  font-weight: 600 !important;
  font-size: 14px;
}
.primary_btn,
button.primary_btn {
  border: 2px solid #e50914;
  background-color: #e50914;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  padding: 8px 30px;
  transition: 0.2s;
  margin: 0;
  cursor: pointer;
  height: 42px;
}
.primary_btn:hover,
button.primary_btn:hover {
  border: 2px solid #e50914;
  background: 0 0 !important;
  color: #e50914;
}
.toggle_custom.switch input {
  display: none;
}
.toggle_custom.switch {
  display: inline-block;
  width: 60px;
  height: 30px;
  transform: translateY(50%);
  position: relative;
}
.toggle_custom .slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 0 0 2px #e5091454, 0 0 4px #e5091454;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.4s;
  background: #e5091454;
}
.toggle_custom .slider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 30px;
  transform: translateX(-30px);
  transition: 0.4s;
}
.allrowmobile span.validation_txt{
  position: unset !important;
}
.allrowmobile .labelspan {
  color: #fff !important;
}
.modaltoggles .toggle_custom input:checked + .slider:before{
  transform: translateX(-25px) !important;
}
.toggle_custom input:checked + .slider:before {
  transform: translateX(25px);
  /* transform: translateX(15px); */
  background: #fff;
}
.toggle_custom input:checked + .slider {
  box-shadow: 0 0 0 2px #141414, 0 0 2px #141414;
  background: #e50914;
}
.form-control,
.is-focused .form-control {
  background-image: linear-gradient(to top, #ccc 2px, rgba(156, 39, 176, 0) 2px),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px) !important;
  border: none !important;
  border-radius: 0 !important;
}
.primary_inp {
  font-size: 16px;
  color: #969696;
  border-radius: 10px !important;
  border: 1px solid #64528d !important;
  background-image: linear-gradient(0deg, #fff 0, rgb(255 255 255) 0),
    linear-gradient(0deg, #fff 0, hsla(0, 0%, 82.4%, 0) 0) !important;
  background: #fff;
}
.input_grp_style_1 {
  border-radius: 10px !important;
  border: 1px solid #64528d !important;
  background-image: linear-gradient(0deg, #fff 0, rgb(255 255 255) 0),
    linear-gradient(0deg, #fff 0, hsla(0, 0%, 82.4%, 0) 0) !important;
}
.input_grp_style_1 input {
  background-image: linear-gradient(0deg, #fff 0, rgb(255 255 255) 0),
    linear-gradient(0deg, #fff 0, hsla(0, 0%, 82.4%, 0) 0) !important;
  border: none !important;
}
.inp_grp {
  position: relative;
}
.inp_append {
  position: absolute;
  margin: 5;
  top: 4px;
  right: 10px;
  color: #000;
}
button.create_btn {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #fff;
  text-transform: capitalize;
  height: 42px;
}
.bg_red_1 {
  background: linear-gradient(
    to left,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
  color: #fff;
  padding-bottom: 45px;
  padding-top: 45px;
  margin-top: -50px;
  margin-bottom: 35px;
}
.bg_red_1 .inner_title {
  color: #fff;
}
.select1 {
  border-width: 0 !important;
  border-bottom-color: #e89191 !important;
  border-radius: 0;
  background: #fff !important;
  box-shadow: 0 0 0 #fff !important;
  color: #000 !important;
}
.primary_modal {
  z-index: 9999999999;
}
.react-multi-email [data-tag] {
  padding: 0.6em 0.8em !important;
}
.react-multi-email [data-tag] [data-tag-item] {
  overflow: unset !important;
}
/* .react-multi-email [data-tag] {
  background-color: #e50914 !important;
  color: #fff !important;
} */
.primary_modal .modal-content {
  background-color: #fff;
  border-radius: 5px;
  /* box-shadow: 0px 0px 12px 0px lightgray; */
 
  box-shadow: 0px 0px 12px 0px  #d3d3d305;
}
@media (min-width: 450px) {
  .primary_modal .modal-sm {
    max-width: 400px;
    margin: 0 auto;
  }
}

.primary_modal .modal-dialog .modal-header {
  display: block;
}
.primary_modal .modal-header .close {
  float: none;
  position: absolute;
  color: #000;
  top: 21px;
  right: 25px;
  font-size: 30px;
}
.primary_modal h5.modal-title {
  margin: 0;
  font-size: 25px;
  font-weight: 700;
  color: #000;
}
.primary_label_dark {
  color: #000;
  font-size: 16px;
  font-weight: 800;
  margin: 0;
  margin-bottom: 10px !important;
}
.file_btn.btn_small {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.small_btn {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.hex {
  position: relative;
  max-width: 100%;
  width: 85px;
}
.hex:before {
  content: "";
  display: block;
  padding-bottom: 70%;
}
.hex img {
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 115%;
}
.font_16 {
  font-size: 16px;
}
.font_600 {
  font-weight: 600;
}
.line_20 {
  line-height: 20px;
}
.holder {
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid gray;
  margin: 0 auto;
}
input[type="file"].photo {
  margin-top: 5px;
  opacity: 0;
  z-index: 999;
  max-width: 100%;
}
.profile_edit_panel {
  position: relative;
  width: 160px;
  margin: 0 auto;
}
.profile_edit_icon {
  position: absolute;
  top: -3px;
  z-index: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.profile_edit_icon i {
  background: #e50914;
  background-image: linear-gradient(
    to left,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 2px solid #e50914;
  font-size: 12px;
  cursor: pointer;
}
.primary_tab {
  display: flex;
  justify-content: flex-start !important;
  border-bottom: 1px solid #e50914;
}
.primary_tab.myitemstab{
  display: inherit !important;
  width: 100%;
  overflow: auto;
  padding-bottom: 8px !important;
}
/* .ReactModal__Overlay {background-color: #141414 !important;} */
.ReactModal__Content {
  background: transparent !important;
}
.proposal_panel {
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e7e7e7;
}
.proposal_panel_overall .proposal_panel:last-child {
  border-bottom: none;
}
.proposal_panel_content {
  margin-left: 30px;
}
.proposal_panel_content h3 {
  color: #6c6d6d;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.proposal_panel_content p {
  color: #000;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}
.proposal_panel_content p span {
  color: #9a9696;
}
.proposal_panel .badge {
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 600;
  width: 110px;
  border-radius: 30px;
  text-align: center;
  text-transform: capitalize;
}
.proposal_panel .badge_active {
  background-color: #33b9f2;
}
.proposal_panel .badge_closed {
  background-color: #e95e5e;
}
.proposal_panel .badge_pending {
  background-color: #fcd917;
}
.form-control {
  color: #000 !important;
  font-weight: 600;
}
textarea.textarea_des{
  color: #fff !important;
}
.light_theme textarea.textarea_des{
  color: #000 !important;
}
.text_brown {
  color: #8a8888;
}
.text_desc_sm {
  font-size: 14px;
  line-height: 18px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cat_title {
    font-size: 16px !important;
  }
  .holder {
    width: unset !important;
    height: unset !important;
    min-width: unset !important;
    min-height: unset !important;
  }
  .holder img {
    width: 100% !important;
    height: unset !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .profile_edit_panel {
    max-width: 100%;
  }
}
@media only screen and (min-width: 992px) {
  .holder {
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn_icon_only span {
    display: none;
  }
  .create_btn.btn_icon_only.btn_rect {
    font-size: 16px !important;
    border-radius: 50% !important;
    padding: 5px 12px !important;
  }
  .btn_icon_only .fas {
    padding-right: 0 !important;
  }
  .holder {
    height: unset !important;
    width: unset !important;
    min-width: unset !important;
    min-height: unset !important;
  }
  .profile_edit_panel {
    width: unset;
  }
  .primary_btn.small_btn {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
@media only screen and (min-width: 1200px) {
  .profile_edit_panel {
    margin: unset !important;
  }
}
.proposal_tab .nav-link {
  color: #000 !important;
  font-size: 17px !important;
}
.proposal_tab .nav-link.active,
.proposal_tab .nav-link:hover {
  color: #fff !important;
}
.btn_text {
  justify-content: space-between;
}
.inner_header header {
  padding: 15px 0 !important;
  background-color: #fff !important;
}
@media only screen and (max-width: 375px) {
  .proposal_btn {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
.error_text {
  font-size: 14px;
  color: #aca4a1;
  margin-bottom: 0;
}
.high {
  margin-top: -46px;
  margin-left: 50px;
}
.formCls .form-control {
  height: 46px !important;
}
.overlay_my_bg {
  position: absolute;
  z-index: 3;
  background-color: transparent;
  width: 100%;
  height: 100%;
}
.items_header {
  padding-top: 78px !important;
}
.items_bg_pink {
  background: linear-gradient(#dfdfdf, #fafafa, #d1d1d0);
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.item_prof {
  position: relative;
}
.item_prof input[type="file"] {
  max-width: 100%;
}
.address_text {
  color: #32302d;
  font-size: 16px;
  font-weight: 700;
}
.not_found_text {
  color: #c5c2c2;
  font-size: 30px;
  font-weight: 700;
}
.not_found_text_sub {
  color: #714e4c !important;
  font-size: 16px;
  font-weight: 600;
}
.notes_fa {
  padding-left: 15px;
  color: #e50914;
}
button.btn_outline_red {
  background-image: linear-gradient(90deg, transparent 0, transparent);
  color: #636363 !important;
  height: 42px;
  border-radius: 30px;
  padding: 15px 30px;
  transition: 0.2s;
  border: 2px solid #e50914;
  text-transform: capitalize;
}
button.btn_outline_red:hover {
  background-image: linear-gradient(
    to left,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
  color: #fff !important;
  border-color: #e50914 !important;
}
.bg_red_icon {
  background-color: #e50914;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #fff;
  margin-left: 15px;
}
.items_tab .nav-link {
  text-align: center;
  line-height: 20px;
}
.text-gray {
  color: gray;
}
.creators_details img:hover,
.img-border img:hover {
  opacity: 1;
}
.items_tab .nav-link .tab_head {
  color: #838383 !important;
  font-size: 17px;
}
.cubstart .nav-link .tab_head {
  color: #fff !important;
}
.items_tab .nav-link .tab_count {
  color: #e50914 !important;
}
.items_tab .nav-link.active .tab_head,
.items_tab .nav-link:hover .tab_head {
  color: #fff !important;
}
.navtabs .items_tab {
  align-items: center;
}
@media only screen and (max-width: 1199px) {
  .items_tab_outer .primary_tab {
    display: block !important;
  }
}
.flex_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.follow_media {
  align-items: center;
}
.media_num {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0;
}
.media_text {
  color: #fff;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.MYmedia_text {
  color: #747474;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}
.following_row .single_collectible .item {
  border-radius: 5px !important;
}
.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  position: relative;
}
.info_header {
  padding-top: 78px;
  padding-bottom: 0;
}
.info_row {
  min-height: calc(100vh - 307px);
  height: 100%;
}
.info_title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}
.info_h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.info_h3 span {
  color: #fff;
}
.info_media {
  border-bottom: 1px solid #9e9e9eb0;
  padding: 20px 15px 20px 0;
}
.info_media .media_text {
  font-weight: 700;
}
.info_big_card {
  border-radius: 10px;
  background: #fff;
}
button.btn_outline_red {
  font-weight: 600;
  font-size: 14px;
}
.info_big_card button.btn_outline_red {
  min-width: 125px;
}
.proposal_panel_overall .info_media:last-child {
  border-bottom: none !important;
}
.info_tab .nav-tabs.masonry_tab .nav-link {
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-size: 16px !important;
}
.info_tab .nav-tabs.masonry_tab {
  border-bottom: 1px solid #e50914 !important;
}
@media only screen and (max-width: 400px) {
  .info_big_card button.btn_outline_red,
  .info_big_card button.create_btn {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 575px) {
  .d-flex.state.abstract_stae {
    display: grid !important;
    grid-template-columns: 50% 50% !important;
    gap: 0.5%;
  }
  .d-flex.state.abstract_stae h3 {
    margin-top: 0 !important;
  }
  .d-flex.state > div {
    margin-bottom: 2px !important;
  }
  .d-flex.state.abstract_stae p {
    margin-bottom: 0 !important;
    padding-left: 10px !important;
  }
  .d-flex_blk_cu {
    display: block !important;
  }
  .social_a_icon span {
    padding-left: 0px !important;
  }
  .info_h3 {
    margin-bottom: 20px !important;
  }
  .d-flex_blk_cu .create_btn {
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-top: 20px !important;
  }
  .btn_div_info .create_btn {
    padding-left: 20px !important;
    padding-right: 20px !important;
    min-width: 100% !important;
  }
  .btn_filter_row .create_btn {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .proposal_panel_content h3 {
    font-size: 16px;
  }
  .proposal_panel_content p {
    font-size: 14px;
  }
  .proposal_panel_content {
    margin-left: 20px;
  }
  .grid_toggle {
    display: grid !important;
    grid-template-columns: 75% 25%;
    gap: 2%;
  }
  .flex_block_mob {
    display: block !important;
  }
  .copyright_txt {
    margin-left: 0px !important;
  }
  button.create_btn {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .info_flex_body {
    display: block !important;
  }
  .ml-cus {
    margin-left: 0 !important;
    margin-top: 15px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .info_flex_body {
    display: block !important;
  }
  .ml-cus {
    margin-left: 0 !important;
    margin-top: 15px !important;
  }
  .info_big_card button.btn_outline_red,
  .info_big_card button.create_btn {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
.arrow_expand .fas {
  color: #9a9696;
  font-size: 25px;
}
.img_des {
  text-align: center;
  margin-top: 20px;
  display: none;
}
.arrow_expand .fas {
  position: absolute;
  top: 20px;
  right: 0;
}
.arrow_expand {
  padding: 20px;
}
#image_div.expand_img {
  flex: 0 0 100%;
  max-width: 100%;
}
#img_des.show_des {
  display: block;
}
#detai_div.hide_detail {
  display: none;
}
#arrow_icon {
  cursor: pointer;
}
#arrow_icon.fa-shrink:before {
  content: "\f065" !important;
}
.buy_title_sm {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: 700;
}
.buy_desc_sm {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  font-weight: 600;
}
.buy_desc_sm_bold {
  color: #000;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0;
  font-weight: 600;
}
.buy_title_md {
  color: #000;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
#buy_modal .buy_title_md {
  margin-bottom: 0;
}
.bor_bot_modal {
  border-bottom: 1px solid #e50914;
}
.approve_text {
  color: #030303;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;
}
.approve_desc,
.text_drk_bl {
  color: #9a9696;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0;
}
.approve_media .fas {
  background: #fff;
  box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
  color: #636363;
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.approve_media .fas.pro_complete {
  background-image: linear-gradient(
    to left,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
  color: #fff;
  border-color: #e50914;
}
button:disabled {
  color: #ffffffa1;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.spinner_icon {
  -webkit-animation: 1s linear infinite spinner;
  animation: 1s linear infinite spinner;
  font-size: 25px;
}
.purchase_text {
  color: #323030;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}
.purchase_desc {
  color: #474445;
  font-size: 16px;
  font-weight: 600;
}
.place_bit_desc {
  color: #675555;
  font-weight: 700;
  font-size: 16px;
}
.place_bit_desc_2 {
  color: #402c2d;
  font-weight: 700;
  font-size: 16px;
}
.place_bit_desc_2 .text_red {
  font-size: 20px;
  font-weight: 700;
  color: #e50914;
}
.input_grp_style_1 .input-group-text {
  border-bottom-color: #ccc !important;
  height: 35px !important;
  font-weight: 700;
  font-size: 14px !important;
}
.input_grp_style_1 input {
  border-bottom-color: #ccc !important;
  background-image: linear-gradient(to top, #ccc 2px, rgba(156, 39, 176, 0) 2px),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px) !important;
}
.input_grp_style_1 input:disabled {
  background-image: linear-gradient(to top, #ccc 1px, rgba(156, 39, 176, 0) 1px),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px) !important;
  background-size: 1px 0 !important;
  border-bottom: 1px solid #ccc !important;
  height: 35px !important;
}
.label_muted {
  color: gray;
}
.bid_form label {
  /* color: #000; */
  color: #fff;
  font-weight: 800;
  text-align: left;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px !important;
}
#image_div_owner.expand_img {
  flex: 0 0 100%;
  max-width: 100%;
}
#img_des_owner.show_des {
  display: block;
}
#detai_div_owner.hide_detail {
  display: none;
}
#arrow_icon_owner {
  cursor: pointer;
}
#arrow_icon_owner.fa-shrink:before {
  content: "\f065" !important;
}
.accept_desc {
  line-height: 20px;
  margin-top: 20px;
}
.btn_outline_red {
  font-weight: 600;
  font-size: 16px;
}
.btn_outline_red {
  background-image: linear-gradient(90deg, transparent 0, transparent);
  color: #e50914 !important;
  height: 40px;
  border-radius: 5px;
  padding: 10px 30px;
  transition: 0.2s;
  border: 1px solid #e50914;
  text-transform: capitalize;
}
.btn_outline_red:hover {
  background-image: linear-gradient(90deg, #e50914, #e50914);
  color: #fff !important;
}
.items_bg img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  max-height: 280px;
  min-height: 280px;
}
.edit_cover_text {
  position: absolute;
  right: 20px;
  bottom: 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-shadow: #000 0.1em 0.1em 0.2em;
  cursor: pointer;
  opacity: 0;
  z-index: 4;
}
.items_bg:hover .edit_cover_text {
  opacity: 1;
}
.edit_cover_text:hover {
  color: #fff;
  text-shadow: #ccc 0.1em 0.1em 0.2em;
}
.edit_cover_container {
  position: relative;
}
@media only screen and (max-width: 350px) {
  .edit_cover_text {
    bottom: 72px !important;
  }
}
.btn_header {
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  width: calc(100% - 30px);
}
.btn_header > span {
  justify-content: center !important;
}
@media only screen and (min-width: 992px) and (max-width: 1279px) {
  .ticketname,
  .ticketloactions,
  .addressticket {
    text-align: center;
  }
}
@media only screen and (max-width: 1280px) {
  .collectionspaedimg {
    min-height: 180px !important;
    max-height: 180px !important;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1279px) {
  .commun_navbar.main_navbar li {
    margin: 18px auto;
    text-align: center;
  }
  .collectionspaedimg {
    max-height: 110px !important;
    min-height: 110px !important;
  }
}
@media only screen and (min-width: 1280px) {
  .home_header header {
    padding: 0px !important;
  }
  .seafhcnlksdf {
    margin-right: 0 !important;
    margin-top: 4px !important;
  }
  .home_header header ul li.menu_dropdown {
    padding: 0px !important;
    padding-left: 0px !important;
    margin-right: 18px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .home_header header ul li.menu_dropdown:last-child {
    margin-right: 0 !important;
  }
  .searchmneu_dd {
    right: -8% !important;
    min-width: 300px !important;
    left: 0 !important;
  }
  .commun_navbar.main_navbar li:nth-child(2) {
    margin-left: 15px;
    text-align: center;
  }
  .search_input_community .search_inp {
    width: 400px;
  }
}
button.btn_outline_red {
  background: #fff;
  color: #636363;
  border-color: #fff;
  font-weight: 600 !important;
  font-size: 14px !important;
  box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
}
button.create_btn:hover {
  background-color: transparent !important;
}
.login_form label {
  color: #000;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0;
}
.pwd_link {
  text-decoration: underline;
  color: #867e7c;
  font-weight: 700;
  cursor: pointer;
}
.pwd_link:hover {
  color: #e50914 !important;
}
.create_link {
  text-decoration: underline;
  color: #e50914;
  font-weight: 700;
  cursor: pointer;
}
.create_link:hover {
  color: #867e7c !important;
}
.forgot_desc {
  color: #6f6f6f;
  font-weight: 600;
  margin-bottom: 20px;
}
@media only screen and (max-width: 575px) {
  .info_title {
    font-size: 18px !important;
  }
  .collectionspaedimg {
    max-height: 180px;
    min-height: 180px;
  }
  .primary_modal h5.modal-title {
    font-size: 20px !important;
  }
}
.dur_text {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 14px !important;
}
.filter_dropdown {
  justify-content: flex-end;
}
.filter_dropdown button {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.filter_menu {
  padding: 15px;
  min-width: 8rem !important;
  border-radius: 10px !important;
}
.filter_menu .custom-control-label {
  padding-left: 20px;
  height: 22px;
  display: flex;
  align-items: center;
  color: #737373;
  font-weight: 400;
}
.filter_menu .custom-control-label::before {
  background-color: #9f9f9f;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e50914;
  border-color: #e50914;
}
.filter_menu .custom-checkbox {
  padding-left: 0;
}
.main_navbar svg,
header svg {
  fill: #fff !important;
}
.main_navbar svg:hover,
header svg:hover {
  fill: #fff !important;
}
.main_navbar .icon_txt {
  color: #787878 !important;
}
.main_navbar a:hover .icon_txt {
  color: #e50914 !important;
}
.main_navbar a:hover svg {
  fill: #e50914 !important;
}
.filter_btn {
  background-color: transparent !important;
  color: #787878 !important;
  font-size: 18px;
  border: none !important;
  padding: 0 !important;
  box-shadow: 0 0 0 0 rgb(250 250 250 / 14%), 0 0 0 0 rgb(250 250 250 / 20%),
    0 0 0 0 rgb(250 250 250 / 12%) !important;
}
.noti_head {
  color: #fff;
  text-align: center;
  font-weight: 700;
  padding-top: 10px;
  font-size: 18px;
}
.no_noti {
  color: #9a9696;
  font-size: 14px;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 0;
}
.see_all_link {
  font-size: 15px;
  font-weight: 600;
  color: #e50914 !important;
  width: 100%;
  text-align: center;
  text-decoration: underline !important;
}
.see_all_link:hover {
  color: #787878 !important;
}
.noti_ul .makeStyles-caret-65 {
  display: none !important;
}
.title_div {
  padding: 10px 0;
  border-bottom: 1px solid #c3bbbb47;
}
.title_discussion {
  color: #000;
  font-weight: 600;
}
.list_grp_style_1 .list-group-item :first-child {
  margin-right: 0 !important;
}
.list_grp_style_1 .list-group-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
  display: block;
  border-bottom: 1px solid #c3bbbb47;
}
.cat_title {
  color: #6f6f6f;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}
.cat_desc {
  font-size: 14px;
  color: #929292;
  font-weight: 600;
  line-height: 25px;
}
.cat_link {
  font-size: 15px;
  color: #929292;
  font-weight: 700;
  line-height: 25px;
}
.cat_link_green {
  color: #76d238 !important;
}
.cat_link_blue {
  color: #6076e0 !important;
}
.cat_dur {
  color: #443f40;
  font-weight: 600;
}
.masonry_tab.dis_tab {
  border-color: #c3bbbb47 !important;
  border-bottom-width: 1px !important;
}
.sel_period {
  background: #ffffff69 !important;
  padding-left: 15px;
  padding-right: 15px;
  color: #969696 !important;
  --moz-appearance: menulist !important;
  --webkit-appearance: menulist !important;
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);
  max-width: 270px;
}
.table_style_1 th {
  color: #969696 !important;
  font-weight: 600 !important;
}
.table_style_1 td {
  font-weight: 600 !important;
}
.table_style_1 th:first-child,
.table_style_1 td:first-child {
  min-width: 200px;
}
.table_style_1 th:nth-child(2),
.table_style_1 td:nth-child(2) {
  min-width: 175px;
}
.table_style_1 th:last-child,
.table_style_1 td:last-child {
  min-width: 200px;
}
.cat_title_sm {
  font-size: 18px !important;
}
.dis_head .inner_title {
  align-items: baseline;
}
.icon_red {
  color: #e50914;
}
.dis_accordion > div[role="button"] {
  background: linear-gradient(#dfdfdf, #fafafa, #d1d1d0);
}
.dis_accordion {
  border-bottom: none !important;
}
.m-0.panel_header_dis > div {
  margin: 0 !important;
  padding: 5px 0 !important;
}
.text_gry {
  color: #969696;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.panel_header_dis {
  align-items: baseline !important;
  margin-top: 15px !important;
}
@media only screen and (min-width: 992px) {
  .pl-lg-cus {
    padding-left: 2.5rem !important;
  }
}
.accordian_para {
  font-weight: 500;
  color: #000;
  line-height: 25px;
  width: 100%;
  font-size: 14px;
}
.desc_discussion {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  padding-top: 15px;
}
.reply_uer_name {
  font-size: 16px;
  color: #000;
  font-weight: 700;
}
.reply_txt {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin: 0;
  line-height: 30px;
}
.accordion_form {
  background: linear-gradient(#dfdfdf, #fafafa, #d1d1d0);
  margin-top: 20px;
}
.post_link {
  font-size: 15px;
  color: #594c4b;
  font-weight: 700;
  line-height: 25px;
  cursor: pointer;
}
.cat_link:hover,
.post_link.active,
.post_link:hover {
  color: #e50914 !important;
  text-decoration: underline;
}
@media only screen and (min-width: 768px) {
  .border_left_md {
    border-left: 1px solid #ccc;
    padding-left: 30px;
  }
}
.cat_title:hover {
  color: #e50914 !important;
}
/* faq css */
.faq_a {
  color: #e50914;
  text-decoration: underline;
}
.faq_a:hover,
.faq_a:focus {
  color: #787878;
  text-decoration: underline;
}
.faq_bold_text {
  font-weight: 700;
}
/* dopdown */
.dropdown-toggle.filter_btn::after {
  content: none !important;
}
.filter_menu.filter_menu_info {
  background-color: #fbe1e0;
  border-radius: 2px !important;
  padding: 0;
}
.filter_menu_info .menu_itm.active,
.filter_menu_info .menu_itm:hover {
  background-color: #e50914;
}
.menu_itm {
  padding: 5px 15px;
  cursor: pointer;
}
.menu_itm span {
  color: #000;
}
.menu_itm.active span,
.menu_itm:hover span {
  color: #fff;
}
#share_modal .icon_div {
  cursor: pointer;
}
#share_modal .icon_div .fab {
  color: #000;
  font-size: 30px;
}
#share_modal .icon_div p {
  color: #000;
  font-weight: 600;
}
#share_modal .icon_div:hover .fab,
#share_modal .icon_div:hover p {
  color: #e50914;
}
.connect_row .create_box {
  background-color: transparent;
  border-color: #ccc;
}
.connect_row .create_box img {
  max-width: 30px;
  min-width: 30px;
  filter: brightness(0) invert(1);
}
.connect_row .create_box {
  max-width: 120px;
  min-height: 120px;
  display: inline-flex;
  min-width: 170px;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
}
.connect_row .create_box:last-child {
  margin-right: 0;
}
.connect_row.connect_row_blk {
  display: flex;
  max-width: 100%;
  overflow-x: auto;
}
.connect_row .create_box h2 {
  color: #000 !important;
}
.connect_row .create_box .text-white {
  color: #807676 !important;
}
/* notification */
.noti_ul_dd li {
  list-style-type: none;
  border-top: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
}
.noti_ul_dd {
  padding-left: 0;
  cursor: pointer;
}
.noti_ul #menu-list {
  min-width: 200px !important;
}
.noti_ul #menu-list li {
  margin: 0 !important;
}
.noti_time {
  color: #000;
  font-weight: 600;
}
.noti_msg {
  color: #787878;
  word-break: break-word;
  white-space: break-spaces;
}
.form_note.font_12 {
  font-size: 14px !important;
}
.create_sing_bx {
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 15px !important;
  cursor: pointer;
}
.create_sing_bx .fas {
  color: #9a8288;
  font-size: 20px;
}
.create_sing_bx p {
  margin-bottom: 0 !important;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-top: 10px;
}
.fa-infinity::before {
  content: "\f534";
}
.selct_form_input_h {
  min-height: 46px !important;
}
.selxet_app {
  min-width: 100px !important;
}
.font_we_700_note_txt {
  font-weight: 700;
}
.form_note_link_boild {
  color: #e50914;
  font-weight: 700;
  cursor: pointer;
}
.form_note_link_boild:hover {
  color: #969696 !important;
}
.single_dd_1 button {
  background-color: transparent !important;
  box-shadow: 0 0 0 0 rgb(153 153 153 / 14%), 0 0 0 0 rgb(153 153 153 / 20%),
    0 0 0 0 rgb(153 153 153 / 12%) !important;
  border-bottom: 1px solid #e89191 !important;
  border-radius: 0 !important;
  color: #aca4a1 !important;
  width: 100% !important;
  text-align: left !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  padding: 8.5px 0 !important;
}
.single_dd_1 button span {
  justify-content: flex-start !important;
  width: 100% !important;
}
.single_dd_1 .filter_menu div {
  cursor: pointer;
  padding: 5px;
}
.single_dd_1 .filter_menu div:hover {
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(156 39 176 / 40%);
  background-image: linear-gradient(
    to left,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
  color: #fff !important;
}
.home_header header.makeStyles-transparent-14 {
  background: none;
  background-color: transparent !important;
  box-shadow: none !important;
}
.home_header header.makeStyles-white-16 {
  background: none;
  background-color: #000 !important;
  box-shadow: none !important;
}
.single_dd_1 .filter_menu {
  width: 100% !important;
}
.single_dd_1 .filter_btn {
  display: flex;
  justify-content: space-between;
}

.single_dd_1 .filter_btn i {
  font-size: 15px !important;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .d-flex.state.abstract_stae {
    display: grid !important;
    grid-template-columns: 50% 50% !important;
    gap: 0.5%;
  }
  .d-flex.state.abstract_stae h3 {
    margin-top: 0 !important;
  }
  .d-flex.state.abstract_stae p {
    margin-bottom: 0 !important;
    padding-left: 10px !important;
  }
  .d-flex.state > div {
    margin-bottom: 3px !important;
  }
  .sociallinks {
    position: unset !important;
  }
  .sociallinks .state > div {
    padding: 8px 15px;
  }
  .d-flex.state > div {
    border: 1px solid var(--borderclr) !important;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .notable_sec.masonry .item h2 {
    font-size: 18px !important;
  }
  .cat_title {
    font-size: 16px;
  }
  .single_collectible .item h3 {
    font-size: 16px !important;
  }
}
.filter > .filter-icon {
  border-bottom: 1px solid #e5e8eb;
  border-bottom: 1px solid var(--borderclr);
  padding-top: 15px;
  padding-bottom: 15px;
}
.dropdown_header_ul.user_dd_ul {
  position: relative;
}
.usemneu_dd {
  position: absolute;
  will-change: transform;
  top: 65px;
  right: 0;
  background-color: #0000008f !important;
  min-width: 270px !important;
  max-width: 100% !important;
  box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%),
    0 7px 10px -5px rgb(0 0 0 / 15%);
  -webkit-box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%),
    0 7px 10px -5px rgb(0 0 0 / 15%);
  -o-box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
  border-radius: 0;
  border: 1px solid #ffffff4f;
  padding: 0;
  color: #000 !important;
  z-index: 10 !important;
}
.img_user_noti {
  width: 40px !important;
  height: 40px !important;
  margin-top: 20px !important;
}
.usemneu_dd_wallet {
  position: absolute;
  will-change: transform;
  top: 65px;
  right: 0;
  background-color: #000000db !important;
  min-width: 270px !important;
  max-width: 100% !important;
  box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%),
    0 7px 10px -5px rgb(0 0 0 / 15%);
  -webkit-box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%),
    0 7px 10px -5px rgb(0 0 0 / 15%);
  -o-box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
  border-radius: 0;
  border: 1px solid #ffffff4f;
  padding: 0;
  color: #000 !important;
  z-index: 10 !important;
}
@media only screen and (max-width: 1279px) {
  .usemneu_dd {
    max-width: 100% !important;
    min-width: 240px !important;
    left: 0 !important;
  }
  .usemneu_dd_wallet {
    max-width: 100% !important;
    min-width: 240px !important;
    left: 0 !important;
  }
  .searchmneu_dd {
    max-width: 235px !important;
    min-width: 235px !important;
    left: 0 !important;
  }
}
.earn_crypto_section .create_btn {
  font-size: 14px !important;
  font-weight: 500 !important;
}
@media only screen and (min-width: 1280px) {
  .pl-cust-wal {
    padding-left: 0px !important;
  }
  .img_user_noti {
    width: 40px !important;
    height: 40px !important;
    margin-top: 20px !important;
  }
  #searchmneu_dd .img_user_noti {
    border: 1px solid #ffffff9e;
    width: 40px !important;
    height: 40px !important;
    margin-top: 0 !important;
    background-color: #fff;
  }
  .user_ul_new {
    max-width: 25px;
    min-width: 25px;
    max-height: 25px;
    min-height: 25px;
    margin-right: 20px !important;
    border-radius: 50%;
  }
}
@media only screen and (max-width: 1279px) {
  .pl-cust-wal {
    margin-left: 0px !important;
  }
  .img_user_noti {
    width: 40px !important;
    height: 40px !important;
    margin-right: 20px !important;
  }
  .user_ul_new {
    max-width: 25px;
    min-width: 25px;
    border-radius: 50%;
    max-height: 25px;
    min-height: 25px;
  }
}
.dropdown_header_ul .noti_ul_dd li:hover {
  background-color: #fbfdff !important;
  background: #fbfdff17 !important;
  box-shadow: rgb(4 17 29 / 25%) 0 0 8px 0;
}
.noti_ul_dd li:hover {
  color: #000 !important;
}
.font_we_700 {
  font-weight: 700;
}
button.new_btn_grad {
  background-image: linear-gradient(
    to left,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
  height: 42px;
  border-radius: 30px;
  padding: 5px 30px;
  transition: 0.2s;
  border: 1px solid #e50914;
  font-weight: 500 !important;
  font-size: 14px !important;
  box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  color: #fff !important;
}
.new_btn_grad a {
  color: #fff !important;
}
button.new_btn_grad:hover {
  background-image: linear-gradient(
    to right,
    #fff 0,
    #fff 60%,
    #fff 80%
  ) !important;
  color: #636363 !important;
  border: 1px solid #fff !important;
  box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
}
button.new_btn_grad:hover a {
  color: #636363 !important;
}
.coming_soon_sec {
  min-height: calc(100vh - 250px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner_pagh_bg_1 .inut_gro_stke_1 {
  background: none !important;
  border: 1px solid #fff;
}
.coming_soon_sec p.com_text {
  font-size: 60px;
  font-weight: 700;
  line-height: 100px;
  margin: 0;
  background-image: linear-gradient(90deg, #e50914, #e50914);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
@media only screen and (max-width: 575px) {
  .fontz{
    font-size: 0.7rem !important;
  }
  .cat_title {
    font-size: 16px;
  }
  .coming_soon_sec p.com_text {
    font-size: 35px !important;
    line-height: 60px !important;
  }
}
.price_desc {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  padding: 1px 4px;
  color: #2ea4cf;
  font-weight: 700;
  opacity: 0;
}
.price_desc a {
  color: #2ea4cf !important;
}
.like_desc {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  padding: 1px 8px;
  color: #ea40a5;
  font-weight: 700;
  opacity: 0;
}
.like_desc.liked .far,
.masonry_likes.liked .far {
  font-weight: 900 !important;
  cursor: pointer;
}
.masonry_new .item {
  display: inline-block;
  background: #fff;
  padding: 1em;
  width: 100%;
  transition: 1s;
  -webkit-transition: 1s;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);
  border-radius: 2px;
  margin-top: 10px;
}
.masonry_new .item .fa-ellipsis-h {
  color: #ccc !important;
}
#searchmneu_dd p {
  color: #fff;
}
.searchmneu_dd {
  position: absolute;
  will-change: transform;
  top: 60px;
  right: 0;
  background-color: #141414db !important;
  min-width: 270px;
  max-width: 100% !important;
  box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%),
    0 7px 10px -5px rgb(0 0 0 / 15%);
  -webkit-box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%),
    0 7px 10px -5px rgb(0 0 0 / 15%);
  -o-box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
  border-radius: 0 0 10px 10px;
  padding: 0;
  color: #000 !important;
  z-index: 10 !important;
}
.noti_head {
  padding: 15px !important;
  text-align: left;
  font-weight: 700 !important;
}
.noti_head span {
  font-weight: 700;
  font-size: 18px;
  color: #fff;
}
.info_des {
  color: #969696;
  font-weight: 600;
  font-size: 16px;
  padding-top: 15px;
}
.flex_body {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}
.header_media .media_num {
  color: #e50914;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 5px;
}
.balance_txt_header {
  font-weight: 700;
  font-size: 14px;
  color: #fff;
}
.noti_parent .user_prof_ul li {
  list-style-type: none !important;
  border-bottom: 1px solid #ccc;
  padding: 0px !important;
}
.noti_parent .user_prof_ul li a {
  padding: 15px 10px !important;
}
.user_prof_ul li a:hover,
.user_prof_ul li a:hover span {
  color: #e50914 !important;
}
.user_prof_ul li span {
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500 !important;
}
.noti_parent .user_prof_ul li:hover {
  background-color: #000 !important;
}
.user_prof_ul {
  padding: 0 !important;
  margin: 20px 0 20px !important;
}
.heade_switch .toggle_custom.switch {
  margin-right: 0 !important;
  top: -8px !important;
  width: 50px;
  height: 22px;
}
.heade_switch .toggle_custom .slider:before {
  min-width: 45px !important;
}
.primary_modal .modal-dialog {
  margin-top: 20px;
  margin-bottom: 20px;
}
.formCls .form-control {
  height: 46px !important;
}
.single_collectible .item_multiple {
  position: relative;
  border: #c7c7c7;
  border-bottom: 15px groove #c7c7c7;
}
.primary_modal {
  padding-right: 0px !important;
}
.primary_modal .modal-title {
  display: grid;
  grid-template-columns: 100% 10%;
}
#learn_modal li,
#learn_modal p {
  font-size: 15px;
  font-weight: 500;
  color: #282425;
  line-height: 2;
}
.accordian_para p {
  color: #939393;
  font-size: 16px;
}
.nav-tabs.masonry_tab.proposal_tab .nav-link {
  padding: 12px 25px !important;
}
.nav-tabs.masonry_tab.proposal_tab {
  margin: 20px 0px;
  padding-left: 5px !important;
}
.font_14 {
  font-size: 14px !important;
}
.item_prof_img {
  min-width: 120px;
  min-height: 120px;
  max-width: 120px;
  max-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 6px solid #fff;
  border-radius: 50%;
  margin-top: -50px;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}
.ma_no_gap {
  column-gap: 0em !important;
}
.img_media_new {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #fff;
  border-radius: 50%;
}
.media_num.font_14 {
  font-size: 14px !important;
}
.icon_img {
  position: relative;
}
.img_dis {
  min-width: 45px;
  min-height: 45px;
  max-width: 45px;
  max-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid gray;
  border-radius: 50%;
}
.select_show_dd {
  --moz-appearance: menulist !important;
  --webkit-appearance: menulist !important;
}
.arrow_back {
  font-size: 27px;
  color: #6f6f6f;
}
.arrow_back:hover {
  color: #e50914;
}
.cur_pointer {
  cursor: pointer;
}
.primary_inp {
  font-size: 14px !important;
}
.create_btn.bg-white:hover {
  background-image: linear-gradient(
    to left,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
  color: #fff !important;
  border: 1px solid #e50914 !important;
}
.filter_btn.white_text_filter_btn {
  color: #fff !important;
}
.connect_row_conn_wal.connect_row .create_box img {
  filter: unset !important;
}
.connect_row_conn_wal.connect_row .create_box h2 {
  color: #fff !important;
}
.connect_row_conn_wal.connect_row .create_box .text-white {
  color: #ded1d1 !important;
}
.create_btn .text_back a {
  color: #fff !important;
}
.coming_soon_btn,
.coming_soon_btn:hover {
  border: 2px solid #e50914;
}
.dropdown .drop-down {
  font-size: 22.5px !important;
}
section.liveauction {
  background: linear-gradient(#dfdfdf, #fafafa, #d1d1d0);
}
.change_price_img_div {
  max-width: 200px;
  max-height: 150px;
  min-width: 200px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  margin: 20px auto;
}
.font_we_600 {
  font-weight: 600;
}
.font_12 {
  font-size: 12px !important;
}
.dd_info_inline {
  display: inline !important;
}
.acti_card .follow_media.icon_img {
  align-items: flex-start !important;
}
.follow_media.icon_img .media_num {
  font-size: 13px !important;
}
.acti_card .card-body .follow_media {
  align-items: center !important;
  justify-content: center;
  height: 100%;
}
.acti_card .bg_red_icon_sm {
  top: unset !important;
  top: -10px !important;
}
.icon-img-div {
  position: relative;
}
.connect_row_conn_wal .create_box h2 {
  font-size: 19px !important;
  text-align: center;
}
.connect_row.connect_row_conn_wal .create_box img {
  max-width: 45px;
  min-width: 45px;
}
.baner_r_span {
  font-size: 25px !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: transparent !important;
  background-image: linear-gradient(
      to top,
      #ffffff 0px,
      rgba(156, 39, 176, 0) 0px
    ),
    linear-gradient(to top, #decacb 0px, rgba(210, 210, 210, 0) 0px) !important;
}
.min_h_45_px {
  min-height: 45px;
}
.min_h_45_px input {
  color: #fff !important;
}
header img.logo_img {
  max-width: 220px !important;
}
.heading_grey {
  color: #373737;
  font-weight: 700;
  font-size: 25px;
}
.notable_img {
  border-radius: 50%;
  margin-top: -60px;
  margin-left: 20px;
}
.notable_sec {
  position: relative;
}
.notable_sec .owl-prev {
  position: absolute;
  top: 50%;
  left: -10px;
}
.notable_sec .owl-next {
  position: absolute;
  top: 50%;
  right: -10px;
}
.card_user_div {
  border-radius: 0px 0px 15px 15px;
}
/* .card_user_bg_1 {background-image: linear-gradient(to left, #141414, #141414, #000000 90%) !important;} */
/* .card_user_bg_2 {background-image: linear-gradient(to left, #e50914, #e50914, #e50914 90%)  !important;} */
.card_user_bg_3 {
  background-image: linear-gradient(60deg, #00a69c 0, #0fc9be 60%, #0fc9be 80%);
}
.item_inner_img img {
  height: 100%;
  object-fit: contain;
  width: 100%;
  max-height: 245px;
  min-height: 245px;
  min-width: 220px;
}
/* .single_collectible .item_inner_img img{padding: 60px;} */
.notable_sec.masonry .item h2 {
  color: #fff !important;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px !important;
}
.heading_grey .dropdown {
  display: inline !important;
}
.heading_grey .dropdown .drop-down {
  color: #267cbe !important;
  font-weight: 700;
  font-size: 20px;
}
.create_btn.btn_rect {
  border-radius: 10px !important;
}
.dropdown-menu {
  max-height: 252px;
  overflow-y: auto;
}
.trend_sec .owl-prev {
  position: absolute;
  top: 50%;
  left: -10px;
}
.trend_sec .owl-next {
  position: absolute;
  top: 50%;
  right: -10px;
}
.trend_sec .item_inner_img {
  height: 200px !important;
}
.trend_sec .user_prof_img {
  margin-top: -40px;
  max-height: 45px;
  max-width: 45px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.trend_sec.masonry .item h2 {
  color: #282425 !important;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0 !important;
  margin-top: 20px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}
.tick_img_blue {
  max-width: 15px;
  display: inline !important;
  margin-top: 20px;
}
.trend_sec.masonry .item p {
  color: #282425 !important;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  padding: 0 50px;
}
.heading_grey.text-white {
  text-shadow: 2px 2px #5959592e;
}
.img_desc_nft h2 {
  font-weight: 800 !important;
  max-width: 45%;
  white-space: nowrap;
}
.trend_sec.masonry .item h2.mt-0 {
  margin-top: 0 !important;
}
footer {
  text-align: left !important;
}
.footer_sm_text {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.footer_ul li a {
  color: #fff;
  font-size: 16px;
}
.footer_ul li a:hover {
  color: #a4d2eb;
  font-weight: 600;
}
.user_prof_ul.mt-0 {
  margin-top: 0px !important;
}
.user_prof_ul.mb-0 {
  margin-bottom: 0px !important;
}
.user_prof_ul li {
  padding: 15px 5px !important;
}
.user_prof_ul li:last-child {
  border: none !important;
}
.user_prof_ul li.swithcj_li {
  padding: 11px 15px !important;
}
@media only screen and (max-width: 1024px) {
  .mobile_nav {
    width: 95% !important;
  }
  .search_inp {
    width: 83% !important;
    padding-left: 25px !important;
    font-size: 12px !important;
  }
  .search_inp.d-block .search_inp_group_prepend {
    margin-left: 10px;
    color: #fff;
  }
  .search_inp.d-none .search_inp_group_prepend {
    left: 7px !important;
    color: #fff;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .search_inp {
    width: 100% !important;
  }
  .mobile_nav {
    width: 420px !important;
  }
}
.like_count {
  font-weight: 600;
  color: #707a83;
  margin-bottom: 0 !important;
}
.like_div_round {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  right: 30px;
  top: 30px;
  background: #b5acac45;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
  cursor: pointer;
  z-index: 1;
}
.profiel_img_pos {
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  position: absolute;
  left: 15px;
  top: -20px;
}
.star_img_blue {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
  position: absolute;
  left: 28px;
  top: -27px;
}
.img_desc_nft {
  position: relative;
}
.fa-heart.liked.active,
.fa-heart.liked {
  font-weight: 700;
}
.fa-heart.liked2.active,
.fa-heart.liked2:hover {
  font-weight: 700;
}
.my_items_head {
  color: #a5a5a5 !important;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  word-break: break-all;
}
.my_items_head_1 {
  color: #474445;
  font-weight: 600;
}
.inner_page_bg_1,
.inner_pagh_bg_1,
.inner_pagh_bg_2 {
  background: top/cover no-repeat #141414;
}
.inner_page_bg_2 {
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.inut_gro_stke_1 {
  position: relative;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(to right, #91d9e7, #c4d2e5) border-box;
  color: #313149;
  border: 2px solid transparent;
  border-radius: 10px;
  width: 65%;
}
.join_head {
  color: #c5c2c2 !important;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0 !important;
  margin-top: 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.light_theme .join_head {
  color: #101010 !important;
}
.my_items_desc_1 {
  color: #474445;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.social_a_icon span {
  color: #b7b7b7;
  font-weight: 600;
  font-size: 14px;
  padding-left: 10px;
}
/* .backclr{
  background-image: linear-gradient(to right, red , yellow);
} */
.social_a_icon:hover span {
  color: #e50914;
}
.tab_count_badge {
  background-image: linear-gradient(
    to left,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
  color: #fff;
  font-size: 12px !important;
  padding: 2px 8px;
  border-radius: 25px;
  margin-left: 5px;
  min-width: 35px !important;
  display: inline-block;
}
.ma_no_gap {
  column-gap: 0px !important;
}
.ma_no_gap .card-inner-item {
  margin: 0px !important;
}
.ma_no_gap .masonry {
  margin: 0px !important;
}
.card_main_iner .item_inner_img {
  height: 250px !important;
}
.pos-reltv {
  position: relative;
}
.card_main_iner {
  justify-content: flex-start !important;
}
.join_head.mt-0 {
  margin-top: 0 !important;
}
.acti_card {
  position: relative;
  color: #313149;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: 0 0 0 0 #eee9e9 !important;
  background-color: transparent !important;
}
.rounded-corners-gradient-borders {
  border: 1px solid #e50914;
  background-image: none;
  background-size: 100% 1px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
  border-radius: 0;
}
.btn_filter_row .create_btn {
  font-size: 14px !important;
}
.btn_filter_row .create_btn:hover {
  font-size: 14px !important;
}
.acti_card:hover {
  box-shadow: 0px 0px 6px 6px rgb(0 0 0 / 10%) !important;
}
.img_info_rad {
  border-radius: 5px;
  border: 1px solid #cccccc38;
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  min-height: 500px;
  max-height: 500px;
}
#buy_modal .modal-body button.create_btn {
  margin-bottom: 20px !important;
}
#buy_modal button.btn_outline_red {
  height: 52px;
}
.user_name_inf {
  font-size: 14px !important;
  font-weight: 700;
  margin-left: 10px;
  color: #fff;
}
.badge-outline-blue {
  border: 1px solid #e50914;
  border-radius: 5px;
  color: #fff;
  font-weight: 700;
  padding: 5px 10px;
  font-size: 12px !important;
}
.user_name_inf_big {
  font-size: 14px !important;
  font-weight: 500;
  color: #000;
  word-break: break-all;
  line-height: 2;
}
.btn_div_info .create_btn {
  min-width: 130px !important;
  text-transform: uppercase;
  font-size: 16px !important;
  height: 38px !important;
}
.btn_div_info .create_btn:hover {
  min-width: 130px !important;
  text-transform: uppercase;
  font-size: 16px !important;
  height: 38px !important;
}
.container_full {
  max-width: 1440px !important;
  margin: 0 auto;
}
.comment_sec_inout img {
  max-width: 50px;
}
.comment_sec_inout {
  display: flex;
  align-items: center;
}
.comment_sec_inout .inut_gro_stke_1 {
  border-radius: 5px;
  background: 0 0 !important;
  height: 50px !important;
  border: 1px solid #fff;
}
.comment_sec_inout .inut_gro_stke_1 input {
  border: none !important;
  background-image: linear-gradient(to top, #ccc 0, rgba(156, 39, 176, 0) 0),
    linear-gradient(to top, #d2d2d2 0, rgba(210, 210, 210, 0) 0) !important;
  height: 50px !important;
  color: #fff !important;
  font-weight: 500 !important;
}
.bor_top_blue {
  border-top: 2px solid #e50914;
  border-left: 2px solid #e50914;
  margin-top: 97px;
  padding-right: 0;
}
.info_tab_ouert .primary_tab {
  border-bottom: 2px solid #e50914;
}
.text_big_owner {
  font-size: 20px !important;
  color: #323335;
  font-weight: 600;
}
.MYtext_big_owner {
  font-size: 16px !important;
  color: #323335;
  font-weight: 600;
}
.text_sm_owner {
  font-size: 16px !important;
  color: #323335;
  font-weight: 600;
}
.expand_icon {
  max-width: 25px;
}
@media only screen and (max-width: 575px) {
  .MYtext_big_owner {
    text-align: center;
  }
  .btn_div_info .create_btn {
    padding-left: 20px !important;
    padding-right: 20px !important;
    min-width: 100% !important;
  }
  .img_info_rad img {
    max-width: 70% !important;
    min-height: 297px !important;
    max-height: 297px !important;
  }
  .img_info_rad {
    min-height: 300px !important;
    max-height: 300px !important;
  }
  .table-dark td {
    width: 50% !important;
  }
  .info_tab_ouert .primary_tab {
    border-bottom: unset !important;
  }
  .footerhead {
    padding-bottom: 0 !important ;
  }
  .inner_pagh_bg_1 {
    text-align: center;
  }
}
.send_iocn_bg {
  width: 40px;
  height: 40px;
  background-color: #e50914;
  border: 1px solid #e50914;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5px;
  cursor: pointer;
}
.send_iocn_bg:hover {
  background-color: #fff;
  color: #e50914;
}
.heading_big_blk {
  color: #fff;
  font-size: 30px;
  font-weight: 800;
}
.heading_sm_blk {
  color: #fff;
  font-size: 20px;
  font-weight: 800;
}
.heading_sm_blk_new {
  color: #fff;
  font-size: 16px;
  font-weight: 800;
}
.explore_tab_full {
  display: block;
}
.create_row_img .item_inner_img img {
  height: unset !important;
  width: auto !important;
}
.create_row_img .item_inner_img {
  height: 250px !important;
}
.input-group.input_grp_style_1.min_h_45 input:disabled {
  height: 45px !important;
}
.noitem{
  text-align: center;
  color: #fff;
  margin-top: 20%;
}
.light_theme .noitem{
  color: black !important;
}
.primary_inp {
  font-size: 16px;
  color: #969696;
  min-height: 46px !important;
  border-radius: 10px !important;
  border: 1px solid #ccc !important;
  background-image: linear-gradient(0deg, #fff 0, rgb(255 255 255) 0),
    linear-gradient(0deg, #fff 0, hsla(0, 0%, 82.4%, 0) 0) !important;
}
.input_grp_style_1 {
  border-radius: 10px !important;
  border: 1px solid #ccc !important;
  min-height: 45px !important;
  background-image: linear-gradient(0deg, transparent 0, transparent 0),
    linear-gradient(0deg, #fff 0, hsla(0, 0%, 82.4%, 0) 0) !important;
}
.input_grp_style_1 input,
.input_grp_style_1 input:disabled {
  background-image: linear-gradient(0deg, #fff 0, rgb(255 255 255) 0),
    linear-gradient(0deg, #fff 0, hsla(0, 0%, 82.4%, 0) 0) !important;
  border: none !important;
  min-height: 45px !important;
  color: #fff !important;
}
.light_theme .css-1go3xin-option{
  background-color: unset !important;
}
.light_theme .css-1opnhvy-singleValue{
  color:#000 !important;
}
.light_theme .input_grp_style_1 input, .input_grp_style_1 input:disabled {
  color: #000 !important;
}
.dark_theme .input_grp_style_1 input:disabled {
  color: #fff !important;
}
.input_grp_style_1 .input-group-text {
  border: none !important;
  min-height: 45px !important;
}
.select1 div {
  border-bottom-color: #ccc !important;
}
.react-select__menu {
  background-color: #000 !important;
}
.card_grad_he_full .acti_card {
  height: 100%;
}
.img_with_star {
  position: relative;
  overflow: visible;
}
.img_with_star .star_img_blue {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
  position: absolute;
  left: 28px;
  top: -5px;
}
@media only screen and (max-width: 767px) {
  .pad_top_create,
  .pad_top_notfound {
    padding-top: 0px !important;
  }
  .inner_pageheader {
    padding-top: 30px !important;
  }
}
.not_found_text_404 {
  color: #c5c2c2;
  font-size: 40px;
  font-weight: 800;
  line-height: 50px;
}
.not_found_desc {
  font-size: 18px;
  color: #252526;
  margin-bottom: 22px;
  font-weight: 600;
}
.bid_form label.text-center {
  text-align: center !important;
}
.purple_circle_dot {
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #2c9bd8;
  background-image: linear-gradient(
    to left,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
}
.connectwallet1 {
  background-color: #141414;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.followingsys,
.rankingsyse {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.activitysys {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.not_banner_dessc {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}
.banner_user {
  color: #474445;
  font-weight: 600;
  line-height: 20px;
}
.noti_ul_dd {
  border: 1px solid #ccc;
  border-radius: 15px;
}
.searchscroll{
  height: 160px;
  overflow: auto;
}
.noti_ul_dd li:first-child {
  border-top: none !important;
  border-radius: 15px 15px 0px 0px;
}
.noti_ul_dd li:last-child {
  border-radius: 0px 0px 15px 15px;
}
.noti_ul_dd li:hover {
  box-shadow: rgb(4 17 29 / 25%) 0 0 8px 0;
}
.create_para_big {
  color: #9a9696;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0;
}
.conet_desc {
  color: #6e6b6b;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0;
}
.dropdown .drop-down.btn_rect {
  font-size: 16px !important;
  border-radius: 50% !important;
  padding: 5px 10px !important;
  border: 1px solid #e50914 !important;
}
.menu_more_dd {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  transform: none !important;
  right: 0 !important;
  left: unset !important;
  top: 55px !important;
}
.searchmneu_dd .noti_ul_dd {
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px !important;
}
.searchmneu_dd .noti_ul_dd li:first-child,
.searchmneu_dd .noti_ul_dd li:last-child {
  border-radius: 0px !important;
}
.foot_logo_new {
  max-width: 120px !important;
}
.main_navbar li a:focus,
.main_navbar li a:focus-visible {
  background: 0 0 !important;
}
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover,
.dropdown-menu a.active,
.dropdown-menu a:active,
.dropdown-menu a:focus,
.dropdown-menu a:hover {
  box-shadow: none !important;
  background: 0 0 !important;
  color: #e50914 !important;
}
.word_brk_adrs {
  word-break: break-all;
  white-space: break-spaces;
}
.loadmodal{
  background-color: red;
}
.laodingpart {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.laodingpart p {
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
}
.social_a_icon:hover span {
  color: #e50914 !important;
}
#share_modal .icon_div:hover .fab,
#share_modal .icon_div:hover p {
  color: #e50914 !important;
}
.input_grp_style_1 input:focus {
  background-image: linear-gradient(0deg, transparent 0, transparent 0),
    linear-gradient(0deg, #fff 0, hsla(0, 0%, 82.4%, 0) 0) !important;
}
.main_navbar svg:hover,
header svg:hover {
  fill: #fff !important;
  color: #fff !important;
}
.noti_head.pb-0 {
  padding-bottom: 0px !important;
}
.comment_sec_inout .inut_gro_stke_2 {
  border-radius: 10px !important;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(to right, #415199, #69a5ce) border-box;
  height: 48px !important;
  width: 95%;
}
.comment_sec_inout .inut_gro_stke_1 {
  border-radius: 10px !important;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(to right, #415199, #69a5ce) border-box;
  height: 48px !important;
  width: 95%;
}
.my_items_head_nim {
  color: #fff !important;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
}
.proposal_panel_overall::-webkit-scrollbar {
  display: none;
}
.proposal_panel_overall {
  overflow: scroll;
}
.fa-trash {
  color: #e50914;
  margin-top: 7px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  --webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  --moz-appearance: textfield;
}
.myclass {
  margin-left: auto;
}
.main_navbar li a:focus {
  color: #e50914;
  background: 0 0;
}
.colecton_name_baner_bue {
  color: #e50914;
  font-size: 14px;
  font-weight: 700;
}
.feaures_p,
.feaures_ul li {
  color: #000 !important;
  font-weight: 600;
  font-size: 14px;
}
.feaures_ul {
  padding-left: 15px;
}
.primary_modal h5.modal-title {
  font-size: 20px !important;
}

a.create_btn {
  background-image: linear-gradient(
    to left,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
  height: 42px;
  border-radius: 30px;
  padding: 5px 15px;
  transition: 0.2s;
  border: 1px solid #e50914;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #fff !important;
  text-align: center;
  box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
}
a.create_btn:hover {
  background: #fff !important;
  color: #636363 !important;
  border-color: #fff;
  font-weight: 600 !important;
  font-size: 14px !important;
}
a.create_btn:hover a {
  color: #e50914 !important;
}
.exploremneu_dd_res,
.statsmneu_dd_res {
  position: absolute;
  will-change: transform;
  top: 65px;
  right: 0;
  border: 1px solid #ffffff4f;
  background-color: #0000008f !important;
  min-width: 270px !important;
  max-width: 100% !important;
  box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%),
    0 7px 10px -5px rgb(0 0 0 / 15%);
  -webkit-box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%),
    0 7px 10px -5px rgb(0 0 0 / 15%);
  -o-box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
  border-radius: 0;
  padding: 0;
  color: #000 !important;
  z-index: 10 !important;
  max-height: 80vh;
  overflow-y: auto;
}
@media only screen and (max-width: 1279px) {
  .but_cls_res {
    margin-bottom: 0px !important;
  }
  .but_cls_res_stats.but_cls_res {
    margin-top: 0px !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  .exploremneu_dd_res,
  .statsmneu_dd_res {
    max-width: 100% !important;
    min-width: 240px !important;
    left: 0 !important;
  }
}
@media only screen and (min-width: 1280px) {
  .main_navbar li a {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .ml-cust-search {
    margin-right: auto !important;
  }
}
.not_found_text_sub {
  color: #6b6868 !important;
}
.create_row_img .item_inner_img img {
  object-fit: contain !important;
}
.single_dd_1 button {
  border-bottom: 1px solid #d2d2d2 !important;
  color: #fff !important;
}
.single_dd_1 .filter_menu div:hover {
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(106 100 107 / 40%) !important;
}
.selct_form_input_h {
  background: transparent !important;
  border-radius: 10px !important;
  color: #fff !important;
  border: 1px solid #ccc !important;
}
.reactloader {
  display: inline;
  padding: 41%;
}
.scrreactloader {
  padding-left: 40%;
}
.searreactloader {
  display: inline !important;
  flex: 1;
  align-self: center;
}
.CMS p {
  font-size: 18px;
  color: #252526;
  margin-bottom: 22px;
  font-weight: 600;
}
.CMS h1 {
  font-size: 30px;
  font-weight: 700;
  color: #030303;
  line-height: 40px;
}
/* / ...............v.......... / */
.qrcode_integ_title_align p,
.qrcode_integ_title_green_align p {
  color: #fff;
}
.qrcode_integ_whole_align {
  background-color: #fff !important;
}
.qrcode_description_dtls_align p,
.qrcode_integ_whole_align h6 {
  color: #000;
}
.container-fluid.fluid_padding_align.d-flex {
  max-width: 100%;
  padding-right: 30px;

}
.frr{
  /* justify-content: center;
    padding-right: 5px !important;
    padding-left: 5px !important; */
}

p.definition_text_align {
  margin-bottom: 1rem;
}
.qrcode_address_dtls_align small {
  color: #fff;
}
:root {
  --borderclr: #e5e8eb;
  --themeclr: #279fda;
  --background-linear: #1e90ff;
  --background: #141414;
  --color: #fff;
  --color_hover: #e50914;
  --bgred: #e50914;
  --font: "Netflix";
  --shadow: 0 2px 2px 0 rgba(176, 39, 39, 0.14),
    0 3px 1px -2px rgba(176, 39, 39, 0.2), 1px 5px 0 rgba(180, 41, 41, 0.12);
}
.inputbtn button {
  position: absolute;
  right: 6px;
  bottom: 1px;
  height: 43px;
  border-radius: 9px !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.w-full {
  width: 100%;
}
.expand {
  width: 100%;
}
.filtertab .nav {
  justify-content: flex-start !important;
}
.card-body ul li {
  list-style-type: none;
  margin-bottom: 10px;
}
.card-body ul {
  padding-left: 0;
}
input[type="checkbox"] {
  margin-right: 12px;
}
.sideinput input {
  background: 0 0 !important;
}
.searchinput .d-flex {
  justify-content: space-between;
  color: #000;
}
.sideinput {
  border: 1px solid var(--borderclr);
  border-radius: 0;
  height: 41px;
}
.custcheck input[type="checkbox"]:after {
  content: "";
  background: #fff;
  position: absolute;
  height: 20px;
  width: 20px;
  top: 0;
  box-shadow: 0 0 5px grey;
  border-radius: 4px;
  bottom: 0;
  margin: auto;
}
.custcheck input[type="checkbox"]:checked:after {
  border: 1px solid #2aa0da;
}
.custcheck input[type="checkbox"]:checked ~ span.check:after {
  content: "";
  border: 5px solid;
  height: 4px;
  border-color: #e50914#e50914;
  width: 8px;
  display: block;
  border-width: 0 0 2px 2px;
  transform: rotate(311deg);
}
span.check {
  position: absolute;
  top: 3px;
  bottom: 0;
  margin: auto;
  left: 6px;
  height: 10px;
  pointer-events: none;
}
.custcheck {
  position: relative;
}
.chainimg img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 6px;
  box-shadow: 0 0 5px #c1c1c1;
}
.filter-icon {
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-icon span {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}
.filter {
  position: sticky;
  top: 80px;
}
.filete1 {
  border-left: 1px solid var(--borderclr);
  max-height: 500px;
  overflow-y: auto !important;
}

.filter .card-body {
  background: #141414;
}
.filter > .filter-icon:hover {
  box-shadow: -2px 2px 5px #c5c4c44a;
}
.filter > .filter-icon:hover {
  box-shadow: -2px 2px 5px #c5c4c44a;
}
.filtersec {
  align-items: flex-start;
  border-top: 1px solid var(--borderclr);
}
.filteritems {
  max-height: 100vh;
  overflow: auto;
}
.filtersec.d-flex {
  align-items: flex-start;
}
.newmyitems .item_prof_img {
  margin-left: auto;
  margin-right: auto;
}
.state > div {
  flex: 1;
  border: 1px solid var(--borderclr);
}
.state {
  max-width: 500px;
  margin: auto;
}
.state h3 {
  color: #000;
  margin-bottom: 0;
  font-weight: 600;
}
.state > div:nth-child(3) {
  border-left: 0;
  border-right: 0;
}
.state > div:nth-child(2) {
  border-left: 0;
}
.state > div:first-child {
  border-radius: 10px 0 0 10px;
}
.state > div:last-child {
  border-radius: 0 10px 10px 0;
}
.newmyitems .join_head {
  display: block;
}
.newmyitems .join_head span {
  margin-right: 10px;
}
.newmyitems .inut_gro_stke_1 {
  max-width: 500px;
  margin: auto;
}
.sociallinks {
  position: absolute;
  right: 0;
  top: 64px;
}
.filter {
  min-width: 365px;
  transition: all 0.5s ease;
}
.sociallinks button {
  padding: 0;
  background: 0 0;
  box-shadow: none;
  min-width: unset;
}
.sociallinks .state > div:last-child {
  border-left: 0;
}
.sociallinks .state > div {
  padding: 6px 15px;
}
.themeclr {
  color: var(--themeclr);
}
.avgprice {
  font-size: 15px;
  line-height: 1;
  margin-bottom: 0;
}
.pricestate {
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 0;
}
.light_theme .rdtPicker{
  width: 100% !important;
}
.pricestate span {
  padding-right: 5px;
  font-size: 12px;
}
.d-grid.itemsselect {
  display: grid;
  grid-template-columns: 30% 20% 20%;
}
.price-tab p {
  font-size: 12px;
  font-weight: 500;
  color: gray;
  line-height: 1;
}
.activtab.table th,
.activtab.table td {
  vertical-align: middle;
  text-align: center;
  color: #fff;
}
.activtab.table a {
  color: #e50914;
  font-weight: 600;
}
.activtab.table a:hover {
  color: #fff;
  font-weight: 600;
}
.tableprofimg img,
.tableprofimg video {
  width: 40px;
  height: 40px;
  border-radius: 5px !important;
  margin-right: 5px;
  object-fit: contain;
}
.tableprofimg {
  align-items: center;
}
.filteredlist ul li button {
  background: #f1fafd;
  border: 1px solid #15b2e5;
  border-radius: 10px;
  padding: 6px 12px;
}
.filteredlist ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}
.filteredlist ul {
  padding-left: 0;
  margin-bottom: 0;
}
.filteredlist ul li:last-child button {
  background: 0 0;
  border: transparent;
  font-size: 13px;
  color: #279fda;
  font-weight: 700;
  text-transform: capitalize;
}
.filteredlist ul li:last-child button:hover {
  box-shadow: none;
}
.filteredlist ul li button:hover {
  box-shadow: 0 0 5px grey;
}
.sidehide.filter {
  min-width: 60px !important;
  width: 60px !important;
}
.filteredlist ul li span.fa-times {
  margin-left: 7px;
  color: gray;
}
.sidehide.filter .filterbtn span {
  display: none;
}
.filtersec.d-flex .masonry .item {
  transition: unset;
}
.filtersec .w-full {
  overflow: hidden;
}
.filterbtn.mobilebtn {
  position: fixed;
  bottom: 9px;
  z-index: 99;
  display: inline-block;
  width: 129px;
  margin: auto;
  left: 0;
  right: 0;
  padding: 8px 12px;
  border-radius: 20px;
  color: #fff !important;
}
.mobilebtn {
  display: none !important;
}
.filterbtn.mobilebtn label {
  color: white;
}
.filterbtn.mobilebtn span {
  color: white;
  margin-right: 10px;
}
.sidehide.filter .filteritems {
  visibility: hidden;
}
.stabtn button {
  width: calc(50% - 8px);
  margin: 4px;
  background-color: #141414 !important;
  color: #d5d5d5 !important;
  border-color: #f1f1f1;
  font-weight: 600 !important;
  font-size: 14px !important;
  box-shadow: 0 4px 18px 0 rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
  border-radius: 10px;
  text-transform: capitalize !important;
}
.stabtn button:hover {
  background-image: linear-gradient(
    to left,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
  border: 1px solid #e50914 !important;
  color: #fff !important;
}
.minmax input {
  height: 39px;
  padding: 5px;
}
.minmax > div {
  width: calc(50% - 38px);
}
.minmax .MuiInputBase-formControl {
  border-radius: 10px;
}
.minmax button {
  height: 46px !important;
  min-width: calc(50% - 38px);
  border-radius: 10px;
}
.itemsselect select,
.pricefil select {
  border: 1px solid var(--borderclr) !important;
  border-radius: 10px !important;
  background: 0 0 !important;
}
.price p {
  margin: 0;
  font-size: 14px;
  margin-left: 12px;
}
.offers{
  margin-top: 10px;
}
.offers p{
  margin-left: 10px !important;
}
.pricefil select:focus {
  box-shadow: 0 0 5px #dedede !important;
}
.itemsselect {
  gap: 2%;
}
.itemsselect > *:first-child {
  flex: 2;
}
.itemsselect > *:nth-child(2) {
  flex: 1;
}
.itemsselect > *:nth-child(3) {
  flex: 1;
}
button.chainimg {
  background-color: transparent !important;
  color: #000;
  font-size: 16px;
  padding: 0;
  box-shadow: none;
  display: block;
}
button.chainimg:hover,
button.chainimg:focus {
  box-shadow: none;
  color: black;
}
@media (max-width: 1025px) {
  .sidehide.filter {
    position: fixed;
    top: 0;
    overflow-y: hidden;
    z-index: 999999;
    min-width: 100vw !important;
    height: 100vh;
    background: #fff;
  }
  .sidehide.filter .filterbtn span {
    display: block;
  }
  .sidehide.filter .filteritems {
    visibility: visible;
  }
  .mobilebtn {
    display: block !important;
  }
  .sideshow.filter {
    display: none;
  }
}
@media (max-width: 575px) {

  .allthemes .makeStyles-button-45 {
    padding: 8px 20px;
  }
  .frr {
    padding-right: 0px !important;
  }
  .allthemes{
    text-align: end;
  }
  .allthemes .svg_lft_align svg {
    left: 0px !important;
}
  .inputbtn button {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .d-flex.state > div {
    border: 1px solid var(--borderclr) !important;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .sociallinks .state > div {
    padding: 8px 15px;
  }
  .sociallinks .d-flex.state {
    grid-template-columns: 49% 49%;
    gap: 2%;
    display: grid !important;
    grid-template-columns: 19.5% 19.5% 19.5% 19.5% 19.5%;
    gap: 0.5%;
  }
  .dropdown .drop-down.dd_myitems_menu {
    color: #787878 !important;
    font-size: 16px !important;
  }
  .sociallinks {
    position: unset !important;
  }
  .d-flex.itemsselect {
    display: block !important;
  }
  .filteredlist ul {
    overflow-x: auto;
    white-space: nowrap;
  }
  .d-grid.itemsselect {
    gap: 15px;
    grid-template-columns: 100%;
  }
  .state h3 {
    font-size: 15px;
  }
  .state p {
    font-size: 14px;
  }
  .inut_gro_stke_1 {
    width: 100%;
  }
}
.btn_rect:disabled {
  color: #fff !important;
}
.dd_myitems_main.dropdown button i {
  color: #787878 !important;
  font-size: 16px !important;
}
.dropdown-right.dd_myitems_menu {
  right: 0 !important;
  left: unset !important;
  transform: none !important;
  top: 40px !important;
}
.dropdown-right.dd_myitems_menu a {
  color: #000 !important;
}
.dropdown-right.dd_myitems_menu a:hover,
.dropdown-right.dd_myitems_menu .dropdown-item:hover a {
  color: #fff !important;
  background: transparent !important;
}
.sidehide #filter_icon_right::before {
  content: "\f061" !important;
}
.sidehide.filter > .filter-icon {
  border-bottom: none !important;
}
.sociallinks .state > div {
  cursor: pointer;
}
.sociallinks .state > div:hover i {
  color: #e50914 !important;
}
.dropdown .drop-down.itemsselect_dd_new {
  border: 1px solid #e50914 !important;
  border-radius: 3px !important;
  background: 0 0 !important;
  color: #e50914;
  padding: 5px 20px;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.light_theme .header svg,
.light_theme .main_navbar svg {
  fill: #000 !important;
}
#ticketscards .dropdown .drop-down.itemsselect_dd_new {
  border: 1px solid #e50914 !important;
  border-radius: 3px !important;
  background: 0 0 !important;
  color: #e50914;
  padding: 5px 30px;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.num_text_table {
  color: #fff;
  font-weight: 700;
}
.table_ranking th {
  font-weight: 700 !important;
}
.table_ranking td {
  font-weight: 600 !important;
}
.text_red_table {
  color: #fff !important;
}
.text_green_table {
  color: #fff !important;
}
.text_none_table {
  color: #fff !important;
}
.price-tab span {
  color: #fff;
}
.table_ranking th:nth-child(2),
.table_ranking td:nth-child(2) {
  min-width: 180px !important;
}
.table_ranking th:nth-child(5),
.table_ranking td:nth-child(5) {
  min-width: 100px !important;
}
.activtab tbody tr:hover {
  transition: 0.2s;
  box-shadow: rgb(4 17 29 / 25%) 0 0 8px 0;
  background-color: rgb(251 253 255 / 6%);
}
.badge-timer {
  width: 100% !important;
  padding: 5px 10px;
  font-size: 14px !important;
  background-color: transparent !important;
  border: 1px solid #e50914;
  color: #e50914;
  border-radius: 3px;
  font-weight: 500;
  text-transform: capitalize;
  max-width: -webkit-max-content;
  max-width: max-content;
}
.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: "\f256";
}
@media only screen and (max-width: 1025px) {
  .filter_btn_scroll_web {
    display: none !important;
  }
  .filter_btn_scroll {
    display: flex !important;
  }
}
@media only screen and (min-width: 1026px) {
  .filter_btn_scroll {
    display: none !important;
  }
}
.overflow_body {
  max-height: 100vh !important;
  overflow-y: hidden !important;
}
@media only screen and (max-width: 1199px) {
  .filteritems {
    max-height: 90vh !important;
  }
}
button.create_btn:hover.mobilebtn:hover {
  color: #636363 !important;
  box-shadow: 0 14px 26px -12px rgb(153 153 153 / 42%),
    0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%) !important;
}
button.create_btn:hover.mobilebtn:hover span,
button.create_btn:hover.mobilebtn:hover label {
  color: #636363 !important;
}
.fa-dollar:before,
.fa-usd:before {
  content: "\f155";
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #cdcdcd;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.make_offer_badge.badge-timer {
  max-width: 93% !important;
}
.contarct_details {
  background-color: #202020 !important;
  padding: 10px 0px;
}
.contarct_details div p:last-child {
  text-align: right;
  font-weight: 700;
}
.contarct_details div p:first-child {
  text-align: left;
  font-weight: 600;
}
.contarct_details div p {
  padding: 5px 0px 0px 0px !important;
}
.break_over_token {
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
  width: 200px;
}
.contarct_details div {
  display: flex;
  justify-content: space-between;
}
.img_info_rad {
  cursor: pointer;
}
.img_info_rad img {
  object-fit: contain;
  max-width: 40%;
  min-height: 497px;
  max-height: 497px;
}
.infoimg_modal .modal-dialog {
  width: 100% !important;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
}
.infoimg_modal .modal-content {
  height: min(100vw - 50px, 100vh - 50px);
  width: min(100vw - 50px, 100vh - 50px);
  background: #000 !important;
  border: 0;
  position: initial;
  border-radius: initial;
}
.light_theme .infoimg_modal .modal-content{
  background:#fff !important;
}
.infoimg_modal .modal-body {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.infoimg_modal .close {
  position: relative;
  right: -64px;
  top: -5px;
  color: #e50914;
  opacity: 1;
  font-size: 30px;
  font-weight: 700;
  text-shadow: 0 0 0 transparent !important;
  z-index: 9;
  margin-left: auto;
}
.infoimg_modal .close span {
  color: #fff;
  background: #000;
  border-radius: 50%;
  padding: 1px 13px;
}
.infoimg_modal .modal-body {
  background: 0 0 !important;
}
header img.logo_img {
  max-width: 100% !important;
  width: 100px;
}
.custom_container {
  max-width: 95%;
}
.search_inp_group {
  z-index: 0;
}
.ReactModalPortal {
  z-index: 9999;
}
.search_inp_group .search_inp {
  padding: 6px 15px 6px 15px;
  height: auto !important;
}
header.fixedTop {
  position: fixed;
  z-index: 1001;
  background-color: #000 !important;
}
.home_header header {
  margin-bottom: 0px !important;
}
header svg, .light_theme .homesec  header svg,
.light_theme .homesec .main_navbar svg ,
.main_navbar svg {
  fill: #fff !important;
}
 
.light_theme header svg,
.light_theme .main_navbar svg {
  fill: #000 !important;
}
section.body-top {
  padding: 60px 0px !important;
}
.CMS h1 {
  font-size: 50px;
  line-height: 1.25;
  margin-top: 0px;
}
.p-b-sec {
  padding-bottom: 45px !important;
}
.p-t-sec {
  padding-top: 45px !important;
}
button.create_btn {
  background-color: #e50914 !important;
  border-radius: 3px;
  border: 2px solid #e50914 !important;
  width: 160px;
  height: auto !important;
  transition: 0.5s ease-in-out;
}
.btn.new_btn_grad {
  background: #e50914 !important;
  color: #ffff !important;
  cursor: pointer;
  font-size: 16px;
  border: 2px solid #e50914 !important;
  border-radius: 5px !important;
  padding: 8px 25px !important;
  font-weight: 400 !important;
  text-decoration: none;
}
.btn.new_btn_grad:hover {
  background: none !important;
  color: #f9231a !important;
  border: 1px solid #f9231a !important;
}
.btn.new_btn_grad:hover span a {
  color: #f9231a !important;
}
button.create_btn:hover {
  background-color: transparent !important;
  border: 1px solid #e50914 !important;
  font-size: 16px !important;
  color: #e50914 !important;
}
button.create_btn {
  border: 1px solid #e50914 !important;
}
.item_inner_img .like_div_round {
  display: none;
}
button.create_btn.bg-white {
  background-image: linear-gradient(
    to right,
    #e50914,
    #e5091450,
    #e50914 90%
  ) !important;
  transition: all 0.5s ease !important;
}
button.create_btn.bg-white div {
  padding-top: 10px;
  padding-bottom: 10px;
  background: #f2f4f4 !important;
}
button.create_btn.bg-white:hover div {
  border: 0px solid !important;
  color: #fff;
}
button.create_btn.bg-white:hover {
  border: 1px solid #e50914 !important;
  background-image: none !important;
  color: #e50914 !important;
}
.searchmneu_dd .noti_ul_dd {
  margin-top: 30px;
}
button.create_btn {
  padding: 5px 20px;
  font-size: 16px !important;
}
.item_inner_img {
  border-radius: 0px;
}
.img_desc_nft h2 {
  font-weight: 600 !important;
  padding-bottom: 10px;
}
.colecton_name_baner_bue {
  color: #e50914;
}
p {
  margin-bottom: 0px;
}
.card_inner_item {
  border-radius: 5px;
  cursor: pointer;
}
.create_page .card_inner_item {
  border-radius: 5px;
  padding: 0;
}
.trend_sec.masonry .item p {
  display: flex;
  justify-content: center;
}
.trend_sec.masonry .item p {
  padding: 0 10px;
}
footer .footer_desc_grey {
  color: #676262 !important;
  line-height: 2;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.footer_ul li a {
  color: #676262 !important;
  line-height: 2.5;
}
.footer_ul li a:hover {
  color: #000 !important;
  transition: all 0.5s ease;
}
footer .footer_sm_text {
  color: #676262;
}
footer .footer_sm_text p {
  line-height: 2;
  text-align: justify;
  padding-top: 20px !important;
}
.newsletter_grp button {
  color: #676262;
}
.cardItem .price img {
  width: auto !important;
}
.item .cardItem {
  box-shadow: 1px 2px 10px -5px #000;
  background: #fff;
}
.item .cardItem .cardImg {
  padding: 20px 15px;
}
.cardItem .card_details h6 {
  font-size: 14px;
  color: #000;
  text-transform: capitalize;
  font-weight: 600;
}
.cardItem .price p {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 0;
}
.cardItem .price p.eth {
  color: #000 !important;
}
.cardItem .user img {
  width: 41px;
  height: 30px;
  border-radius: 50%;
}
.cardItem .card_price {
  padding: 15px 0;
}
.cardItem p.user_title {
  font-size: 11px !important;
  font-weight: 600 !important;
  color: #000 !important;
  padding-bottom: 7px;
}
.cardItem p.description {
  font-size: 10px !important;
  color: #000;
}
.cardImg .timed_auction {
  background-image: linear-gradient(
    to right,
    #e50914,
    #e5091450,
    #e50914 90%
  ) !important;
  padding: 5px;
  margin: auto;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 175px;
  left: 0;
  right: 0;
}
.newsletter_inp:focus {
  box-shadow: 1px 2px 18px -3px rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
}
.newsletter_inp {
  color: rgba(0, 0, 0, 0.5);
}
.foot_logo_new {
  max-width: 200px !important;
}
.featured {
  background: 0 0;
}
.timed_auction span {
  color: #fff;
  font-size: 14px;
}
.creators_card .items_card {
  background: #fff;
  box-shadow: 1px 1px 20px -5px rgb(0 0 0 / 30%);
  padding: 5px 20px;
}
.creators_card p.sub-title {
  font-size: 14px;
  color: #000;
  font-weight: 700;
  padding-bottom: 5px;
}
.creators_card p.items {
  font-size: 13px;
}
.creators_card .item_count h2 {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.3);
}
.creators_card .inner_card {
  border-radius: 50% !important;
}
.heading_grey .block {
  display: inline-block !important;
}
.masonry {
  margin: auto !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999 !important;
}
.tick_img_blue {
  display: none !important;
}
.trend_sec.masonry .item p {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 13px;
}
.inner_pageheader.my_items {
  margin-top: 0 !important;
}
.price .item p {
  font-size: 14px;
  font-weight: 500;
}
.img_desc_nft.cardItem {
  background: 0 0;
  box-shadow: 0 0;
  padding: 0 0 0 12px;
}
.item .cardItem .cardImg {
  position: relative;
}
.img-fluid.profiel_img_pos {
  display: none;
}
.star_img_blue {
  display: none !important;
}
.heading_big_blk,
.heading_sm_blk {
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 2px;
}
.heading_sm_blk_new {
  font-weight: 600;
}
.MuiButton-outlined {
  padding: 15px !important;
}
.stabtn button:hover {
  border: 1px solid #fff !important;
  border-radius: 0px !important;
}
.stabtn button {
  border-radius: 0px;
}
.create_btn.btn_rect {
  border-radius: 0px !important;
}
button.create_btn {
  width: auto;
}
/* .card_user_bg_2{background-image:linear-gradient(to left,#141414,#141414,#000 90%)!important} */
.heading_grey .dropdown .drop-down {
  text-transform: capitalize;
  background: linear-gradient(32deg, #e50914, #e50914 80%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.primary_btn,
button.primary_btn {
  background: #e50914 !important;
  border: 0 solid;
}
.plus i{
  color: #15224A !important;
  font-size: 15px;
}
.minus i{
  color: #15224A !important;
  font-size: 15px;
  transform: rotate(180deg);
}
.modal-body button.create_btn {
  width: 100%;
  border-radius: 30px !important;
  margin-bottom: 30px;
  padding: 12px 0;
}
.modal-body button.create_btn.recordlevel{
  border-radius: 4px !important;
  padding: 12px 14px !important;
  width: unset !important;
}
.file_btn {
  background: #e50914 !important;
  color: #fff !important;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  border: 1px solid #e50914;
  box-shadow: none;
}
.file_btn:hover {
  background: none !important;
  color: #fff;
  border: 1px solid #e50914;
  box-shadow: none;
}
.primary_btn:hover,
button.primary_btn {
  background: 0 0 !important;
  color: #e50914 !important;
  border: 1px solid #e50914;
}
.create_btn:hover a {
  color: #e50914;
}
.sliderbanncontinue a {
  color: #fff !important;
}
button.btn_outline_red {
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(to left, #e50914, #e50914, #e50914 90%) border-box !important;
  border: 1px solid transparent;
}
.user_prof_ul li span {
  transition: all 0.5s ease;
  text-shadow: 2px 1px #000;
}
.owl-carousel .owl-stage-outer {
  /* padding: 10px 4px; */
  padding: 30px 4px;
}
.owl-carousel .owl-prev {
  position: absolute;
  top: 50%;
  left: -10px;
}
.owl-carousel .owl-next {
  position: absolute;
  top: 50%;
  right: -10px;
}
.create_sing_bx {
  background-image: linear-gradient(to left, #141414, #141414, #000000 90%);
}
@media only screen and (min-width: 1201px) {
  .masonry {
    column-count: unset;
  }
}
@media only screen and (max-width: 1280px) {
  .home_header header ul li.menu_dropdown button {
    width: 100% !important;
  }
  .ml-cust-search {
    margin-left: 2rem !important;
  }
  .main_navbar {
    margin-left: 30px !important;
  }
}
@media screen and (max-width: 1280px) {
  .menu_dropdown button {
    padding: 0.9375rem 8px;
    color: #fff !important;
  }
  .main_navbar li a {
    color: #fff !important;
  }
  .timed_auction span {
    font-size: 14px;
  }
  .owl-carousel .owl-prev {
    position: absolute;
    top: 50%;
    left: 10px;
  }
  .owl-carousel .owl-next {
    position: absolute;
    top: 50%;
    right: 10px;
  }
  .main_navbar li a {
    padding: 0.9375rem 10px;
  }
}
@media screen and (max-width: 1199px) {
  .profile_edit_icon i {
    width: 23px;
    height: 23px;
  }
  .container {
    max-width: 95%;
  }
  .yellowheight .content .header .titlegenereal {
    font-size: 16px !important;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 95% !important;
  }
  .MuiDrawer-modal .MuiPaper-root {
    width: 260px !important;
  }
  .light_theme .MuiDrawer-modal .MuiPaper-root {
  background-color: #fff !important;
  }
}
.mobile_nav {
  background-color: #141414 !important;
  overflow: visible !important;
}
.light_theme .mobile_nav {
  background-color: #fff !important;
}
@media screen and (max-width: 767px) {
  .p-t-sec {
    padding-top: 30px !important;
  }
  .p-b-sec {
    padding-bottom: 30px !important;
  }
  .not_found_text_sub {
    padding-bottom: 30px !important;
  }
  .card_main_iner.masonry {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 575px) {
  .cardItem p.user_title {
    font-size: 11px !important;
  }
  .infoimg_modal .close {
    right: 0 !important;
  }
  .img_desc_nft h2 {
    max-width: 100px;
  }
  .info_header .create_btn {
    /* width: 40px; */
    height: 40px !important;
  }
  .info_header .create_btn.mb_btn{
    width: unset !important;
  }
  .ticketsflesd {
    flex-direction: column;
  }
  .contarct_details div {
    justify-content: flex-start;
  }
  .proposal_panel_overall {
    padding-bottom: 0 !important;
  }
}
@media screen and (max-width: 480px) {
  .media_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
  .heading_big_blk,
  .heading_sm_blk {
    font-size: 28px !important;
  }
  .heading_grey .dropdown .drop-down {
    margin-top: 30px;
    margin-left: 10px;
  }
  .join_head {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .items_bg img {
    min-height: 150px;
  }
  .item_prof_img {
    margin-top: -56px !important;
  }
}
.profile_edit_icon i,
.select1 div,
footer a {
  cursor: pointer;
}
.not_found_text_sub,
.proposal_panel_overall {
  padding-bottom: 45px !important;
}
.items_tab .nav-link .tab_head {
  font-size: 16px;
  margin: auto !important;
}
.explore_tab_full {
  display: flex;
  justify-content: flex-start !important;
}
.explore_tab_full #all-tab:nth-child(n) {
  content: "" !important;
  margin-right: 15px !important;
}
.explore_tab_full #all-tab:last-child {
  content: none;
  margin-right: 0 !important;
}
.card_price .price {
  margin-top: 5px;
}
.inp_append {
  top: 13px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}
.select1 div {
  border-bottom-color: transparent !important;
  margin-top: -1.5px;
}
.item_prof .item_prof_img {
  background: #000;
}
.privacy_policy h1 {
  text-align: center;
  color: #fff;
  letter-spacing: 2px;
}
.heading_big_blk,
.heading_sm_blk {
  width: 100%;
  font-size: 40px;
}
.not_found_text_sub {
  padding-top: 10px !important;
}
footer .primary_inp {
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}
.footer_ul li a {
  font-size: 14px;
  font-weight: 600;
}
.footer_sm_text p {
  font-size: 14px;
}
.heading_sm_blk_new {
  color: #c5c2c2;
}
.dropdown-menu .dropdown-item {
  text-transform: capitalize;
}
span.fa-share {
  display: none;
}
.form-control:focus,
.form-control:focus-visible,
.form-control:focus-within {
  text-shadow: none;
  outline: 0;
  transform: none;
  border: 0 solid;
}
.create_page .item_inner_img img {
  max-height: 147px;
  min-height: 157px;
  max-width: 100%;
  object-fit: contain;
}
.walletconnect-modal__base {
  max-width: 300px !important;
}
.info_header .text_big_owner {
  font-size: 13px !important;
}
.item_inner_img video {
  max-height: 265px;
  min-height: 265px;
  object-fit: contain;
  max-width: 100%;
}
.main_navbar .active a,
.main_navbar .active button span {
  color: #e50914 !important;
  position: relative;
}
.MYtext_big_owner {
  margin-top: 20px !important;
}
.trend_sec.masonry .item p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  justify-content: flex-start;
}
.masonry .item:hover {
  transition: 0.5s !important;
}
.creators_card .inner_card img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.modal-body .bid_form {
  margin-top: 20px;
}
.primary_modal h5.modal-title {
  margin-bottom: 20px;
  margin-top: 20px;
}
.my_items_desc_1,
.my_items_head_1 {
  margin-bottom: 20px;
}
.create_row_img p {
  line-height: 2;
  color: #fff;
  font-size: 14px;
}
.text_big_owner span {
  color: #fff;
  cursor: pointer;
}
.text_big_owner .text-truncate {
  color: #fff;
  cursor: pointer;
}
.contractadd {
  display: flex;
}
@media screen and (max-width: 360px) {
  .drop_d-block {
    display: block !important;
    text-align: center;
  }
}
a {
  text-decoration: none !important;
}
.connect_wallet .img_user_noti {
  margin-top: 0px !important;
}
footer .footer_sm_text {
  line-height: 2;
}
.toggle_custom .slider {
  border-radius: 10px;
}
.toggle_custom .slider::before {
  width: 20px;
  border-radius: 30px;
  transform: none;
}
.card_main_iner h3 {
  margin-top: 0px !important;
}
.user .img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.card_inner_item .far.fa-heart {
  margin-right: 10px;
  color: #e50914;
  font-size: 18px;
}
.tokename_title {
  font-size: 20px !important;
}
.card_details {
  position: relative;
}
.card_details span {
  position: absolute;
  bottom: 45px;
  width: 160px;
  left: 0;
  right: 0;
  margin: auto;
  background-image: linear-gradient(
    to left,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
  color: #fff;
  padding: 10px;
  text-align: center;
}
.timeleft {
  border: 1px solid #fff;
  text-align: center;
  color: #fff;
  padding: 1px 8px;
  border-radius: 5px;
  position: relative;
}
.card_main_iner .item_inner_img {
  position: relative;
}
.card_main_iner .item_inner_img .timeleft {
  position: absolute;
  bottom: 35px;
}
.card_whole .card_inner_item .like_count {
  color: #e50914;
  text-align: center;
  font-size: 14px;
  z-index: 9;
  position: relative;
}
.profiel_img_pos {
  display: none !important;
}
.create_single_mulitple .timeleft {
  display: none !important;
}
.notable_sec.masonry .item h2 {
  color: #fff !important;
  font-weight: 500;
  font-size: 19px;
  margin-bottom: 10px !important;
  letter-spacing: 2px;
}
.info_header .timeleft {
  width: auto;
  margin-top: 25px !important;
  padding: 10px !important;
}
.item_inner_img {
  height: auto;
}
.colecton_name_baner_bue {
  text-align: left;
}
.wrapper{
  display: grid;
  grid-gap: 10px;
  /* grid-template-columns: repeat(6, 100px);
  grid-template-rows: 100px 100px 100px; */
  grid-auto-flow: column;
}
.notable_sec img {
  max-height: 201px;
  min-height: 201px;
}
.card_inner_item a.btn.btn_purple_sm {
  text-transform: capitalize !important;
}
.trend_sec.masonry .item p {
  margin: auto !important;
  margin-top: 15px !important;
}
.badge-timer {
  margin-bottom: 0px !important;
}
#login_modal {
  z-index: 999999;
}
.gooele {
  background-color: #1c89fe;
  border: 1px solid #1c89fe;
}
.gooele a {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
.fbfackbook {
  background-color: #3268b7;
  border: 1px solid #3268b7;
}
.fbfackbook a {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
.create_box.active {
  background: #e50914 !important;
}
@media screen and (min-width: 1280px) {
  .menu_dropdown button {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .menu_dropdown .posleft {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    -webkit-transform: perspective(999px) rotateX(-50deg) translateZ(0)
      translate3d(-50%, 15px, 0);
    transform: perspective(999px) rotateX(-50deg) translateZ(0)
      translate3d(-50%, 15px, 0);
    transition: visibility 0.25s, opacity 0.25s, transform 0.25s,
      -webkit-transform 0.25s;
    -webkit-transform-origin: left;
    transform-origin: left;
    left: 50%;
    top: 100%;
    background-clip: border-box;
  }
  .menu_dropdown.rightside:hover .posleft {
    opacity: 1;
    z-index: 999 !important;
    transition: 0.5s;
    top: 28px !important;
    transform: perspective(999px) rotateX(0) translateZ(25px)
      translate3d(-10%, 15px, 5px) !important;
    -webkit-transform: perspective(999px) rotateX(0) translateZ(25px)
      translate3d(-10%, 15px, 5px);
    -webkit-animation: 0.25s forwards show-navbar-dropdown;
    animation: 0.25s forwards show-navbar-dropdown;
    pointer-events: auto;
    visibility: visible;
  }
  .menu_dropdown:hover .posleft {
    opacity: 1;
    z-index: 999 !important;
    transition: 0.5s;
    top: 28px !important;
    transform: perspective(999px) rotateX(0) translateZ(25px)
      translate3d(-80%, 15px, 5px) !important;
    -webkit-transform: perspective(999px) rotateX(0) translateZ(25px)
      translate3d(-80%, 15px, 5px);
    -webkit-animation: 0.25s forwards show-navbar-dropdown;
    animation: 0.25s forwards show-navbar-dropdown;
    pointer-events: auto;
    visibility: visible;
  }
  .usemneu_dd_wallet.posleft {
    left: -20px !important;
  }
}
@media screen and (max-width: 1279px) {
  .noti_parent li {
    padding: 10px 0px;
  }
  .noti_parent li a {
    padding: 5px;
  }
  .menu_dropdown .posleft {
    opacity: 0;
    visibility: hidden;
  }
  .menu_dropdown:hover .posleft {
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease;
  }
  .exploremneu_dd_res,
  .statsmneu_dd_res {
    top: 50px;
  }
}
.dropdown_header_ul li:hover a {
  color: #e50914;
  transition: all 0.5s ease;
}
.menu_dropdown .active {
  color: #e50914 !important;
}
.info .tab-pane .proposal_panel_overall {
  max-height: 400px;
  overflow-y: auto;
}
.my_items .btn_filter_row button {
  max-width: 144px !important;
  width: 100% !important;
}
.btn_filter_row .active {
  background: #121212 !important;
  border: 1px solid #e50914 !important;
}
.stabtn .active {
  background: #000 !important;
}
.stabtn .active span {
  color: #fff;
}
.infoimg_modal .modal-content img {
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
  margin-top: -14px;
}
.newmyitems .table-responsive::-webkit-scrollbar {
  width: 12px;
}
/* Track */
.newmyitems .table-responsive::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
/* Handle */
.newmyitems .table-responsive::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 0, 0, 0.8);
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
header .main_navbar li .explore_extra {
  padding: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
/* scrollbar */
.container-fluid {
  max-width: 1440px;
  margin: auto;
}
.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end !important;
}
.homelinklink:hover {
  color: #fff !important;
}
.homelinklink.active {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-decoration: none;
}
.bodyoverflow {
  overflow: hidden;
}
.homelinks {
  display: flex;
  align-items: center;
}
.pHieT .selected {
  background-color: #000 !important;
}
.listeitems {
  position: absolute;
  width: 100%;
}
.fabellsafter::before {
  content: "";
  position: absolute;
  background-color: #28ca5e;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  top: 7px;
  right: 10px;
  box-shadow: 0 0 7px 2px #000;
}
#myprofiles img {
  border-radius: 5px;
  border: 2px solid #28ca5e;
  width: 40px;
  height: 40px;
}
.bannerheadjustifuy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: initial !important;
}
.fabells {
  color: #fff;
}
.sortdown {
  font-size: 16px;
  color: #fff;
}
.searchlist.active {
  display: block;
}
.searchlist {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  top: 70px;
  height: 240px;
  box-shadow: 0 0 5px 1px #00000012;
  display: none;
  min-height: 310px;
  max-height: 310px;
}
.searchlist .createitemsec {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
}
.arryasearch {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}
#navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
#navbar.scroll {
  position: fixed;
  top: 0;
  background-color: #000 !important;
  box-shadow: -1px 3px 7px 0 #0000006b;
  z-index: 9999;
}
.connectwallet:hover {
  background: none !important;
  color: #e50914 !important;
}
.connectwallet {
  background: #e50914 !important;
  color: #ffff !important;
  cursor: pointer;
  font-size: 16px;
  border: 2px solid #e50914 !important;
  border-radius: 5px !important;
  padding: 8px 25px !important;
  font-weight: 400 !important;
  text-decoration: none;
}
.homelinklink {
  color: #bcbcbc;
  font-size: 16px !important;
  text-decoration: none;
}
#mobilenav {
  position: absolute;
  width: 50%;
  right: -497px;
  top: 88px;
  background-color: #141414;
  height: 100vh;
  transition: 0.3s linear;
}
#mobilenav.show {
  right: 0px;
}
.burger {
  display: none;
}
.burger.clicked p:first-child {
  transform: rotate(45deg);
}
.burger.clicked p:nth-child(2) {
  display: none;
}
.burger.clicked p:last-child {
  transform: rotate(-45deg);
  margin-top: -2px;
  width: 30px;
}
.burger:focus-visible {
  outline: none;
}
.burger {
  position: relative;
  border: none;
  background: none;
}
button:focus:not(:focus-visible) {
  box-shadow: none;
}
.burger p:first-child {
  background-color: #fff;
  height: 2px;
  width: 30px;
  border-radius: 50px;
  margin-bottom: 0;
}
.listnone {
  display: none;
}
.overflowbody {
  overflow: hidden;
}
.burger p:last-child {
  margin-top: -9px;
  background-color: #fff;
  height: 2px;
  width: 20px;
  margin-bottom: 0;
  border-radius: 50px;
}
.list {
  margin: 0;
}
.listitem {
  list-style: none;
  color: #fff;
  cursor: pointer;
}
.sliderbanncont p {
  color: #c5c2c2;
  font-size: 10px;
  margin: 0;
}
@media (max-width: 1500px) and (min-width: 1441px) {
  #ticketscards {
    min-width: unset !important;
    max-width: unset !important;
    width: unset !important;
  }
  .trendingban {
    min-width: unset !important;
    max-width: unset !important;
    width: unset !important;
  }
}
@media (max-width: 1440px) and (min-width: 1200px) {
 
  .ticketesexplorenpage .trendingbans {
    min-height: 650px !important;
    max-height: 650px !important;
  }
  #ticketscards,
  .trendingban {
    min-width: unset !important;
    max-width: unset !important;
    width: unset !important;
  }
}
@media (max-width: 991px) {
  .carddnone {
    display: block !important;
  }
  .main_navbar {
    margin-left: 0 !important;
  }
  .main_navbar li a {
    color: #000;
    font-weight: 500 !important;
    font-size: 16px !important;
  }
  .quicklinks {
    margin-top: 20px;
  }
  .notifidiv {
    display: none !important;
  }
  .pHieT > ul {
    background: #000 !important;
    border-radius: 24px !important;
  }
  .homelinks {
    display: none;
  }
  .buybtn {
    margin-left: 20px;
  }
  .about_bg img {
    width: 100%;
  }
  .burger {
    display: block;
  }
  #mobilenav {
    top: 1px;
    z-index: -1;
    width: 47%;
  }
}
@media (max-width: 767px) {
  #mobilenav {
    top: 1px;
    z-index: -1;
    width: 47%;
  }
}
@media (max-width: 575px) {
  .info_header .text_big_owner {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .item_prof {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text_big_owner span {
    margin-left: 0 !important;
  }
  .buybtn {
    padding: 5px 8px !important;
    font-size: 16px !important;
    margin-left: 10px;
  }
  .search_inp {
    padding-left: 14px !important;
  }
  .search_inp.d-block .search_inp_group_prepend {
    left: 7px !important;
  }
  #mobilenav {
    top: 1px;
    z-index: -1;
    width: 47%;
  }
}
header.fixedTop {
  position: fixed;
  z-index: 9999;
}
header {
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%),
    0 7px 12px -5px rgb(33 33 33 / 46%) !important;
}
/* header */
.home_header {
  /* background-color: #141414; */
  background-color: #1e1e1e;
  position: relative;
  z-index: 9;
}
.homebanner {
  position: relative;
  margin-top: -78px;
}
.notifidiv {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}
.headbanenimg {
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.headbanenimg img {
  object-fit: cover;
  min-height: 100vh;
  width: 100%;
}
.headbanenimg::after {
  content: "";
  position: absolute;
  background: linear-gradient(
    to bottom,
    #00000094 10%,
    transparent 30%,
    #00000040 60%,
    #141414 100%
  );
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.homebanerheight {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.homebanerheightexplore {
  background-color: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  color: #000;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #fff;
  margin-right: 20px;
  transition: all 0.5s ease;
}
.homebanerheightexplore:hover {
  box-shadow: -2px 7px 10px 2px #00000075;
  color: #000;
}
.homebanerheightcreate {
  background-color: #6d6d6ed5;
  padding: 10px 30px;
  border-radius: 5px;
  color: #fff !important;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #6d6d6ed5;
  margin-right: 20px;
  transition: all 0.5s ease;
}
.homebanerheightcreate:hover {
  box-shadow: -2px 7px 10px 2px #00000075;
  color: #000;
}
.homebanerheight h2 {
  color: #fff;
  font-size: 80px;
  text-shadow: 0 0 12px #0009;
  text-align: center;
  font-weight: 600;
  width: 95%;
  margin: 0 auto;
}
/* header */
/* footer */
.light_theme .footerhead {
  background-color: #fff !important
}
.footerhead {
  padding: 30px 0;
  cursor: pointer;
}
.joincommunity h2 {
  color: #fff;
  font-weight: 600;
  font-size: 24px;
}
.footerhead p {
  color: rgba(255, 255, 255, 0.473);
  font-size: 14px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  line-height: 1.5;
  font-weight: 500;
  text-align: justify;
}
.quicklinks p {
  color: rgba(255, 255, 255, 0.473);
  font-size: 14px !important;
}
.footsign {
  color: #e50914 !important;
  font-weight: 600;
}
.footerhead h2 {
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: right;
}
.plus,
.minus {
  color: #e50914;
}
.footerhead ul {
  padding: 0;
}
.joinlinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.joinlinks i {
  font-size: 28px;
  color: #fff;
  transition: 0.5s ease;
}
.joinlinks i:hover {
  font-size: 28px;
  color: #e50914;
}
.footerhead ul li {
  list-style: none;
  color: #000;
  font-size: 16px;
  margin-bottom: 10px;
}
.footerhead ul li span {
  margin-right: 12px;
}
.quicklinks {
  position: relative;
}
.quicklinks ul {
  padding: 0;
}
.quicklinks p {
  font-size: 24px;
  font-weight: 500;
}
.quicklinks ul li a {
  list-style: none;
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 400;
  opacity: 0.5;
  transition: all 0.5s ease;
}
.quicklinks ul li a:hover {
  color: #fff;
  opacity: 1;
}
.quicklinks ul li span {
  margin-right: 12px;
}

.footerhead input[type="email"]:focus-visible {
  outline: none !important;
  border-bottom: 1px solid transparent;
}
.footerhead input[type="email"] {
  --webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 10px 0;
  width: 60%;
  color: #fff;
  border: none;
}
.footeremailadd {
  border-radius: 1px;
  border: 1px solid rgba(255, 255, 255, 0.473);
  margin-top: 10px;
  padding: 1px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quicklinks .footsign {
  color: #ff002e;
  font-size: 16px;
  font-weight: 600;
}
.footerhead .connectwallet {
  background: #f9231a !important;
  color: #fff !important;
  font-size: 16px;
  border: 2px solid #f9231a !important;
  border-radius: 30px !important;
  padding: 10px 25px !important;
  max-width: 150px;
  font-weight: 500 !important;
  text-align: center;
}
.footerhead .connectwallet:hover {
  box-shadow: 0 14px 26px -12px rgb(153 153 153 / 42%),
    0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%);
}
.copyrights {
  color: #e50914 !important;
  font-size: 16px;
  text-align: center;
  margin: 0 !important;
  font-weight: 600 !important;
  text-align: center !important;
}
/* footer */
/* trending */
.owl-carousel {
  z-index: unset !important;
}
.trendingsec {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  position: relative;
  z-index: 0;
}
.trendingsec h2 {
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  transition: 1s ease-in-out;
}
.trendingsec:hover .trendingsecexplore {
  transition: 0.5s ease-in-out;
  opacity: 1;
  margin-left: 0;
  display: block;
}
.trendingsecexplore {
  align-items: center;
  gap: 10px;
  display: flex;
  margin-left: -60px;
  display: none;
}
#trending {
  margin-top: -80px;
  position: relative;
  z-index: 9;
  margin-bottom: 50px;
}
.trendingsecexplore h2 {
  font-size: 18px;
  color: #54b9c5;
  font-weight: 500;
  margin: 0;
}
.faAngleRight {
  font-size: 20px;
  color: #54b9c5;
  font-weight: 600;
}
#faAngleRight2 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}
.style_prevu_kit {
  display: inline-block;
  border: 0;
  margin-top: -22px;
  z-index: 0;
  border-radius: 2px;
  position: relative;
}

.register.text-center p {
  font-size: 16px;
}
#visible2 .yellowheight .content {
  display: none;
}
#visible2 .yellowheight:hover .content {
  display: block;
}
.light_theme .bordertop{
  border-top: 1px solid black;
}
.allthemes .makeStyles-button-45{
  box-shadow: unset;
  background: black !important;
  border-radius: 50px;
  padding: 12px 12px;
}
.style_prevu_kit:hover {
 
  /* box-shadow: 0 0 10px #ffffff1f; */
  box-shadow: 0 0 10px  #ffffff00;
  position: absolute;
  /* background-color: #141414; */
  -webkit-transition: 0.2s ease-in;
  -webkit-transform: scale(1.5);
  -ms-transition: 0.2s ease-in;
  -ms-transform: scale(1.5);
  -moz-transition: 0.2s ease-in;
  -moz-transform: scale(1.5);
  border-radius: 5px;
  transition: 0.2s ease-in;
  transform: scale(1.3);
}
#login_modal_home .modal-dialog .modal-body {
  padding-left: 70px !important;
  padding-right: 70px !important;
}
#login_modal_home::placeholder {
  font-weight: 400px !important;
}
#login_modal_home input[type="text"] {
  --webkit-appearance: none !important;
  appearance: none !important;
  border: 1px solid #ccc !important;
  border-bottom: none !important;
  border-radius: 4px !important;
  padding: 23px 8px !important;
}
.create_single_mulitple button.create_btn {
  padding: 10px 20px;
}
#login_modal_home button.create_btn:hover {
  background-color: transparent !important;
  color: #e50914 !important;
  padding: 12px 4px;
}
#login_modal_home button.create_btn {
  background-color: #e50914 !important;
  border: 1px solid #e50914 !important;
  border-radius: 4px !important;
  display: block !important;
  width: 100% !important;
  padding: 12px 4px;
}
#register_modal .modal-dialog .modal-body {
  padding-left: 70px !important;
  padding-right: 70px !important;
}
#register_modal::placeholder {
  font-weight: 400px !important;
}
#register_modal input[type="password"],
#register_modal input[type="text"] {
  --webkit-appearance: none !important;
  appearance: none !important;
  border: none !important;
  border-bottom: none !important;
  border-radius: 4px !important;
  padding: 15px 8px !important;
  background: 0 0 !important;
  color: #000 !important;
  font-weight: 400;
}
#register_modal .registerpagesd {
  display: flex;
  align-items: center;
  justify-content: center;
}
#register_modal .headinpurt {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0 9px 0 9px;
  border: 1px solid #00000017;
}
#register_modal .headinpurt i {
  color: #9f9d9d;
  font-size: 12px !important;
}
#register_modal button.create_btna:hover {
  background-color: transparent !important;
  color: #e50914 !important;
}
#register_modal button.create_btna {
  background-color: #e50914 !important;
  border: 1px solid #e50914 !important;
  border-radius: 0px !important;
  display: block !important;
  width: 25% !important;
  color: #fff;
  margin: 0;
}
#register_modal button.create_btns:hover {
  background-color: #e50914 !important;
  color: #fff !important;
  border: 1px solid #e50914 !important;
}
#register_modal button.create_btns {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 0px !important;
  display: block !important;
  width: 25% !important;
  box-shadow: 0 0 6px 2px #0000001f;
  transition: all 0.5s ease;
}

#login_modal .modal-dialog .modal-body {
  padding-left: 70px !important;
  padding-right: 70px !important;
}
#login_modal::placeholder {
  font-weight: 400px !important;
}
#login_modal input[type="text"] {
  --webkit-appearance: none !important;
  appearance: none !important;
  border: 1px solid #ccc !important;
  border-bottom: none !important;
  border-radius: 4px !important;
  padding: 23px 8px !important;
}
#login_modal button.create_btn:hover {
  background-color: transparent !important;
  color: #e50914 !important;
}
#login_modal button.create_btn {
  background-color: #e50914 !important;
  border: 1px solid #e50914 !important;
  border-radius: 4px !important;
  display: block !important;
  width: 256px !important;
}
#visible2 .yellowheight .price-container .primary-price {
  font-size: 12px;
}
#visible2 .yellowheight .price-container .right .top,
#visible2 .yellowheight .price-container .left .top {
  font-size: 8px;
}
#visible2 .yellowheight .content .header .titlegenereal,
#visible2 .yellowheight .left__date-month-wrapper-inner h2 {
  font-size: 11px;
}
#visible2 .yellowheight .price-container span,
#visible2 .yellowheight .brand p {
  font-size: 11px !important;
}
#visible2 #ticketscards .profilebamds {
  width: 20px !important;
  height: 20px !important;
}
#visible2 .yellowheight .card-button-container .button button {
  padding: 7px 15px !important;
  height: 30px;
  font-size: 12px;
}
.trendingban {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 130px;
  max-height: 130px;
  min-width: 328px;
  max-width: 328px;
  border-radius: 5px;
}
.trendingban img {
  object-fit: cover !important;
  border-radius: 5px;
  max-width: 100%;
  min-height: 240px;
  max-height: 240px;
  height: 240px;
  display: block;
  transition: 0.5s;
}
#register_modal .modal-dialog {
  max-width: 750px;
  min-width: 700px;
  top: 12px;
  cursor: pointer;
}
#register_modal .metas {
  text-align: left !important;
  font-weight: 600 !important;
}
#register_modal .loginformcont {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
}
#register_modal .connectionwallet {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
#register_modal .connectionwallet .connectcontinure {
  display: flex;
  align-items: self-start;
  flex-direction: column;
}
#register_modal .connectionwallet .venly {
  width: 127px;
  background-color: #fff;
  padding: 30px !important;
  margin-right: 12px;
  transition: all 0.5s ease;
  border: 1px solid #00000017;
}
#register_modal .close img {
  width: 20px;
}
#register_modal .connectionwallet .venly:hover {
  background-color: #fff;
  box-shadow: 1px 8px 8px 1px #0000002e;
}
#register_modal .contine:first-child {
  margin-bottom: 20px;
}
#register_modal .contine {
  padding: 20px !important;
  width: 150px !important;
  transition: all 0.5s ease;
  background-color: #fff;
  margin-right: 12px;
  margin-bottom: 20px;
  border: 1px solid #00000017;
}
#register_modal .contine:hover {
  background-color: #fff;
  box-shadow: 1px 8px 8px 1px #0000002e;
}
#register_modal .modal-content {
  background-color: #fff;
  border-radius: 0px !important;
  box-shadow: 0px 0px 12px 0px lightgray;
}
#register_modal h5.modal-title {
  color: #000 !important;
}
#register_modal p {
  color: #000 !important;
  font-size: 13px;
}
.usercontrolicon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.sliderbanncont {
  display: flex;
  align-items: center;
  gap: 10px;
}
#artssec .style_prevu_kit:hover {
  transform: scale(1.3);
  position: relative !important;
  z-index: 999;
  transition: 0.5s;
  /* box-shadow: 0 4px 20px #ff000066; */
}
.likedred{
  margin-right: 0px !important;
}
.sliderbanncont img {
  width: 30px !important;
  height: 30px !important;
  border-radius: 4px;
  border: 1px solid #fff;
  min-height: unset !important;
}
.sliderbanncont h2 {
  font-size: 12px;
  color: #fff;
  margin: 0;
  text-align: left;
}
.faAngleRight2flex {
  display: flex;
  align-items: center;
  gap: 5px;
}
#faHeart {
  color: #e50914;
}
.faHeartflex h3 {
  font-size: 14px;
  color: #fff;
  margin: 0;
  text-align: center;
}
.usercontroliconstock {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}
#tickets .usercontroliconstock {
  display: flex;
  align-items: baseline;
  padding: 10px;
  justify-content: space-between;
}
.explroeapge .trendingban {
  min-width: unset;
  max-width: unset;
}
.usercontroliconstock h2 {
  /* color: #e50914; */
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  text-align: left;
}
.ticketpricelist h2 {
  font-size: 24px;
  color: #fff;
}

.bidonowbtn a {
  background-color: #e50914;
  color: #fff !important;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 5px;
  border: 1px solid #e50914;
}
.bidonowbtn a:hover {
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 5px;
}
.owl-theme .owl-dots {
  position: absolute;
  top: -46px;
  right: 0;
}
.owl-carousel .owl-nav button.owl-next {
  font-size: 50px !important;
  color: #fff !important;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 1;
}
.ticketesexplorenpage .trendingbans {
  min-height: 650px;
  max-height: 650px;
}
#Ticket #ticketscards .trendingbans {
  min-height: 650px;
  max-height: 650px;
}
.tickets .style_prevu_ki1t .bidonowbtn {
  text-align: center;
}
.owl-carousel .owl-nav button.owl-next:hover {
  background-color: transparent !important;
}
.owl-carousel .owl-nav button.owl-prev {
  font-size: 50px !important;
  color: #fff !important;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 1;
}
.light_theme .owl-carousel .owl-nav button.owl-prev {
  color: #101010 !important;
}
.light_theme .owl-carousel .owl-nav button.owl-next {
  color: #101010 !important;
}
.carddnone {
  display: none;
}
#tickets .trendingban {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 680px !important;
  max-height: 680px !important;
  border-radius: 5px;
}
/* trending */
/* arts */
.trendingban {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 130px;
  max-height: 130px;
  border-radius: 5px;
}
.trendingban img {
  object-fit: contain;
  border-radius: 5px;
}
.usercontrolicon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.sliderbanncont {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sliderbanncont h2 {
  font-size: 12px;
  color: #fff;
  margin: 0;
}
.faHeartflex {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
}
#faHeart {
  color: #e50914;
}
.faHeartflex h3 {
  font-size: 14px;
  color: #fff;
  margin: 0;
}
.usercontroliconstock {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}
/* arts */
/* Videos */
#videos {
  margin-top: 50px;
}
.trendingban img {
  object-fit: contain;
  border-radius: 5px;
}
.usercontrolicon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.sliderbanncont {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sliderbanncont h2 {
  font-size: 12px;
  color: #fff;
  margin: 0;
}
.faHeartflex {
  display: flex;
  align-items: center;
  gap: 5px;
}
#faHeart {
  color: #e50914;
}
.faHeartflex h3 {
  font-size: 14px;
  color: #fff;
  margin: 0;
}
.usercontroliconstock {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}

/* Videos */
/* games */
#games {
  margin-top: 50px;
}
.trendingban img {
  object-fit: contain;
  border-radius: 2px;
}
.usercontrolicon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.sliderbanncont {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sliderbanncont h2 {
  font-size: 12px;
  color: #fff;
  margin: 0;
}
.faHeartflex {
  display: flex;
  align-items: center;
  gap: 5px;
}
#faHeart {
  color: #e50914;
}
.faHeartflex h3 {
  font-size: 14px;
  color: #fff;
  margin: 0;
}
.usercontroliconstock {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}
/* games */
.trendingban {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 310px;
  max-height: 310px;
  border-radius: 2px;
}
#artssec .trendingban,
.trending.tickets .trendingban {
  min-height: 240px;
  border: 1px solid #6e6e6e0f;
  max-height: 240px;
  max-width: 100%;
  min-width: 180px !important;
  /* min-width: 280px !important; */

  transition: 0.5s;
}
#ticketscards .trendingban {
  min-width: 240px !important;
}
/* #artssec .style_prevu_kit:hover .trendingban{
  max-width: 100% !important;
  min-width: 210px !important;
  transition: 0.5s;
} */

.usercontrolicon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.sliderbanncont {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sliderbanncont h2 {
  font-size: 12px;
  color: #fff;
  margin: 0;
}
.faHeartflex {
  display: flex;
  align-items: center;
  gap: 5px;
}
#faHeart {
  color: #e50914;
}
.faHeartflex h3 {
  font-size: 14px;
  color: #fff;
  margin: 0;
}
.usercontroliconstock {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}
.owl-theme .owl-dots {
  position: absolute;
  top: -46px;
  right: 0;
}
.carddnone{
 position: absolute;
 bottom: 0;
 background: rgb(0, 0, 0);
 background: rgba(0, 0, 0, 0.5);
 width: 100%;
 transition: 0.5s;
}
.style_prevu_kit:hover .carddnone {
  display: block;
}
.hover_big .owl-stage-outer {
  overflow: visible !important;
}
/* .owl-carousel .owl-stage-outer {
  overflow: unset !important;
} */
/* .style_prevu_kit:hover  .owl-stage-outer {
  overflow: unset !important;
} */
.hover_big .owl-item.more_index {
  z-index: 99999 !important;
}
.hover_big .owl-item.more_index .style_prevu_kit {
  position: relative !important;
  z-index: 99999 !important;
  transition: 0.5s;
}

.profilebamds {
  border-radius: 5px;
  border: 1px solid #28ca5e;
  width: 20px !important;
  min-height: 20px;
  height: 20px !important;
}
.profilebamds1 {
  border-radius: 5px;
  border: 1px solid #fff;
  width: 40px !important;
  min-height: 40px;
  height: 40px !important;
}
/* music */
/* tickets */
#tickets {
  margin-top: 50px;
  min-height: 100vh;
}
#ticketscards .faHeartflexdate {
  background-color: #ffffff40;
  max-width: 40%;
  height: auto;
  border-radius: 4px;
  padding: 12px 10px;
  margin-bottom: 16px;
}
.buynowpad{
  padding: 0px 0px;
}
.usercontrolicon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 2px 6px;
  min-height: 60px;
}
.sliderbanncont {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 85%;
  min-width: 85%;
  max-width: 85%;
}
.buynowbtn{
  width: 100%;
  background-color: #e50914 !important;
  color: #fff !important;
  font-size: 12px;
  padding: 5px 15px !important;
  border-radius: 2px !important;
  border: 1px solid #e50914 !important;
  margin: 0px 0px 0px 0px;
  box-shadow: unset !important;
}
.buynowbtn:hover{
  background-color: transparent !important;
}
.sliderbanncont h2 {
  font-size: 11px;
  color: #ffffff96;
  margin: 0;
}
.sliderbanncont p {
  font-size: 11px;
  color: #fff;
  margin: 0;
  font-weight: 600;
  text-align: left;
}
.balancenft {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: #ffffff40;
  padding: 35px 0;
  border-radius: 8px;
}
.faHeartflex {
  display: flex;
  align-items: center;
  gap: 5px;
}
.likedbtn{
  position: absolute;
  top: 10px !important;
  right: 7px;
  background: #0000008f;
  padding: 8px;
  border-radius: 50px;
}
.likedbtn:hover{
  background:transparent;
  transition: 0.5s;
}
.likedbtn:hover .liked2  {
 color: red;
 transition: 0.5s;
}

#faHeart {
  color: #e50914;
}
.faHeartflex h3 {
  font-size: 14px;
  color: #fff;
  margin: 0;
}
.usercontroliconstock {
  display: unset;
  align-items: center;
  padding: 0px;
  justify-content: space-between;
}
.owl-theme .owl-dots {
  position: absolute;
  top: -46px;
  right: 0;
}
.owl-theme .owl-dots .owl-dot span {
  border-radius: 0px !important;
  width: 8px !important;
  height: 2px !important;
  margin-left: -3px;
}
.owl-carousel .owl-nav button.owl-next {
  font-size: 50px;
  color: #fff;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 9;
}
.owl-carousel .owl-nav button.owl-next:hover {
  background-color: transparent !important;
}
/* tickets */
#arts {
  margin-top: -100px;
  position: relative;
  z-index: 6 !important;
}
#tickets {
  position: relative;
  z-index: 1 !important;
}
/* promotions */
.promotion {
  background-color: #0000007a;
  height: 100%;
  padding: 80px 0;
  cursor: pointer;
  text-align: center;
  position: relative;
  z-index: -999999999;
}
.promotionssec h2 {
  text-align: center;
  color: #fff;
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 600;
}
.promotionssec p {
  text-align: center;
  color: #d9d9d9;
  font-size: 18px;
  margin-bottom: 35px;
}
.promotionssec .explroenft {
  color: #fff !important;
  font-size: 18px;
  border: 1px solid #e50914;
  border-radius: 5px;
  padding: 15px 60px;
  margin-top: 80px !important;
  background-color: #e50914;
  transition: all 0.5s ease;
}
.promotionssec .explroenft:hover {
  background-color: transparent;
  color: #e50914 !important;
}
/* promotions */
/* topcreators */
.topcreators {
  margin: 100px 0;
}
.topcreators h2 {
  color: #252525;
  font-size: 30px;
  text-align: center;
  font-weight: 800;
  margin: 30px 0;
}
.topprofile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.topprofile h2 {
  padding: 0;
  margin: 0;
  color: #030303;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
  text-align: left;
}
.topprofile img {
  border-radius: 20px;
  width: 80px;
  height: 80px;
  border: 3px solid #fff;
  box-shadow: 0 0 9px 3px #00000029;
}
/* topcreators */
/* explore */
.explore {
  min-height: calc(100vh - 445px);
}
.explorepage h3 {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 800;
  color: #000;
  margin-bottom: 30px;
}
/* myitems */
.myitems {
  min-height: calc(100vh - 445px);
}
.myitemsbanner {
  min-height: 200px;
}
.myitemsbanner img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  max-height: 200px;
  min-height: 200px;
}
.myitems .items_bg_pink {
  min-height: 335px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.myitems .item_prof {
  position: relative;
}
.myitems .item_prof_img {
  cursor: pointer;
  min-width: 150px;
  min-height: 150px;
  max-width: 150px;
  max-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid grey;
  border-radius: 50%;
  margin-top: -100px;
  margin-bottom: 20px;
}
.myitems .address_text {
  color: #000;
  word-break: break-all;
  font-size: 25px;
  font-weight: 700;
}
.myitems .editprofileitems {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
}
.myitems .editprofileitems .sharealt {
  color: #000;
  background-color: #fff;
  border: 1px solid #ff002e;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.myitems .editprofileitems .sharealt:hover {
  background-color: #ff002e;
  color: #fff;
}
.myitems .items_bg_pink h4 {
  color: #484848;
  font-size: 16px;
  font-weight: 400;
}
.myitems .items_bg_pink h3 {
  color: #484848;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}
.myitems .twitter {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}
.myitems .twitter span p {
  margin-bottom: 0;
}
.myitems button.btn.newbtn {
  background-image: -webkit-linear-gradient(89deg, #03377f, #03377f);
  background-image: var(--theme-grad);
  padding: 0;
  height: 42px;
  width: 138px;
  border-radius: 10px;
}
.myitems span.gradbtn {
  display: block;
  background-image: -webkit-linear-gradient(89deg, #03377f, #03377f);
  background-image: var(--theme-grad);
  height: 38px;
  width: 130px;
  margin: auto;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
}
.myitems .bg_red_icon {
  background-color: #f09;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #fff;
  margin-left: 15px;
}
.myitems .item_prof_img img {
  object-fit: contain;
}
.my_itemstabs .tabbable-panel .nav-tabs {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.my_itemstabs .tabbable-panel .nav-tabs {
  text-align: center;
}
.my_itemstabs .tabbable-panel .nav-tabs h2 {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
.my_itemstabs .tabbable-panel .nav-tabs p {
  font-size: 18px;
  color: #008bff;
  font-weight: 400;
  margin-bottom: 0;
}
.myitemsload {
  text-align: center;
}
.myitemsload .connectwallet {
  text-align: center;
  margin: 20px 0;
  padding: 8px 25px !important;
}
.myitems .tabbable-panel .nav-tabs li {
  position: relative;
}
.myitems .tabbable-panel .nav-tabs li.active a:before {
  content: "";
  position: absolute;
  background: #008bff;
  margin: 0;
  padding: 0;
  height: 1px;
  left: 0;
  right: 0;
  top: auto;
  width: 100%;
  bottom: -9px;
  transition: 0.5s;
}
/* myitems */
/* model */
@media screen and (max-width: 767px) {
  .modal-content {
    width: 100%;
  }
  #register_modal .modal-dialog {
    max-width: 520px;
    min-width: 510px;
  }
  #register_modal .headinpurt {
    min-width: 208px;
    max-width: 208px;
  }
  /* #insertingPwmodalnew .media-dialog{max-width: 520px;min-width: 510px;}
#insertingPwmodalnew .headinpurt{    min-width: 208px;max-width: 208px;} */
}
.footerhead h2 {
  text-align: left;
}
.joinlinks {
  justify-content: flex-start;
}
.owl-item {
  text-align: center !important;
}
/* model */
.faq {
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
  font-size: 24px;
}
/* faq */
@media screen and (max-width: 1280px) and (min-width: 992px) {
  #trending .owl-carousel .owl-stage-outer.result_hover {
    overflow: visible !important;
  }
  .fixedprice p {
    font-size: 14px;
  }
  .topprofile img {
    border-radius: 10px;
    width: 50px;
    height: 50px;
  }
  .topprofile h2 {
    font-size: 17px;
  }
  .topprofile p {
    font-size: 14px;
  }
}
@media screen and (max-width: 991px) {
  .connectwallet {
    text-align: center;
  }
  .dispkflex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .infodetails {
    margin-top: 20px;
  }
  .style_prevu_kit.yellowheight .content {
    display: block !important;
  }
}
@media screen and (max-width: 767px) {
  .style_prevu_kit.yellowheight .content {
    display: block !important;
  }
  .homebanerheight h2 {
    font-size: 60px;
  }
  .fixedprice p {
    font-size: 14px;
  }
  .createbtn {
    justify-content: center;
  }
  .createmultiple,
  .createsingle {
    width: 50%;
  }
  .style_prevu_kit:hover {
    transform: scale(1);
  }
  .carddnone {
    display: block;
  }
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel .owl-nav button.owl-prev {
    top: 50px;
  }
  .trendingban {
    max-height: 280px;
    min-height: 280px;
  }
  .explroeapge .card_inner_item {
    max-height: 490px;
    min-height: 490px;
}
}
@media screen and (max-width: 575px) {
  /* .home_header {
    background-color: black !important;
    } */
    #artssec .trendingban, .trending.tickets .trendingban {
      min-height: 270px;
      max-height: 270px;
      min-width: 240px !important;
  }
    .restof .subd_jointr_contimg{
      object-fit: cover;
      border-radius: 10px;
      width: 100%;
    }
    .subd_bannerbtns{
      text-align: center;
    }
  .footerhead img {
    max-width: 50%;
  }
  #register_modal .modal-dialog .modal-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .tickets h5 {
    margin-right: 0 !important;
  }
  #register_modal button.create_btns,
  #register_modal button.create_btna {
    width: 70% !important;
  }
  .homebanerheight h2 {
    font-size: 38px;
  }
  #register_modal .modal-dialog {
    max-width: 290px;
    min-width: 290px;
    margin: 0 auto;
  }
  #register_modal .connectionwallet {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  #register_modal p {
    text-align: center;
    margin: 0 auto;
  }
  #register_modal .contine,
  #register_modal .connectionwallet .venly {
    margin-top: 20px !important;
  }
  .connectcontinure {
    order: 2;
  }
  #register_modal .loginformcont {
    gap: 0px;
    flex-direction: column !important;
  }
  #register_modal .registerpagesd {
    gap: 10px;
    flex-direction: column !important;
  }
  #register_modal button.create_btn {
    width: 100% !important;
    margin: 0 !important;
  }
  .search_inp_group_prepend {
    color: #fff !important;
  }
  .search_inp.d-block .search_inp_group_prepend {
    left: 4px !important;
  }
  .search_inp.d-block .search_inp_group_prepend {
    left: 0px !important;
  }
  .d-none.search_inp .search_inp_group_prepend {
    left: 40px !important;
  }
  .promotionssec h2 {
    font-size: 28px;
  }
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel .owl-nav button.owl-prev {
    top: 50px;
  }
  .style_prevu_kit:hover {
    transform: scale(1);
  }
  .carddnone {
    display: block;
  }
  .owl-theme .owl-dots {
    display: none !important;
  }
  .homelinklink,
  .homelinklink.active {
    font-size: 14px !important;
  }
  .quicklinks {
    margin-top: 20px;
  }
  .exploredropdown .btn-success {
    width: 100%;
    text-align: center;
  }
  .dropdown-menu.show {
    width: 100%;
  }
  .getintouchfoot {
    text-align: center;
  }
  .viewipes {
    text-align: center;
    margin-right: 20px;
  }
  .sliderbanncont img {
    border-radius: 5px;
    border: 1px solid #28ca5e;
    width: 20px !important;
    height: 20px !important;
  }
  #httpslinks {
    font-size: 13px;
  }
  .createcollectible h2 {
    font-size: 18px;
  }
  .createsingleimgesec {
    min-height: 200px;
    max-height: 200px;
  }
  .createbtn {
    justify-content: center;
    flex-direction: column;
  }
  .createmultiple,
  .createsingle {
    width: 100%;
  }
  .uploadfilecol {
    flex-direction: column;
    align-items: center;
  }
  .uploadfilecol h2,
  .uploadfilecol p,
  .createitemsec {
    text-align: center;
  }
  .infoimage {
    min-height: 200px;
  }
  .infodetails {
    flex-direction: column;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
  }
  #navbar .connectwallet {
    font-size: 14px !important;
    margin-left: 20px;
  }
}
@media screen and (max-width: 420px) {
  .homebanerheightexplore,
  .homebanerheightcreate {
    padding: 8px 25px;
  }
  #navbar .connectwallet .m-4 {
    margin: 0 !important;
    margin-right: 20px !important;
  }
  #navbar .connectwallet {
    font-size: 11px !important;
    padding: 5px 11px !important;
  }
  #navbar .navbar-brand img {
    width: 75%;
  }
}
.carddnone.more_index {
  z-index: 999 !important;
  position: relative !important;
}
.content.more_index {
  display: none;
}
.tickets h5 {
  text-align: center;
  font-size: 45px;
  color: #fff;
  font-weight: 600;
  margin-right: 30px;
}
.style_prevu_ki1t:hover .content {
  display: block;
}
.tickets .style_prevu_ki1t {
  background-color: none !important;
  box-shadow: 0 0 9px 4px #b1b1b166;
  border-radius: 5px;
  position: relative;
}
#visible2 .bidonowbtn a {
  padding: 10px 30px !important;
}
.hover_big .owl-item.more_index:hover #ticketscards .trendingban {
  min-height: 490px !important;
  max-height: 490px !important;
  z-index: 9999 !important;
}
#visible2 .usercontroliconstock {
  align-items: baseline;
}
#visible2 .usercontrolicon {
  align-items: center;
}
#visible2 .usercontrolicon img {
  align-items: center;
  width: 30px;
}
#ticketscards .ticketsliste {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  background: rgba(26, 26, 26, 0.8);
}
.limetededitionlist {
  flex-grow: 1;
  padding-right: 8px;
}
#artssec {
  z-index: -999;
  position: relative;
}
#visible2 .tickets .trendingban img {
  min-height: unset;
}
.trending.tickets {
  margin-top: -100px;
}
#visible2 .owl-item {
  min-height: 130px;
  max-height: 130px;
  height: 130px;
}
.tickets .style_prevu_ki1t .faHeartflexdate h3 span {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
}
#ticketscards .faHeartflexdate h3 {
  font-size: 18px !important;
  font-weight: 600 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#ticketscards .faHeartflexdate {
  background-color: #ffffff40;
  max-width: 40%;
  height: auto;
  border-radius: 4px;
  padding: 12px 10px;
  margin-bottom: 16px;
}
#arts .style_prevu_ki1t .faHeartflex h3 span {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
}
.tickets .style_prevu_ki1t .sliderbanncont h2 {
  font-size: 12px;
  color: #fff;
  font-weight: 600;
}
.tickets .style_prevu_ki1t .profilebamds {
  border-radius: 5px;
  border: 1px solid #fff;
  width: 40px !important;
  min-height: 20px;
  height: 40px !important;
}
.tickets .style_prevu_ki1t .bidonowbtn a {
  padding: 10px 80px !important;
}
#ticketscards .faHeartflexstock h3 span {
  text-align: left;
  font-size: 18px !important;
  color: #fff;
  font-weight: 600;
  border-top: 1px solid #fff;
  padding-top: 5px;
}
.tickets .trendingban img {
  width: 100%;
  object-fit: cover !important;
  min-height: 680px;
}
.explroeapge {
  padding-top: 30px;
}
.explroeapge .tickets .trendingban img {
  width: 100%;
  object-fit: contain !important;
  min-height: 650px;
}
.explroeapge .tickets .trendingban.trendingbans img {
  width: 100%;
  object-fit: cover !important;
  min-height: 650px;
}
.tickets .ticketname {
  padding: 10px 0 0 0;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  line-height: 1.4;
}
.tickets .addressticket {
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
.ticketloactions {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff78;
  text-transform: uppercase;
  padding: 0 10px;
  margin: 0;
}
.tickets .style_prevu_ki1t .usercontroliconstock h2 {
  font-size: 16px;
}
.tickets .style_prevu_ki1t .usercontroliconstock h2 span {
  font-size: 16px;
  color: #ffffff78;
  font-weight: 600;
  text-transform: uppercase;
}
.tickets .style_prevu_ki1t .bidonowbtn a {
  background-color: #e50914;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  padding: 10px 15px;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #e50914;
}
#ticketscards .faHeartflex {
  display: block;
  background-color: #ffffff40;
  padding: 12px 10px;
  margin-bottom: 16px;
  border-radius: 4px;
}
#ticketscards .faHeartflex h3 {
  padding-bottom: 10px;
}
#ticketscards .ticketnameedition {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 800;
  margin-bottom: 2px;
}
#ticketscards .timingshoe {
  border-top: 1px solid #fff;
  padding-top: 10px;
}
#ticketscards .profilebamds {
  border-radius: 50px;
  width: 25px !important;
  height: 25px !important;
}
#ticketscards .sliderbanncont h2 {
  font-size: 12px !important;
  color: #ccc !important;
  font-weight: 600;
}
.locationihiste {
  text-align: right;
}
#ticketscards .usercontrolicon {
  border-bottom: 1px solid #686868;
  padding-bottom: 20px;
}
.liked2 {
  margin-right: 10px;
  /* color: #e50914; */
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}
#arts .usercontroliconstock {
  flex-direction: row;
}
.tickets .style_prevu_ki1t .bidonowbtn:hover a {
  background-color: transparent;
  color: #e50914;
  font-size: 16px;
  text-transform: uppercase;
  padding: 10px 15px;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #e50914;
}
.style_prevu_kit.yellowheight .content {
  display: none;
}
.visible2 .style_prevu_kit {
  background-color: transparent;
}
#arts .ticketname {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
}
#arts .addressticket {
  padding: 10px 0 0 10px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin: 0;
}
#arts .ticketloactions {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff78;
  text-transform: uppercase;
  padding: 0 10px;
  margin: 0;
}
#arts .style_prevu_ki1t .usercontroliconstock h2 {
  font-size: 12px;
}
#arts .style_prevu_ki1t .usercontroliconstock h2 span {
  font-size: 10px;
  color: #ffffff78;
  font-weight: 600;
  text-transform: uppercase;
}
.holder img {
  object-fit: cover;
  aspect-ratio: 1/1;
}
.ticketclass h2 {
  font-size: 13px;
  color: #999;
  border: 1px solid #999;
  padding: 4px 12px;
  min-width: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.seecolelgions {
  font-size: 18px;
  font-weight: 600;
  color: #e50914;
  margin-top: 30px;
}
.ticketsdate {
  display: flex;
  align-items: stretch;
  gap: 10px;
  margin-bottom: 30px;
}
.date-time-wrapper {
  background: rgba(51, 51, 51, 0.7);
  margin-right: 8px;
  padding: 12px;
  border-radius: 4px;
  position: relative;
  width: 300px;
}
.date-time-wrapper p {
  margin: 0;
  font-size: 14px;
  color: #ccc;
  font-weight: 600;
}
.date-time-wrapper h2 {
  margin: 5px 0;
  font-size: 24px !important;
  color: #ccc;
  font-weight: 600;
}
.date-time-wrapper h3 {
  margin: 10px 0;
  font-size: 14px;
  color: #ccc;
  font-weight: 600;
}
.beforecsclas {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.beforecsclas i {
  color: #e50914;
}
.ticketlocations {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ticketlocations i {
  color: #e50914;
}
.ticketlocations p {
  font-size: 16px;
  margin: 0;
  color: #fff;
  font-weight: 600;
}
.ticketpricelist {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ticketpricelist p {
  font-weight: 800;
  font-size: 12px;
  color: #ccc;
  text-transform: uppercase;
}
.ticketpricelist h1 {
  font-weight: 800;
  font-size: 24px;
  color: #fff;
  text-transform: uppercase;
}
.ticketsflesd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
.yellowheight {
  position: relative;
}
.yellowheight .content {
  background: #292828db !important;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 16px;
  width: 100%;
}
.home .yellowheight .content {
  max-width: 240px;
  min-width: 240px;
  overflow: hidden;
}
.yellowheight .content .header {
  display: flex;
  width: 100%;
}
.yellowheight .content .header .left-main {
  flex-grow: 1;
  padding-right: 8px;
}
.yellowheight .content .header .left-main .market-label {
  font-size: 11px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 1.2px;
  color: #ccc;
  text-transform: uppercase;
}
.yellowheight .left-main .brand {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin: 12px 0;
}
.yellowheight .content .header .titlegenereal {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.18px;
  color: #fff;
  margin: 8px 0 12px;
  white-space: pre-wrap;
}
.yellowheight .content .header .band {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin: 12px 0;
}
.yellowheight .content .brand span {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  overflow: hidden;
}
.yellowheight .brand p {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #ccc;
  margin: 0 !important;
  white-space: break-spaces;
}
.yellowheight .time-stamp-container {
  max-width: 87px;
  height: 100%;
  background: #ffffff40;
  border-radius: 4px;
  padding: 12px 10px;
  margin-bottom: 16px;
}
.yellowheight .time-stamp-container .left {
  border-bottom: 1px solid #fff;
  padding-bottom: 14px;
}
.yellowheight .time-stamp-container .left .left__date-month-wrapper {
  display: flex;
  min-width: 70px;
}
.yellowheight
  .time-stamp-container
  .left
  .left__date-month-wrapper
  .left__date-month-wrapper-inner {
  display: flex;
  flex-direction: column;
}
.yellowheight .left__date-month-wrapper-inner label {
  font-weight: 700;
  font-size: 11px;
  line-height: 1.09;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  margin-bottom: 7px;
}
.yellowheight .left__date-month-wrapper-inner h2 {
  font-weight: 600;
  font-size: 11px;
  line-height: 1.08;
  letter-spacing: -0.24px;
  color: #fff;
  margin: 0;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  word-break: break-all;
}
.yellowheight .content .line {
  align-self: stretch;
  flex-grow: 0;
  border-top: 1px solid #686868;
  margin-bottom: 12px;
}
.yellowheight .price-container {
  display: flex;
  margin-bottom: 12px;
}
.yellowheight .price-container .left {
  flex-grow: 1;
}
.yellowheight .price-container .left .top {
  line-height: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 11px;
  line-height: 1.09;
  color: #ccc;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}
.yellowheight .price-container .right .top {
  line-height: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 11px;
  line-height: 1.09;
  color: #ccc;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}
.yellowheight .price-container .bottom {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.1px;
  align-self: stretch;
  flex-grow: 0;
  font-stretch: normal;
  font-style: normal;
  color: #fff;
  height: 75px;
  overflow-x: auto;
}
.yellowheight .price-container .primary-price {
  font-size: 16px;
  font-weight: 500 !important;
  word-break: break-all !important;
  font-size: 14px !important;
}
.yellowheight .price-container .secondary-price {
  font-size: 14px;
  font-weight: 500 !important;
  color: #ccc;
}
.yellowheight .price-container span {
  white-space: break-spaces;
  font-size: 14px;
}
.yellowheight .price-container .right {
  flex-grow: 1;
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.yellowheight .card-button-container {
  display: flex;
  align-items: center;
}
.yellowheight .card-button-container .icon img {
  width: 25px;
  margin-right: 30px;
  object-fit: cover;
}
.yellowheight .card-button-container .button {
  width: 100%;
}
.yellowheight .card-button-container .button button {
  font-weight: 600;
  font-size: 16px;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 13px 24px 12px;
  border-radius: 4px;
  border: 1px solid #e50914;
  background-color: #e50914;
  width: 100%;
  color: #e50914;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: capitalize !important;
  color: #fff;
}
.yellowheight .card-button-container .button button:hover {
  background-color: none;
  color: #fff;
  background-color: transparent;
  color: #fff;
  transition: all 0.5s ease;
}
@media screen and (max-width: 1280px) {
  #visible2 .yellowheight .trendingban img {
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
@media screen and (max-width: 1440px) {
  #visible2 .yellowheight .trendingban img {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .yellowheight .left__date-month-wrapper-inner h2 {
    font-size: 14px !important;
  }
  .yellowheight .content .header .titlegenereal {
    font-size: 14px !important;
  }
  .collectionspaedimg {
    max-height: 200px;
    min-height: 200px;
  }
}
.home_header #ticketscards,
.home_header .trendingban {
  margin-bottom: 0px;
}
.dropdown .dropdown-item:nth-child(n) {
  content: "";
  border-bottom: 1px solid #fff;
  margin: 0px;
}
.dropdown .dropdown-item:last-child {
  content: none;
  border-bottom: 0px solid #fff;
  margin: 0px;
}
.dropdown .dropdown-menu {
  background: #000;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}
.explore .trendingban {
  max-width: 100% !important;
  min-width: 100% !important;
}
.explore .dropdown .dropdown-menu {
  max-width: 150px;
  min-width: 150px;
}
.left .left__date-month-wrapper-inner h2 {
  word-wrap: anywhere;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
.explore .bottom .primary-price {
  text-align: right;
}
.my_items .trendingban {
  max-width: 100% !important;
  min-width: 100% !important;
}
.info .dropdown .dropdown-menu {
  max-width: 200px;
  min-width: 200px;
}
button {
  border-radius: 5px;
}
.info .dropdown {
  margin-top: 5px;
}
.footerlogo_align {
  display: flex;
  justify-content: center;
}
.footerlogo {
  text-align: center;
  max-width: 100px !important;
  height: 60px !important;
  width: 100px;
  background-image: url("../src//assets/images/lazymintingnew/logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.header_logo_align {
  display: flex;
  justify-content: center;
}
.light_theme .homesec .header_logo_align {
  background-image: url("../src//assets/images/lazymintingnew/logo.png") !important;
}
.light_theme .homesec .fixedTop .header_logo_align {
  background-image: url("../src//assets/images/lazymintingnew/logodark.png") !important;
}

.header_logo_align {
  text-align: center;
  max-width: 100px !important;
  height: 55px !important;
  width: 90px;
  background-image: url("../src//assets/images/lazymintingnew/logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}
.cardimagelogo{
  max-width: 100px !important;
  height: 40px !important;
  width: 75px !important;
}
.detaillogo{
  max-width: 300px !important;
  height: 200px !important;
  width: 300px;
}
.subd_topwinner .header_logo_align {
  height: 34px !important;
}
.backimges .header_logo_align {
  height: 34px !important;
}
.light_theme .header_logo_align {
  background-image: url("../src//assets/images/lazymintingnew/logodark.png");
}

#artssec {
  margin-top: 30px;
}
.explore .trendingban.trendingbans a {
  width: 100%;
}
.proposal_panel_overall {
  overflow: hidden;
}
.inner_pageheader.followingsys {
  padding-top: 0px;
  min-height: 100vh !important;
}
.sliderbanncont p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 100px; */
  width: 70px;

}
.inner_pageheader {
  padding-top: 0px !important;
}
.yellowheight .price-container .loc {
  text-align: center;
}
.collectionspaed {
  position: relative;
  border-radius: 5px;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  transition: 0.5s ease;
}
.collectionspaedimg {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  max-height: 250px;
  overflow: hidden;
  border-radius: 5px;
}
.collectionspaedimg img {
  object-fit: cover !important;
  min-height: 250px;
  max-width: 100%;
}
.collectionsimgs {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #fff;
}
.collectionsimgecontent {
  padding: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  flex-direction: column;
  margin-top: -50px;
}
.collectionsimgecontent img {
  width: 60px;
  border-radius: 5px;
  object-fit: cover !important;
  border: 1px solid #fff;
  background: #fff;
  height: 60px;
}

.collectionsimgecontent h2 {
  margin-bottom: 0;
  font-size: 18px;
  color: #fff;
  margin-top: 20px;
}
.collectionsimgecontent h3 {
  margin-bottom: 0;
  font-size: 14px;
  color: #ccc;
  margin-top: 5px;
}
svg {
  margin: 0 !important;
}
.sheetselected {
  color: #898989;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  top: -13px;
}
.bulkminting_profile {
  position: relative;
}
.bulkminting_profile .Warning_label {
  position: absolute;
  bottom: -50px;
}
.bulkminting_profile img {
  height: 100%;
  object-fit: contain !important;
  border: 1px solid #ffffff4a;
  width: 100%;
  border-radius: 5px;
  max-height: 245px;
  min-height: 245px;
  min-width: 220px;
}
.light_theme .bulkminting_profile img {
  border: 1px solid #000;
}
.bulkminting_profile i {
  background: #e50914;
  background-image: linear-gradient(
    270deg,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
  color: #fff;
  width: 30px;
  bottom: 37px;
  right: 0;
  left: 0;
  position: absolute;
  height: 30px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 2px solid #e50914;
  font-size: 12px;
}
.bulkminting_profile input {
  position: absolute;
  right: 0;
  bottom: 37px;
  opacity: 0;
  z-index: 99;
  left: 42%;
}
.bulking_cover {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.bulkminting_profileimg {
  position: relative;
}
.items_profile {
  object-fit: contain !important;
  max-width: 100%;
}
.cooled {
  margin-top: 0 !important;
}
.bulkminting_profileimg img {
  object-fit: contain !important;
  border-radius: 5px;
  border: 1px solid #ffffff4a;
  width: 150px !important;
  height: 150px;
}
.bulkminting_profileimg i {
  background: #e50914;
  background-image: linear-gradient(
    270deg,
    #e50914,
    #e50914,
    #e50914 90%
  ) !important;
  color: #fff;
  width: 30px;
  bottom: -12px;
  right: 0;
  left: 57px;
  position: absolute;
  height: 30px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e50914;
  font-size: 12px;
}
.bulkminting_profileimg input {
  position: absolute;
  right: 0;
  bottom: -12px;
  opacity: 0;
  z-index: 99;
  left: 44px;
  width: 50px;
}
.collectioncardimg {
  min-height: 230px;
  max-height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.colledtheart i {
  color: #e50914;
  font-size: 16px;
  margin-right: 5px;
}
.colledtheart p {
  font-size: 14px;
  color: #ccc;
  margin-bottom: 0;
}
.colledtheart {
  display: flex;
  align-items: center;
}
.collection_stock h3 {
  color: #e50914;
  text-align: center;
  font-weight: 600;
  margin: 0;
  font-size: 14px;
  z-index: 9;
  position: relative;
}
.collectioncardimg img {
  object-fit: cover !important;
  max-width: 100%;
  min-height: 230px;
}
.bgidd {
  background-image: none !important;
}
.collection_stock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.collection_stock a {
  color: #fff;
  background: #e50914;
  padding: 7px 15px;
  border-radius: 5px;
  transition: 0.5s;
  border: 1px solid #e50914;
}
.collection_stock a:hover {
  background: none;
  color: #e50914;
}
.item_inner_img {
  border-radius: 15px 15px 0 0;
  overflow: hidden;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.price-container .bottom,
.sliderbanncontinue,
.titlegenereal {
  text-align: left;
}
/* collectioncards */
.collectionscard .trendingban {
  min-height: 650px !important;
}
.collectionscard .trendingban {
  max-width: 100% !important;
  min-width: 100% !important;
}
.collectionscard .trendingban img {
  min-height: 650px !important;
  max-width: 100%;
  object-fit: cover !important;
}
/* collectioncards */
.followingsys .trendingban {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 230px;
  max-height: 230px;
  max-width: 100% !important;
  min-width: 100% !important;
  border-radius: 5px;
}
.followingsys .trendingban img {
  object-fit: cover !important;
  min-height: 420px;
  max-width: 100%;
}
.myitemsdf .trendingban img {
  width: 100%;
  object-fit: cover !important;
  min-height: 650px;
}
.item_prof_img img {
  max-width: 100% !important;
  object-fit: cover;
}
.donwloadfiles {
  margin-bottom: 0;
  color: #fff;
  margin-right: 10px;
}
.fileindees {
  margin: 0 auto !important;
  padding-bottom: 50px !important;
}
.gf.fileindees {
  margin: 0 auto !important;
  padding-top: 16px !important;
  padding-bottom: 10px !important;
}
.home_header .connectwallet {
  margin-left: 20px !important;
}
.searchmneu_dd {
  z-index: 999 !important;
}
.home_header header ul li.menu_dropdown.loginUser_1 {
  width: 22px;
  margin-left: 0 !important;
  margin-right: 10px !important;
}
.home_header header ul li.menu_dropdown.loginUser_1 i {
  color: #fff;
  font-size: 18px;
}
.dropbox {
  display: none;
  background: 0 0;
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}
.overlay {
  background-color: #9d0208;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
  top: 0;
  color: #fff;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  --webkit-appearance: none !important;
  margin: 0 !important;
}
.tickets_collection {
  max-width: 90%;
  margin: auto;
}
.home .style_prevu_ki1t:hover {
  max-width: 220px !important;
  min-width: 220px !important;
}
.user_name_inf_big {
  color: #fff;
}
.bg_pink .nft_title span {
  word-wrap: anywhere;
  display: inline-block;
}
.dropbox {
  display: none;
  background: 0 0;
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}
.searchmneu_dd {
  z-index: 999 !important;
}
@media screen and (max-width: 1279px) {
  .exploremneu_dd_res,
  .statsmneu_dd_res {
    background: #000 !important;
  }
}
.forgot_password p a {
  font-size: 14px;
}
.register p {
  font-size: 14px !important;
}
.login_form .loin_pass input {
  background-image: none !important;
  border: 0px solid !important;
}
.login_form .loin_pass #loginemail {
  border: 0px solid !important;
}
.contarct_details div p {
  font-size: 14px !important;
}
.contarct_details {
  background: transparent !important;
}
footer {
  background-color: #202020 !important;
}
.inner_pageheader {
  padding: 40px 0px !important;
}
.info_header {
  padding-top: 40px !important;
}
.modal-body .react-share__ShareButton p {
  font-size: 14px !important;
}
.copyrights {
  padding: 30px 0px !important;
}
.heading_big_blk {
  margin-bottom: 50px;
}
.my_items {
  padding-top: 0px !important;
}
.cooled {
  padding-top: 0px !important;
}
.table-dark {
  background: transparent !important;
}
.table-dark td {
  border-color: transparent !important;
  padding: 4px 10px 4px 0;
  font-weight: 600;
  width: 30%;
}
.table-dark td:nth-child(2) {
  padding: 4px 0;
  word-break: break-all;
  font-weight: 400;
  width: unset;
}
.connectcontinure button {
  width: 100% !important;
  text-align: center !important;
}
.connectcontinure .fblogin,
.connectcontinure .googlelogin {
  width: 100%;
  text-align: center !important;
}
#buy_modal .buy_title_md {
  color: #e50914;
}
.mybiod {
  color: #fff;
}
.mybiods {
  color: #ccc;
}
.rightsl {
  text-align: right;
}
.lazytour {
  position: fixed;
  top: 90%;
  z-index: 9999999;
}

.stabtn_mainbtn {
  padding: 10px 20px !important;
  border: 1px solid !important;
}
.swithcj_li .hsder_ul_spn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swithcj_li .hsder_ul_spn svg {
  height: 15px !important;
  fill: #fff !important;
}
.swithcj_li .hsder_ul_spn .moon_icon {
  fill: #e50914 !important;
}
.main_navbar .toggle_custom .slider:before {
  min-width: 13px !important;
  width: 13px !important;
  background-color: #fff !important;
}
.mobile_nav .sun_icon {
  margin-left: 10px !important;
}
.theme_change_span {
  font-size: 15px;
  padding-top: 5px;
}

.full_card_view #visible2 .yellowheight .content {
  display: block;
}
.moon_icon:hover,
.sun_icon:hover {
  cursor: pointer;
}
.privacy_policy_lnk {
  text-decoration: underline !important;
}
.privacy_policy_dtl ul {
  list-style-type: none !important;
}
.privacy_policy_dtl .privacy_policy_title {
  font-size: 20px !important;
}
.main_modal_browsing {
  color: #fff !important;
  font-size: 12px !important;
}
.theme_change_btn_align {
  margin-right: 20px !important;
  width: 50px !important;
  max-width: 50px !important;
}

.theme_change_btn::after {
  display: none !important;
}
.theme_change_btn button {
  border-radius: 50% !important;
  margin-left: 20px !important;
  height: 30px !important;
  width: 30px !important;
  margin-top: 10px !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  flex-shrink: 0 !important;
}

.common_fontweigt_align {
  font-weight: 600 !important;
}
.client_title_align {
  font-size: 55px;
}
.onboard_dtls_align {
  font-size: 16px;
  color: #fff;
  font-weight: 300;
}
/* .trendingbans_bgcolor{
	background-color: #000 !important;
} */

/* kr css */
/* @media only screen and (min-width:1278px) and (max-width:1279px){
	.MuiDrawer-modal .MuiPaper-root {
		width: 260px !important;
	}
	.mobile_nav {
		width: 420px !important;
	}
	.main_navbar {
		display: block !important;
		margin-left: 30px !important;
	}
	.main_navbar li {
		float: none;
	}
	.menu_dropdown .posleft {
		opacity: 0;
		visibility: hidden;
	}
	.exploremneu_dd_res, .statsmneu_dd_res {
		max-width: 100%!important;
		min-width: 240px!important;
		left: 0!important;
	}
	.main_navbar li {
		float: none;
	}


} */

/* theme css */

body.light_theme {
  background-color: #fff !important;
}
.light_theme .accordian_para p {
  color: #545454 !important;
}
.light_theme .home_header {
  /* background-color: #f2f2f2 !important; */
  background-color: #f2f2f2;
  color: #000 !important;
}
.light_theme .loading_bgcolor.laodingpart{
  background-color: #fff !important;
}
.dark_theme .loading_bgcolor.laodingpart{
  background-color: #000 !important;
}

.light_theme .footerlogo_align {
  display: flex;
  justify-content: center;
}
.light_theme .header_bar_align .header_logo_align {
  /* background-image:url("../src//assets/images/lazymintingnew/logo.png"); */
}
.light_theme .footerlogo {
  text-align: center;
  max-width: 200px !important;
  height: 60px !important;
  width: 100px;
  background-image: url("../src//assets/images/lazymintingnew/lazyminter_whitetheme.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.light_theme .header_logo_align {
  display: flex;
  justify-content: center;
}
/* .light_theme .soulbounds_align{
		height: 200px !important;
		width: 200px !important;
	}
	.light_theme .soulbounds_align .soulbounds_logo{
		height: 200px !important;
		width: 200px !important;

	} */
.light_theme .fixedTop .header_logo_align {
  text-align: center;
  max-width: 200px !important;
  height: 55px !important;
  width: 90px;
  background-image: url("../src//assets/images/lazymintingnew/lazyminter_whitetheme.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}
.light_theme .home_header header ul li.menu_dropdown.loginUser_1 i {
  color: #000 !important;
}

.light_theme .home_header header ul li.menu_dropdown.loginUser_1 i {
  color: #000 !important;
}
.light_theme
  .header_bar_align
  header.fixedTop
  ul
  li.menu_dropdown.loginUser_1
  i,
.light_theme .header_bar_align header ul li.menu_dropdown.loginUser_1 i {
  color: #fff !important;
}

.light_theme
  .header_bar_align
  header.fixedTop
  ul
  li.menu_dropdown.loginUser_1
  i {
  color: #000 !important;
}

.light_theme .footer_sm_text p {
  color: grey !important;
}
.light_theme .joincommunity h2,
.light_theme .joincommunity .joinlinks .footer_social_icons {
  color: #000 !important;
}
.light_theme .joincommunity .joinlinks .footer_social_icons:hover {
  color: #e50914 !important;
}
.light_theme .quicklinks h2 {
  color: #000 !important;
}
.light_theme .footeremailadd {
  border: 1px solid #000 !important;
}
.light_theme .promotion {
  background-color: #fff !important;
}
.light_theme .promotionssec p {
  color: #000 !important;
}
.light_theme .footerhead input[type="email"] {
  color: #000 !important;
}
.light_theme .footerhead input[type="email"]::placeholder {
  color: #000 !important;
}

.light_theme .inner_pageheader,
.light_theme .filtersec,
.light_theme .activtab.table th {
  background-color: #fff !important;
  color: #000 !important;
}
.light_theme .sideshow.filter button,
.light_theme .sideshow.filter span,
.light_theme .card-header,
.light_theme #collapseOne,
.light_theme #collapseOne .card-body,
.light_theme #collapseTwo,
.light_theme #collapseTwo .card-body {
  color: #000 !important;
  background-color: #fff !important;
}

.light_theme .card-body .stabtn button {
  color: #000 !important;
  border: 1px solid #000 !important;
}
.light_theme.mobile_nav {
  background-color: #fff !important;
  background: #fff !important;
  color: #000 !important;
}

.light_theme .stabtn_icons,
.light_theme .stabtn_text {
  color: #000 !important;
}
.light_theme .stabtn_mainbtn {
  padding: 10px 20px !important;
  border: none !important;
}

.light_theme .sideinput input[type="text"] {
  color: #000 !important;
}
.light_theme .stabtn_mainbtn:hover {
  border: none !important;
  background-color: #e50914 !important;
}
.light_theme .stabtn_mainbtn:hover .stabtn_text,
.light_theme .stabtn_mainbtn:hover .stabtn_icons {
  background-color: #e50914 !important;
  color: #fff !important;
}
.light_theme .sideshow.filter button.stabtn_applybtn.create_btn {
  background-color: #e50914 !important;
  color: #000 !important;
  border: none !important;
}
.light_theme .sideshow.filter button.stabtn_applybtn.create_btn:hover {
  outline: 1px solid #e50914 !important;
  color: #e50914 !important;
  background-color: transparent !important;
}
.light_theme .inner_pageheader {
  background-color: #fff !important;
  color: #000 !important;
}
.light_theme .inner_pageheader .heading_big_blk,
.light_theme .inner_pageheader .faq_panel,
.light_theme .accordian_head h2 {
  color: #000 !important;
  background-color: #fff !important;
}
.light_theme .card_inner_item,
.light_theme .card_user_div.card_user_bg_1,
.light_theme .card_user_div.card_user_bg_2 {
  background-color: #fff !important;
  background-image: linear-gradient(to left, #fff, #fff, #fff 90%) !important;
}
.light_theme .card_user_div.card_user_bg_1 h2,
.light_theme .card_user_div.card_user_bg_1 p,
.light_theme .card_user_div.card_user_bg_2 h2,
.light_theme .card_user_div.card_user_bg_2 p {
  color: #000 !important;
}
.light_theme .shadow_card {
  border-radius: 30px !important;
}
.light_theme .card_user_div.card_user_bg_1 .create_button,
.light_theme .card_user_div.card_user_bg_2 .create_button {
  border: 1px solid #000 !important;
  color: #000 !important;
}
.light_theme .card_user_div.card_user_bg_1 .create_button:hover,
.light_theme .card_user_div.card_user_bg_2 .create_button:hover {
  background-color: #e50914 !important;
  color: #fff !important;
  border: none !important;
}
.light_theme .ticketsflesd h5 {
  color: #000 !important;
}
.light_theme .heading_marketplace {
  color: #000 !important;
}
.light_theme .tokename_title,
.light_theme .profiel_img_pos {
  color: #000 !important;
}
.light_theme .table_ranking th,
.light_theme .collectionsimgecontent h2,
.light_theme .user_title {
  color: #000 !important;
}

.light_theme .collectionspaed {
  box-shadow: -2px -1px 8px 2px lightgrey;
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
.light_theme .explore_tab_full .nav-link.active .tab_head,
.light_theme .explore_tab_full .nav-link:hover .tab_head {
  color: #000 !important;
}
.light_theme .card_inner_item {
  box-shadow: -2px -1px 8px 2px lightgrey;
}

.light_theme .img_info_rad {
  border: 1px solid #000 !important;
}
.light_theme .table-dark td,
.light_theme .ticketpricelist h2,
.light_theme .media_text,
.light_theme .ticketpricelist h1 {
  color: #000 !important;
}
.light_theme .inut_gro_stke_1 {
  border: 1px solid #000 !important;
}
.light_theme .inut_gro_stke_1 input[type="text"] {
  color: #000 !important;
}
.light_theme .masonry_tab_nav .items_tab_new .nav-link.active .tab_head,
.light_theme .masonry_tab_nav .items_tab_new .nav-link:hover .tab_head,
.light_theme .text_big_owner span,
.light_theme .text-truncate {
  color: #000 !important;
}
.light_theme .media .media-body .banner_desc_user,
.light_theme .ticketlocations_text,
.light_theme .info_title,
.light_theme .mybiod {
  color: #000 !important;
}
.light_theme .inner_page_bg_1,
.light_theme .inner_pagh_bg_1,
.light_theme .inner_pagh_bg_2 {
  background: top/cover no-repeat #fff;
  background-color: #fff !important;
}
.light_theme header.fixedTop {
  background-color: #fff !important;
}
.light_theme .main_navbar li a,
.light_theme .menu_dropdown button,
.light_theme .search_inp_group_prepend i {
  color: #000 !important;
}
.light_theme .header svg,
.light_theme .main_navbar svg {
  fill: #000 !important;
}
.light_theme .search_inp_group_prepend i:hover,
.light_theme .header svg:hover,
.light_theme .main_navbar svg:hover,
.light_theme .menu_dropdown button:hover {
  color: #e50914 !important;
  fill: #e50914 !important;
}
.light_theme .primary_label,
.light_theme .file_btn:hover,
.light_theme .bulking_cover,
.light_theme .donwloadfiles,
.light_theme .form-control:disabled,
.light_theme .form-control[readonly] {
  color: #000 !important;
}
.light_theme .primary_label span {
  color: grey !important;
}
.light_theme .bulkminting_profileimg img {
  border: 1px solid #000 !important;
}
.light_theme .yellowheight .content {
  background: #fff !important;
  opacity: 0.9;
}
.light_theme .yellowheight .time-stamp-container {
  background-color: #8d8d8d !important;
}
.light_theme .yellowheight .left__date-month-wrapper-inner h2 {
  color: #fff !important;
}
.light_theme .yellowheight .price-container .bottom,
.light_theme .yellowheight .content .header .titlegenereal,
.light_theme .yellowheight .card-button-container .button button,
.light_theme .privacy_policy h1,
.light_theme .dropdown-menu .dropdown-item,
.light_theme .dropdown-menu li > a {
  color: #000 !important;
}
.light_theme .dropdown-menu {
  background: #fff !important;
  z-index: 100000000000000;
}
input[placeholder="Enter phone number"] {
  width: 100% !important;
}
.light_theme .dropdown .dropdown-item:nth-child(n) {
  /* border-bottom: 1px solid #000 !important; */
  border-bottom: 1px solid #fff !important;
}
.light_theme .dropdown .dropdown-item:last-child {
  border: none !important;
}
.light_theme .dropdown .dropdown-item:nth-child(n):hover {
  color: #e50914 !important;
}

.light_theme .header_bar_align .main_navbar li a,
.light_theme .header_bar_align .menu_dropdown button span {
  /* color:#fff !important */
}
.light_theme .header_bar_align .swithcj_li .hsder_ul_spn .moon_icon {
  fill: #fff !important;
}
.light_theme .header_bar_align .swithcj_li .hsder_ul_spn .sun_icon {
  fill: #e50914 !important;
}
.light_theme .fixedTop .main_navbar li a,
.light_theme .header_bar_align .fixedTop .menu_dropdown button span,
.light_theme .header_bar_align .fixedTop .search_inp_group_prepend i {
  color: #000 !important;
}
.light_theme .header_bar_align svg,
.light_theme .header_bar_align .main_navbar svg {
  /* color:#fff !important;
fill:#fff !important; */
}
.light_theme .header_bar_align .fixedTop svg,
.light_theme .header_bar_align .fixedTop .main_navbar svg {
  color: #000 !important;
  fill: #000 !important;
}
.light_theme .swithcj_li .hsder_ul_spn svg {
  height: 15px !important;
  fill: #fff !important;
}
.light_theme .swithcj_li .hsder_ul_spn .sun_icon {
  fill: #e50914 !important;
}
.light_theme .fixedTop .swithcj_li .hsder_ul_spn .moon_icon {
  fill: #000 !important;
}
.light_theme .swithcj_li .hsder_ul_spn .moon_icon {
  fill: #000 !important;
}
.light_theme .header_bar_align .search_inp_group_prepend i {
  /* color:#fff !important */
}
.light_theme .switch.toggle_custom {
  border: none !important;
  outline: none !important;
}
.light_theme .trendingsec h2,
.light_theme #faAngleRight2 {
  color: #000 !important;
}
.light_theme .trendingsecexplore h2 {
  color: #54b9c5 !important;
}
.light_theme .usemneu_dd_wallet {
  background-color: #fff !important;
}
.light_theme .usemneu_dd {
  background-color: #fff !important;
}
.light_theme .user_prof_ul li span {
  color: #000 !important;
  text-shadow: none !important;
}
.light_theme .user_prof_ul li:hover {
  background-color: #fff !important;
}
.light_theme .usemneu_dd_wallet .noti_head span,
.light_theme .usemneu_dd_wallet .balance_txt_header span,
.light_theme .usemneu_dd_wallet .media_num,
.light_theme .sliderbanncontinue a {
  color: #000 !important;
  
}
.light_theme .sliderbanncontinue a {
  color: #fff !important;
}
.light_theme .exploremneu_dd_res,
.light_theme .statsmneu_dd_res {
  background-color: #fff !important;
}
.light_theme .style_prevu_kit:hover {
  background-color: #fff !important;
  box-shadow: -2px -1px 8px 2px #d3d3d340;
  /* box-shadow: -2px -1px 8px 2px lightgrey; */
}
.light_theme .yellowheight .content .header .left-main .market-label,
.light_theme .yellowheight .brand p,
.light_theme .yellowheight .price-container .secondary-price,
.light_theme .yellowheight .price-container .right .top {
  color: #e50914 !important;
}
.light_theme #TokenPrice,
.light_theme #USDTokenPrice,
.light_theme .selct_form_input_h,
.light_theme .dropdown-menu,
.light_theme .single_dd_1 button {
  color: #000 !important;
}

.light_theme .btn.btn_purple_sm {
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 3px 15px;
  transition: 0.2s;
  color: #000;
  margin: 0 !important;
}
.light_theme .btn.btn_purple_sm:hover {
  background-color: #e50914 !important;
  color: #fff !important;
  border: none !important;
}
.light_theme .searchmneu_dd,
.light_theme .hide_img_align {
  background-color: #fff !important;
}
.light_theme .searchmneu_dd p,
.light_theme .search_inp,
.light_theme .pricebanner_title_hint_align,
.light_theme .solution_options_head_align,
.light_theme .solution_options_hint_align,
.light_theme .pricing_features_text_align,
.light_theme .pricing_reviewer_name,
.light_theme .common_title_align,
.light_theme .common_hint_align,
.light_theme .common_hint_one_align {
  color: #000 !important;
}
.light_theme .search_inp{
  background: #ffffffd9;
}
.light_theme .pricinghome_banner_btn,


.light_theme .reviewing_dtls_card_align {
  border-right: 1px dashed lightgray;
}
/* .light_theme .pricing_accordian_align {
	background-color: #f5f5f5 !important;
	
} */
.pricing_accordian_align {
  box-shadow: none !important;
  border: none !important;
}

.light_theme .search_bar_align,
.light_theme .admint_five_div_head_align,
.light_theme .admin_chart_align,
.light_theme .bacground_dtls_aligning,
.light_theme .subscriber_whole_dtls_bgalign {
  background-color: #141414 !important;
  color: #fff !important;
}
.light_theme .owner_admin_text_head,
.light_theme .owner_admin_text,
.light_theme .dropdown_toggle_align::after,
.light_theme .qrcode_scan_txt_align {
  color: #000 !important;
}
.light_theme .user_header_drpdwn_align {
  box-shadow: 0px 0px 12px 0px #dbdbdb;
}
.light_theme .primary_inp {
  /* background: #d7d7d7 !important; */
}
.light_theme .whole_journey_dtls_align,
.light_theme .pricing_cusjourney_dtls_align {
  background-color: #f5f6f7;
}
.light_theme .heading_blue_txt {
  stroke: #000 !important;
  color: #15224a !important;
  -webkit-text-stroke-color: #000;
}
.light_theme .sub_blue_text {
  color: #15224a !important;
}
.light_theme .on_demand_hinttxt_align {
  color: gray;
  font-size: 18px;
}
.light_theme .dark_gray_text{
  color: #4B4B4B !important;
}
.light_theme .gray_text {
  color: gray !important;
}
/* .light_theme .lazy_logo_align {
  filter: invert(1) !important;
} */
.type_dif_txt {
  font-size: 28px !important;
  font-weight: 600;
}
.accod_title_align {
  font-size: 17px;
  font-weight: 700;
}
.playfair_txt {
  font-family: "Playfair Display", Sans-serif !important;
}

/* end theme css */

/* start of dark theme modal css */
.dark_theme .modal-header {
  background-color: #000 !important;
  color: #fff !important;
}
.dark_theme .modal-content {
  /* box-shadow: -1px 1px 8px 1px grey !important; */
  box-shadow: -1px 1px 8px 1px #8080801a !important;
}
.dark_theme .modal-body {
  background-color: #000 !important;
  color: #fff !important;
}
.dark_theme .modal-title {
  color: #fff !important;
}
.dark_theme .media-body .approve_text {
  color: #fff !important;
}
.dark_theme .media-body .approve_desc {
  color: grey !important;
}

.dark_theme .modal-body input[type="number"],
.dark_theme .modal-body .btn_outline_red {
  color: #fff !important;
}

.dark_theme .modal-body .btn_outline_red {
  color: #fff !important;
  background-color: transparent !important;
  background: transparent !important;
  border: 1px solid #fff !important;
}
.dark_theme .modal-body p,
.dark_theme .modal-body ol li {
  color: #fff !important;
}
.dark_theme .rdtPicker {
  background: #000 !important;
  width: 100% !important;
}
.dark_theme .modal-body .btn_outline_red:hover {
  color: #e50914 !important;
  border: 1px solid #e50914 !important;
}
.dark_theme #share_modal .icon_div .fab {
  color: #fff !important;
}
.dark_theme #share_modal .icon_div:hover .fab {
  color: #e50914 !important;
}
.dark_theme #change_price_modal .modal-content {
  background-color: #000 !important;
}

/* .dark_theme #register_modal h5.modal-title,.dark_theme #register_modal p{
	color:#fff !important;
}
.dark_theme .privacy_policy_title,.dark_theme .privacy_policy_main_title{
	color:#fff !important;
} */

/* end of dark theme modal css */
/* end kr css */

.full_card_view #ticketscards .trendingban {
  min-height: 490px !important;
  max-height: 490px !important;
  z-index: 9999 !important;
}
.full_card_view .yellowheight .content {
  display: block !important;
}

#visible2.visible_def_big .owl-item {
  min-height: unset !important;
  max-height: unset !important;
  height: 100% !important;
}

.full_card_view.style_prevu_kit:hover {
  transform: none !important;
  max-width: unset !important;
  min-width: unset !important;
}

/* login page css */
.modal #home_main_modal.modal-body {
  padding: 0 !important;
}
.main_modal_row {
  margin: 0 !important;
}

.whole_left_modal {
  background-color: #0abab5 !important;
}
.whole_right_modal {
  background-color: #040824 !important;
}
.left_modal_content {
  padding: 50px 50px 30px 50px !important;
}
.left_modal_content .italic_text {
  font-style: italic !important;
}
.left_modal_content h2 {
  font-weight: 600;
  font-size: 50px;
  line-height: 1 !important;
}
.left_modal_content .left_modal_clarifytext {
  margin-top: 30px;
  font-size: 18px;
  word-spacing: 5px;
  line-height: 1.5 !important;
}
.left_modal_content .quicktour_btn {
  background-color: #000 !important;
  color: #fff !important;
  border-radius: 30px !important;
  font-size: 12px !important;
  padding: 0px 25px;
}
.left_modal_content .quicktour_btn:hover {
  background-color: transparent !important;
  color: #000 !important;
  outline: 2px solid #000 !important;
  font-weight: 550 !important;
}
.left_modal_content .modal_left_ul ul {
  display: inline-flex;
  padding-left: 0;
}
.left_modal_content .modal_left_ul ul li {
  list-style-type: none;
  margin-right: 35px;
}
.left_modal_content .modal_left_ul ul li .main_modal_li_links {
  color: #fff !important;
}
.left_modal_content .modal_left_ul ul li .main_modal_li_links:hover {
  color: #000 !important;
  transform: scale(1.2);
  transition: all 1s;
}
.right_modal_content {
  padding: 15px !important;
}

.right_modal_clarifytext {
  line-height: 1.5 !important;
  font-size: 13px !important;
}
.right_modal_clarifytext .welcome_text {
  font-weight: 550 !important;
  font-size: 16px !important;
  font-style: italic !important;
}
.right_modal_content .main_modal_inputgrp {
  border: 1px solid #fff !important;
  padding: 0 !important;
  background-color: #2a2e45 !important;
  border-radius: 6px !important;
}

.main_modal_inputgrp input[type="text"],
.main_modal_inputgrp input::placeholder {
  color: #fff !important;
}
.main_modal_login {
  background-color: #0abab5 !important;
  color: #fff !important;
  font-size: 12px !important;
  border-radius: 30px !important;
  width: 49% !important;
}
.main_modal_inputgrp .input-group-prepend i {
  color: #fff !important;
}
.googlelogin button,
.fblogin button {
  height: 35px !important;
  width: 70% !important;
  font-size: 12px !important;
}
.main_modal_inputgrp .input-group-prepend,
.main_modal_inputgrp input[type="text"] {
  padding: 0 !important;
  height: 35px !important;
}
.main_modal_inputgrp input[type="text"],
#register_modal .main_modal_inputgrp input[type="text"],
#resetpwmodal .main_modal_inputgrp input[type="text"] {
  padding: 0px !important;
}
center .help_text_dtl {
  font-size: 13px !important;
}

.check_label_align label {
  padding: 0px !important;
  line-height: 0 !important;
}
.terms_link {
  color: #0abab5 !important;
  text-decoration: underline !important;
  font-size: 12px !important;
  margin-left: 25px !important;
  line-height: 0 !important;
  position: relative;
  top: -11px !important;
}
.resetpw_link {
  color: #0abab5 !important;
  font-size: 12px !important;
}
.top_quicktour_btn {
  background-color: #0abab5 !important;
  color: #fff !important;
  font-size: 12px !important;
  padding: 0px 15px !important;
  border-radius: 30px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.top_quicktour_btn:hover {
  color: #0abab5;
  outline: 1px solid #0abab5;
}
.main_modal_close_btn {
  color: #fff !important;
  font-size: 18px !important;
}
.whiteline_div {
  width: 40px !important;
  border-bottom: 3px solid #fff !important;
  margin-top: 30px !important;
}

@media screen and (220px < width < 768px) {
  .whole_left_modal {
    display: none !important;
  }
  .googlelogin button,
  .fblogin button {
    width: 100% !important;
  }
  .right_modal_content {
    padding: 10px 5px 5px 5px !important;
  }
  .top_quicktour_btn {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

/* end login page css */

/* .light_theme .ReactModal__Overlay
{
	background-color: #fff !important;
} */

/* .dark_theme p.light {
  display: none;
} */
.dark_theme p.dark {
  display: block;
}
p.light {
  display: block;
}
p.dark {
  display: none;
}

/* modal css */
.modal-body .react-share__ShareButton p {
  font-size: 12px !important;
}
#share_modal .icon_div .fab {
  color: #000;
  font-size: 20px;
}
.image_fox {
  background-color: #fff !important;
}
.email_sent_text {
  font-size: 18px !important;
  color: #fff !important;
  font-weight: 0 !important;
}
.email_sent_text_dtl {
  font-size: 12px !important;
  color: #fff !important;
  font-weight: 0 !important;
}
.subPara_text {
  color: #fff !important;
  font-size: 12px !important;
  text-decoration: none !important;
}
.contactus_textin_modal {
  text-decoration: underline;
  color: #fff !important;
  font-size: 15px !important;
}

.fox_valey_dtl_toptxt {
  font-size: 35px !important;
  font-weight: 550 !important;
  color: #fff !important;
  font-style: italic !important;
}
.fox_valey_dtl_toptxt span {
  font-size: 25px !important;
  letter-spacing: 1px !important;
  font-weight: 0 !important;
  line-height: 1 !important;
  color: #fff !important;
  font-style: none !important;
}
.image_fox,
.venly_img {
  height: 100px !important;
  width: 100px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #fff !important;
}
.image_fox img,
.venly_img img {
  height: 90px !important;
  width: 90px !important;
}
.metamask_side,
.venly_side {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column;
}
.metamask_side h5,
.venly_side h5 {
  font-size: 18px !important;
  font-weight: 550 !important;
}
.metamask_side span,
.venly_side span {
  font-size: 12px !important;
  position: relative;
  top: -15px;
}
.main_modal_inputgrp input::placeholder {
  font-size: 11px !important;
}

.main_modal_submit_btn {
  cursor: pointer;
}
.main_modal_submit_btn:hover {
  cursor: pointer;
}

.congrats_txt_main_modal {
  color: #fff !important;
  text-align: center !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}
.main_modal_rdrct_text {
  display: block !important;
  font-size: 12px !important;
  color: #fff !important;
  text-align: center !important;
}
.resetpw_modal_clarifytext {
  color: #fff !important;
  text-align: left !important;
  font-size: 12px !important;
}
/* end modal css */
#resetpwmodal .modal-dialog {
  max-width: 750px;
  min-width: 700px;
  top: 12px;
  cursor: pointer;
}


/* modal css */
#resetpwmodal .modal-dialog .modal-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
#resetpwmodal::placeholder {
  font-weight: 400px !important;
}
#resetpwmodal input[type="password"],
#resetpwmodal input[type="text"] {
  --webkit-appearance: none !important;
  appearance: none !important;
  border: none !important;
  border-bottom: none !important;
  border-radius: 4px !important;
  padding: 23px 8px !important;
  background: 0 0 !important;
  color: #000 !important;
  font-weight: 400;
}
#resetpwmodal .registerpagesd {
  display: flex;
  align-items: center;
  justify-content: center;
}
#resetpwmodal .headinpurt {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0 9px 0 9px;
  border: 1px solid #00000017;
}
#resetpwmodal .headinpurt i {
  color: #9f9d9d;
  font-size: 12px !important;
}
#resetpwmodal button.create_btna:hover {
  background-color: transparent !important;
  color: #e50914 !important;
}
#resetpwmodal button.create_btna {
  background-color: #e50914 !important;
  border: 1px solid #e50914 !important;
  border-radius: 0px !important;
  display: block !important;
  width: 25% !important;
  color: #fff;
  margin: 0;
}
#resetpwmodal button.create_btns:hover {
  background-color: #e50914 !important;
  color: #fff !important;
  border: 1px solid #e50914 !important;
}
#resetpwmodal button.create_btns {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 0px !important;
  display: block !important;
  width: 25% !important;
  box-shadow: 0 0 6px 2px #0000001f;
  transition: all 0.5s ease;
}

#resetpwmodal .modal-dialog {
  max-width: 750px;
  min-width: 700px;
  top: 12px;
  cursor: pointer;
}
#resetpwmodal .metas {
  text-align: left !important;
  font-weight: 600 !important;
}
#resetpwmodal .loginformcont {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
}
#resetpwmodal .connectionwallet {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
#resetpwmodal .connectionwallet .connectcontinure {
  display: flex;
  align-items: self-start;
  flex-direction: column;
}
#resetpwmodal .connectionwallet .venly {
  width: 127px;
  background-color: #fff;
  padding: 30px !important;
  margin-right: 12px;
  transition: all 0.5s ease;
  border: 1px solid #00000017;
}
#resetpwmodal .close img {
  width: 20px;
}
#resetpwmodal .connectionwallet .venly:hover {
  background-color: #fff;
  box-shadow: 1px 8px 8px 1px #0000002e;
}
#resetpwmodal .contine:first-child {
  margin-bottom: 20px;
}
#resetpwmodal .contine {
  padding: 20px !important;
  width: 150px !important;
  transition: all 0.5s ease;
  background-color: #fff;
  margin-right: 12px;
  margin-bottom: 20px;
  border: 1px solid #00000017;
}
#resetpwmodal .contine:hover {
  background-color: #fff;
  box-shadow: 1px 8px 8px 1px #0000002e;
}
#resetpwmodal .modal-content {
  background-color: #fff;
  border-radius: 0px !important;
  box-shadow: 0px 0px 12px 0px lightgray;
}
#resetpwmodal h5.modal-title {
  color: #000 !important;
}
#resetpwmodal p {
  color: #000 !important;
  font-size: 13px;
}

#insertingPwmodalnew .modal-dialog .modal-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
#insertingPwmodalnew::placeholder {
  font-weight: 400px !important;
}
#insertingPwmodalnew input[type="password"],
#insertingPwmodalnew input[type="text"] {
  --webkit-appearance: none !important;
  appearance: none !important;
  border: none !important;
  border-bottom: none !important;
  border-radius: 4px !important;
  padding: 0px 0px !important;
  background: 0 0 !important;
  color: #000 !important;
  font-weight: 400;
}
#insertingPwmodalnew .registerpagesd {
  display: flex;
  align-items: center;
  justify-content: center;
}
#insertingPwmodalnew .headinpurt {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0 9px 0 9px;
  border: 1px solid #00000017;
}
#insertingPwmodalnew .headinpurt i {
  color: #9f9d9d;
  font-size: 12px !important;
}
#insertingPwmodalnew button.create_btna:hover {
  background-color: transparent !important;
  color: #e50914 !important;
}
#insertingPwmodalnew button.create_btna {
  background-color: #e50914 !important;
  border: 1px solid #e50914 !important;
  border-radius: 0px !important;
  display: block !important;
  width: 25% !important;
  color: #fff;
  margin: 0;
}
#insertingPwmodalnew button.create_btns:hover {
  background-color: #e50914 !important;
  color: #fff !important;
  border: 1px solid #e50914 !important;
}
#insertingPwmodalnew button.create_btns {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 0px !important;
  display: block !important;
  width: 25% !important;
  box-shadow: 0 0 6px 2px #0000001f;
  transition: all 0.5s ease;
}

#insertingPwmodalnew .modal-dialog {
  max-width: 750px;
  min-width: 700px;
  top: 12px;
  cursor: pointer;
}
#insertingPwmodalnew .metas {
  text-align: left !important;
  font-weight: 600 !important;
}
#insertingPwmodalnew .loginformcont {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
}
#insertingPwmodalnew .connectionwallet {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
#insertingPwmodalnew .connectionwallet .connectcontinure {
  display: flex;
  align-items: self-start;
  flex-direction: column;
}
#insertingPwmodalnew .connectionwallet .venly {
  width: 127px;
  background-color: #fff;
  padding: 30px !important;
  margin-right: 12px;
  transition: all 0.5s ease;
  border: 1px solid #00000017;
}
#insertingPwmodalnew .close img {
  width: 20px;
}
#insertingPwmodalnew .connectionwallet .venly:hover {
  background-color: #fff;
  box-shadow: 1px 8px 8px 1px #0000002e;
}
#insertingPwmodalnew .contine:first-child {
  margin-bottom: 20px;
}
#insertingPwmodalnew .contine {
  padding: 20px !important;
  width: 150px !important;
  transition: all 0.5s ease;
  background-color: #fff;
  margin-right: 12px;
  margin-bottom: 20px;
  border: 1px solid #00000017;
}
#insertingPwmodalnew .contine:hover {
  background-color: #fff;
  box-shadow: 1px 8px 8px 1px #0000002e;
}
#insertingPwmodalnew .modal-content {
  background-color: #fff;
  border-radius: 0px !important;
  box-shadow: 0px 0px 12px 0px lightgray;
}
#insertingPwmodalnew h5.modal-title {
  color: #000 !important;
}
#insertingPwmodalnew p {
  color: #000 !important;
  font-size: 13px;
}

#selectWalletModal .modal-dialog .modal-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
#selectWalletModal::placeholder {
  font-weight: 400px !important;
}
#selectWalletModal input[type="password"],
#selectWalletModal input[type="text"] {
  --webkit-appearance: none !important;
  appearance: none !important;
  border: none !important;
  border-bottom: none !important;
  border-radius: 4px !important;
  padding: 0px 0px !important;
  background: 0 0 !important;
  color: #000 !important;
  font-weight: 400;
}
#selectWalletModal .registerpagesd {
  display: flex;
  align-items: center;
  justify-content: center;
}
#selectWalletModal .headinpurt {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0 9px 0 9px;
  border: 1px solid #00000017;
}
#selectWalletModal .headinpurt i {
  color: #9f9d9d;
  font-size: 12px !important;
}
#selectWalletModal button.create_btna:hover {
  background-color: transparent !important;
  color: #e50914 !important;
}
#selectWalletModal button.create_btna {
  background-color: #e50914 !important;
  border: 1px solid #e50914 !important;
  border-radius: 0px !important;
  display: block !important;
  width: 25% !important;
  color: #fff;
  margin: 0;
}
#selectWalletModal button.create_btns:hover {
  background-color: #e50914 !important;
  color: #fff !important;
  border: 1px solid #e50914 !important;
}
#selectWalletModal button.create_btns {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 0px !important;
  display: block !important;
  width: 25% !important;
  box-shadow: 0 0 6px 2px #0000001f;
  transition: all 0.5s ease;
}

#selectWalletModal .modal-dialog {
  max-width: 750px;
  min-width: 700px;
  top: 12px;
  cursor: pointer;
}
#selectWalletModal .metas {
  text-align: left !important;
  font-weight: 600 !important;
}
#selectWalletModal .loginformcont {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
}
#selectWalletModal .connectionwallet {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
#selectWalletModal .connectionwallet .connectcontinure {
  display: flex;
  align-items: self-start;
  flex-direction: column;
}
#selectWalletModal .connectionwallet .venly {
  width: 127px;
  background-color: #fff;
  padding: 30px !important;
  margin-right: 12px;
  transition: all 0.5s ease;
  border: 1px solid #00000017;
}
#selectWalletModal .close img {
  width: 20px;
}
#selectWalletModal .connectionwallet .venly:hover {
  background-color: #fff;
  box-shadow: 1px 8px 8px 1px #0000002e;
}
#selectWalletModal .contine:first-child {
  margin-bottom: 20px;
}
#selectWalletModal .contine {
  padding: 20px !important;
  width: 150px !important;
  transition: all 0.5s ease;
  background-color: #fff;
  margin-right: 12px;
  margin-bottom: 20px;
  border: 1px solid #00000017;
}
#selectWalletModal .contine:hover {
  background-color: #fff;
  box-shadow: 1px 8px 8px 1px #0000002e;
}
#selectWalletModal .modal-content {
  background-color: #fff;
  border-radius: 0px !important;
  box-shadow: 0px 0px 12px 0px lightgray;
}
#selectWalletModal h5.modal-title {
  color: #000 !important;
}
#selectWalletModal p {
  color: #000 !important;
  font-size: 13px;
}
@media screen and (min-width: 320px) and (max-width: 991px) {
  .whole_left_modal,
  .fox_valey_dtl_toptxt {
    display: none !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .modal-content {
    width: 100%;
  }
  #insertingPwmodalnew .modal-dialog {
    max-width: 520px;
    min-width: 510px;
  }
  #insertingPwmodalnew .headinpurt {
    min-width: 208px;
    max-width: 208px;
  }
  /* #insertingPwmodalnew .media-dialog{max-width: 520px;min-width: 510px;}
		#insertingPwmodalnew .headinpurt{    min-width: 208px;max-width: 208px;} */
}

@media only screen and (max-width: 575px) {
  #insertingPwmodalnew .modal-dialog .modal-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #insertingPwmodalnew .modal-dialog {
    max-width: 290px;
    min-width: 290px;
    margin: 0 auto;
  }
  #insertingPwmodalnew .connectionwallet {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  #insertingPwmodalnew p {
    text-align: center;
    margin: 0 auto;
  }
  #insertingPwmodalnew .contine,
  #insertingPwmodalnew .connectionwallet .venly {
    margin-top: 20px !important;
  }
  #insertingPwmodalnew .loginformcont {
    gap: 0px;
    flex-direction: column !important;
  }
  #insertingPwmodalnew .registerpagesd {
    gap: 10px;
    flex-direction: column !important;
  }
  #insertingPwmodalnew button.create_btn {
    width: 100% !important;
    margin: 0 !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .modal-content {
    width: 100%;
  }
  #selectWalletModal .modal-dialog {
    max-width: 520px;
    min-width: 510px;
  }
  #selectWalletModal .headinpurt {
    min-width: 208px;
    max-width: 208px;
  }
  /* #insertingPwmodalnew .media-dialog{max-width: 520px;min-width: 510px;}
		#insertingPwmodalnew .headinpurt{    min-width: 208px;max-width: 208px;} */
}

@media only screen and (max-width: 575px) {
  #selectWalletModal .modal-dialog .modal-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #selectWalletModal .modal-dialog {
    max-width: 290px;
    min-width: 290px;
    margin: 0 auto;
  }
  #selectWalletModal .connectionwallet {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  #selectWalletModal p {
    text-align: center;
    margin: 0 auto;
  }
  #selectWalletModal .contine,
  #insertingPwmodalnew .connectionwallet .venly {
    margin-top: 20px !important;
  }
  #selectWalletModal .loginformcont {
    gap: 0px;
    flex-direction: column !important;
  }
  #selectWalletModal .registerpagesd {
    gap: 10px;
    flex-direction: column !important;
  }
  #selectWalletModal button.create_btn {
    width: 100% !important;
    margin: 0 !important;
  }
}

#congradulationModal .modal-dialog .modal-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
#congradulationModal::placeholder {
  font-weight: 400px !important;
}
#congradulationModal input[type="password"],
#congradulationModal input[type="text"] {
  --webkit-appearance: none !important;
  appearance: none !important;
  border: none !important;
  border-bottom: none !important;
  border-radius: 4px !important;
  padding: 0px 0px !important;
  background: 0 0 !important;
  color: #000 !important;
  font-weight: 400;
}
#congradulationModal .registerpagesd {
  display: flex;
  align-items: center;
  justify-content: center;
}
#congradulationModal .headinpurt {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0 9px 0 9px;
  border: 1px solid #00000017;
}
#congradulationModal .headinpurt i {
  color: #9f9d9d;
  font-size: 12px !important;
}
#congradulationModal button.create_btna:hover {
  background-color: transparent !important;
  color: #e50914 !important;
}
#congradulationModal button.create_btna {
  background-color: #e50914 !important;
  border: 1px solid #e50914 !important;
  border-radius: 0px !important;
  display: block !important;
  width: 25% !important;
  color: #fff;
  margin: 0;
}
#congradulationModal button.create_btns:hover {
  background-color: #e50914 !important;
  color: #fff !important;
  border: 1px solid #e50914 !important;
}
#congradulationModal button.create_btns {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 0px !important;
  display: block !important;
  width: 25% !important;
  box-shadow: 0 0 6px 2px #0000001f;
  transition: all 0.5s ease;
}

#congradulationModal .modal-dialog {
  max-width: 750px;
  min-width: 700px;
  top: 12px;
  cursor: pointer;
}
#congradulationModal .metas {
  text-align: left !important;
  font-weight: 600 !important;
}
#congradulationModal .loginformcont {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
}
#congradulationModal .connectionwallet {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
#congradulationModal .connectionwallet .connectcontinure {
  display: flex;
  align-items: self-start;
  flex-direction: column;
}
#congradulationModal .connectionwallet .venly {
  width: 127px;
  background-color: #fff;
  padding: 30px !important;
  margin-right: 12px;
  transition: all 0.5s ease;
  border: 1px solid #00000017;
}
#selectWallcongradulationModaletModal .close img {
  width: 20px;
}
#congradulationModal .connectionwallet .venly:hover {
  background-color: #fff;
  box-shadow: 1px 8px 8px 1px #0000002e;
}
#congradulationModal .contine:first-child {
  margin-bottom: 20px;
}
#congradulationModal .contine {
  padding: 20px !important;
  width: 150px !important;
  transition: all 0.5s ease;
  background-color: #fff;
  margin-right: 12px;
  margin-bottom: 20px;
  border: 1px solid #00000017;
}
#congradulationModal .contine:hover {
  background-color: #fff;
  box-shadow: 1px 8px 8px 1px #0000002e;
}
#congradulationModal .modal-content {
  background-color: #fff;
  border-radius: 0px !important;
  box-shadow: 0px 0px 12px 0px lightgray;
}
#congradulationModal h5.modal-title {
  color: #000 !important;
}
#congradulationModal p {
  color: #000 !important;
  font-size: 13px;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .modal-content {
    width: 100%;
  }
  #congradulationModal .modal-dialog {
    max-width: 520px;
    min-width: 510px;
  }
  #congradulationModal .headinpurt {
    min-width: 208px;
    max-width: 208px;
  }
  /* #insertingPwmodalnew .media-dialog{max-width: 520px;min-width: 510px;}
				#insertingPwmodalnew .headinpurt{    min-width: 208px;max-width: 208px;} */
}

@media only screen and (max-width: 575px) {
  #congradulationModal .modal-dialog .modal-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #congradulationModal .modal-dialog {
    max-width: 290px;
    min-width: 290px;
    margin: 0 auto;
  }
  #congradulationModal .connectionwallet {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  #congradulationModal p {
    text-align: center;
    margin: 0 auto;
  }
  #congradulationModal .contine,
  #insertingPwmodalnew .connectionwallet .venly {
    margin-top: 20px !important;
  }
  #congradulationModal .loginformcont {
    gap: 0px;
    flex-direction: column !important;
  }
  #congradulationModal .registerpagesd {
    gap: 10px;
    flex-direction: column !important;
  }
  #congradulationModal button.create_btn {
    width: 100% !important;
    margin: 0 !important;
  }
}

#mailSentModal .modal-dialog .modal-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
#mailSentModal::placeholder {
  font-weight: 400px !important;
}
#mailSentModal input[type="password"],
#congradulationModal input[type="text"] {
  --webkit-appearance: none !important;
  appearance: none !important;
  border: none !important;
  border-bottom: none !important;
  border-radius: 4px !important;
  padding: 0px 0px !important;
  background: 0 0 !important;
  color: #000 !important;
  font-weight: 400;
}
#mailSentModal .registerpagesd {
  display: flex;
  align-items: center;
  justify-content: center;
}
#mailSentModal .headinpurt {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0 9px 0 9px;
  border: 1px solid #00000017;
}
#mailSentModal .headinpurt i {
  color: #9f9d9d;
  font-size: 12px !important;
}
#mailSentModal button.create_btna:hover {
  background-color: transparent !important;
  color: #e50914 !important;
}
#mailSentModal button.create_btna {
  background-color: #e50914 !important;
  border: 1px solid #e50914 !important;
  border-radius: 0px !important;
  display: block !important;
  width: 25% !important;
  color: #fff;
  margin: 0;
}
#mailSentModal button.create_btns:hover {
  background-color: #e50914 !important;
  color: #fff !important;
  border: 1px solid #e50914 !important;
}
#mailSentModal button.create_btns {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 0px !important;
  display: block !important;
  width: 25% !important;
  box-shadow: 0 0 6px 2px #0000001f;
  transition: all 0.5s ease;
}

#mailSentModal .modal-dialog {
  max-width: 750px;
  min-width: 700px;
  top: 12px;
  cursor: pointer;
}
#mailSentModal .metas {
  text-align: left !important;
  font-weight: 600 !important;
}
#congradulatmailSentModalionModal .loginformcont {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
}
#mailSentModal .connectionwallet {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
#mailSentModal .connectionwallet .connectcontinure {
  display: flex;
  align-items: self-start;
  flex-direction: column;
}
#mailSentModal .connectionwallet .venly {
  width: 127px;
  background-color: #fff;
  padding: 30px !important;
  margin-right: 12px;
  transition: all 0.5s ease;
  border: 1px solid #00000017;
}
#mailSentModal .close img {
  width: 20px;
}
#mailSentModal .connectionwallet .venly:hover {
  background-color: #fff;
  box-shadow: 1px 8px 8px 1px #0000002e;
}
#mailSentModal .contine:first-child {
  margin-bottom: 20px;
}
#mailSentModal .contine {
  padding: 20px !important;
  width: 150px !important;
  transition: all 0.5s ease;
  background-color: #fff;
  margin-right: 12px;
  margin-bottom: 20px;
  border: 1px solid #00000017;
}
#mailSentModal .contine:hover {
  background-color: #fff;
  box-shadow: 1px 8px 8px 1px #0000002e;
}
#mailSentModal .modal-content {
  background-color: #fff;
  border-radius: 0px !important;
}
#mailSentModal h5.modal-title {
  color: #000 !important;
}
#mailSentModal p {
  color: #000 !important;
  font-size: 13px;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .modal-content {
    width: 100%;
  }
  #mailSentModal .modal-dialog {
    max-width: 520px;
    min-width: 510px;
  }
  #mailSentModal .headinpurt {
    min-width: 208px;
    max-width: 208px;
  }
  /* #insertingPwmodalnew .media-dialog{max-width: 520px;min-width: 510px;}
						#insertingPwmodalnew .headinpurt{    min-width: 208px;max-width: 208px;} */
}

@media only screen and (max-width: 575px) {
  #mailSentModal .modal-dialog .modal-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #mailSentModal .modal-dialog {
    max-width: 290px;
    min-width: 290px;
    margin: 0 auto;
  }
  #mailSentModal .connectionwallet {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  #mailSentModal p {
    text-align: center;
    margin: 0 auto;
  }
  #mailSentModal .contine,
  #insertingPwmodalnew .connectionwallet .venly {
    margin-top: 20px !important;
  }
  #mailSentModal .loginformcont {
    gap: 0px;
    flex-direction: column !important;
  }
  #mailSentModal .registerpagesd {
    gap: 10px;
    flex-direction: column !important;
  }
  #mailSentModal button.create_btn {
    width: 100% !important;
    margin: 0 !important;
  }
 
}

/* end modal css */

.tickets .style_prevu_ki1t.card_details_alignable {
  box-shadow: 0 0 0px 0px #fff !important;
  background-color: unset !important;
}
.main_not_box {
  box-shadow: 0 0 12px 5px #0006 !important;
}
#visible2.visible_def_big .owl-item {
  padding: 10px !important;
}
.main_modal_nxt_btn {
  background-color: #0abab5 !important;
  color: #fff !important;
  font-size: 10px !important;
  padding: 0px 15px !important;
  border-radius: 30px !important;
  border: none !important;
  outline: none !important;
}
.error_display_text {
  color: #e50914 !important;
  font-size: 12px !important;
}
.main_modal_inputgrp input[type="text"],
#register_modal .main_modal_inputgrp input[type="text"],
#register_modal input[type="password"],
#resetpwmodal input[type="password"],
#resetpwmodal input[type="text"],
#insertingPwmodalnew input[type="password"] {
  color: #fff !important;
}
.main_modal_confirm_btn,
.main_password_submit_btn {
  background-color: #0abab5 !important;
  color: #fff !important;
  font-size: 12px !important;
  height: 30px !important;
  padding: 0px 15px !important;
  border-radius: 30px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.create_pg_create_btn {
  position: absolute !important;
  bottom: 5px !important;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  min-width: 100px !important;
}
.yellowheight .trendingbans .content {
  background-color: #000 !important;
}
@media (768px < width < 992px) {
  .create_pg_create_btn {
    position: absolute !important;
    bottom: 5px !important;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    min-width: 100px !important;
  }
  .admin_new_subscribers {
    font-size: 16px !important;
  }
  .admin_seall_txt {
    font-size: 12px !important;
  }
  .subscribers_align_dtls {
    font-size: 13px !important;
  }
  .follwers_count_align {
    font-size: 10px !important;
  }
  .pricing_platform_title_align{
    /* padding: 0px 100px; */
  }
}
@media (319px < width < 992px) {
  #sidebar{
    min-height: calc(100vh - 71px) !important;
    top: 71px !important;
  }

}
@media (319px < width < 768px) {
  .create_pg_create_btn {
    position: absolute !important;
    bottom: 0px !important;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    min-width: 100px !important;
  }
  .grid_icon_align {
    padding: 10px !important;
    height: 40px;
  }
  .grid_icon_alignOne {
    padding: 10px !important;
    height: 40px;
  }
  .pricing_cusjourney_dtls_align{
    padding: 10px;
  }
  .pricing_platform_title_align{
    padding: 0px !important;
    font-size: 20px !important;
  }
  .anim_blue_round {
    height: 300px !important;
  }
  .pricing_video_btn_align {
    padding: 10px 10px !important;   
    font-size: 12px !important;
  }
}
/* @media (992px < width < 1061px){
	.create_pg_create_btn_single{
		position: relative !important;
		top: 77px !important;
	}

}
@media (1060px < width < 1189px){
	.create_pg_create_btn_multiple{
		position: relative !important;
		top: 24px !important;
	}
	

} */

/* kr css */
.Toastify__toast-container.Toastify__toast-container--top-right{
  z-index: 10000000000 !important;
}
.container-fluid.fluid_padding_align {
  padding-left: 0 !important;
}
.banner_Pricing_txt_align {
  padding: 30px 15px 10px 15px;
  text-align: center;
  color: #fff !important;
}
.banner_Pricing_txt_align h1 {
  font-size: 70px;
}
.banner_Pricing_txt_align p {
  padding: 30px 200px;
  font-size: 18px;
}
.pricing_card_align {
  width: 100%;
  padding: 70px 20px 200px 20px;
  border: 1px solid lightgrey;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.pricing_card_align h3 {
  font-size: 25px;
  color: #fff;
}
.pricing_card_align span {
  color: grey !important;
  padding: 30px 0px !important;
}
.pricing_card_align h2 {
  font-size: 33px;
  font-weight: 700;
  color: #0abab5;
  text-align: center;
}

.plan_type_display{
  text-align: center !important;
  height: 80px !important;
  min-height: 80px;
  max-height: 80px;

}
.prcng_blue_cmnheight{
  height: 116px !important;
  min-height: 116px !important;
  max-height: 116px !important;
}


.pricing_card_hr_align {
  background-color: #fff;
  height: 2px !important;
  width: 40%;
}
.pricing_freetial_btn_align {
  padding: 17px 40px;
  margin: 30px 0px;
  background-color: #fff;
  color: #000 !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 500 !important;
}
.pricing_freetial_btn_align:hover,.light_theme .pricing_freetial_btn_align:hover{
  background-color: #0abab5;
  color: #fff !important;
}
.pricing_buynow_btn_align {

  background-color: #fff;
  color: #000 !important;
  padding: 6px 30px !important;
  box-shadow: 12px 8px 3px #686868  !important;
  border: none !important;
  outline: none;
  font-weight: 600;
  border-radius: 15px;
  margin-top: 40px;
  cursor: pointer;
}
.pricing_buynow_btn_align:hover {
  background-color: #0abab5;
  color: #fff !important;
}
/* .pricing_buynow_btn_align:disabled {
  opacity: 0.5 !important;
  color: #e50914 !important;
} */
.light_theme .pricing_buynow_btn_align {
  outline: 3px solid #535353 !important;
  box-shadow: 12px 8px 3px gray !important;
}
.light_theme .pricing_buynow_btn_align:hover {
  background-color: #0abab5;
  color: #000 !important;
}


.pricing_card_dtls_align {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}
.pricing_rounded_check_align {
  color: #e50914;
  margin-right: 10px;
  margin-top: 5px;
}
.pricing_card_txt_align {
  margin-top: 15px;
}
.best_value_tab_align {
  background-color: #e50914;
  color: #fff;
  width: 100%;
  padding: 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  position: absolute;
  top: -85px !important;
}
.doodleman_img_align {
  position: absolute;
}
.pricing_card_align_last {
  padding-bottom: 30px !important;
}
.pricing_accordtitle_align {
  text-align: center;
}
.common_title_align {
  font-size: 50px;
  letter-spacing: 1px;
  color: #fff;
}
.common_hint_align {
  color: #fff;
  font-size: 20px;
  margin-top: 20px;
  font-weight: 600;
}
.question_img_align {
  height: 200px;
  width: 150px;
}
.dark_theme .pricing_accordian_align {
  background-color: #141414 !important;
  color: #fff !important;
  box-shadow: none !important;
}
.main_accord_align {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 150px;
}
.common_hint_one_align {
  color: #fff;
  font-size: 15px;
}
.owl_carousal_align button {
  display: none !important;
}
.owl_item_align {
  display: flex;
  justify-content: center;
  align-items: center;
}
.owl_img_align {
  height: 55px !important;
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important;
  min-height: 55px !important;
  max-height: 55px !important;
}
.newton_img.owl_img_align{
  min-width: 60px !important;
  max-width: 60px !important;
  width: 60px !important;

}
.pricinghome_img_align{
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}
.newton_img.pricinghome_img_align{
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
}

.whole_journey_dtls_align {
  padding: 100px 250px;
  text-align: center;
  background-color: #070707;
  border-radius: 20px;
  position: relative;
}
.lady_selfie_img_align {
  position: absolute;
  left: -70px;
  top: 130px;
}
.boy_study_img_align {
  position: absolute;
  right: 0;
}
.hide_img_align {
  height: 115px;
  background-color: #141414;
  width: 100%;
  z-index: 100;
  position: relative;
}
.pricing_home_banner_align {
  padding: 50px 0px;
  color: #fff;
  position: relative;
}
.pricebanner_title_text_align {
  font-size: 70px;
  letter-spacing: 1px;
  font-weight: 700;
}
.pricebanner_title_hint_align {
  text-align: center;
}
.pricinghome_banner_btn_align {
  margin-top: 30px;
}
.pricinghome_banner_btn{
  padding: 15px 40px;
  border-radius: 30px;
  font-size: 18px;
  background-color: #fff;
  outline: none;
  border: none;
  color: #000;
  cursor: pointer;
  z-index: 100;
  position: relative;

}
.light_theme .pricinghome_banner_btn{
  border: 3px solid gray;

}
.pricinghome_banner_btn:hover,.light_theme .pricinghome_banner_btn:hover{
  outline: none;
  background-color: #0abab5;
  color: #fff;
}
.pricinghome_banner_btn_first {
  padding: 15px 40px;
  border-radius: 30px;
  font-size: 18px;
  background-color: #0abab5;
  outline: none;
  border: none;
  color: #fff;
  margin-right: 15px;
  cursor: pointer;
  z-index: 100;
  position: relative;
}
.pricinghome_banner_btn_first:hover{
  background-color: #fff;
  color: #000;
}

.light_theme .pricinghome_banner_btn_first:hover{
  outline: 3px solid gray;
  background-color: #fff;
  color: #000;
}

.pricebanner_bluedot_img_align {
  position: absolute;
  bottom: 0;
  left: -50px;
}
.pricebanner_img_align_dtls,.best_ftr_dtls{
  position: relative;
}
.anim_blue_round{
  height: 400px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #106eb7;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}
.anim_small_blue_round{
  height: 250px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #106eb7;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}
.timeshare_img{
  padding-top: 100px;
}
.girlcall_img{
  position: absolute;
  right: 0;
  bottom: -100px;
}
.pricebanner_main_img_align {
  height: 600px;
  max-height: 600px;
  width: 100%;
}
.solution_txt_align {
  text-align: center;
}
.solution_title_text_align {
  font-size: 50px;
  color: #fff;
  font-weight: 700;
}
.solution_hint_text_align {
  color: #fff;
  padding: 0px 250px;
  margin-top: 20px;
}
.solution_options_align {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.solution_images_align {
  height: 70px;
  width: 70px;
  min-height: 70px;
  min-width: 70px;
  max-height: 70px;
  max-width: 70px;
}
.solution_options_head_align {
  font-size: 25px;
  margin-top: 20px;
  color: #fff;
}
.solution_options_hint_align {
  font-size: 15px;
  margin-top: 20px;
  color: #fff;
}
.pricing_features_text_align {
  font-size: 15px;
}
.pricing_pkatform_dtls_align {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.pricing_platform_title_align {
  text-align: center !important;
  width: 100%;
  /* padding: 0px 300px; */
}
.pricing_video_btn_align {
  margin-top: 20px;
  padding: 10px 40px;
  border-radius: 30px;
  color: #000;
  font-size: 15px;
  background-color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}
.pricing_video_btn_align:hover {
 background-color: #0abab5;
 color: #fff;
}
.light_theme .pricing_video_btn_align{
  outline: 3px solid gray;
}
.pricing_home_bannerimg_align {
  position: relative;
}
.pricing_home_banner {
  margin-top: 50px;
  width: 100%;
  max-width: 100%;
  height: auto;
}
.boy_usingmbl {
  position: absolute;
  left: 0;
  bottom: -100px;
}
.girl_usinglap {
  position: absolute;
  right: 0;
  bottom: -80px;
}
.prcing_review_dtls_align {
  margin-top: 200px;
}

.pricing_lying_img_aling {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.pricing_dtls_left_align {
  position: relative;
  width: 100%;
}
.quote_img_align {
  position: absolute;
  z-index: -100;
  left: -90px;
  top: -20px;
}
.reviewing_dtls_card_align {
  padding: 10px;
  border-right: 1px dashed gray;
}
.review_prsn_img_align {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.star_icon_align {
  font-size: 20px;
  color: goldenrod;
  margin-right: 5px;
}
.pricing_reviewer_name {
  color: #fff;
  font-size: 18px;
  margin-top: 20px;
}
.customer_journey_dtls_align {
  margin-top: 100px;
}
.pricing_cusjourney_dtls_align {
  text-align: center;
  width: 100%;
  padding: 100px;
  background-color: #2c2c2c;
  border-radius: 20px;
  position: relative;
}
.pricing_selfie_lady_align {
  position: absolute;
  bottom: 0px;
  left: -80px;
}
.pricing_study_boy_align {
  position: absolute;
  bottom: 0px;
  right: 0;
  border-bottom-right-radius: 20px;
}
/* .footer_support_div_align {
  height: 150px;
} */
.scrolling_move_sec_align {
  /* padding-top: 15px; */
  width: 100% !important;
  max-width: 100% !important;
  margin-left: 15px !important;
}
.line_input_align{
  border: none;
  background-color: transparent;
  outline: none;
  color: #fff;
  border-bottom: 3px solid #0abab5 !important;
  margin-top: 10px;
  max-width: 70%;
}
.line_input_align::placeholder{
  font-size: 15px !important;
  text-align: center;
}

.light_theme .hide_txt_align,.light_theme .line_input_align{
  color: #000;
}


.sidebar_list_align {
  list-style-type: none;
  padding-left: 10px !important;
  padding: 8px;
}
.sidebar_list_align:hover {
  background-color: #f4f4f4;
}
.sidebar_list_align:hover .sidebar_hiding_txt {
  color: #e50914;
}
.lazylogo_user_sec_align {
  border: 1px solid lightgray;
  border-radius: 50%;
  height: 100px !important;
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lazylogo_user_img_align {
  border-radius: 50%;
  height: 100%;
  width: 100% !important;
  object-fit: contain !important;
}
.wholeuserprofile_edit_icon_align {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userprofile_edit_icon_align {
  height: 30px;
  width: 30px;
  background-color: #e50914;
  color: #fff !important;
  font-size: 13px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.file_input_tag_align {
  position: absolute;
  width: 100%;
  z-index: 10000;
  opacity: 0 !important;
}
.displayname_input_align {
  border-radius: 10px;
  padding: 5px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  width: 100%;
  margin-top: 20px;
  color: #000 !important;
  font-weight: 600 !important;
}
.displayname_input_align::placeholder {
  color: gray !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}
.displayname_outline_input_align {
  border-radius: 10px;
  padding: 5px 10px;
  border: none !important;
  outline: 1px solid #fff !important;
  background-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
  margin-top: 20px;
  color: #fff !important;
  font-weight: 600 !important;
}
.userprofile_textarea_align {
  border-radius: 10px;
  padding: 5px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  width: 100%;
  margin-top: 20px;
  color: #000 !important;
  font-weight: 600 !important;
}
.mask_group_img_align {
  width: 90%;
  height: 300px;
  margin-top: 15px;
}

/* end kr css */

/* separate kr css */
.admin_topbar_align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search_bar_align {
  border: none !important;
  outline: none !important;
  padding: 5px !important;
  border-radius: 15px !important;
  background-color: #323232a2 !important;
  color: #fff !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admin_searchbar_align {
  border: none !important;
  outline: none !important;
  padding: 5px !important;
  border-radius: 15px !important;
  background-color: transparent !important;
  color: #fff !important;
  width: 100%;
}

.admin_searchbar_align::placeholder {
  color: gray !important;
  font-size: 13px !important;
}
.admin_searchbar_align:active,
.admin_searchbar_align:focus {
  border: none !important;
  outline: none !important;
}
.search_icons_admin {
  padding-right: 10px !important;
}
.modal-body .form-check-input {
  margin-top: 0.8rem !important;
}
.modal-body .checkdiv .form-check-input {
  width: 17px !important;
  height: 18px !important;
}
.checkdiv{
  margin-left: 0px !important;
}
.dropdown1 .primary_inp{
   min-height: 41px !important;
   background: transparent !important;
    color: #fff !important;
}
.light_theme .dropdown1 .primary_inp{
  color: #101010 !important;
}
.form-check-input.journey_checkbox{
  margin-top: -2px !important;
  margin-left: 10px !important;
  position: unset !important;
}
.form-check-input.journey_checkbox[type="checkbox"]{
  text-align: center;
  vertical-align: middle;
  width: 24px !important;
  height: 22px !important;
  /* appearance: none; */
  border-radius: 10%;
  border: 1px solid rgb(191 35 42 / 90%);
  box-shadow: none;
}
.form-check-input.journey_checkbox[type="checkbox"]:checked{
  background-color: red;
  color: white;
}
.btn-box .create_btn{
  margin-bottom: 0px !important;
  border-radius: 4px !important;
}
.btn-box button.create_btn{
  border-radius: 4px !important;
  margin-bottom: 2px !important;
  padding: 8px 0px !important;
}
.admin_profile_dtls_align {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  position: relative;
}
.light_theme .react-select__control{
  background-color: transparent !important;
}
.light_theme .react-select__value-container{
  background-color: #fff !important;
}
.admin_dropdown_align {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.admin_dropdown_img_align {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 8px;
}
.admin_top_bell_align {
  color: grey !important;
  font-size: 20px !important;
  margin-right: 20px;
  cursor: pointer;
}
.admin_dropdown_txt_align {
  line-height: 1 !important;
  display: block;
  padding: 5px !important;
  margin-right: 8px;
}
.owner_admin_text_head {
  position: relative;
  margin-bottom: 10px !important;
  color: #fff;
}
.dropdown_toggle_align {
  background-color: transparent !important;
  color: #fff !important;
  border: none;
  outline: none;
  box-shadow: none;
}
.dropdown_toggle_align::after {
  display: none !important;
}
.admint_five_div_head {
  display: flex !important;
  justify-content: flex-start;
  align-items: center !important;
}
.admint_five_div_head_align {
  background-color: rgba(85, 85, 85, 0.562) !important;
  padding: 10px !important;
  border-radius: 15px !important;
  width: 100% !important;
  margin-bottom: 15px !important;
}
.admint_nrm_round {
  font-size: 8px !important;
  margin-right: 5px !important;
}
.admin_green_round {
  color: #29bb89 !important;
}
.admin_red_round {
  color: #f4303c !important;
}
.admin_violet_round {
  color: #724ee6 !important;
}
.admin_sandal_round {
  color: #f6c059 !important;
}

.div_text {
  font-size: 15px !important;
  color: #a8a8a8 !important;
  white-space: nowrap;
}
.green_text {
  color: green !important;
  font-size: 12px;
}

.admin_graph_img_align {
  height: 50px;
  width: 50px;
}
.count_txt {
  font-size: 25px !important;
  color: #fff !important;
  font-weight: 700 !important;
}
.admin_five_graph_align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admin_badge_icon_align {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 5px !important;
}
.admin_certificate_icon {
  color: #1e90ff;
  font-size: 20px !important;
}
.admin_tick_icon {
  position: absolute;
  color: #fff !important;
  font-size: 10px !important;
}
.subscribers_align_dtls {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
}
.follwers_count_align {
  position: relative;
  top: -9px;
  color: lightgray !important;
  font-size: 14px !important;
  font-weight: 500;
}
.admin_new_subscribers {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 0 !important;
}
.admin_seall_txt {
  color: #fff;
  padding: 0 !important;
  text-decoration: none;
  font-size: 13px;
  cursor: pointer;
}
.admin_seall_txt:hover {
  color: #e50914 !important;
}
.admin_subscriber_dtls_align {
  margin-top: 15px !important;
  display: flex;
  justify-content: flex-start;
}
.admin_chart_align {
  padding: 10px !important;
  background-color: #1e2026;
  border-radius: 15px !important;
}
.graph_drpdwn_align{
  display: flex;
  justify-content: flex-end;
}
/* .overflow_sm{
	min-width: 500px !important;
	overflow: auto !important;
} */

.admin_whole_chart_align {
  margin-top: 20px !important;
  display: flex;
  justify-content: space-between;
}
.grid_icon_align {
  padding: 5px 15px;
  border-radius: 10px;
  background-color: #e50914 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.grid_icon_alignOne {
  border: 1px solid #626262;
  padding: 5px 15px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  background-color: transparent;
  align-items: center;
  margin-right: 20px;
}
.admin_grid_icon {
  color: #fff !important;
}
.dropdown_togle_align_graph {
  border: 1px solid #fff;
  color: #fff !important;
  border-radius: 10px !important;
  background-color: transparent;
}
.chart_options_whole {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admin_addnewuser_btn {
  background-color: #e50914 !important;
  padding: 5px 10px;
  color: #fff;
  border-radius: 10px;
  white-space: nowrap !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.table_edit_dlt_align {
  margin-right: 10px;
  cursor: pointer;
}
.owner_admin_text_table {
  font-size: 12px !important;
  color: #fff !important;
}
.admin_table_th_align {
  background-color: transparent !important;
  color: #fff !important;
}
.benefit_btn_align {
  background-color: #fff;
  color: #000;
  border: none;
  outline: none;
  padding: 4px 20px;
  border-radius: 8px;
  font-size: 15px;
  margin-top: 15px;
  font-weight: 500;
}
.benefit_btn_align:hover {
  outline: 1px solid #fff;
  color: #fff;
  background-color: transparent;
}
.glazier_img_div_align{
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.glazier_img_align{
  height: 140px;
}

/* .admin_bell_badge{
	e50914
position: absolute;
left: 10px;
border-radius: 50%;
font-size: 10px;
top: 28px;
background-color: #e50914;
} */

.admin_bell_badge_align {
  border-radius: 50%;
  background-color: #0275d8 !important;
  padding: 5px 7px;
  position: absolute;
  left: 10px;
  top: 15px;
  cursor: pointer;
}
.sidebar_btwn_dtls_align {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100vh; */
}
.achieve_head_align{
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 0 !important;

}
.sidebar_tiffany_dtls {
  background-color: #0abab5;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0px 10px;
}
.upgrade_plan_btn {
  border: none;
  outline: none;
  font-size: 11px;
  padding: 2px 20px;
  border-radius: 30px;
  background-color: #fff;
  color: #000;
  margin-top: 15px;
  font-weight: 600;
  letter-spacing: 1px;
}
.tiffay_dtls_head {
  font-size: 15px;
  font-weight: 600;
}
.tiffay_dtls_hint {
  font-size: 12px;
  text-align: center;
  line-height: 1.5 !important;
  margin-top: 10px;
  color: #eaeaea;
}
.side_shrink .sidebar_tiffany_dtls {
  display: none !important;
}
/* .edit_header_align{
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #fff;
} */
.edit_header_title_align {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
.edit_header_close_icon {
  color: #fff;
  font-size: 20px;
}
.edit_inputs_align {
  padding: 8px;
  border-radius: 7px;
  border: 1px solid grey;
  width: 100%;
  background-color: transparent;
  color: #fff;
}
.light_theme .edit_inputs_align {
  color: #101010;
}
.edit_inputs_align::placeholder {
  color: #d3d3d3 !important;
  font-size: 15px;
}
.table_check_align {
  border: none !important;
  outline: none !important;
}
.edit_user_table_align thead th {
  border: none !important;
  vertical-align: top !important;
  font-size: 15px;
  text-align: center;
}
.edit_user_table_align tbody td {
  text-align: center !important;
}
.edit_table_container_align {
  margin: 30px 0px 0px 0px !important;
  padding: 0 !important;
}
.qrcode_address_dtls_align {
  text-align: center;
  font-weight: 500;
  color: #fff;
  background-color: #0abab5 !important;
  border-radius: 10px;
  padding: 5px 0px;
  margin: 0 !important;
}
.qrcode_address_dtls_align small {
  font-weight: 500;
}
.qrcode_img_align {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.qrcode_img {
  /* height: 300px;
width: 300px;
min-height: 300px;
min-width: 300px; */
  max-height: 230px;
  max-width: 230px;
}
.qrcode_scan_txt_align,
.qrcode_event_text {
  font-size: 20px;
  letter-spacing: 3px !important;
  text-align: center;
  color: #fff;
  padding: 0 !important;
}
.qrcode_integ_title_align {
  text-align: center;
  font-weight: 500;
  color: #fff;
  background-color: var(--bgred) !important;
  border-radius: 10px;
  padding: 5px 0px;
  padding-bottom: 15px;
}
.qrcode_integ_whole_align,
.qrcode_whole_dtls_align {
  background-color: #313131;
  border-radius: 10px;
}
.qrcode_description_dtls_align {
  position: relative;
}
.qrcode_btn_align {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -20px;
  width: 100%;
}
.qrcode_attendee_align {
  color: #fff;
  background-color: #000;
  font-size: 20px;
  font-weight: 600;
  padding: 5px 15px;
  border-radius: 10px;
  border: none !important;
  outline: none !important;
}
.description_title_align {
  font-weight: 600;
  color: #fff;
  line-height: 1rem;
}
.definition_text_align {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 1rem;
  word-break: break-all;
}
.verified_userbtn_cntr{
width: 100%;
text-align: center;
}
.qrcode_description_btn_align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.qrcode_red_btn_align {
  color: #fff;
  background-color: var(--bgred) !important;
  padding: 10px 40px;
  border: none !important;
  outline: none !important;
  border-radius: 7px;
}
.qrcode_black_btn_align {
  color: #fff;
  background-color: #000 !important;
  padding: 10px 40px;
  border: none !important;
  outline: none !important;
  border-radius: 7px;
}
.qrcode_integ_title_green_align {
  text-align: center;
  font-weight: 500;
  color: #fff;
  background-color: green !important;
  border-radius: 10px;
  padding: 5px 0px;
  padding-bottom: 15px;
}
.qrcode_scanning_btn_align {
  color: #fff;
  background-color: green !important;
  padding: 10px;
  width: 100%;
  border: none !important;
  outline: none !important;
  border-radius: 7px;
}
.qrcode_verify_btn_align {
  color: #fff;
  background-color: green !important;
  padding: 10px;
  padding: 10px 40px;
  border: none !important;
  outline: none !important;
  border-radius: 7px;
}
.edituser_modal_body_align {
  background-color: #141414;
}
.modal_cls_btn_align {
  color: #fff !important;
}
.user_dashboard_table_align td {
  color: #fff;
}
.five_bar_dtls_align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.admint_txt_greenarrow_align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.table_share_btn_align {
  border: none;
  outline: none;
  background-color: transparent;
  border: 1px solid #fff;
  width: 80px;
  height: 30px !important;
  color: #fff;
  font-size: 12px;
  border-radius: 10px !important;
  cursor: pointer;
}
.table_share_btn_align:hover {
  color: #e50914;
  border: 1px solid #e50914;
}
.sub_img_dtls_align {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sub_dtls_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.sub_dtls_txt_align {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.qrcode_progress_bar_align {
  height: 5px;
}
.details_link_txt_align {
  color: skyblue !important;
  font-size: 12px !important;
  text-decoration: none !important;
}
.last_activity_sub_align {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* overflow-y: scroll; */
}
.congrats_bottom_txt_align {
  line-height: 1.2;
  color: #fff;
  margin-top: 10px;
  font-size: 15px;
}
.subscriber_whole_dtls_alignred {
  background-color: #0abab5;
  padding: 10px !important;
  height: 100% !important;

  border-radius: 15px !important;
}
.subscriber_whole_dtls_bgalignOne {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100% !important;
}
.bacground_dtls_aligning {
  background-color: #3b3b3b8f !important;
  padding: 20px !important;
  border-radius: 15px !important;
}
.user_header_drpdwn_align {
  border-radius: 7px;
}
.start_scan_btn{
  border:none;
  outline: none;
  border-radius: 10px;
  background-color: #0abab5;
  color: #fff;
  padding: 7px 20px;
  font-weight: 600;
}
.start_scan_btn:hover{
  background-color: transparent;
  color: #0abab5;
  outline: 1px solid #0abab5;
}

/* separate kr css */

/* Sidebar css kr */
.light_theme #sidebar{
  background-color: #fff;
}
.light_theme #sidebar .sidebar_hiding_txt{
  color: #000;
}
#sidebar {
  min-width: 180px;
  min-height: calc(100vh - 71px) !important;
  overflow-y: auto;
  background-color: #141414;
  position: sticky;
  bottom: 0px !important;
  top: 0px !important;
  z-index: 1;
}
#sidebar.side_shrink {
  /* min-width: 50px;
  max-width: 50px; */
  min-width: 46px;
  max-width: 46px;
}
#sidebar.side_shrink .sidebar_hiding_txt {
  display: none;
}
#sidebar.side_shrink .sidebar_top_img_align {
  height: 20px;
  width: 30px;
}
#sidebar.side_shrink .sidebar_list_align {
  display: flex;
  justify-content: center;
  align-items: center;
}
#sidebar.side_shrink .lis_paren_side span,
#sidebar.side_shrink .lis_paren_side_accordion span,
#sidebar.side_shrink .btn_white_outline_round,
#sidebar.side_shrink .mode_taxt,
#sidebar.side_shrink .lis_paren_side_accordion:hover .icon_text_div span {
  display: none;
  min-width: 20px !important;
}
#sidebar.side_shrink .inner_card_body_acc {
  position: fixed;
  left: 50px !important;
  background-color: #0a0a0a !important;
  border-radius: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px;
  margin-top: -30px;
  /* top:0px !important; */
}

#sidebar.side_shrink .inner_card_body_acc .sidebar_inner_ul {
  padding-right: 1.5rem !important;
}
#sidebar.side_shrink .lis_paren_side_accordion:hover .icon_text_div {
  max-width: 30px !important;
  min-width: 30px !important;
}

#sidebar.side_shrink .lis_paren_side:hover {
  min-width: 30px;
  max-width: 30px;
  padding-left: 7px;
}
#sidebar.side_shrink .footer_theme_div {
  left: 3px !important;
}
#sidebar.side_shrink .lis_paren_side_accordion:hover button {
  padding-right: 0px !important;
}

#sidebar.side_shrink .shrink_side_btn {
  left: 35px !important;
}

#sidebar.side_shrink .sidebar_ul {
  margin-left: 5px !important;
}
.shrink_side_btn {
  position: fixed;
  left: 160px;
  top: 50vh;
}
.right_side_sec {
  width: calc(100% - 250px);
  /* width: 100%; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */
}
.right_side_spacing {
  padding-left: 20px;
  padding-right: 20px;
}
.sidebar_shr .right_side_sec {
  width: calc(100% - 50px);
  /* width: 100%; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */
}
.sidebar_ul {
  padding-top: 30px !important;
  padding-left: 0px !important;
}

.sidebar_ul li {
  list-style-type: none !important;
  padding: 5px 10px;
  margin-bottom: 20px;
  /* max-width: fit-content; */
}
.li_bot_less {
  margin-bottom: 10px !important;
}
.sidebar_ul li img {
  max-width: 15px;
}
.sidebar_ul span {
  color: #d6d6d6;
  padding-left: 10px;
  font-size: 13px;
}
.sidebar_inner_ul a {
  font-size: 13px;
  color: #d6d6d6 !important;
}
#sidebar .accordion .card {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0px !important;
}

#sidebar .accordion .card-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
#sidebar .accordion .card button {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #fff !important;
  text-decoration: none !important;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}
.sidebar_inner_ul a {
  color: #d6d6d6 !important;
}
.sidebar_inner_ul a:hover,
.sidebar_inner_ul a.active {
  color: #16f9b2 !important;
}

.sidebar_whole_img_align {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mle_15{
  margin-left: 15px !important;
  
}
.sidebar_top_img_align {
  height: 60px;
  width: 100px;
}
.get-started-btn {
  font-size: 13px;
  font-weight: 400;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 32px;
  padding: 0px 16px;
  background-color: #e50914;
  /* background-image: linear-gradient(90deg, #5957ef, #1d97e0, #18e5bc); */
  color: white;
  border: 0px;
  border-radius: 8px;
  box-shadow: rgb(149 149 187 / 40%) 0px -1px 0px 0px inset;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}
.get-started-btn.rounded_btn_wal {
  border-radius: 50% !important;
  width: 30px !important;
  height: 30px !important;
  padding: 2px !important;
}
.get-started-btn.rounded_btn_wal svg {
  font-size: 14px !important;
  width: 14px !important;
  height: 14px !important;
}
.get-started-btn svg {
  color: #fff !important;
  fill: #fff !important;
}

.get-started-btn:hover {
  background-color: #fff;
  /* border: 1px solid var(--textblue) !important; */
  color:  #e50914;
}
.get-started-btn-fill.greenbtn {
  background: #519c67 !important;
  border-color: #519c67 !important;
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px !important;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px !important;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 48px;
  padding: 0px 24px;
  color: white;
  text-transform: capitalize !important;
}
.get-started-btn-fill.greenbtn:hover {
  color: #fff !important;
  border-color: #519c67 !important;
}

.get-started-btn-fill.purplebtn {
  background: #16f9b2 !important;
  border-color: #16f9b2 !important;
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px !important;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px !important;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 48px;
  padding: 0px 24px;
  color: white;
  text-transform: capitalize !important;
}
.get-started-btn-fill.purplebtn:hover {
  color: #fff !important;
  border-color: #16f9b2 !important;
}
.header_usercirc_align {
  margin-right: 15px !important;
}

/* sidebar media query css */

@media screen and (min-width: 320px) and (max-width: 991px) {
  #sidebar.side_shrink .lis_paren_side:hover {
    min-width: 150px !important;
  }
  #sidebar.side_shrink .lis_paren_side_accordion:hover button {
    padding-left: 0px !important;
  }
  #sidebar {
    position: fixed !important;
    z-index: 1000 !important;
    max-height: 100vh !important;
    min-height: 100vh !important;

    overflow-y: auto !important;
    /* min-width: 50px; */
  }
  #sidebar .lis_paren_side span,
  #sidebar .lis_paren_side_accordion span,
  #sidebar .btn_white_outline_round,
  #sidebar .mode_taxt,
  #sidebar .lis_paren_side_accordion:hover .icon_text_div span {
    display: none;
    min-width: 20px !important;
  }
  #sidebar .sidebar_ul {
    margin-left: 5px !important;
  }

  #sidebar .shrink_side_btn {
    left: 155px !important;
  }
  #sidebar .side_shrink {
    min-width: 250px;
    min-height: calc(100vh - 55px);
    max-height: calc(100vh - 55px);
    overflow-y: auto;
    background-color: #0a0a0a;
    position: sticky;
    top: 55px;
    z-index: 1;
  }
  #sidebar.side_shrink .shrink_side_btn {
    position: fixed;

    top: 50vh !important;
  }
  .side_shrink {
    min-width: 50px !important;
  }
  .right_side_sec {
    width: calc(100% - 50px);
    margin-left: 50px;
  }

  #sidebar.side_shrink .lis_paren_side span,
  #sidebar.side_shrink .lis_paren_side_accordion span,
  #sidebar.side_shrink .mode_taxt,
  #sidebar.side_shrink .lis_paren_side_accordion:hover .icon_text_div span {
    display: inline !important;
    min-width: 20px !important;
  }
  #sidebar.side_shrink .btn_white_outline_round {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 15px !important;
    height: 15px !important;
    max-width: 15px !important;
    max-height: 15px !important;
    min-width: 15px !important;
    min-height: 15px !important;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: transparent;
    font-size: 12px !important;
  }
  #sidebar .inner_card_body_acc {
    position: fixed;
    left: 50px !important;
    background-color: #0a0a0a !important;
    border-radius: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px;
    margin-top: -30px;
  }
  #sidebar.side_shrink .accordion .card button {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #fff !important;
    text-decoration: none !important;
    box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  #sidebar .footer_theme_div {
    left: 3px !important;
  }
  #sidebar.side_shrink .footer_theme_div {
    left: 20px !important;
  }
  #sidebar .inner_card_body_acc .sidebar_inner_ul {
    padding-right: 1.5rem !important;
  }
  #sidebar.side_shrink .accordion .card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  #sidebar .inner_card_body_acc {
    position: fixed;
    left: 50px !important;
    background-color: #0a0a0a !important;
    border-radius: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px;
    margin-top: -30px;
  }
  .light_theme #sidebar .inner_card_body_acc {
    background-color: #fff !important;
    box-shadow: 0px 1px 9px -1px #02020299 !important;
  }
  .light_theme #sidebar .collapse .inner_card_body_acc {
    box-shadow: 0px 0px 0px 0px #02020299 !important;
  }

  #sidebar .lis_paren_side_accordion:hover button {
    padding-right: 0px !important;
  }
  #sidebar .lis_paren_side_accordion:hover .icon_text_div {
    max-width: 30px !important;
    min-width: 30px !important;
  }
  #sidebar.side_shrink .inner_card_body_acc {
    position: unset !important;
    left: unset !important;
    background-color: transparent !important;
    border-radius: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: unset !important;
  }
  #sidebar.side_shrink .lis_paren_side_accordion:hover .icon_text_div {
    min-width: 150px !important;
    min-height: 30px !important;
  }
}

@media only screen and (max-width: 575px) {
  .tempflow .subd_logo{
    position: unset !important;
    transform: unset !important;
    margin-top: 0px !important;
  }
  #sidebar {
    box-shadow: 4px 1px 7px -2px #02020299 !important;
  }

  .mask_group_img_divalign {
    display: flex;
    justify-content: center;
  }
  .mask_group_img_align {
    width: 70%;
  }
  .glazier_img_div_align{
    justify-content: center;
    margin-top: 20px;
  }
  .admin_new_subscribers{
    font-size: 15px;
  }
  .graph_drpdwn_align{
    justify-content: flex-start;
  }
  .pricebanner_main_img_align {
    height: 350px !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .mask_group_img_divalign {
    display: flex;
    justify-content: center;
  }
  .mask_group_img_align {
    width: 60%;
  }
}
@media only screen and (max-width: 991px) {
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#lock_parent.active,
  #sidebar.side_shrink .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink .icon_text_div#lock_parent.active {
    min-width: 150px !important;
    min-height: 30px !important;
  }
  .light_theme #sidebar.side_shrink .icon_text_div#launchpad_parent.active span,
  .light_theme
    #sidebar.side_shrink
    .icon_text_div#privatesale_parent.active
    span,
  .light_theme #sidebar.side_shrink .icon_text_div#lock_parent.active span {
    color: #fff !important;
  }
}

@media only screen and (min-width: 992px) {
  .profile_edit_icon i {
    width: 23px;
    height: 23px;
  }
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#lock_parent.active,
  #sidebar.side_shrink .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink .icon_text_div#lock_parent.active {
    min-height: 30px;
    max-width: 30px !important;
    min-width: 30px !important;
    display: flex;
    align-items: center;
  }
  .light_theme #sidebar.side_shrink .inner_card_body_acc {
    background-color: #fff !important;
    box-shadow: 0px 1px 9px -1px #02020299 !important;
  }
 
}
@media (min-width: 319px) and (max-width: 768px) {
 
  /* .sidebar_ul li{
    margin-top: 20px !important;
  } */
  .profile_edit_icon i {
    width: 23px;
    height: 23px;
  }
  .holder {
    min-height: 150px;
    min-width: 150px;
    max-height: 150px;
    max-width: 150px;
    aspect-ratio: 1/1;
  }
  #sidebar.side_shrink .shrink_side_btn {
    left: 20px !important;
  }
  .side_shrink {
    min-width: 35px !important;
    width: 35px !important;
  }
  .scrolling_move_sec_align {
    padding-left: 35px !important;
  }
  .qrcode_red_btn_align,
  .qrcode_black_btn_align,
  .qrcode_verify_btn_align {
    padding: 10px 15px;
  }
  .qrcode_integ_title_align p,
  .qrcode_integ_title_green_align p {
    font-size: 14px;
  }
  
  .pricing_freetial_btn_align {
    padding: 7px 20px;
    margin: 30px 0px;
    border: none !important;
    box-shadow: none !important;
    border-radius: 30px;
    font-size: 13px;
  }
  .banner_Pricing_txt_align p {
    padding: 0;
  }
  .whole_journey_dtls_align {
    padding: 25px;
  }
  .common_title_align {
    font-size: 30px;
  }
  .banner_Pricing_txt_align h1 {
    font-size: 40px;
  }
  .common_hint_align,
  .on_demand_hinttxt_align,
  .pricing_card_txt_align {
    font-size: 15px;
  }
  .pricebanner_title_text_align {
    font-size: 30px !important;
  }
  .pricing_home_banner_align{
    padding: 10px 0px;
  }
  .pricebanner_title_hint_align {
    font-size: 13px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .scrolling_move_sec_align {
    padding-left: 35px !important;
  }
  .whole_journey_dtls_align {
    padding: 70px;
  }
}
@media (min-width: 992px) {
  .scrolling_move_sec_align {
    margin-left: 10px !important;
  }
 
}

.dashbox {
  min-width: 180px !important;
}
.subscriber_whole_dtls_bgalign {
  height: 100% !important;
  padding: 10px;
  border-radius: 15px;
  background-color: #1e2026;
}
/* end of sidebar media query css */

/* end sidebar css kr */

/* manoj css */
.topcardsecs {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 20px;
}
.blackcard_style {
  box-sizing: border-box;
  min-width: 155px;
  margin-top: 0px !important;
  height: 100px;
  background: #1e2026;
  color: #fff;
  box-shadow: none !important;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.blackcard_style .firstrow {
  text-align: left;
  display: flex;
  align-items: center;
}
.blackcard_style .graph_align {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width:992px){
  .boy_study_img_align,
  .lady_selfie_img_align,.boy_usingmbl,.girl_usinglap,.pricing_selfie_lady_align,.pricing_study_boy_align,.girlcall_img{
    display: none !important;
  }
  .solution_hint_text_align{
    padding: 20px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .topcardsecs {
    display: grid;
    gap: 10px;
    grid-template-columns: auto auto auto auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .topcardsecs {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .topcardsecs {
    display: grid;
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 575px) {
  .likedbtn {
    top: 20px !important;
}
  .titless{
    position: absolute;
    bottom: 0px;
    padding: 6px 8px;
    background: #00000063;
    width: 100%;
  }
  .subd_mobedition {
    margin-top: 10px !important;
}
  .topcardsecs {
    display: grid;
    grid-template-columns: auto;
  }
  .congrats_bottom_txt_align {
    font-size: 12px;
  }
  .five_div_card.card{
    margin-top: -15px !important;
  }
  .pricinghome_banner_btn_first,.pricinghome_banner_btn{
    padding: 5px 15px !important;
    font-size: 13px !important;
  }
  .pricing_cusjourney_dtls_align{
    padding: 10px;
  }
  .solution_title_text_align {
    font-size: 30px;
  }
}
/* end manoj css */
.closebtn {
  background: transparent;
  color: #fff;
  border: 0;
  font-size: 24px;
}

.dark_theme .tickets .style_prevu_ki1t {
  box-shadow: 0 0 9px 4px #56565666;
}
.light_theme .pricing_card_align h3 {
  color: #000;
}
.light_theme .pricing_card_hr_align {
  background-color: unset;
}
.light_theme .pricing_freetial_btn_align {
  background-color: #fff;
  color: #000 !important;
  outline: 3px solid gray;
}
.light_theme .laodingpart img.items_profile {
  filter: invert(1);
}
.dark_theme .modal-header .close,
.dark_theme .log_arrow_icon {
  color: #fff !important;
}
.light_theme .blackcard_style,
.light_theme .admin_chart_align,
.light_theme .subscriber_whole_dtls_bgalign {
  background-color: #e3e3e3 !important;
}
.light_theme .div_text {
  color: #2a2a2a !important;
}
.light_theme .count_txt,
.light_theme .admin_new_subscribers,
.light_theme .admin_new_subscribers,
.light_theme .sub_dtls_txt_align,
.light_theme .sub_dtls_txt_align,
.light_theme .table_share_btn_align,
.light_theme .no_fol_found,
.light_theme .dropdown_togle_align_graph {
  color: #000 !important;
}
.light_theme .table_share_btn_align,
.light_theme .dropdown_togle_align_graph {
  outline: 1px solid #000;
}
.light_theme .admin_chart_align tspan {
  fill: #000 !important;
}
.light_theme .admin_chart_align line {
  stroke: #000 !important;
}

@media (max-width: 767px) {
  .laodingpart img.items_profile {
    max-width: 100px !important;
    width: 100px !important;
    max-height: 80px !important;
  }
}
@media (max-width: 1279px) {
  .theme_change_btn button {
    margin-left: 8px !important;
  }
  .log_arrow_icon {
    margin-left: 10px;
  }
  .loggingout.log_arrow_icon{
    margin-top: 15px;
    margin-bottom: 5px;
  }
}

.light_theme .homepage.header_bar_align .main_navbar li a,
.light_theme .homepage.header_bar_align .menu_dropdown button span {
  color: #fff !important;
}
.light_theme .homepage.header_bar_align .fixedTop .main_navbar li a,
.light_theme .homepage.header_bar_align .fixedTop .menu_dropdown button span,
.light_theme .header_bar_align.homepage .fixedTop .search_inp_group_prepend i {
  color: #000 !important;
}
.light_theme .header_logo_align.homepage {
  background-image: url("../src//assets/images/lazymintingnew/logo.png");
}
.light_theme .header_bar_align.homepage .search_inp_group_prepend i {
  color: #fff !important;
}
.light_theme .header .homepage svg,
.light_theme .homepage .main_navbar svg {
  fill: #fff !important;
}


.modal.fade{
  opacity: 1;
  filter: blur(0.7) !important;
  z-index: 9999 !important;
  background-color: #343434d1;
}
.fade.show {
   /* opacity: 0.5;  */
  z-index: 8;
   /* background: red;  */
}
body.modal-open .home_header header{
/* z-index: 1;  */
}

.scrolling_move_sec_align{
height: calc(100vh - 71px);
overflow-y: auto;
overflow-x: hidden;
}
.scrolling_move_sec_align::-webkit-scrollbar{
display: none;
scrollbar-width:none;
}
.scrolling_move_sec_align {
  scrollbar-width:none;
}
html, #root {
  scrollbar-width:thin;
  scrollbar-color: #e50914 #fff;
}

.dark_theme .modal_inp_white{
  color: #fff !important;
}
.light_theme .modal_inp_white{
  color: #000 !important;
}
.items_profile.smal_main_lm{
  height: 100px !important;
  width: 100px !important;
}
.noti_parent.noti_parnt_user.exploremneu_dd_res.posleft.viz_visi{
  overflow: visible !important;
}
.double_hover_view{
  position: relative !important;
}
.double_hover{
  display: none !important;
}
.double_hover{
position: absolute;
width: 100% !important;
background-color: #0000008f !important;
outline: 1px solid #6b6b6b;
right: -270px;
top: 0;
}
.double_hover_view:hover .double_hover{
  display: block !important;
 
}
.mobile_visible{
  display: none;
}
@media(576px < width < 1200px){
  .double_hover{
    left: -240px;
  }
}
@media(319px < width < 576px){
  .double_hover_view:hover .mobile_visible{
    display: block;
  }
  .double_hover{
    display: none;
  }
  .mob_top_align{
    margin-top: 30px !important;
  }
}
.loading_blackbgcolor{
  background-color: #000 !important;
}
/* .dark_loading.ReactModal__Body--open .ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: #000 !important;

}
.light_loading.ReactModal__Body--open .ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: #fff !important;
} */

.dark_theme .ReactModal__Body--open .ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: red !important;
}
.light_theme .ReactModal__Body--open .ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: white !important;
}


.viewmoretxt.shrink{
  height: 101px;
  overflow: hidden;
}

.pwd_eye{
  padding: 4px 7px;
  position: relative;
  top: 1px;
}
.light_theme .double_hover{
  background-color: #fff !important;
}
.main_modal_input{
  border: none !important;
}
/* #PasswordOne#insertingPwmodalnew input[type="text"],#ConfrimPassword#insertingPwmodalnew input[type="text"]{
  color: #fff !important;
} */

.viewmoretxt {
  max-height: 204px;
  overflow-y: auto;
  text-align: justify;
  padding-right: 5px;
}
.infoheart i{
  /* border: 1px solid #fff;
  border-radius: 50px; */
  /* padding: 10px; */
  margin-right: 5px;
  font-size: 14px;
}
.infoheart{
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.coin_header_pol{
  height: 40px;
  width: 40px;
  max-height: 40px;
  max-width: 40px;
  position: relative;
  top: 4px;
}
.whole_templates{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  flex-wrap: wrap;
  gap: 20px;
}
.single_template{
  background-color: #fff;
  border-radius: 10px;
  flex:1;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  min-height: 150px;
  min-width: 155px;
  padding: 10px;

}
.single_template img{
aspect-ratio: 3/2;
object-fit: contain;

width: 65px;
    height: 65px;
    margin: auto;
}
.template_ttl{
  font-size: 15px;
  text-align: center;
  color: #000;
  font-weight: bold;
  /* white-space: wrap !important;
  word-break: break-all !important; */
  word-wrap: break-word !important;
  min-height: 70px;
}
.graybtn {
  background: #53585C !important;
  color: #fff;
  border-radius: 7px;
  border-color: #fff;
  font-size: 15px;
  width: 200px;
}
.graybtn:hover {
border-color: #53585c;
color: #fff;
}

/* subdomain css */
/* .subd_banner{
  background-image: url('../src/assets/images/faces/imagebg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  
} */
.subd_banner .sub_domain_banner {
  width: 100%;
  object-fit: cover;
 min-height: 100vh;
}
.subdomain_des .subd_banner{
  position: relative;
}
.subdomain_des .subd_banner .inner_banner{
  position: absolute;
  top: 8%;
  width: 100%;
}
.prev_sub{
  margin: 20px;
  margin-left: 50px;
}
.subd_banner_content{
  margin: 50px 0px;
}
.subd_logo{
  height: 80px;
  width:100px;
  object-fit: contain;
  border-radius: 15px;
}
.subd_title{
  color: #fff;
  font-size: 40px;
  margin-top: 30px;
  font-weight: 600;
}
/* .light_theme .subd_title {
 color: #101010;
} */
.subd_hinttxt{
  color: #fff;
  font-size: 15px;
  margin: 30px 0px 40px 0px;
}
.light_theme .subd_hinttxt {
  color: #fff;
}
.light_theme .bgblck{
  color: #101010;
}
/* .light_theme .subd_hinttxt {
  color: black;
} */
.subd_orangebtn{
  border: none;
  outline: none;
  /* color: #fff; */
  color: black;
  border-radius: 15px;
  padding: 10px 30px;
  font-size: 14px !important;
  font-weight: 700;
  /* background-color: #EB3838 !important; */
  background-color: #FFC700 !important;
  margin-right: 20px;
}
.subd_outlinebtn{
  outline: none;
  border: 1px solid #fff;
  color: #EB3838 !important;
  background-color: transparent !important;
  border-radius: 15px;
  padding: 10px 30px;
  font-size: 13px !important;

}
.subd_flexntfs{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 250px;
  
}
.subd_avtgrp{
  /* justify-content: flex-end; */
  margin-top: 30px;
  max-width: 250px;
  overflow-x: auto;
  pointer-events: all;
  cursor: pointer;
  justify-content: left !important;
}



.subd_avtgrp::-webkit-scrollbar {
  width: 0px;
  height: 0px !important;
}

/* Track */
.subd_avtgrp::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}
 
/* Handle */
.subd_avtgrp::-webkit-scrollbar-thumb {
  background: #000 !important; 
  border-radius: 10px;
}

/* Handle on hover */
.subd_avtgrp::-webkit-scrollbar-thumb:hover {
  background: #000 !important; 
}




.subd_jointr_ttl{
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.subd_jointr_hint{
  color: #fff;
  font-size: 13px;
  text-align: center;
}

.subd_jointr_cntttl{
  /* color: #fff; */
  color: #FFC700;
  font-size: 35px;
  font-weight: 600;
  word-wrap: break-word;
}
.subd_jointr_cnthint a{
  color: #06a8ff !important;
  font-weight: 600;
}
.subd_jointr_cnthint{
  color: #fff;
  font-size: 15px;
  opacity: 0.7;
  margin-top: 20px;
}
.light_theme .subd_jointr_cnthint{
  /* color: #101010; */
  font-weight: 600;
  opacity: 0.8;
}
.subd_single_avt{
  height: 43px;
  width: 43px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.subd_single_avt img{
  height: 40px;
    width: 40px;
    min-height: 37px;
    min-width: 36px;
    max-height: 37px;
    max-width: 36px;
  object-fit: cover;
  border-radius: 50%;
}
.subd_universal{
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  overflow-wrap: anywhere;
}
.light_theme .subd_universal{
  color: #101010;
}
.subd_ethreum{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.subd_value{
  color: #fff;
  font-size: 13px;
  margin-left: 10px;
}
.subd_whole_avtgrp{
  display: flex;
  justify-content: flex-start;
  align-items: center;  
  margin-top: 20px;
}
.subd_singleavttxt{
  display: flex;
  justify-content: flex-start;
  align-items: center; 
  position: relative;
  top: 11px;
  left: 20px;
}
.subd_mobsingleavt.subd_singleavttxt{
  left: 0;
}
.subd_nointrcontbg{
  padding: 30px;
  background-color: #000;
  border-radius: 20px;
  margin-top: 50px;
  border: 4px solid #FFC700;
}
.light_theme .subd_nointrcontbg {
  border: 4px solid #d2d2d2;
}
.light_theme .subd_nointrcontbg {
  /* background-color: #f2f2f2; */
  /* background-color: #e8e8e8; */
  background-color:#eaeaea !important;
  
}
.light_theme .react-select__menu {
  background-color: #fff !important;
}
.light_theme .react-select__option {
  color: black !important;
}
.subd_jointr_ttl.subd_ttlminus{
  position: relative;
  top: -15px;
}
.card.sub_domaincard{
  padding: 0 !important;
  box-shadow: none !important;
  background-color: #000 !important;
  border-radius: 15px;
  margin-top: 0px !important;
}
.light_theme .card.sub_domaincard {
  /* background-color: #e8e8e8 !important; */
  background-color: #d2d2d2 !important;
  /* border: 1px solid black; */
}
.height_subdomaincard.card.sub_domaincard{
  min-height: 430px;
  max-height: 430px;

}
.card.sub_domaincard .subdomain_cardimg{
 padding: 15px;
 min-height: 300px;
 max-height: 300px;
 object-fit: cover;
 border-radius: 30px;
} 
.card.sub_domaincard .card-body{
  padding: 0px 15px 15px 15px !important;
}
.sub_domaincard_rel{
  position: relative;
}
.sub_domaincard_absdtl{
  background-color: #ffffff52;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 10px;
}
.sub_domaincard_txt{
  color: #fff;
    font-size: 15px;
    padding: 0;
    margin: 0;
    line-height: 1;

}
.sub_domaincard_bold{
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.card.sub_domaincard .blur_effect{
display: none;
}
.card.sub_domaincard#blur_card .blur_effect{
  position: absolute;
  background: #313131bf;
  user-select: none;
 
  /* -webkit-filter: blur(15px) !important;
  filter: blur(15px) !important; */
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px);
  height: 85%;
  width: 100%;
  top: 0px;
  display: block;
  border-radius: 10px;
}

.subd_cardsublogo{
  height: 45px;
  width: 50px;
  border-radius: 50%;

}
.subd_collectall{
  margin-top: 100px;
}
.subd_cardwhole{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  min-height: 65px;
  max-height: 65px;
}
.subd_cardwhole h6{
  /* font-size: 15px; */
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  margin: 0 !important;
  overflow-wrap: anywhere;
  min-height: 32px;
}
.light_theme .subd_cardwhole h6{
  color: #101010;
}
.subd_cardwhole p{
  font-size: 13px;
  color: rgba(255, 255, 255, 0.4);
  margin: 0;
}
.subd_clctnowbtn{
  border: none;
  outline: none;
  border-radius: 15px;
  /* background-color: #8738EB; */
  background-color: #FFC700;
  color: black;
  font-weight: 700;
  /* color: #fff; */
  width: 100%;
  margin-top: 10px;
  padding: 7px 0px;
  font-size: 14px;
}
.subd_whiteborderbtn{
  border: 1px solid #fff;
  outline: none;
  border-radius: 15px;
  padding: 7px 25px;
  color: #fff;
  background-color: transparent;
  font-size: 13px;
  
}
.subd_topwinner{
  margin-top: 100px;
  position: relative;
}
.subd_topwinner .voilet_blurdark{
  position: absolute;
  right: 0px;
  top: -11px;
  height: 350px;
  width: 350px;
  border-radius: 50%;
  /* background-color: #8738EB; */
  filter: blur(180px);
  z-index: -1;
}
.subd_topwinner .blue_blurdark{
  position: absolute;
  left: 0;
  bottom: 0;
  height: 250px;
  width: 250px;
  border-radius: 50%;
  /* background-color: #0D5ED9; */
  filter: blur(150px);
  z-index: -1;
}
.subd_collectallcont{
  position: relative;
}
.clct_all.blue_blurdark{
  position: absolute;
  right: 0;
  top: 30%;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  /* background-color: #0D5ED9; */
  filter: blur(165px);
  z-index: -1;
  
}
.subd_topwinner_hint{
  text-align: center;
  color: #fff;
  font-size: 13px;
  margin-top: 20px;
}
.light_theme .subd_topwinner_hint {
  text-align: center;
  color: #101010;
}
.subd_winnercard_whole{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subd_cardsublogoal{
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.card.subd_winnercard{
  background-color: #000 !important;
  box-shadow: none !important;
  margin: 0;
  margin-bottom: 30px !important;
  border-radius: 12px;
}
.light_theme .card.subd_winnercard {
  /* background-color: #fff !important; */
  /* background-color: #e8e8e8 !important; */
  background-color: #d2d2d2 !important;
  /* border: 1px solid #101010; */
}
.card.subd_winnercard .card-body{
  padding: 15px !important;
}
.subd_winnercardrow{
  margin-top: 50px;
  padding-bottom: 100px;
}
.subdf_hint{
  color: #fff;
  font-size: 15px;
  margin-top: 15px;

}
.subdf_dtls p{
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.light_theme .subdf_dtls p {
  color:#101010;
}
.light_theme .subdf_hint {
  color:#101010;
}
.light_theme .subdf_dtls ul li a{
  color:#101010;
  /* font-weight: 500; */
}
.light_theme .subdf_dtls address {
  color:#101010;
}
.light_theme .subdf_round {
  color: black;
}
.subdf_dtls ul{
  list-style-type: none;
  padding-left: 0;
  margin-top: 15px;
}
.light_theme .subd_jointr_ttl {
  color: #101010;
}
.light_theme .subd_jointr_hint {
  color: #101010;
}
.subdf_dtls ul li{
  color: #fff;
  font-size: 15px;
  cursor:pointer;
  margin-top: 5px;
  padding: 0;
}
.subdf_dtls address{
  color: #fff;
  font-size: 15px;
  margin-top: 20px;
}
.subdf_roundwhole{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.subdf_round{
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 10px;
  color: #fff;
  margin-right: 10px;
}
.subdf_round:hover{
  border: 1px solid #ffc700;
  color: #ffc700;
  cursor: pointer;
}
.subdf_fbround.subdf_round{
  padding: 10px 13px;
}
.subdf_instaround.subdf_round{
  padding: 10px 11px;
}
.subd_jointreasure_dtls{
  margin-top: 60px;
  position: relative;
}
.voilet_blurdark.jointr_right{
  position: absolute;
  right: 0;
  top: 0;
  height: 350px;
  width: 350px;
  border-radius: 50%;
  /* background-color: #8738EB; */
  filter: blur(150px);
  z-index: -1;
}
.jointr_left.voilet_blurdark{
  position: absolute;
  left: 0;
  bottom: 0;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  /* background-color: #8738EB; */
  filter: blur(110px);
  z-index: -1;
}
.voilet_blur{
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: #BA68C8;
  filter: blur(182px);
}
.blue_blur{
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: #1183E6;
  filter: blur(182px);
  margin-left: 300px;
}
.subdf_bg{
  /* background-image: linear-gradient(45deg, #ba68c838, #1183e633),url('../src/assets/images/faces/Vectorveli.png'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background: linear-gradient(45deg, #ba68c838, #1183e633); */
  background-clip: content-box;
}
.bannerbgclr{
  /* background-color: black; */
  background-image: linear-gradient(to right, black, black,#2f2f2f, #dbdcdd);
  border-radius: 4px;
  padding: 20px 24px;
}
.light_theme .bannerbgclr {
  background-color: #fff !important;
  /* background-image: linear-gradient(to left, #fff, #fff, #fff 90%) !important; */
  background-image: linear-gradient(to right, black, black,#2f2f2f,#dbdcdd, #dbdcdd);
  /* box-shadow: -2px -1px 8px 2px #d3d3d3ad; */
  /* border: 2px solid #101010; */
  border-radius: 6px;
}
/* subdomain mobile css */
.subd_mobheader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
}
.subd_bannerfull{
  height: 600px;
  /* background-image: url('../src/assets/images/faces/violetbg.png'); */
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  /* padding: 0px 20px; */
  padding: 0px 4px;
  position: relative;
}
.subd_mobbanner{
  margin-top: 100px;
  border-radius: 15px;
}
.subd_mobttl{
  font-size: 25px;
    font-weight: 600;
    color: #fff;
}
.light_theme .subd_mobttl {
  color: black;
}
.subd_mobhintt{
  font-size: 15px;
  color: #fff;
  opacity: .7;
}
.light_theme .subd_mobhintt {
color: black;
opacity: 1;
}
.subd_mobhinttcenter{
  font-size: 15px;
  color: #fff;
  opacity: .7;
  text-align: center;
  word-wrap: break-word;
}
.light_theme .subd_mobhinttcenter {
  color: #000;
  opacity: 1;
}
.subd_mobedition{
  margin-top: 40px;
  position: relative;
}
.subd_mobbanbtn{
  margin-top: 20px;
}
.subd_mobtrend{
  margin-top: 100px;
  position: relative;
}
.subd_mobttlhot{
  color: #fff;
  font-size: 25px;
    font-weight: 500;
    text-align: center;
}
.light_theme .subd_mobttlhot {
  color: #000;
}
.subd_mobhinttrend{
  color: #fff;
  font-size: 15px;
  opacity: .7;
  text-align: center;
}
.mob_topwinner.subd_mobttlhot{
  margin-top: 100px;
}
.subd_cardswipe.mySwiper .swiper-pagination-bullet{
  background: #fff !important;
  opacity: 1 !important;
  position: relative;
  top:15px;
}
.subd_cardswipe.mySwiper .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background:#FFC700 !important;
}
.subd_lengthswipe .swiper-pagination-bullet.swiper-pagination-bullet-active{
  width: 20px  !important;
  border-radius: 20px;
  background: #C4C4C4 !important;
}
.subd_lengthswipe.mySwiper .swiper-pagination-bullet{
  background: #C4C4C4 !important;
  opacity: 1 !important;
  position: relative;
  top:15px;
}
.dullblue_blurbg{
  height: 200px;
  width: 200px;
  border-radius: 50%;
  /* background-color: #1296E9; */
  filter: blur(110px);
  position: absolute;
  left: -60px;
  top: 80px;
  z-index: 0;
}
.dullviolet_blurbg{
  height: 250px;
  width: 250px;
  border-radius: 50%;
  /* background-color: #8738EB; */
  filter: blur(80px);
  position: absolute;
  right: -15px;
  top: 40%;
  z-index: -1;
}
.dullblue_blurbgedit{
  height: 150px;
  width: 150px;
  border-radius: 50%;
  /* background-color: #1296E9; */
  filter: blur(110px);
  position: absolute;
  right: 0px;
  top: -100px;
  z-index: 0;
}
.dullviolet_blurbgedit{
  height:150px;
  width: 150px;
  border-radius: 50%;
  /* background-color: #8738EB; */
  filter: blur(80px);
  position: absolute;
  left: 0px;
  bottom: 70px;
  z-index: -1;
}
.dullviolet_blurbgtrend{
  height:250px;
  width: 250px;
  border-radius: 50%;
  /* background-color: #8738EB; */
  filter: blur(80px);
  position: absolute;
  right: 0px;
  top: -60px;
  z-index: -1;
}
.dullviolet_blurbgtrendleft{
  height:250px;
  width: 250px;
  border-radius: 50%;
  /* background-color: #8738EB; */
  filter: blur(80px);
  position: absolute;
  left: 0px;
  top : 200px;
  z-index: -1;
}
.dullviolet_blurbgtrendright{
  height:250px;
  width: 250px;
  border-radius: 50%;
  /* background-color: #8738EB; */
  filter: blur(80px);
  position: absolute;
  right: 0px;
  bottom : 50px;
  z-index: -1;
}
.subd_mobbanbtn .subd_orangebtn,.subd_mobbanbtn .subd_outlinebtn{
  font-size: 15px !important;
}
.subd_mobtopwinner{
  position: relative;
}
.dullviolet_blurbgtrendwinner{
  height: 150px;
  width: 150px;
  border-radius: 50%;
  /* background-color: #8738EB; */
  filter: blur(80px);
  position: absolute;
  left: -80px;
  top: -50px;
  z-index: -1;
}
.dullblue_blurbgtrendwinner{
  height: 200px;
  width: 200px;
  border-radius: 50%;
  /* background-color: #1296E9; */
  filter: blur(60px);
  position: absolute;
  left: -70px;
  bottom: -60px;
  z-index: 0;
}

#addcampaingModal .modal-dialog{
  min-width: 1140px;

}
.campaignmdl_inps{
  margin-bottom: 15px;
}
.cmpny_logo_flex{
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}
#addcampaingModal .labelspan{
  flex-shrink: 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background: linear-gradient(0deg, #53585C, #53585C);
  color: #fff;
  padding: 5px 0px;
  font-size: 13px;
  /* white-space: nowrap; */
  line-height: 1;
  text-align: center;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 117px;
  height: 40px;
  justify-content: center;
}
.campaignmodal_cards{
  display: grid;
  grid-template-columns: repeat(4,auto);
  gap: 15px;
}
#addcampaingModal .card.sub_domaincard{
  background-color: lightgray !important;
  margin-bottom: 15px !important;
}
.light_theme #addcampaingModal .card.sub_domaincard{
  background-color: #000 !important;
}
#addcampaingModal .card.sub_domaincard .subdomain_cardimg{
    padding: 7px 7px 0px !important;
    min-height: 120px;
    object-fit: cover;
    border-radius: 15px !important;
}
#addcampaingModal .card.sub_domaincard .subdomain_cardimg.blur_img{
  filter: blur(5px);
}
#addcampaingModal .card.sub_domaincard .subd_domaincardtxt{
  color: #fff !important;
}
#addcampaingModal .card.sub_domaincard .card-body{
  padding: 0px 5px 5px !important;
}
#addcampaingModal .subd_clctnowbtn {
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: #8738EB;
  color: #fff;
  width: 100%;
  padding: 0px;
  font-size: 13px;
}
#addcampaingModal .edit_inputs_align{
  padding: 5px 8px !important;
}
.campaignmdl_inpdbl{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.campaignmdl_inpdblbet{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.campaign_blutbtn.admin_mdl_red_btn.admn_img_upld_btn{
  width: 160px !important;
  
}
.admin_mdl_red_btn{
  border: none !important;
  outline: none !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  background-color: #0abab5 !important;
  padding: 6px 0px !important;
  min-width: 117px !important;
  border-radius: 10px !important;
}
.admin_mdl_red_btn:hover{
  outline: 2px solid #0abab5;
  color: #0abab5;
  background-color: transparent;
}
#addcampaingModal .width_algd.edit_inputs_align{
  width: 80%;
}
#addcampaingModal .width_algd.edit_inputs_align.cmpny_logo{
  width: 100%;
  margin-right: 15px;
}
.campainmdl_imgdimen{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.campaign_bannerimg{
  width: 75%;
  height: 150px;
  max-width: 75%;
  object-fit: cover;
}
.subd_logomodal{
  width: 100px;
  height: 70px;
  border-radius: 10px;
}
.campaignmdl_triplebtns{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.campaignmdl_triplebtns .admin_mdl_red_btn{
  font-size: 15px !important;
  margin-right: 10px;
}
#cke_editor2 .cke_inner,#cke_editor3 .cke_inner{
  max-height: 200px !important;
  height: 200px !important;
  overflow: auto !important;
}
.campaign_fileinpdiv{
  position: relative;
  overflow: hidden;
}
.campaignmdl_inpdblbet.campaign_fileinpdiv{
  justify-content: flex-end;
}
.campaign_fileinp{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  z-index: 0;
}
.campaign_muitbl div{
  border: none !important;
  color: #fff !important;
}
.light_theme .campaign_muitbl div{
  border: none !important;
  color: #000 !important;
}
.campaign_muitbl .css-1pi9rcw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
  background-color: transparent !important;
}
.campaign_muitbl .css-1pi9rcw-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled{
  opacity: 1;
}
/* .campaign_muitbl .css-7c8dww-MuiDataGrid-root .MuiDataGrid-row.Mui-selected{
  background-color: #62626214 !important;
} */

.light_theme .campaign_muitbl .css-1pi9rcw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
  background-color: transparent !important;
  color: #000 !important;
}
.light_theme .campaign_muitbl .css-1pi9rcw-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled{
  opacity: 1;
  color: #000 !important;
}
.light_theme .single_template{
  background-color: #e3e3e3 ;
}
/* .light_theme .template_ttl{
  color: #fff;
} */
.light_theme .campagin_inverimg{
  filter: invert(1);
}
.cambaign_glazier{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cambaign_glazier .glazier_img_align{
  height: 250px;
}
.scrolablediv{
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}
.scrolablediv > * {
  flex-shrink: 0;
}
.campaignmdl_cltita{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.cambaignmdl_italic{
  font-size: 13px !important;
  font-style: italic !important;
  color:gray !important;  
}
.campaignmdl_checks{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.campaignmdl_xamplediv{
  height: 40px;
  width: 40px;
  border-radius: 10px;
  background-color: lightgray;
}
.campaign_select .css-h372v2-control{
  background-color: transparent !important;
}
.campaign_select .primary_inp,.campaign_select .select1{
  background: transparent !important;
  background-image: linear-gradient(0deg, #000 0, #000 0), linear-gradient(0deg, #000 0, hsla(0, 0%, 82.4%, 0) 0) !important;
}
.campaign_select .react-select__indicator-separator{
  background-color: transparent !important;
}
.campaign_select .form-control,.campaign_select .is-focused .form-control{
  background-image: linear-gradient(0deg, #000 0, #000 0), linear-gradient(0deg, #000 0, hsla(0, 0%, 82.4%, 0) 0) !important;
}
.yes1.form-control.selxet_app.campaign_select{
  padding : 0 !important;
}
.whole_right_modal .terms_link{
  color: #0abab5 !important;
}
 /* .css-1p6w7p5-MuiDataGrid-root .MuiDataGrid-columnHeader, .css-1p6w7p5-MuiDataGrid-root .MuiDataGrid-cellf,
 .css-1p6w7p5-MuiDataGrid-root .MuiDataGrid-cell
 {
  border: none !important;
}
 .css-1p6w7p5-MuiDataGrid-root .MuiDataGrid-cell,.css-gl260s-MuiDataGrid-columnHeadersInner{
  color: #fff !important;
} */
.mys_card .trendingban{
  min-height: 300px;
  max-height: 300px;
}
.mys_card .trendingban img {
  min-height: 300px;
  max-height: 300px;
}
.mys_card .usercontroliconstock .price.main_sec{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 140px;
}
.mys_card .usercontroliconstock input[type="checkbox"]{
  margin: 0px !important;
  margin-left: -7px !important;
  margin-top: 3px !important;
  opacity: 1;
}
.mys_card .usercontroliconstock input[type="checkbox"]:checked{
  background-color: red;
  border-color: red;
}

.mys_card .usercontroliconstock .round_check{
  background: #e50914;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mys_show_card .sub_domaincard {
  position: relative;

}
.mys_show_card .sub_domaincard .card-body .check_input{
  position: absolute;
  top: 10px;
  right: 10px;
  background: #8738EB;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mys_show_card .sub_domaincard .card-body input[type="checkbox"]{
  margin: 0 !important;
  margin-left: -7px;
  margin-top: 5px;
}
.mys_show_card .sub_domaincard .mys_img_show{
  min-height: 185px;
  max-height: 185px;
  width: 100%;
  border-radius: 13px;

}
.tick_card .yellowheight .content{
max-width: 100% !important;

}
.tick_card  .trendingbans{
  min-height: 500px !important;
  max-height: 500px !important;
}
.tick_card  .trendingbans img{
  min-height: 500px !important;
  max-height: 500px !important;
}
.tick_card .style_prevu_ki1t{
  border: 1px solid transparent;
}
.tick_card .style_prevu_ki1t:hover {
  box-shadow: 1px 0px 5px 5px #000000;
  border: 1px solid #8d8d8d;
  max-width: 100% !important;
}
.tick_card .card-button-container{
  width: 100%;
  justify-content: space-between;
}
.tick_card .card-button-container .round_check {
  background: #e50914;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tick_card .card-button-container .round_check input{
  margin: 0px !important;

}

.mys_card .card_inner_item:hover{
  box-shadow: 1px 0px 5px 5px #000000;
  border: 1px solid #fff;
}
.btn.table_btn{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: #e50914;
}
/* campagin modal media query */
@media only screen and (min-width:319px) and (max-width:350px){
  #addcampaingModal .modal-dialog{
    max-width: 300px;
    min-width: 300px;
  }
  .campaignmodal_cards{
    grid-template-columns: repeat(1,auto);
  }
  .campaignmdl_triplebtns .admin_mdl_red_btn.admn_img_upld_btn{
    min-width: 80px !important;
    font-size: 12px !important;
  }
  .cambaign_glazier .glazier_img_align{
    height: 200px;
  }
  /* .whole_templates{
    grid-template-columns: auto;
  } */
}
@media only screen and (min-width:350px) and (max-width:400px){
  #addcampaingModal .modal-dialog{
    max-width: 350px;
    min-width: 350px;
  }
  .campaignmodal_cards{
    grid-template-columns: repeat(1,auto);
  }
  .campaignmdl_triplebtns .admin_mdl_red_btn.admn_img_upld_btn{
    min-width: 90px !important;
    font-size: 12px !important;
  }
  .whole_templates{
    grid-template-columns: auto;
  }
}
@media only screen and (min-width:400px) and (max-width:500px){
  #addcampaingModal .modal-dialog{
    max-width: 400px;
    min-width: 400px;
  }
  .campaignmodal_cards{
    grid-template-columns: repeat(1,auto);
  }
  /* .whole_templates{
    grid-template-columns: auto auto;
  } */
}
@media only screen and (min-width:500px) and (max-width:600px){
  #addcampaingModal .modal-dialog{
    max-width: 500px;
    min-width: 500px;
  }
  .campaignmodal_cards{
    grid-template-columns: repeat(2,auto);
  }
  /* .whole_templates{
    grid-template-columns: auto auto;
  } */
  
}
@media only screen and (min-width:600px) and (max-width:700px){
  #addcampaingModal .modal-dialog{
    max-width: 500px;
    min-width: 500px;
  }
  .campaignmodal_cards{
    grid-template-columns: repeat(2,auto);
  }
  /* .whole_templates{
    grid-template-columns: auto auto auto;
  } */
}
@media only screen and (min-width:700px) and (max-width:800px){
  #addcampaingModal .modal-dialog{
    max-width: 600px;
    min-width: 600px;
  }
  .campaignmodal_cards{
    grid-template-columns: repeat(2,auto);
  }
  /* .whole_templates{
    grid-template-columns: auto auto auto;
  } */
}
@media only screen and (min-width:800px) and (max-width:900px){
  #addcampaingModal .modal-dialog{
    max-width: 700px;
    min-width: 700px;
  }
}
@media only screen and (min-width:900px) and (max-width:1000px){
  #addcampaingModal .modal-dialog{
    max-width: 800px;
    min-width: 800px;
  }
  .campaignmodal_cards{
    grid-template-columns: repeat(2,auto);
  }
}
@media only screen and (min-width:1000px) and (max-width:1100px){
  #addcampaingModal .modal-dialog{
    max-width: 900px;
    min-width: 900px;
  }
  .campaignmodal_cards{
    grid-template-columns: repeat(2,auto);
  }
}
@media only screen and (min-width:1100px) and (max-width:1200px){
  #addcampaingModal .modal-dialog{
    max-width: 980px;
    min-width: 980px;
  }
  .campaignmodal_cards{
    grid-template-columns: repeat(2,auto);
  }

}
@media only screen and (min-width:1200px) and (max-width:1300px){
  #addcampaingModal .modal-dialog{
    max-width: 1000px;
    min-width: 1000px;
  }
}

/* end of campagin modal media query */
/* endof subdomain mobile css */




@media only screen and (min-width:319px) and (max-width:575px){
  .subd_jointr_ttl.subd_ttlminus {
    position: relative;
    top: 0px;
}
  .subdomain_des{
    display: none;
  }
  .subdomain_des.prev_sub{
    display: block;
  }
  .subd_avtgrp .subd_mobavatar{
    min-height: 30px;
    max-height: 30px;
    min-width: 30px;
    max-width: 30px;
  }
  .subdf_dtls{
    text-align: center;
    margin-top: 20px;
  }
  .subdf_roundwhole{
    justify-content: left;
  }
  .subd_mobfootlogo{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

  }
  .subdf_hint{
    text-align: left;
  }
  .subdf_bg{
   background-size: auto;
   background-repeat: repeat;
  }
}
@media only screen and (min-width:319px) and (max-width:450px){
  .subd_mobbanbtn .subd_orangebtn,.subd_mobbanbtn .subd_outlinebtn{
    padding: 10px 15px;
    
  }
  .subd_bannerfull{
    height: 500px;
  }
}
@media only screen and (min-width:576px){
.subdomain_mob{
  display: none;
}
}
@media only screen and (max-width:575px){
  .checkdiv{
    margin-left: 0px !important;
  }
#resetpwmodal .modal-dialog{
  max-width: 290px;
  min-width: 290px;
  margin: 0 auto;
}}


@media only screen and (min-width:576px) and (max-width:767px){
  #resetpwmodal .modal-dialog{
max-width: 520px;
    min-width: 510px;
}
}

@media only screen and (min-width:768px) and (max-width:991px){
  #resetpwmodal .modal-dialog{
max-width: 750px;
    min-width: 700px;
    top: 12px;
    cursor: pointer;
  }}
/* end of subdomain css */

.subdf_dtls ul li a{
  color: #fff;
}
.subdf_dtls ul li a:hover{
  color: #e50914;
}




@media only screen  and (max-width:991px){
  .prev_sub {
    margin: 20px;

}
.tempflow{
  margin: 0px !important;

}
.subdomain_des .subd_banner .inner_banner{
  position: unset;
}
.subd_banner .sub_domain_banner {
  object-fit: contain;
  min-height: unset;
}
.subd_whole_avtgrp {
  display: unset;
}
}

@media only screen and (max-width:767px){
.mys_card .usercontroliconstock .price.main_sec {
  gap: 120px;
}
}
.previews{
   display: none;
}
@media only screen and (max-width: 575px){
  .allrowmobile{
    margin-right: -8px !important;
    margin-left: -13px !important;
  }
  .previews{
    display: block;
 }
  .previews2{
    display: none;
 }
  .subd_nointrcontbg {
    border-radius: 0px;
    border: 6px solid #FFC700;
}
.prev_sub {
    margin-left: 15px;
   max-width: 100%;
}
.subdf_dtls p,.subdf_dtls ul li,.subdf_dtls address{
  text-align: left;
}
.prev_sub .subd_banner .mble_bg{
  display: block;
}
.prev_sub .subd_banner .web_bg{
  display: none;
}
.prev_sub .subd_banner .mble_bg .banner_img{
  /* background: url('../src/assets/images/lazymintingnew/mble_bg_prev.png'); */
  /* height: 450px; */
  width: 100%;
  display: flex;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-items: center;
  position: relative;
}
.prev_sub .subd_banner .mble_bg .banner_img img{
  padding: 10px;
}
.prev_sub .subd_banner .mble_bg,.prev_sub .subd_banner{
  position: relative;
}
.prev_sub .subd_banner .mble_bg .logo_banner{
  position: absolute;
  top: 10px;
  left: 20px;
  width: 60px;
}
}
@media only screen and (min-width: 576px){
.prev_sub .subd_banner .mble_bg{
  display: none;
}
.prev_sub .subd_banner .web_bg{
  display: block;
}
}

@media only screen and (min-width: 1200px){

.subd_banner .sub_domain_banner{
 min-height: 700px;
 max-height: 700px;
 object-fit: cover;
 width: 100vw;
}
}
@media only screen and (max-width: 575px){
  .subd_banner_content {
    margin: 10px 0px;
}

.subd_title {
  margin-top: 10px;
  position: absolute;
  bottom: 2px;
  padding: 5px 20px;
  background:#00000063;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}
.subd_hinttxt {
  margin: 20px 0px 30px 0px;
  text-align: justify;
}
.subd_mobhintt{
  text-align: justify;
}
.listoutt{
  position: relative;
}
.subd_banner .sub_domain_banner
{
min-height:320px;
max-height:320px;
object-fit: cover;
}
.subd_mobbanner{
  min-height: 350px;
  max-height: 350px;
  object-fit: cover;
  width: 100%;
  margin-top: 60px;
}
.css-1ytufz-MuiAvatarGroup-root {
  justify-content: left;
}
.subd_nointrcontbg .subd_jointr_contimg{
  min-height: 250px !important;
  max-height: 250px !important;
  width: 100%;
}

.subd_jointr_ttl{
  font-size: 20px;
}
.subd_title,.subd_jointr_cntttl{
  font-size: 25px;
}
span.validation_txt{
  left: 7%;
  white-space: wrap ;
}
.subd_logo{
  position: absolute;
  top: 7%;
  /* left: 33%; */
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 15px;
}

.lightimg{
  position: unset;
  margin-top: -20px;
  margin-left: 10%;
    height: 70px;
    width: 70px;
}
.imagelogo{
  position: unset;
  transform: unset;
  margin-top: 0px;
}
.companylogo{
  position: unset !important;
  transform: unset !important;
}
}
@media only screen and (min-width: 576px) and (max-width: 991px){

  .subd_banner .sub_domain_banner
  {
  min-height: 450px;
  max-height: 450px;
  object-fit: cover;
  }
  .subd_jointr_ttl{
    font-size: 26px !important;
  }
  }
 

  .subd_nointrcontbg .subd_jointr_contimg{
    min-height: 350px;
    max-height: 350px;
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
  }
.react-select__option{
  color: #fff !important;
}
.mobd_customcontainer.container{
  max-width: 100% !important;
}
.scrolled_avatars ul{
  white-space: nowrap;
  list-style-type: none;
  display: inline-block;
  padding: 0;
  max-width: 250px;
  overflow-x: auto;

}
.scrolled_avatars ul li{
  display: inline;
  padding: 0;
  margin-left: -20px;


}
.scrolled_avatars ul li:first-child
{
  margin-left:0px !important;
}

span.validation_txt{
  color: #e50914 !important;
  top: -10px;
  position: relative;
  left:30%;
} 

#scrolling_avatars::-webkit-scrollbar{
  display: none;
}
#scrolling_avatars {
  -ms-overflow-style: none; 
  scrollbar-width: none;  

}
@media only screen and (max-width:576px){

  .sublogo_hide.subd_logo{
    display: none;
  }
  .subd_mobttl,.lefty_left.subd_jointr_cnthint{
    text-align: center;
  }
  .subd_orangebtn{
    margin-top: 15px;
  }
  .subd_mobhinttcenter{
    margin-top: 20px;
    text-align: left;
  }
  .subd_mobhinttcenter a{
   color:#06a8ff !important;
  }
}
.footer_subd_logo{
  height: 70px;
  width:100px;
  object-fit: contain;
  border-radius: 15px;
}
.spinner_aligner{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
[aria-label="three-dots-loading"]{
  justify-content: center;
}
.spinner-border{
  width: 6rem;
  height: 6rem;
}
/* .wekit_aligned{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
} */
.double_hover_view.subhover .double_subhover{
  display: none !important;
  }
.double_hover_view.subhover:hover .double_subhover{
display: block !important;
}

@media only screen and (min-width:1400px){

  .home .style_prevu_ki1t:hover {
  max-width: 100% !important;
  min-width: 100% !important;
}
.home .style_prevu_ki1t .trendingban{
  width: 100% !important;
  min-width: 0px !important;
}}
/* LMwallet */

.lmwallet .walletpages {
  min-height: 100vh;
  padding: 15px
}

.lmwallet  .walletpages .walletdrop button,
.lmwallet  .walletpages .walletdrop button:focus {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  color: #000;
  font-size: 12px;
  box-shadow: none;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0.5px solid #707070;

} .lmwallet  .walletpages .walletdrop .dropdown-menu a{
  border-bottom: none !important;
  padding: 4px 23px;
}
.lmwallet  .walletpages .walletdrop.show button{
  background-color: #fff;
  border: .5px solid #707070 !important;
  color: #000;
}
.lmwallet  .walletpages .walletdrop .dropdown-menu{
  border: .5px solid #707070;
}
.lmwallet  .walletpages .walletdrop button:hover{
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border-radius: 10px;
  opacity: 1;
  color: #000 !important;
  font-size: 12px;
}

.lmwallet  .walletpages .walletdrop button:active {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  color: #000;
}

.lmwallet .walletpages .walletdrop .walletdropmenu {
  border: 0.5px solid #707070;
  border-radius: 10px;
}

.walletpages .walletdrop .walletdropmenu a {
  border-bottom: 0px;
}
.walletpages .walletdrop{
  margin-left: 15px;
}


.walletpages .tabsec ul li button {
  color: #767676 !important;
  background: none;
  font-size: 14px;
}

.walletpages .tabsec ul li button.active {
  color: red !important;
  background: none !important;
}

.walletpages .tabsec .tabtopsec {
  border-radius: 12px;
  opacity: 1;
  padding: 20px;
  height: 60px;
  padding-bottom: 10px;
}

.walletpages .tabsec .tabtopsec  {
  background: #141414 0% 0% no-repeat padding-box;
  padding: 20px;
  display: flex;
  align-items: center;
}
.light_theme .walletpages .tabsec .tabtopsec  {
  background: #e9e9e9 0% 0% no-repeat padding-box;
}
.walletpages .tabsec .tabtopsec ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-bottom: 0px;
  padding-left: 15px !important;

  /* position: relative; */
  
}
.walletpages .tabsec .tabtopsec ul li.active{
  color: #E50914;
  position: relative;

}
.walletpages .tabsec .tabtopsec ul li{
  font-size: 14px !important;
  cursor: pointer;
}
.walletpages .tabsec .tabtopsec ul li{
  color: #767676;
}
.walletpages .tabsec .tabtopsec ul .active::before {
  position: absolute;
  content: "";
  border-bottom: 5px solid #E50914;
  height: 0px;
  width: 120%;
  bottom: -15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  left: -5px;

}


.walletpages .tabsec .tabinnersec {
  background: #141414 0% 0% no-repeat padding-box;
  border-radius: 12px;

  margin-top: 20px;
}
.light_theme .walletpages .tabsec .tabinnersec {
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 8px #00000029;
}

.lmwallet  .walletpages  .leftsec .btnsec_add{
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 20px;
  padding-left: 0px;
}
.lmwallet  .walletpages  .leftsec .btnsec_add img{
  width: 35px;
  height: 35px;
}

.wallet_modal_style .modal_btn{
  background: #E50914 0% 0% no-repeat padding-box;
border-radius: 5px;
width: 100px !important;
color: #fff;
border: 1px solid #E50914;
font-size: 12px;
}
.wallet_modal_style .modal-dialog .modal-content{
  background: #1E1E1E 0% 0% no-repeat padding-box;
border-radius: 12px;
}
.wallet_modal_style .modal-header{
 justify-content: center;

}
.wallet_modal_style .modal-title{
  font-size: 14px;
  color: #fff;
  text-align: center;

}
.wallet_modal_style .modal-footer{
  justify-content: center;
}
.wallet_modal_style label{
  text-align: left;
   font-weight: 200 !important;
  letter-spacing: 0px;
  color: #C9C9C9;
  font-size: 11px;
  opacity: 0.51;
}
.wallet_modal_style  input, .wallet_modal_style  input:focus{
  background: #141414 0% 0% no-repeat padding-box;
border-radius: 8px !important;
border-bottom: none !important;
color: #fff !important;
font-size: 12px !important;
font-weight: 400 !important;
background-image: none !important;
}
.wallet_modal_style .modal_close_icon{
  position: absolute;
  right: -5px;
  top: -10px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  z-index: 9;
}
.wallet_modal_style .detail_img_sec{
 width: 100%;
 min-height: 300px;
 max-height: 300px;
 margin-bottom: 20px;

}
.wallet_modal_style .detail_img_sec img{
  width: 100%;
  min-height: 300px;
  max-height: 300px;
  object-fit: cover;
  border-radius: 10px;
 
 }
.wallet_modal_style .modal-dialog .modal-body{
  padding: 20px !important;
}
.lmwallet .walletpages .tabsec .tabinnersec .cy_list{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lmwallet .walletpages .tabsec .tabinnersec  .ulstyle{
  list-style: none;
  margin-bottom: 0px;

  padding: 20px;

} 
.name_row{
  cursor: pointer;
}
.lmwallet .walletpages .tabsec .tabinnersec  .ulstyle li{
  margin-bottom: 20px;
}
.lmwallet .walletpages .tabsec .tabinnersec .ulstyle li:last-child{
  margin-bottom: 0px;
}
.lmwallet .walletpages .tabsec .tabinnersec .cy_list .leftsec .coinimg{
  width: 40px;
  height: 40px;
  border-radius: 50px;
  object-fit: cover;
}
.lmwallet .walletpages .tabsec .tabinnersec .cy_list .leftsec{
  display: flex;
  align-items: center;
  gap: 20px;
}
.lmwallet .walletpages .tabsec .tabinnersec .cy_list .leftsec .name{
  text-align: left;
letter-spacing: 0px;
color: #FFFFFF;
font-size: 12px;
line-height: normal;
}
.light_theme .lmwallet .walletpages .tabsec .tabinnersec .cy_list .leftsec .name{
  color: #000;
}
.lmwallet .walletpages .tabsec .tabinnersec .cy_list .leftsec .value{
  text-align: left;
letter-spacing: 0px;
color: #777777;
font-size: 10px;
line-height: normal;

}
.lmwallet .walletpages .tabsec .tabinnersec .cy_list  .balance{
  text-align: left;
letter-spacing: 0px;
color: #FFFFFF;
font-size: 12px;
line-height: normal;
}
.light_theme .lmwallet .walletpages .tabsec .tabinnersec .cy_list  .balance{
color: #000;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .topsec{
  border-bottom:1px solid grey;
  padding: 20px;
}
.light_theme .lmwallet .walletpages .tabsec .tabinnersec .nftinner .topsec{
  border-bottom:1px solid #eeeeee;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .cardsec{
  padding: 20px;
  padding-bottom: 40px;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .cardsec .scrollrow{
  max-height: 600px;
    overflow: auto;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .cardsec .detail_cardstyle{
  background: #1E2026 0% 0% no-repeat padding-box;
 border-radius: 8px;
 cursor: pointer;
}
.light_theme .lmwallet .walletpages .tabsec .tabinnersec .nftinner .cardsec .detail_cardstyle{
  background: #F2F2F2 0% 0% no-repeat padding-box;
}

.lmwallet .walletpages .tabsec .tabinnersec .nftinner .cardsec .detail_cardstyle .det_img{
  min-height: 400px;
  max-height: 400px;
  width: 100%;
}
.demo_img{
  min-height: 170px !important;
  max-height: 170px !important;
  width: 100% !important;
  text-align: center;
  /* padding-top: 10px; */
  /* margin-bottom: 12px; */
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .cardsec .detail_cardstyle .det_img img{
  min-height: 400px;
  max-height: 400px;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
}
.demoimage{
  min-height: 100px !important;
  max-height: 100px !important;
  width: 50% !important;
  margin-top: 10%;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .cardsec .detail_cardstyle .labelname{
  text-align: center;
  padding: 7px;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .cardsec .detail_cardstyle .labelname p{
  font-size: 11px;
  letter-spacing: 0px;
  color: #FFFFFF;
  line-height: 20px;
}
.light_theme .lmwallet .walletpages .tabsec .tabinnersec .nftinner .cardsec .detail_cardstyle .labelname p{
  color: #000;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .topsec .name_row .fa-star{
  color: #F8B84E;
  font-size: 12px;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .topsec .name_row p{
  color: #fff;
}
.light_theme .lmwallet .walletpages .tabsec .tabinnersec .nftinner .topsec .name_row p{
  color: #000;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .topsec .name_row p img{
width: 12px;
height: 12px;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .bal_row .leftsec{
  display: flex;
  gap: 20px;

}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .bal_row .lab_bal{
  background: #1E1E1E 0% 0% no-repeat padding-box;
 border-radius: 7px;
 color: #C9C9C9;
 font-size: 12px;
 padding: 0px 10px;
}
.light_theme .lmwallet .walletpages .tabsec .tabinnersec .nftinner .bal_row .lab_bal{
  background: #F2F2F2 0% 0% no-repeat padding-box;
  color: #000;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .bal_row .leftsec .bal_val{
  color: #2EC900;
  font-size: 12px;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .bal_row .rightsec{
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: end;
word-wrap: break-word;
}
.light_theme .lmwallet .walletpages .tabsec .tabinnersec .nftinner .bal_row .rightsec img{
  filter:invert(1);
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .bal_row .rightsec .grey_val{
  color: #fff;
  opacity: 0.42;
  font-size: 12px;
  font-weight: 200 !important;
  width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.light_theme .lmwallet .walletpages .tabsec .tabinnersec .nftinner .bal_row .rightsec .grey_val{
  color: #000;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .topsec .name_row{
  display: flex;
  gap: 20px;
  align-items: center;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .topsec .name_row .fa-arrow-left{
  color: #fff;
  cursor: pointer;
}
.light_theme .lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .topsec .name_row .fa-arrow-left{
  color: #000;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .detail_imgsec{
  width: 100%;
  min-height: 550px;
  max-height: 550px;
  /* min-height: 390px;
  max-height: 390px; */
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec{
  padding: 25px;
  padding-bottom: 70px;
}
.noimgc{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .detail_imgsec img{
  width: 100%;
  /* min-height: 390px;
  max-height: 390px; */
  min-height: 550px;
  max-height: 550px;
  /* object-fit: cover; */
  object-fit: contain;
  border-radius: 10px;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .rightside .label_card{
  background: #1E1E1E 0% 0% no-repeat padding-box;
border-radius: 8px;
padding: 15px;
}
.light_theme .lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .rightside .label_card{
  background: #F2F2F2 0% 0% no-repeat padding-box;

}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .rightside .label_card .label{
  color: #C9C9C9;
  /* opacity: 0.51; */
  font-size: 13px;
  font-weight: 200 !important;
  margin-bottom: 0px;
}
.light_theme .lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .rightside .label_card .label{
color: #373737;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .rightside .label_card .value{
  color: #fff;
  font-size: 13px;
  font-weight: 200 !important;
  margin-bottom: 0px;
  word-wrap: break-word;
}
.light_theme .lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .rightside .label_card .value{
  color: #000;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .rightside .lab_head{
  color: #FFFFFF;
  font-size: 15px;
  margin-bottom: 20px;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .rightside .btnsec{
  text-align: left;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .rightside .sendbtn{
  background: #E50914 0% 0% no-repeat padding-box;
border-radius: 5px;
width: 120px;
font-size: 13px;
box-shadow: none;
border: 1px solid #E50914;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .rightside .sendbtn:active{
  background: transparent;
  border: 1px solid #E50914;
}
.lmwallet .walletpages .leftsec .address_sec .addr_card{
  background: #141414 0% 0% no-repeat padding-box;
border: 0.5px solid transparent;
border-radius: 10px;
padding: 10px;
width: 80%;
min-height: 80px;
display: flex;
align-items: center;

}
.light_theme .lmwallet .walletpages .leftsec .address_sec .addr_card img{
  filter: invert(1);

}
.light_theme .lmwallet .walletpages .leftsec .address_sec .addr_card{
  background:#e4e4e4 0% 0% no-repeat padding-box;;
}
.lmwallet .walletpages .leftsec .address_sec .addr_card.active{
border: 1px solid #E50914;

}
.lmwallet .walletpages .leftsec .address_sec .addr_card .address{
color: #fff;
opacity: 0.42;
font-size: 12px;
font-weight: 400 !important;
width: 175px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-align: left;
}
.light_theme .lmwallet .walletpages .leftsec .address_sec .addr_card .address{
  color: #767676;
  opacity: 1;
  word-wrap: break-word;
}
.lmwallet .walletpages .leftsec .address_sec .addr_card .bal{
  color: #2EC900;
  font-size: 14px;
  font-weight: 400 !important;
  text-align: left;
  }
  .lmwallet .walletpages .leftsec .address_sec .accordion-header button{
    background: none !important;
    border: none !important;
    padding: 0px !important;
    width: 100%;
  }
  .home_header.lmwallet header.makeStyles-transparent-14{
    /* background-color: #1e1e1e !important; */

  }
  .lmwallet .walletpages .address_sec .accordion-header i {
    font-size: 12px;
    color: #fff;
  }
  .lmwallet .walletpages .address_sec .accordion-header button[aria-expanded="true"] .fa-angle-up{
    display: block;
  
  }
  .lmwallet .walletpages .address_sec .accordion-header button[aria-expanded="true"] .fa-angle-down{
    display: none;
  
  }
  .lmwallet .walletpages .address_sec .accordion-header button[aria-expanded="false"] .fa-angle-up{
    display: none;
  
  }
  .lmwallet .walletpages .address_sec .accordion-header button[aria-expanded="false"] .fa-angle-down{
    display: block;
  
  }
  .addr_card{
    cursor: pointer;
  }
  .sub_domaincard.height_subdomaincard .blur_effect{
    display: none;
    }
    .sub_domaincard.height_subdomaincard#blur_card .blur_effect{
      position: absolute;
      background: #313131bf;
      user-select: none;
     
      /* -webkit-filter: blur(15px) !important;
      filter: blur(15px) !important; */
      backdrop-filter: blur(11px);
      -webkit-backdrop-filter: blur(11px);
      height: 85%;
      width: 100%;
      top: 0px;
      display: block;
      border-radius: 10px;
    }
    
  @media screen  and (max-width:991px) {
    .lmwallet .walletpages .main.leftsec{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .lmwallet .walletpages .leftsec .address_sec .addr_card{
      width: 100%;
    }
    .lmwallet .walletpages .leftsec .btnsec_add{
      padding-left: 20px;
    }
    .lmwallet .walletpages {
      padding: 0px;
    }
    .lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .detail_imgsec img {
      width: 100%;
      min-height: 250px;
      max-height: 250px;
    }
    .lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .detail_imgsec {
      width: 100%;
      min-height: 250px;
      max-height: 250px;
  }
  .lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .rightside .lab_head {
    text-align: center;
    margin-top: 10px;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .rightside .label_card .label {
  font-size: 11px;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .rightside .label_card .value{
  font-size: 11px;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .rightside .btnsec{
  text-align: center;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .bal_row .rightsec{
  justify-content: left;
}

  }


  @media screen and (min-width:576px) and (max-width:991px) {
    .lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .detail_imgsec {
      width: 190px;
      margin: auto;
  }
  .lmwallet .walletpages .tabsec .tabinnersec .nftinner.detailsec .card_detailsec .detail_imgsec img {
    width: 190px;

  }

#ticketscards .trendingban{
  min-width: 100%;
}
}


.extrachk{
 left: 10px !important;
  }
  
  @media screen and (min-width:992px)  {
    .lmwallet .walletpages .leftsec .address_sec{
      max-height: 300px;
      overflow: auto;
      overflow-x: hidden;
    }
  }

  @media screen and (max-width:991px)  {
    .lmwallet .walletpages .leftsec .address_sec .accordion-body{
      max-height: 300px;
      overflow: auto;
      overflow-x: hidden;
    }
  }

  @media screen and (min-width:1200px) and (max-width:1350px)  {
  .lmwallet .walletpages .leftsec .address_sec .addr_card .address{
    width: 145px;
  }
}

.lmwallet .walletpages .tabsec .tabinnersec .nftinner .topsec .name_row .editsec{
  position: relative;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .topsec .name_row .editinput{
  width: 80%;
  color: #fff !important;
}
.light_theme .lmwallet .walletpages .tabsec .tabinnersec .nftinner .topsec .name_row .editinput{
  width: 80%;
  color: #000 !important;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .topsec .name_row .editsec .changepen{
  position: absolute;
  right: 0px;
  top: 10px;
}
.lmwallet .walletpages .tabsec .tabinnersec .nftinner .topsec .name_row .editsec .editpen{
  position: absolute;
  right: 40px;
  top: 10px;
}

@media screen and (min-width:576px) and (max-width:991px) {
  .flexlist{
    flex-wrap: wrap-reverse !important;
  }
}

@media screen and (min-width:576px) and (max-width:767px) {
.collist .pxside{
  padding-left: 3px;
  padding-right: 3px;
}
}


@media (max-width: 575px) {
  .lmwallet .walletpages .tabsec .tabinnersec .cy_list .leftsec{
    display: unset !important;
  }
}





/* .allfile .btn:hover svg{
  color: #fff !important;
} */